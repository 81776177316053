import { Injectable } from '@angular/core';
import { environment } from '@environments/environment.prod';
import { HttpClient } from '@angular/common/http';
import { IdentityPayload } from '@app/common/payload/identity.payload';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class IdentityService {
    uriEndpoint: string = environment.url;
    identitySubject: BehaviorSubject<IdentityPayload> = new BehaviorSubject<IdentityPayload>({});
    identity$: Observable<IdentityPayload>;

    constructor(private http: HttpClient) {
        this.identity$ = this.identitySubject.asObservable();
    }

    public onCreateIdentity(identityPayload: IdentityPayload) {
        return this.http.post<IdentityPayload>(
            `${this.uriEndpoint}/identity/create`,
            identityPayload,
            { observe: 'response' }
        );
    }

    public onGetAllIdentities() {
        return this.http.get<IdentityPayload[]>(`${this.uriEndpoint}/identity`, {
            observe: 'response',
        });
    }

    public onGetIdentityByUserId(userId: number) {
        return this.http.get<IdentityPayload>(`${this.uriEndpoint}/identity/find/${userId}`, {
            observe: 'response',
        });
    }

    public onUpdateIdentity(identityPayload: IdentityPayload) {
        return this.http.patch(
            `${this.uriEndpoint}/identity/update/${identityPayload.id}`,
            identityPayload,
            { observe: 'response' }
        );
    }
}
