<a class="item-media-w">
    <div class="property-photo-count" *ngIf="property.image.length > 1">
        <span class="badge badge-light fa fa-camera text-primary">
            + {{ property.image.length - 1 }}</span
        >
    </div>
    <div
        [defaultImage]="defaultImage"
        *ngIf="property.image.length && property.image[0].secure_url"
        class="item-media"
        [lazyLoad]="property.image[0].secure_url"
        [useSrcset]="true"
    ></div>
</a>

<div class="item-info">
    <div class="item-features">
        <div class="feature">
            <a
                class="btn btn-sm text-white"
                [ngClass]="{
                    'btn-primary': property.status === 'OPEN INVESTMENT',
                    'btn-danger': property.status === 'CLOSED INVESTMENT'
                }"
            >
                {{ property.status }}
            </a>
        </div>
    </div>
    <div class="item-title">
        <h3 class="item-title">
            <a>
                {{ property.title | truncateText: 20 }}
            </a>
        </h3>

        <p class="text-sm-left property-description">
            {{ property.description | truncateText: 40 }}
        </p>
    </div>

    <div class="item-reviews">
        <div class="reviews-stars">
            <div class="feature">{{ property.category }}</div>
        </div>

        <div class="reviews-count space-l-5">
            <div class="feature">{{ property.type }}</div>
        </div>

        <div class="reviews-count">
            <a
                class="btn btn-sm text-white {{
                    property.returnOfInvestment > 20 ? 'btn-primary' : 'btn-dark'
                }} "
            >
                ROI: {{ property.returnOfInvestment | shortNumber: ' ' }}%
            </a>
        </div>
    </div>

    <div class="row space-top-15">
        <div class="col-6">
            <div class="el-tablo highlight">
                <div class="label">
                    Price: <br />
                    <span class="value text-primary" style="font-size: 2rem;">
                        {{ property.price | shortNumber: '₦' }}</span
                    >
                </div>
            </div>
        </div>
        <div class="col-6">
            <div class="el-tablo highlight">
                <div class="label">
                    Target: <br />
                    <span class="value text-primary" style="font-size: 2rem;">
                        {{ property.target | shortNumber: '₦' }}
                    </span>
                </div>
            </div>
        </div>
    </div>

    <div class="property-progress space-top-15">
        <circle-progress
            [outerStrokeColor]="'#047bf8'"
            [responsive]="true"
            [percent]="onGetPercentage()"
            maxPercent="100"
            titleFontSize="30"
            titleFontWeight="400"
            subtitle="INVESTMENT"
            subtitleFontSize="12"
        ></circle-progress>
        <span class="property-progress-text">Progress:</span>
    </div>

    <ng-container *ngIf="user.isAdmin; else userActions">
        <div class="item-buttons toggled-buttons text-center">
            <a
                class="btn btn-toggled off text-primary"
                title="Update Property"
                [routerLink]="
                    '/app/admin/property-update/' + onEncode(property.title) + '/' + property.uud
                "
                [state]="{ data: property }"
            >
                <i class="os-icon os-icon-edit-1"></i>
            </a>
            <a
                class="btn btn-toggled off text-primary"
                title="Close property"
                [swal]="{
                    title: 'Close this property?',
                    icon: 'warning',
                    showConfirmButton: true,
                    showCancelButton: true,
                    confirmButtonColor: '#DD6B55',
                    confirmButtonText: 'Yes, Close It!'
                }"
                (confirm)="onCloseProperty(property)"
                (cancel)="onCancel($event)"
            >
                <i class="os-icon os-icon-close"></i>
            </a>
            <a
                class="btn btn-toggled off text-danger"
                title="Remove this property"
                [swal]="{
                    title: 'Delete this property? ',
                    icon: 'warning',
                    showConfirmButton: true,
                    showCancelButton: true,
                    confirmButtonColor: '#DD6B55',
                    confirmButtonText: 'Yes, Delete It!'
                }"
                (confirm)="onDeleteProperty(property)"
                (cancel)="onCancel($event)"
            >
                <i class="os-icon os-icon-trash"></i>
            </a>
            <a
                class="btn btn-toggled off text-primary"
                [routerLink]="'/app/property/' + onEncode(property.title) + '/' + property.uud"
                [state]="{ data: property }"
                title="View this property"
            >
                <i class="os-icon os-icon-eye"></i>
            </a>
        </div>
    </ng-container>

    <ng-template #userActions>
        <div class="property-action-buttons">
            <a
                class="btn btn-sm btn-primary text-white property-action-a"
                (click)="onInvestInProperty(property)"
                title="Invest in this property"
            >
                <i class="os-icon os-icon-thumbs-up"></i>
                &nbsp;<strong> Invest Now</strong>
            </a>
            <a
                class="btn btn-sm btn-primary property-view-more property-action-a"
                [routerLink]="'/app/property/' + onEncode(property.title) + '/' + property.uud"
                [state]="{ data: property }"
                title="View this property"
            >
                <i class="os-icon os-icon-book"></i>
                <strong>&nbsp; View More Details</strong>
            </a>
        </div>
    </ng-template>
</div>
