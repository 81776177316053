import { Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { AuthService } from '@app/common/service/auth.service';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { Title } from '@angular/platform-browser';
import { environment } from '@environments/environment.prod';

@Component({
    selector: 'app-password-reset',
    templateUrl: './password-reset.component.html',
    styleUrls: ['./password-reset.component.css'],
})
export class PasswordResetComponent implements OnInit, OnDestroy {
    @ViewChild('feedback') feedback: ElementRef;
    email: string = '';
    isError: boolean = false;
    isSubmitted: boolean = false;
    isSuccess: boolean = false;
    message = '';
    subscription: Subscription = new Subscription();
    showMessage: boolean = false;
    constructor(
        private authService: AuthService,
        private toast: ToastrService,
        private renderer: Renderer2,
        private title: Title
    ) {}

    ngOnInit(): void {
        this.title.setTitle(`Password Reset - ${environment.appName}`);
    }

    onVerifyEmail() {
        const ele = this.feedback.nativeElement;
        this.isSubmitted = true;
        if (this.email.length < 1) {
            this.isError = true;
            this.isSubmitted = false;
            return;
        }

        this.subscription.add(
            this.authService.onVerifyEmailBeforePasswordReset(this.email).subscribe(
                (result) => {
                    if (result.status === 200) {
                        this.isSuccess = true;
                        this.isSubmitted = false;
                        this.isError = false;
                        this.showMessage = true;
                        this.message = ' we have sent a link to reset your password to your inbox';
                    }
                },
                (error) => {
                    this.isSuccess = false;
                    this.isSubmitted = false;
                    this.isError = false;

                    if (error.statusCode === 404) {
                        this.showMessage = true;
                        this.message =
                            'If the email you provided is valid, we have sent a link to reset your password ';
                    }
                    // this.renderer.setStyle(ele, 'display', 'block');
                }
            )
        );
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    onHideMessage() {
        setTimeout(() => {
            this.showMessage = false;
        }, 10000);
    }
}
