<div class="logged-user-menu color-style-bright">
    <!--    <div class="bg-icon"><i class="os-icon os-icon-wallet-loaded"></i></div>-->
    <ul>
        <li>
            <a [routerLink]="profileLink"
                ><i class="os-icon os-icon-user-male-circle2"></i><span>Profile</span></a
            >
        </li>
        <li>
            <a [routerLink]="investmentLink"
                ><i class="os-icon os-icon-coins-4"></i><span>Investments</span></a
            >
        </li>
        <li>
            <a [routerLink]="identityLink"
                ><i class="os-icon os-icon-shield"></i><span>Identity</span></a
            >
        </li>
        <li>
            <a (click)="onSignOut()"
                ><i class="os-icon os-icon-signs-11"></i><span>Sign Out</span></a
            >
        </li>
    </ul>
</div>
