import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from '@app/common/service/auth.service';
import { User } from '@app/common/payload/user';
import { Subscription } from 'rxjs';

import { UploaderService } from '@app/common/service/uploader.service';
import { IdentityStatusEnum, IdentityTypeEnum } from '@app/common/enum/identity-type.enum';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { IdentityPayload } from '@app/common/payload/identity.payload';
import { IdentityService } from '@app/common/service/identity.service';
import { CommandEnum } from '@app/common/enum/command.enum';
import { ImagePayload } from '@app/common/payload/property.payload';

@Component({
    selector: 'app-dashboard-identity',
    templateUrl: './dashboard-identity.component.html',
    styleUrls: ['./dashboard-identity.component.css'],
})
export class DashboardIdentityComponent implements OnInit, OnDestroy {
    user: User;
    subscription: Subscription = new Subscription();
    driversLicence = IdentityTypeEnum.DRIVERS_LICENCE;
    intlPassport = IdentityTypeEnum.INTL_PASSPORT;
    idCard = IdentityTypeEnum.ID_CARD;
    identityPayload: IdentityPayload;
    showUploadTypes: boolean = false;
    photo: { isMouseOver: boolean } = { isMouseOver: false };
    command: CommandEnum = CommandEnum.CREATE;
    rejected: IdentityStatusEnum = IdentityStatusEnum.REJECTED;
    verified: IdentityStatusEnum = IdentityStatusEnum.VERIFIED;
    imageObject: { image: string; thumbImage: string; alt: string }[] = [];

    constructor(
        private authService: AuthService,
        private toast: ToastrService,
        private uploaderService: UploaderService,
        private spinnerService: NgxSpinnerService,
        private identityService: IdentityService
    ) {}

    ngOnInit(): void {
        this.uploaderService.onPrepareUploader({
            folder: 'identity',
            tag: 'identity',
        });
        this.subscription.add(
            this.authService.currentUser.subscribe((data) => {
                this.user = data;
            })
        );

        if (this.user.id) {
            this.subscription.add(
                this.identityService.onGetIdentityByUserId(this.user.id).subscribe(
                    (data) => {
                        if (data.status === 200) {
                            this.identityPayload = data.body;
                            this.onPrepareIdentityImage(data.body?.image);
                            if (data.body.status === IdentityStatusEnum.VERIFIED) {
                                this.showUploadTypes = false;
                            }
                        }
                    },
                    (error) => {
                        this.showUploadTypes = true;
                    }
                )
            );
        }
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    async onOpenUploader(identityTypeEnum: IdentityTypeEnum, command?: CommandEnum) {
        const value = await this.uploaderService.onOpenUploadIdentity(identityTypeEnum, 2);
        if (value) {
            const numberOfFiles = Object.keys(value).length;
            this.toast.info('Uploading...');
            this.uploaderService.onUploadFileArr(value).subscribe((data) => {
                if (data.length && data.length == numberOfFiles) {
                    if (command === CommandEnum.CREATE) {
                        const identity = new IdentityPayload();
                        identity.user = this.user;
                        identity.image = data;
                        identity.type = identityTypeEnum;
                        this.subscription.add(
                            this.identityService.onCreateIdentity(identity).subscribe((result) => {
                                if (result.status === 201) {
                                    this.onPrepareIdentityImage(result.body.image);
                                    this.identityPayload = result.body;
                                    this.showUploadTypes = false;
                                    this.toast.success('Uploaded Successfully.');
                                }
                            })
                        );
                    }

                    if (command === CommandEnum.UPDATE) {
                        this.identityPayload.image = data;
                        this.identityPayload.type = identityTypeEnum;
                        this.identityPayload.user = this.user;

                        this.subscription.add(
                            this.identityService.onUpdateIdentity(this.identityPayload).subscribe(
                                (result) => {
                                    if (result.status === 200) {
                                        this.toast.success('Update Successful.');
                                        this.identityPayload.updatedAt = new Date();
                                        this.showUploadTypes = false;
                                        this.onPrepareIdentityImage(data);

                                        if (
                                            this.identityPayload.status ===
                                            IdentityStatusEnum.REJECTED
                                        ) {
                                            this.identityPayload.message = null;
                                            this.identityPayload.status =
                                                IdentityStatusEnum.PENDING;
                                        }
                                    }
                                },
                                (error) => {
                                    this.toast.error(`Error! ${error.message} `);
                                }
                            )
                        );
                    }
                }
            });
        }
    }

    onUpdateIdentity() {
        this.command = CommandEnum.UPDATE;
        this.showUploadTypes = true;
    }

    onPrepareIdentityImage(images: ImagePayload[]) {
        if (images) {
            this.imageObject = [];
            images.forEach((im) => {
                let imageObj = { image: '', thumbImage: '', alt: '' };
                imageObj.image = im.secure_url;
                imageObj.thumbImage = im.secure_url;
                imageObj.alt = 'Uploaded Identity';
                this.imageObject.push(imageObj);
            });
        }
    }
}
