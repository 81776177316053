import {
    Component,
    ElementRef,
    EventEmitter,
    OnInit,
    Output,
    Renderer2,
    ViewChild,
} from '@angular/core';
import {
    PropertyCategoryEnum,
    PropertyStatusEnum,
    PropertyTypeEnum,
} from '@app/common/enum/property.enums';
import { PropertyFilterPayload } from '@app/common/payload/property-filter.payload';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-dashboard-property-filter',
    templateUrl: './dashboard-property-filter.component.html',
    styleUrls: ['./dashboard-property-filter.component.css'],
})
export class DashboardPropertyFilterComponent implements OnInit {
    @ViewChild('filterTitle') filterTitle: ElementRef;
    @ViewChild('filterStatus') filterStatus: ElementRef;
    @ViewChild('filterType') filterType: ElementRef;
    @ViewChild('filterCategory') filterCategory: ElementRef;
    @ViewChild('filterWrapper') filterWrapper: ElementRef;
    @ViewChild('statusFilterButtons') statusFilterButtons: ElementRef;
    @ViewChild('statusTypeButtons') statusTypeButtons: ElementRef;
    @ViewChild('statusCategory') categoryButtons: ElementRef;

    isFilter = false;
    filterPayload: PropertyFilterPayload;
    @Output() appliedFilter: EventEmitter<PropertyFilterPayload> = new EventEmitter<
        PropertyFilterPayload
    >();

    constructor(private renderer: Renderer2, private toastService: ToastrService) {}

    ngOnInit(): void {
        this.filterPayload = {};
    }

    onToggleTitle() {
        const ele = this.filterTitle.nativeElement.classList;
        ele.contains('collapsed') ? ele.remove('collapsed') : ele.add('collapsed');
    }
    onToggleStatus() {
        const ele = this.filterStatus.nativeElement.classList;
        ele.contains('collapsed') ? ele.remove('collapsed') : ele.add('collapsed');
    }

    onToggleType() {
        const ele = this.filterType.nativeElement.classList;
        ele.contains('collapsed') ? ele.remove('collapsed') : ele.add('collapsed');
    }
    onToggleCategory() {
        const ele = this.filterCategory.nativeElement.classList;
        ele.contains('collapsed') ? ele.remove('collapsed') : ele.add('collapsed');
    }

    onSelectStatusFilter(event: MouseEvent, status?: string) {
        const el = this.statusFilterButtons.nativeElement.childNodes;
        el.forEach((node) => {
            if (event.target === node) {
                node.classList.add('on');
                node.classList.remove('off');
            } else {
                node.classList.remove('on');
                node.classList.add('off');
            }
        });
        if (status === 'OPEN') this.filterPayload.status = PropertyStatusEnum.OPEN;
        else if (status === 'CLOSED') this.filterPayload.status = PropertyStatusEnum.CLOSED;
        else if (status === 'PAST') this.filterPayload.status = PropertyStatusEnum.PAST;
        else if (status === 'SHOW ALL') this.filterPayload.status = PropertyStatusEnum.SHOW_ALL;
        else delete this.filterPayload.status;
    }

    onSelectTypeFilter(event: MouseEvent, type?: string) {
        const el = this.statusTypeButtons.nativeElement.childNodes;
        el.forEach((node) => {
            if (event.target === node) {
                node.classList.add('on');
                node.classList.remove('off');
            } else {
                node.classList.remove('on');
                node.classList.add('off');
            }
        });
        if (type === 'LAND') this.filterPayload.type = PropertyTypeEnum.LAND;
        else if (type === 'HOUSE') this.filterPayload.type = PropertyTypeEnum.HOUSE;
        else if (type === 'APARTMENT') this.filterPayload.type = PropertyTypeEnum.APARTMENT;
        else if (type === 'SHOW ALL') this.filterPayload.type = PropertyTypeEnum.ALL;
        else delete this.filterPayload.type;
    }

    onCategoryFilter(event: MouseEvent, category?: string) {
        const el = this.categoryButtons.nativeElement.childNodes;
        el.forEach((node) => {
            if (event.target === node) {
                node.classList.add('on');
                node.classList.remove('off');
            } else {
                node.classList.remove('on');
                node.classList.add('off');
            }
        });
        if (category === 'RESIDENTIAL')
            this.filterPayload.category = PropertyCategoryEnum.RESIDENTIAL;
        else if (category === 'COMMERCIAL')
            this.filterPayload.category = PropertyCategoryEnum.COMMERCIAL;
        else if (category === 'INDUSTRIAL')
            this.filterPayload.category = PropertyCategoryEnum.INDUSTRIAL;
        else if (category === 'SHOW ALL')
            this.filterPayload.category = PropertyCategoryEnum.SHOW_ALL;
        else delete this.filterPayload.category;
    }

    onResetFilter() {
        this.filterPayload = {};
        this.categoryButtons.nativeElement.childNodes.forEach((node) => {
            node.classList.remove('on');
            node.classList.add('off');
        });
        this.statusTypeButtons.nativeElement.childNodes.forEach((node) => {
            node.classList.remove('on');
            node.classList.add('off');
        });
        this.statusFilterButtons.nativeElement.childNodes.forEach((node) => {
            node.classList.remove('on');
            node.classList.add('off');
        });
        this.appliedFilter.emit({ search: ' ', filterType: 'close' });
    }

    onApplyFilter() {
        if (
            !this.filterPayload.type &&
            !this.filterPayload.status &&
            !this.filterPayload.category &&
            !this.filterPayload.search
        ) {
            this.toastService.error('Enter some filters first');
            return;
        }
        this.appliedFilter.emit(this.filterPayload);
    }
    //wip remove magic strings
}
