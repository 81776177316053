<div
    class="all-wrapper with-side-panel solid-bg-all"
    [ngClass]="{ 'content-panel-active': showRightContent }"
>
    <div class="layout-w">
        <!-- mobile layout -->
        <app-dashboard-mobile-menu [menuBag]="menuBag" [user]="user"></app-dashboard-mobile-menu>
        <!-- desktop layout -->
        <div
            class="menu-w color-scheme-light color-style-transparent menu-position-side menu-side-left menu-layout-compact sub-menu-style-over sub-menu-color-bright selected-menu-color-light menu-activated-on-hover menu-has-selected-link"
        >
            <app-dashboard-logo></app-dashboard-logo>
            <app-dashboard-usercard [user]="user"></app-dashboard-usercard>
            <app-dashboard-menu-sidebar [menuBag]="menuBag"></app-dashboard-menu-sidebar>
        </div>
        <div class="content-w">
            <app-dashboard-content-topbar></app-dashboard-content-topbar>
            <router-outlet></router-outlet>

            <!--            <div class="content-panel" *ngIf="isUserMobile">-->
            <!--                <app-user-dashboard-right></app-user-dashboard-right>-->
            <!--            </div>-->
        </div>
    </div>
    <div class="display-type"></div>
</div>
