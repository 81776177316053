import { Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { ReturnsService } from '@app/common/service/returns.service';
import { BankDetails } from '@app/common/payload/user';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '@app/common/service/auth.service';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { SettingService } from '@app/common/service/setting.service';
import { SettingKeysEnum, EmailSettingPayload } from '@app/common/payload/common.payload';
import { Title } from '@angular/platform-browser';
import { environment } from '@environments/environment.prod';

@Component({
    selector: 'app-dashboard-settings',
    templateUrl: './dashboard-settings.component.html',
    styleUrls: ['./dashboard-settings.component.css'],
    animations: [
        trigger('simpleFadeAnimation', [
            state('in', style({ opacity: 1 })),
            transition(':enter', [style({ opacity: 0 }), animate(600)]),
            transition(':leave', animate(600, style({ opacity: 0 }))),
        ]),
    ],
})
export class DashboardSettingsComponent implements OnInit, OnDestroy {
    @ViewChild('emailControl') emailControl: ElementRef;
    bankDetails: BankDetails;
    isLoading: boolean = false;
    accountDetails: BankDetails[];
    hasAccount: boolean = false;
    subscription: Subscription = new Subscription();
    selectedAccount: BankDetails = {};
    activateEmailNotification = {
        yes: false,
        no: false,
        modules: {
            allowInvestment: false,
            allowIdentity: false,
            allowWithdraw: false,
        },
    };
    isUpdating: boolean = false;

    constructor(
        private authService: AuthService,
        private returnsService: ReturnsService,
        private toast: ToastrService,
        private spinner: NgxSpinnerService,
        private settingService: SettingService,
        private renderer: Renderer2,
        private title: Title
    ) {}

    ngOnInit(): void {
        this.title.setTitle(`Update Settings - ${environment.appName}`);
        if (!this.bankDetails) {
            this.bankDetails = {
                bankName: '',
                accountName: '',
                accountNumber: '',
                userId: this.authService.currentUserValue.id,
            };
        }

        this.subscription.add(
            this.returnsService.onGetAppBankDetails().subscribe((res) => {
                if (res.status === 200) {
                    this.hasAccount = true;
                    this.accountDetails = res.body;
                }
            })
        );

        this.subscription.add(
            this.settingService
                .onGetSettingByKey(SettingKeysEnum.EMAIL_SETTING)
                .subscribe((result) => {
                    if (result.status === 200) {
                        const data = result.body.other;
                        if (data.emailSetting.activateEmail) {
                            this.activateEmailNotification.yes = true;
                            this.activateEmailNotification.modules =
                                data.emailSetting.activateEmailOnModules;
                        }
                    }
                })
        );
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    onAddBankDetails() {
        if (this.accountDetails.length >= 3) {
            this.toast.error(`Maximum account allowed`, 'Error ');
            return;
        }
        this.isLoading = true;
        this.bankDetails.userId = this.authService.currentUserValue.id;

        this.subscription.add(
            this.returnsService.onAdminAddBankDetails(this.bankDetails).subscribe(
                (result) => {
                    if (result.status === 201) {
                        let checker = false;
                        this.accountDetails.forEach((x) => {
                            if (x.id === result.body.id) {
                                checker = true;
                                x.id = result.body.id;
                                x.bankName = result.body.bankName;
                                x.accountName = result.body.accountName;
                                x.accountNumber = result.body.accountNumber;
                            }
                        });
                        if (!checker) {
                            this.accountDetails.push(result.body);
                        }
                        this.isLoading = false;
                        this.toast.success(`Created Successfully`, 'Success');
                    }
                },
                (error) => {
                    this.isLoading = false;
                    this.toast.error(`${error.message}`, 'Error ');
                    this.onEmptyBankDetails();
                }
            )
        );
    }

    onSelectAccount(account: BankDetails) {
        this.selectedAccount = account;
        this.bankDetails.accountNumber = this.selectedAccount.accountNumber;
        this.bankDetails.accountName = this.selectedAccount.accountName;
        this.bankDetails.bankName = this.selectedAccount.bankName;
        this.bankDetails.id = this.selectedAccount.id;
    }

    onEmptyBankDetails() {
        this.bankDetails = {
            accountNumber: '',
            accountName: '',
            bankName: '',
            userId: this.authService.currentUserValue.id,
        };
    }

    onDeleteAccount(account: BankDetails) {
        this.subscription.add(
            this.returnsService.onDeleteAccount(account.id).subscribe(
                (result) => {
                    if (result.status === 200) {
                        this.accountDetails = this.accountDetails.filter(
                            (prop) => prop.id !== account.id
                        );
                    }
                },
                (error) => {
                    this.toast.error(`${error.message}`);
                }
            )
        );
    }
    onCancel($event: Swal.DismissReason) {}

    onActivateAccount(id: number) {
        this.spinner.show().then();
        this.subscription.add(
            this.returnsService.onActivateAccount(id).subscribe(
                (result) => {
                    this.spinner.hide().then();
                    if (result.status === 200) {
                        this.accountDetails.forEach((x) => {
                            x.activated = x.id === id;
                        });
                        this.toast.success('Activated successfully');
                    }
                },
                (error) => {
                    this.spinner.hide().then();
                    this.toast.error(`${error.message}`, 'Error');
                }
            )
        );
    }

    toggleSendNotification(value: string) {
        if (value && value === 'yes') {
            this.activateEmailNotification.no = false;
        }
        if (value && value === 'no') {
            this.activateEmailNotification.yes = false;
            this.activateEmailNotification.modules.allowWithdraw = false;
            this.activateEmailNotification.modules.allowInvestment = false;
            this.activateEmailNotification.modules.allowIdentity = false;
            this.onUpdateEmailSetting();
        }
    }

    onUpdateEmailSetting() {
        this.isUpdating = true;
        const ele = this.emailControl.nativeElement;
        this.renderer.addClass(ele, 'disable-control');
        setTimeout(() => {
            let payload = new EmailSettingPayload();
            payload.emailSetting = {
                activateEmail: this.activateEmailNotification.yes,
            };
            payload.emailSetting.activateEmailOnModules = this.activateEmailNotification.modules;
            this.subscription.add(
                this.settingService
                    .onActivateEmailNotification(SettingKeysEnum.EMAIL_SETTING, payload)
                    .subscribe(
                        (result) => {
                            if (result.status === 200) {
                                this.isUpdating = false;
                                this.renderer.removeClass(ele, 'disable-control');
                                this.toast.success('Successfully updated setting', 'Success', {
                                    newestOnTop: true,
                                });
                            }
                        },
                        (error) => {
                            this.isUpdating = false;
                            this.renderer.removeClass(ele, 'disable-control');
                            this.toast.error(`${error.message}`, 'Failed!');
                        }
                    )
            );
        }, 2000);
    }
}
