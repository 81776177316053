import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { AnimationItem } from 'lottie-web';
import { S1LottieConfig } from '@sentinel-one/s1-lottie';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService } from '@app/common/service/auth.service';

@Component({
    selector: 'app-verification-success',
    templateUrl: './verification-success.component.html',
    styleUrls: ['./verification-success.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VerificationSuccessComponent implements OnInit, OnDestroy {
    lottieParams: S1LottieConfig = {
        autoplay: false,
        path: 'assets/shared/graphs/verify-email.json',
        renderer: 'canvas',
        loop: true,
        rendererSettings: {},
    };
    subscription: Subscription = new Subscription();
    token: string;
    email: string;
    valid: boolean = false;

    onAnimationCreated(animation: AnimationItem) {
        animation.play();
        animation.setSpeed(0.5);
    }

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private authService: AuthService,
        private ref: ChangeDetectorRef
    ) {}

    ngOnInit(): void {
        this.subscription.add(
            this.route.params.subscribe((params) => {
                this.token = params['token'];
                this.email = params['email'];
            })
        );

        if (this.token && this.email) {
            this.subscription.add(
                this.authService.onValidateEmailToken(this.token, this.email).subscribe(
                    (result) => {
                        if (result.status === 201) {
                            this.valid = true;
                            this.lottieParams = {
                                ...this.lottieParams,
                                path: 'assets/shared/graphs/success.json',
                            };
                            this.ref.markForCheck();
                        }
                    },
                    (error) => {
                        this.router.navigateByUrl('').then();
                    }
                )
            );
        }
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    animationCreated(animationItem: AnimationItem): void {}
}
