<div class="property-single bg-plain">
    <div class="property-info-w">
        <div class="property-info-head">
            <div class="row">
                <div class="order-box col-lg-8 col-md-8 col-sm-12">
                    <div
                        [@slideInRightOnEnter]
                        [@fadeOutDownOnLeave]
                        class="slide"
                        *ngIf="slide.firstSlide"
                    >
                        <div class="onboarding-slide">
                            <div class="onboarding-media text-center">
                                <img
                                    alt=""
                                    src="/assets/dashboard-assets/image/bigicon6.png"
                                    width="200px"
                                />
                            </div>
                            <div class="onboarding-content with-gradient pad-20">
                                <h4 class="onboarding-title">
                                    Follow these steps to invest
                                </h4>
                                <div class="">
                                    <i class="fa fa-check space-r-2 text-primary"></i> Enter your
                                    investment amount <br />
                                    <i class="fa fa-check space-r-2 text-primary"></i> Make Payment
                                    <br />
                                    <i class="fa fa-check space-r-2 text-primary"></i> Upload Proof
                                    of Payment
                                </div>
                                <form>
                                    <div class="row space-top-25">
                                        <div
                                            class="col-sm-6"
                                            [ngClass]="{
                                                'col-sm-12': !canUserPayFromEarning.value,
                                                'col-sm-6': canUserPayFromEarning.value
                                            }"
                                        >
                                            <div class="form-group">
                                                <label>Your investment amount </label
                                                ><input
                                                    class="form-control"
                                                    placeholder="Enter your amount..."
                                                    [(ngModel)]="formInput.amount"
                                                    name="amount"
                                                    type="number"
                                                    [ngClass]="{ 'f-invalid': formInput.hasError }"
                                                    (focus)="formInput.hasError = false"
                                                />
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <ng-container
                                                *ngIf="
                                                    !canUserPayFromEarning.isLoading;
                                                    else loading
                                                "
                                            >
                                                <div
                                                    class="form-group"
                                                    *ngIf="canUserPayFromEarning.value"
                                                >
                                                    <label
                                                        >Payment Type
                                                        <i
                                                            ngbTooltip="If you would like to invest from the earning of your previous investment, then choose pay from earning"
                                                            class="text-primary fa fa-info-circle"
                                                        ></i
                                                    ></label>
                                                    <select
                                                        class="form-control"
                                                        [(ngModel)]="formInput.paymentOption"
                                                        name="paymentOption"
                                                    >
                                                        <option value="BANK TRANSFER"
                                                            >Bank Transfer</option
                                                        >
                                                        <option value="PAY FROM EARNING"
                                                            >Pay from Earning</option
                                                        >
                                                    </select>
                                                </div>
                                            </ng-container>
                                        </div>
                                        <div class="col-md-12">
                                            <code
                                                >The minimum amount you can enter is
                                                {{ property?.minAmount | shortNumber }}</code
                                            >

                                            <code class="space-l-5"
                                                >and the maximum is
                                                {{ property?.maxAmount | shortNumber }}</code
                                            >
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                    <div
                        [@slideInRightOnEnter]
                        [@fadeOutDownOnLeave]
                        class="slide"
                        *ngIf="slide.secondSlide"
                    >
                        <div class="onboarding-slide">
                            <div class="onboarding-media text-center">
                                <img
                                    alt=""
                                    src="/assets/dashboard-assets/image/bigicon2.png"
                                    width="200px"
                                />
                            </div>
                            <div class="onboarding-content with-gradient">
                                <h4 class="onboarding-title text-center">
                                    Make Payment
                                </h4>
                                <p class="text-center text-muted">
                                    <span class="text-primary">
                                        Make payment to the bank details provided below, and ensure
                                        to take your proof of payment
                                    </span>
                                </p>
                                <div class="bank-details space-top-15 text-uppercase pad-l-20">
                                    <ng-container *ngIf="bankDetails; else noBankDetails">
                                        <p>
                                            Bank Name: <span>{{ bankDetails?.bankName }}</span>
                                        </p>
                                        <p class="space-top-10">
                                            Account Name:
                                            <span>{{ bankDetails?.accountName }} </span>
                                        </p>
                                        <p class="space-top-10">
                                            Account Number:
                                            <span>{{ bankDetails?.accountNumber }}</span>
                                        </p>
                                    </ng-container>
                                    <ng-template #noBankDetails>
                                        <p class="space-top-10">
                                            Contact support for bank details
                                        </p>
                                    </ng-template>
                                    <p class="space-top-10">
                                        Transaction Reference:
                                        <span *ngIf="referenceNumber"> {{ referenceNumber }} </span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div
                        [@slideInRightOnEnter]
                        [@fadeOutDownOnLeave]
                        class="slide"
                        *ngIf="slide.thirdSlide"
                    >
                        <div class="onboarding-slide">
                            <div class="onboarding-content with-gradient">
                                <h4 class="onboarding-title text-center">
                                    Upload Proof of Payment
                                </h4>
                                <app-dashboard-photo-upload
                                    [props]="{ folder: '', tag: '' }"
                                ></app-dashboard-photo-upload>
                            </div>
                        </div>
                    </div>

                    <div class="text-right space-top-10">
                        <button
                            class="btn btn-primary btn-sm space-top-10 slick-button"
                            (click)="onNextSlide()"
                        >
                            <span *ngIf="!slide.isProcessing">
                                {{ slide.buttonText }}
                                <i class="fa fa-arrow-right space-l-5"></i>
                            </span>
                            <span *ngIf="slide.isProcessing">
                                <i class="fa fa-spin fa-spinner text-primary"></i>
                            </span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ng-template #loading>
    <div class="text-center" style="padding: 13px;">
        <i class="fa fa-spinner fa-spin"></i>
    </div>
</ng-template>
