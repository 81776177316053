import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from '@app/common/payload/user';
import { Subscription } from 'rxjs';
import { UserService } from '@app/common/service/user.service';

@Component({
    selector: 'app-dashboard-view-users-profile',
    templateUrl: './dashboard-view-users-profile.component.html',
    styleUrls: ['./dashboard-view-users-profile.component.css'],
})
export class DashboardViewUsersProfileComponent implements OnInit, OnDestroy {
    routeState: any;
    user: User;
    subscription: Subscription = new Subscription();
    id: number;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private userService: UserService
    ) {
        if (this.router.getCurrentNavigation()?.extras?.state) {
            this.routeState = this.router.getCurrentNavigation().extras?.state;
            if (this.routeState) {
                //'Retrieving From Route...;
                this.user = this.routeState.data;
            }
        }
    }

    ngOnInit(): void {
        this.subscription.add(
            this.route.params.subscribe((params) => {
                this.id = params['userId'];
            })
        );

        if (!this.user) {
            this.subscription.add(
                this.userService.onGetUserById(this.id).subscribe((result) => {
                    if (result.status === 200) {
                        this.user = result.body;
                    }
                })
            );
        }
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}
