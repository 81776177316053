<ngx-spinner [fullScreen]="true" [size]="'small'" type="ball-spin"></ngx-spinner>
<div class="rentals-list-w" *ngIf="items && !isLoading; else loaderTemplate">
    <div class="rentals-list">
        <!--      Property Count and Order Toggle-->
        <div class="list-controls">
            <div class="list-info">
                {{ items?.length }} {{ items?.length > 1 ? 'Properties' : 'Property' }} Found.
            </div>
            <div class="list-order">
                <label>Use Filter:</label>
                <button class="btn-primary" (click)="onToggleFilterWrapper()">
                    {{ isFilter ? 'Close Filter' : 'Open Filter' }}
                </button>
            </div>
            <div class="filter-div" #filterWrapper>
                <app-dashboard-property-filter
                    (appliedFilter)="onApplyFilter($event)"
                ></app-dashboard-property-filter>
            </div>
        </div>

        <!--Pagination -->
        <div class="pagination-w space-b-25">
            <div class="pagination-info">
                <div class="pagination-links">
                    <jw-pagination
                        class="point"
                        [pageSize]="pageSize"
                        [items]="items"
                        (changePage)="onChangePage($event)"
                    ></jw-pagination>
                </div>
            </div>
        </div>

        <!-- Property Card-->

        <div class="property-items as-grid">
            <div
                class="property-item"
                *ngFor="let property of pageOfItems"
                [@simpleFadeAnimation]="'in'"
            >
                <app-dashboard-property-card [property]="property"></app-dashboard-property-card>
            </div>
        </div>

        <!-- No property found yet-->
        <div class="no-record" *ngIf="!isLoading && items.length < 1">
            <img
                src="/assets/shared/graphs/no-record.gif"
                height="200"
                width="200"
                alt="No record found"
            />
            <p>
                <code><small>No Property Found!</small></code>
            </p>
        </div>

        <!-- Pagination Control-->
        <div class="pagination-w space-b-25">
            <div class="pagination-info">
                <div class="pagination-links">
                    <jw-pagination
                        class="point"
                        [pageSize]="pageSize"
                        [items]="items"
                        (changePage)="onChangePage($event)"
                    ></jw-pagination>
                </div>
            </div>
        </div>
    </div>
</div>
<ng-template #loaderTemplate>
    <div class="text-center centered"><i class="fa fa-spinner fa-spin fa-2x"></i> <br /></div>
</ng-template>
