export enum InvestmentStatus {
    OPEN = 'OPEN',
    CLOSED = 'CLOSED',
    COMPLETED = 'COMPLETED',
}

export enum InvestmentPaymentOptions {
    BANK_TRANSFER = 'BANK TRANSFER',
    ONLINE_PAYMENT = 'ONLINE PAYMENT',
    DIRECT_TRANSFER = 'DIRECT TRANSFER',
    PAY_FROM_EARNING = 'PAY FROM EARNING',
}
