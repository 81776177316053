<div class="filter-side">
    <div class="filters-header">
        <h4>Filter</h4>
        <div class="reset-filters">
            <div class="toggled-buttons">
                <a class="btn btn-toggled off text-primary" (click)="onApplyFilter()">
                    <i class="os-icon-check-circle os-icon"></i> Apply</a
                >
                <a class="btn btn-toggled off text-danger" (click)="onResetFilter()">
                    <i class="os-icon-refresh-ccw os-icon"></i> Reset</a
                >
            </div>
        </div>
    </div>
    <div #filterTitle class="filter-w point collapsed">
        <div class="filter-toggle" (click)="onToggleTitle()">
            <i class="os-icon-minus os-icon"></i>
        </div>
        <h6 class="filter-header">Title/Description</h6>
        <div class="filter-body">
            <input
                type="text"
                placeholder="Search by title or description"
                class="form-control"
                style="width: 200px;"
                [(ngModel)]="filterPayload.search"
            />
        </div>
    </div>
    <div #filterStatus class="filter-w collapsed">
        <div class="filter-toggle" (click)="onToggleStatus()">
            <i class="os-icon-minus os-icon"></i>
        </div>
        <h6 class="filter-header">Listing Status</h6>
        <div class="filter-body">
            <div class="toggled-buttons" #statusFilterButtons>
                <a class="btn btn-toggled off" (click)="onSelectStatusFilter($event, 'SHOW ALL')"
                    >Show All</a
                >
                <a class="btn btn-toggled off" (click)="onSelectStatusFilter($event, 'OPEN')"
                    >Open</a
                >
                <br />
                <a class="btn btn-toggled off" (click)="onSelectStatusFilter($event, 'CLOSED')"
                    >Closed</a
                >
                <a class="btn btn-toggled off" (click)="onSelectStatusFilter($event, 'PAST')"
                    >Past</a
                >
            </div>
        </div>
    </div>
    <div #filterType class="filter-w collapsed">
        <div class="filter-toggle" (click)="onToggleType()">
            <i class="os-icon-minus os-icon"></i>
        </div>
        <h6 class="filter-header">Property Type</h6>
        <div class="filter-body">
            <div class="toggled-buttons solid" #statusTypeButtons>
                <a class="btn btn-toggled off" (click)="onSelectTypeFilter($event, 'SHOW ALL')"
                    >Show All</a
                ><a class="btn btn-toggled off" (click)="onSelectTypeFilter($event, 'APARTMENT')"
                    >Apartment</a
                >
                <br />
                <a class="btn btn-toggled off" (click)="onSelectTypeFilter($event, 'HOUSE')"
                    >House</a
                ><a class="btn btn-toggled off" (click)="onSelectTypeFilter($event, 'LAND')"
                    >Land</a
                >
            </div>
        </div>
    </div>
    <div #filterCategory class="filter-w collapsed">
        <div class="filter-toggle" (click)="onToggleCategory()">
            <i class="os-icon-minus os-icon"></i>
        </div>
        <h6 class="filter-header">Property Category</h6>
        <div class="filter-body">
            <div class="toggled-buttons solid" #statusCategory>
                <a class="btn btn-toggled off" (click)="onCategoryFilter($event, 'SHOW ALL')"
                    >Show All</a
                ><a class="btn btn-toggled off" (click)="onCategoryFilter($event, 'RESIDENTIAL')"
                    >Residential</a
                >
                <br />
                <a class="btn btn-toggled off" (click)="onCategoryFilter($event, 'COMMERCIAL')"
                    >Commercial</a
                ><a class="btn btn-toggled off" (click)="onCategoryFilter($event, 'INDUSTRIAL')"
                    >Industrial</a
                >
            </div>
        </div>
    </div>
</div>
