import { Injectable } from '@angular/core';
import { environment } from '@environments/environment.prod';
import { HttpClient } from '@angular/common/http';
import { EmailSettingPayload } from '@app/common/payload/common.payload';

@Injectable({
    providedIn: 'root',
})
export class SettingService {
    uriEndpoint: string = environment.url;

    constructor(private http: HttpClient) {}

    onActivateEmailNotification(key: string, payload: EmailSettingPayload) {
        return this.http.put<{ key: string; value: string; other: EmailSettingPayload }>(
            `${this.uriEndpoint}/setting/update/${key}`,
            payload,
            {
                observe: 'response',
            }
        );
    }

    onGetSettingByKey(key: string) {
        return this.http.get<{ key: string; value: string; other: EmailSettingPayload }>(
            `${this.uriEndpoint}/setting/key/${key}`,
            {
                observe: 'response',
            }
        );
    }
}
