import { Component, OnInit } from '@angular/core';
import { ICrumbs } from '@app/common/payload/common.payload';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { environment } from '@environments/environment.prod';
import { CommandEnum } from '@app/common/enum/command.enum';

@Component({
    selector: 'app-dashboard-admin-property-update',
    templateUrl: './dashboard-admin-property-update.component.html',
    styleUrls: ['./dashboard-admin-property-update.component.css'],
})
export class DashboardAdminPropertyUpdateComponent implements OnInit {
    command: CommandEnum = CommandEnum.UPDATE;

    crumbs: ICrumbs[] = [
        { text: 'App', link: `/app` },
        {
            text: 'Dashboard',
        },
        {
            text: 'Update Property',
            link: '/app/admin/property-update/',
        },
    ];

    constructor(private titleService: Title, private router: Router) {}

    ngOnInit(): void {
        this.titleService.setTitle(`Update Property - ${environment.appName}`);
    }
}
