<ngx-spinner [fullScreen]="true" [size]="'small'" type="ball-spin"></ngx-spinner>

<div class="content-i">
    <div class="content-box">
        <div class="row">
            <div class="col-sm-8">
                <div class="element-wrapper compact pt-4">
                    <h6 class="element-header">App Settings</h6>
                    <div class="element-box-tp">
                        <div class="inline-profile-tiles">
                            <div class="row">
                                <ng-container *ngIf="hasAccount; else loading">
                                    <ng-container *ngIf="accountDetails.length">
                                        <div
                                            *ngFor="let account of accountDetails"
                                            class="col-4 col-sm-3 col-xxl-2"
                                            [@simpleFadeAnimation]="'in'"
                                        >
                                            <div class="profile-tile profile-tile-line">
                                                <a class="profile-tile-box">
                                                    <div class="pt-avatar-w">
                                                        <i
                                                            class="fa fa-bank fa-2x text-primary"
                                                        ></i>
                                                        <div
                                                            class="status-pill green"
                                                            [ngClass]="{
                                                                green: account.activated,
                                                                red: !account.activated
                                                            }"
                                                        ></div>
                                                    </div>
                                                    <div class="pt-user-name">
                                                        {{ account.bankName }}<br />
                                                        ACCOUNT
                                                    </div>

                                                    <div
                                                        ngbDropdown
                                                        class="profile-tile-box"
                                                        placement="top-left"
                                                        container="body"
                                                    >
                                                        <a
                                                            class="btn btn-outline-primary"
                                                            id="dropdownBasic1"
                                                            ngbDropdownToggle
                                                        >
                                                            <i class="os-icon os-icon-edit-1"></i>
                                                        </a>
                                                        <div
                                                            ngbDropdownMenu
                                                            aria-labelledby="dropdownBasic1"
                                                        >
                                                            <button
                                                                [disabled]="account.activated"
                                                                ngbDropdownItem
                                                                [swal]="{
                                                                    title: 'Activate Account? ',
                                                                    html:
                                                                        'Do you want to set this account as active account',
                                                                    icon: 'warning',
                                                                    showConfirmButton: true,
                                                                    showCancelButton: true,
                                                                    confirmButtonColor: '#22a007',
                                                                    confirmButtonText:
                                                                        'Yes, Activate It!'
                                                                }"
                                                                (confirm)="
                                                                    onActivateAccount(account.id)
                                                                "
                                                                (cancel)="onCancel($event)"
                                                            >
                                                                Activate
                                                            </button>
                                                            <button
                                                                ngbDropdownItem
                                                                (click)="onSelectAccount(account)"
                                                            >
                                                                Edit
                                                            </button>
                                                            <button
                                                                ngbDropdownItem
                                                                [swal]="{
                                                                    title: 'Delete this Account? ',
                                                                    icon: 'warning',
                                                                    showConfirmButton: true,
                                                                    showCancelButton: true,
                                                                    confirmButtonColor: '#DD6B55',
                                                                    confirmButtonText:
                                                                        'Yes, Delete It!'
                                                                }"
                                                                (confirm)="onDeleteAccount(account)"
                                                                (cancel)="onCancel($event)"
                                                            >
                                                                Delete
                                                            </button>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                    </ng-container>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-8">
                <div class="order-box">
                    <form
                        name="form"
                        (ngSubmit)="f.form.valid && onAddBankDetails()"
                        #f="ngForm"
                        novalidate
                    >
                        <h5 class="alert-heading text-uppercase">Bank Account Details</h5>

                        <div class="form-group">
                            <label>Bank Name</label>
                            <input
                                type="text"
                                class="form-control"
                                placeholder="Enter Bank Name"
                                [(ngModel)]="bankDetails.bankName"
                                #bankName="ngModel"
                                name="bankName"
                                [ngClass]="{ 'f-invalid': f.submitted && bankName.invalid }"
                                required
                            />
                        </div>
                        <div class="form-group">
                            <label>Account Name</label>
                            <input
                                type="text"
                                class="form-control"
                                placeholder="Enter Account Name"
                                [(ngModel)]="bankDetails.accountName"
                                #accountName="ngModel"
                                name="accountName"
                                [ngClass]="{ 'f-invalid': f.submitted && accountName.invalid }"
                                required
                            />
                        </div>
                        <div class="form-group">
                            <label>Account Number</label>
                            <input
                                type="text"
                                class="form-control"
                                placeholder="Enter Account Number"
                                [(ngModel)]="bankDetails.accountNumber"
                                #accountNumber="ngModel"
                                name="accountNumber"
                                [ngClass]="{ 'f-invalid': f.submitted && accountNumber.invalid }"
                                required
                            />
                        </div>
                        <div class="alert-btn">
                            <button class="btn btn-primary text-white" [disabled]="isLoading">
                                save &nbsp;
                                <span *ngIf="!isLoading"><i class="os-icon os-icon-save"></i></span>
                                <span *ngIf="isLoading">
                                    <i class="fa fa-circle-o-notch fa-spin fa-1x fa-fw"></i>
                                </span>
                            </button>

                            <a
                                class="btn btn-primary space-l-20 text-white"
                                (click)="onEmptyBankDetails()"
                            >
                                clear
                                <span><i class="os-icon os-icon-trash-2"></i></span>
                            </a>
                        </div>
                    </form>
                </div>
            </div>

            <div class="col-md-8 space-top-25">
                <div class="order-box" #emailControl>
                    <h5 class="alert-heading text-uppercase space-b-15">
                        Email Notification
                        <span *ngIf="isUpdating"> <i class="fa fa-spinner fa-spin"></i> </span>
                    </h5>

                    <label>Activate Email Notification: </label>
                    <div class="btn-group btn-group-toggle toggle-notification">
                        <label
                            (click)="toggleSendNotification('yes')"
                            class="btn-dark"
                            [ngClass]="{
                                'btn-primary': activateEmailNotification.yes,
                                'btn-dark': !activateEmailNotification.yes
                            }"
                            ngbButtonLabel
                        >
                            <input
                                type="checkbox"
                                ngbButton
                                [(ngModel)]="activateEmailNotification.yes"
                            />
                            Yes
                        </label>
                        <label
                            (click)="toggleSendNotification('no')"
                            class="btn-dark"
                            [ngClass]="{
                                'btn-primary': activateEmailNotification.no,
                                'btn-dark': !activateEmailNotification.no
                            }"
                            ngbButtonLabel
                        >
                            <input
                                type="checkbox"
                                ngbButton
                                [(ngModel)]="activateEmailNotification.no"
                            />
                            No
                        </label>
                    </div>

                    <div
                        class="form-check"
                        *ngIf="activateEmailNotification.yes"
                        [@simpleFadeAnimation]="'in'"
                    >
                        <label class="point form-check-label" (click)="onUpdateEmailSetting()">
                            <input
                                type="checkbox"
                                name="allowIdentity"
                                [(ngModel)]="activateEmailNotification.modules.allowIdentity"
                                class="stick-right" />
                            <span class="space-l-5">
                                Turn on Email For
                                <strong
                                    >Identity
                                    <i
                                        class="fa fa-info-circle"
                                        ngbTooltip="Send email to users when their identity is verified or rejected"
                                        placement="auto"
                                        container="body"
                                    ></i>
                                </strong> </span
                        ></label>
                        <br />
                        <label class="point form-check-label" (click)="onUpdateEmailSetting()">
                            <input
                                type="checkbox"
                                name="allowInvestment"
                                [(ngModel)]="activateEmailNotification.modules.allowInvestment" />
                            <span class="space-l-5"
                                >Turn on Email For
                                <strong
                                    >Investment
                                    <i
                                        class="fa fa-info-circle"
                                        ngbTooltip="Send email to users when their investment is approved"
                                        placement="auto"
                                        container="body"
                                    ></i>
                                </strong> </span
                        ></label>
                        <br />
                        <label class="point form-check-label" (click)="onUpdateEmailSetting()">
                            <input
                                type="checkbox"
                                name="allowEarning"
                                [(ngModel)]="activateEmailNotification.modules.allowWithdraw" />
                            <span class="space-l-5"
                                >Turn on Email For
                                <strong
                                    >Withdraw
                                    <i
                                        class="fa fa-info-circle"
                                        ngbTooltip="Send email to users when their withdraw request is processed "
                                        placement="auto"
                                        container="body"
                                    ></i>
                                </strong> </span
                        ></label>
                    </div>
                    <hr />
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #loading>
    <div class="text-center" style="padding: 13px;">
        <i class="fa fa-spinner fa-spin"></i>
    </div>
</ng-template>
