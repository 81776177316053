import { Component, OnDestroy, OnInit } from '@angular/core';
import { MenuService } from '@app/common/service/menu.service';
import { DashboardService } from '@app/common/service/dashboard.service';
import { CoInvestors } from '@app/common/payload/dashboard-stats-card';
import { Subscription } from 'rxjs';
import { AuthService } from '@app/common/service/auth.service';
import { User } from '@app/common/payload/user';

@Component({
    selector: 'app-user-dashboard-right',
    templateUrl: './user-dashboard-right.component.html',
    styleUrls: ['./user-dashboard-right.component.css'],
})
export class UserDashboardRightComponent implements OnInit, OnDestroy {
    // coInvestors: CoInvestors[];
    // sub: Subscription = new Subscription();
    // user: User;
    constructor(
        private menuService: MenuService,
        private dashboardService: DashboardService,
        private authService: AuthService
    ) {}

    ngOnInit(): void {
        // this.sub.add(
        //     this.authService.currentUser.subscribe((data) => {
        //         this.user = data;
        //     })
        // );
        // this.sub.add(
        //     this.dashboardService.onGetCoInvestorsData(this.user.id).subscribe((result) => {
        //         if (result.status === 200) {
        //             this.coInvestors = result.body;
        //         }
        //     })
        // );
    }

    closeRightContent() {
        this.menuService.rightContent.next(false);
    }

    ngOnDestroy() {
        // this.sub.unsubscribe();
    }
}
