export const environment = {
    production: true,
    url: 'https://api.propertycollabo.com/api',
    appName: 'Property Collabo',
    cloud_name: 'dxvkhfpbn',
    upload_preset: 'shp1nxdg',
    gr_site_key: '6LdDVeEZAAAAADOLuVJATalevK2LVcEwXLf1N9nd',
    gr_secret_key: '6LdDVeEZAAAAAP1JLxWZc_CYXhC-5EAyfaS7B2ps',
    logo_public_id: 'property-collabo/defaults/logo_c5cpbc',
    site_url: 'https://propertycollabo.com',
};
