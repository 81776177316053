<ngx-spinner [fullScreen]="true" [size]="'small'" type="ball-spin"></ngx-spinner>
<div class="content-i">
    <div class="content-box">
        <div class="support-index" #identityCard>
            <div class="support-tickets">
                <div class="support-tickets-header">
                    <div class="tickets-control">
                        <h5>Identities</h5>
                        <div class="element-search">
                            <label for="search"></label>
                            <input id="search" placeholder="Type to filter identities..." />
                        </div>
                    </div>
                    <div class="tickets-filter">
                        <div class="form-group mr-3">
                            <label for="filter" class="d-none d-md-inline-block mr-2">Filter</label>
                            <select
                                id="filter"
                                class="form-control-sm"
                                (change)="onFilterIdentities($event)"
                            >
                                <option value="ALL" selected>ALL</option>
                                <option value="UNAPPROVED">UNAPPROVED</option>
                            </select>
                        </div>
                    </div>
                </div>

                <!-- if route has userId show identity for the user -->
                <ng-container *ngIf="user">
                    <label class="space-b-15 text-primary">You are viewing identity for:</label>
                    <div class="support-ticket point">
                        <div class="st-body">
                            <div class="picture">
                                <ng-container *ngIf="user?.image?.public_id">
                                    <cl-image [public-id]="user?.image?.public_id">
                                        <cl-transformation
                                            width="400"
                                            height="400"
                                            gravity="face"
                                            radius="max"
                                            crop="crop"
                                        >
                                        </cl-transformation>
                                        <cl-transformation width="400" crop="scale">
                                        </cl-transformation>
                                    </cl-image>
                                </ng-container>
                            </div>
                            <div class="ticket-content">
                                <p class="txt-sm">
                                    <span class="text-black-50"> Full Name: </span>
                                    <span class="text-primary">
                                        {{ user?.firstName }} {{ user?.lastName }}
                                    </span>
                                </p>

                                <p class="txt-sm">
                                    <span class="text-black-50"> Email: </span>
                                    <span class="text-primary">
                                        {{ user?.email }}
                                    </span>
                                </p>
                                <p class="txt-sm">
                                    <span class="text-black-50"> Total Investments: </span>
                                    <span class="text-primary">
                                        {{ user?.investments.length }}
                                    </span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="support-ticket" (click)="onSelectIdentity(identity)">
                        <div class="st-meta">
                            <div
                                class="status-pill"
                                [ngClass]="{
                                    yellow: identity?.status === pendingStatus,
                                    green: identity?.status === verifiedStatus,
                                    red: identity?.status === rejectedStatus
                                }"
                            ></div>
                            <div class="status-pill red"></div>
                        </div>
                        <div class="st-body space-top-10">
                            <div class="ticket-content">
                                <h6 class="ticket-title">{{ identity?.type }}</h6>
                                <br />
                                <cl-image
                                    *ngIf="identity?.image?.length"
                                    style="border-radius: 0;"
                                    class="identity-image"
                                    [public-id]="(identity?.image)[0]?.public_id"
                                >
                                    <cl-transformation width="0.2" crop="fit"> </cl-transformation>
                                </cl-image>
                                <br />
                                <small class="ticket-title">
                                    {{ identity?.createdAt | date }}
                                </small>
                            </div>
                        </div>
                    </div>
                </ng-container>

                <!--  if no userId is found in route get all identities -->
                <ng-container *ngIf="!user">
                    <ng-container *ngIf="identities; else loading">
                        <div
                            class="support-ticket"
                            *ngFor="let identity of pageOfItems; let i = index"
                            [id]="'menu-' + i"
                            [@simpleFadeAnimation]="'in'"
                            #myIdentities
                            (click)="onSelectIdentity(identity, i)"
                        >
                            <div class="st-meta">
                                <div
                                    class="status-pill"
                                    [ngClass]="{
                                        yellow: identity?.status === pendingStatus,
                                        green: identity?.status === verifiedStatus,
                                        red: identity?.status === rejectedStatus
                                    }"
                                ></div>
                            </div>
                            <div class="st-body space-top-10">
                                <div class="avatar" *ngIf="identity.image">
                                    <cl-image
                                        style="border-radius: 0;"
                                        class="identity-image"
                                        [public-id]="(identity?.image)[0]?.public_id"
                                    >
                                    </cl-image>
                                </div>
                                <div class="ticket-content">
                                    <h6 class="ticket-title">{{ identity.type }}</h6>
                                    <small class="ticket-title">{{ identity.user?.email }}</small>
                                    <br />
                                    <small class="ticket-title">
                                        {{ identity.createdAt | date }}
                                    </small>
                                </div>
                            </div>
                            <div class="st-foot">
                                <span class="label">Investor:</span
                                ><a class="value with-avatar">
                                    <ng-container *ngIf="identity?.user?.image?.public_id">
                                        <cl-image [public-id]="identity?.user?.image?.public_id">
                                            <cl-transformation
                                                width="400"
                                                height="400"
                                                gravity="face"
                                                radius="max"
                                                crop="crop"
                                            >
                                            </cl-transformation>
                                            <cl-transformation width="200" crop="scale">
                                            </cl-transformation>
                                        </cl-image>
                                    </ng-container>
                                    <span>{{ identity.user?.firstName }}</span></a
                                ><span class="label">Updated:</span>
                                <span class="value">{{ identity.updatedAt | date }}</span>
                            </div>
                        </div>
                        <div class="load-more-tickets">
                            <div class="pagination-w space-b-25">
                                <div class="pagination-info">
                                    <div class="pagination-links">
                                        <jw-pagination
                                            class="point"
                                            [pageSize]="pageSize"
                                            [items]="items"
                                            (changePage)="onChangePage($event)"
                                        ></jw-pagination>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ng-container *ngIf="!identities.length && !identity">
                            <app-dashboard-empty-anim></app-dashboard-empty-anim>
                        </ng-container>
                    </ng-container>
                </ng-container>

                <ng-template #loading>
                    <div class="text-center" style="padding: 13px;">
                        <i class="fa fa-spinner fa-spin"></i>
                    </div>
                </ng-template>
            </div>

            <div class="support-ticket-content-w" #toggleDetailsDiv>
                <ng-container *ngIf="showRightSide">
                    <div class="support-ticket-content">
                        <div class="support-ticket-content-header">
                            <h3 class="ticket-header text-upper">Identity Detail</h3>
                            <a class="back-to-index" (click)="onToggleDetails()"
                                ><i class="os-icon os-icon-arrow-left5"></i><span>Back</span></a
                            >
                        </div>
                        <div class="ticket-thread">
                            <div class="ticket-reply">
                                <div class="ticket-reply-info">
                                    <a class="author with-avatar">
                                        <ng-container
                                            *ngIf="selectedIdentity?.user?.image?.public_id"
                                        >
                                            <cl-image
                                                [public-id]="
                                                    selectedIdentity?.user?.image?.public_id
                                                "
                                            >
                                                <cl-transformation
                                                    width="400"
                                                    height="400"
                                                    gravity="face"
                                                    radius="max"
                                                    crop="crop"
                                                >
                                                </cl-transformation>
                                                <cl-transformation width="200" crop="scale">
                                                </cl-transformation>
                                            </cl-image>
                                        </ng-container>

                                        <span>
                                            {{ selectedIdentity?.user?.firstName }}
                                            {{ selectedIdentity?.user?.lastName }}
                                        </span></a
                                    >
                                    <span class="info-data"
                                        ><span class="label">Last updated on</span
                                        ><span class="value">{{
                                            selectedIdentity?.updatedAt | date
                                        }}</span></span
                                    >
                                </div>

                                <div class="ticket-thread">
                                    <div class="pipelines-w">
                                        <div class="pipeline white lined-primary">
                                            <div class="pipeline-header pad-25">
                                                <h5 class="pipeline-name">User Identity</h5>
                                                <div class="pipeline-header-numbers">
                                                    <div class="pipeline-value">
                                                        <p class="space-top-10">
                                                            <label class="text-black-50">
                                                                Last Uploaded:
                                                            </label>
                                                            <strong>
                                                                {{
                                                                    selectedIdentity?.updatedAt
                                                                        | date
                                                                }}
                                                            </strong>
                                                        </p>
                                                        <p>
                                                            <label class="text-black-50">
                                                                Status:
                                                            </label>

                                                            <strong
                                                                [ngClass]="{
                                                                    'text-danger':
                                                                        selectedIdentity?.status ===
                                                                        rejectedStatus,
                                                                    'text-success':
                                                                        selectedIdentity?.status ===
                                                                        verifiedStatus
                                                                }"
                                                            >
                                                                {{ selectedIdentity?.status }}
                                                            </strong>
                                                        </p>
                                                        <p>
                                                            <label class="text-black-50">
                                                                Type:
                                                            </label>
                                                            <strong>
                                                                {{ selectedIdentity?.type }}
                                                            </strong>
                                                        </p>
                                                        <p
                                                            *ngIf="
                                                                selectedIdentity?.status !==
                                                                rejectedStatus
                                                            "
                                                        >
                                                            <a
                                                                *ngIf="
                                                                    selectedIdentity?.status !==
                                                                    verifiedStatus
                                                                "
                                                                class="btn btn-primary btn-lg text-white"
                                                                (click)="
                                                                    onApproveIdentity(
                                                                        selectedIdentity,
                                                                        verifiedStatus
                                                                    )
                                                                "
                                                            >
                                                                <i class="os-icon os-icon-check"></i
                                                                ><span>Approve</span>
                                                            </a>
                                                            <a
                                                                *ngIf="
                                                                    selectedIdentity?.status !==
                                                                    rejectedStatus
                                                                "
                                                                class="space-l-5 btn-reject btn btn-danger btn-lg text-white"
                                                                (click)="
                                                                    onApproveIdentity(
                                                                        selectedIdentity,
                                                                        rejectedStatus
                                                                    )
                                                                "
                                                                ><i
                                                                    class="os-icon os-icon-close"
                                                                ></i
                                                                ><span>Reject</span></a
                                                            >
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="ticket-reply-content">
                                    <p *ngIf="selectedIdentity.image">
                                        <ng-image-slider
                                            imageSize="{width: '50%'}"
                                            animationSpeed="1"
                                            [images]="imageObject"
                                            [imagePopup]="true"
                                            [lazyLoad]="'true'"
                                            #nav
                                        ></ng-image-slider>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>
