import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from '@app/common/service/auth.service';
import { User, BankDetails } from '@app/common/payload/user';
import { UserService } from '@app/common/service/user.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { UserPasswordResetPayload } from '@app/common/payload/user-password-reset.payload';
import { FileUploader, FileUploaderOptions, ParsedResponseHeaders } from 'ng2-file-upload';
import { Cloudinary } from '@cloudinary/angular-5.x';
import { ImagePayload } from '@app/common/payload/property.payload';
import { SafeUrl, Title } from '@angular/platform-browser';
import { UserPreference } from '@app/common/payload/auth.payload';
import { environment } from '@environments/environment.prod';
import { ReturnsService } from '@app/common/service/returns.service';

@Component({
    selector: 'app-dashboard-profile-page',
    templateUrl: './dashboard-profile-page.component.html',
    styleUrls: ['./dashboard-profile-page.component.css'],
})
export class DashboardProfilePageComponent implements OnInit, OnDestroy {
    user: User;
    passwordResetForm: FormGroup;
    profileUpdateForm: FormGroup;
    isSubmit: boolean = false;
    dateModel: NgbDateStruct;
    userPayload: User;
    subscription: Subscription = new Subscription();
    profileCompleteStats: number;
    identityCompleteStats: number;
    isLoading: boolean = false;
    passwordResetPayload: UserPasswordResetPayload;
    resettingPassword = false;
    //Uploader
    uploader: FileUploader;
    title: string;
    profilePic: SafeUrl;
    hasBaseDropZoneOver: boolean;
    isUploading: boolean = false;
    preference: UserPreference;
    timer;
    checked: string;
    userBankDetails: BankDetails;

    constructor(
        private authService: AuthService,
        private userService: UserService,
        private spinner: NgxSpinnerService,
        private toast: ToastrService,
        private cloudinary: Cloudinary,
        private titleService: Title,
        private returnsService: ReturnsService
    ) {}

    ngOnInit(): void {
        this.titleService.setTitle(`Update Profile - ${environment.appName}`);

        this.subscription.add(
            this.authService.currentUser.subscribe((data) => {
                this.user = data;
                this.profilePic = data?.image?.secure_url || '/assets/shared/images/user-pic.png';
            })
        );

        if (this.user.preference?.allowProfileView === undefined) {
            this.preference = { allowProfileView: false };
        } else {
            this.preference = this.user.preference;
        }

        this.preference.allowProfileView === true ? (this.checked = 'yes') : (this.checked = 'no');

        const date = new Date(this.user?.dob);
        this.dateModel = {
            day: date.getUTCDate(),
            month: date.getUTCMonth() + 1,
            year: date.getUTCFullYear(),
        };
        this.profileUpdateForm = new FormGroup(
            {
                firstName: new FormControl(this.user.firstName, [
                    Validators.required,
                    Validators.minLength(3),
                ]),
                lastName: new FormControl(this.user?.lastName, [
                    Validators.required,
                    Validators.minLength(3),
                ]),
                email: new FormControl({ value: this.user.email, disabled: true }, [
                    Validators.required,
                    Validators.email,
                ]),
                phone: new FormControl(this.user.phone, [Validators.required]),
                city: new FormControl(this.user.city, [Validators.required]),
                state: new FormControl(this.user.state, [Validators.required]),
                country: new FormControl(this.user.country, [Validators.required]),
                address: new FormControl(this.user.address, [Validators.required]),
                dob: new FormControl(this.dateModel, [Validators.required]),
                designation: new FormControl(this.user.designation, [Validators.required]),
            },
            { updateOn: 'blur' }
        );
        this.userPayload = new User();
        this.subscription.add(
            this.userService.onGetProfileCompleteStats(this.user.id).subscribe(
                (result) => {
                    if (result.status === 200) {
                        this.identityCompleteStats = result.body.identityStats;
                        this.profileCompleteStats = result.body.userProfileStats;
                    }
                },
                (error) => {}
            )
        );

        this.subscription.add(
            this.returnsService.getUserBankDetails(this.authService.currentUserValue.id).subscribe(
                (result) => {
                    if (result.status === 200) {
                        this.userBankDetails = result.body;
                    }
                },
                (error) => {
                    if (error.statusCode === 404) {
                        this.userBankDetails = {};
                    }
                }
            )
        );

        this.passwordResetForm = new FormGroup({
            password: new FormControl('', [Validators.required, Validators.minLength(6)]),
            oldPassword: new FormControl('', [Validators.required]),
            passwordConfirm: new FormControl('', [Validators.required]),
        });
        this.passwordResetPayload = {};
        this.onPrepareUploader();
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    get fm() {
        return this.profileUpdateForm.controls;
    }

    get firstName() {
        return this.profileUpdateForm.get('firstName');
    }

    get lastName() {
        return this.profileUpdateForm.get('lastName');
    }

    onUpdateProfile() {
        this.isSubmit = true;
        this.isLoading = true;
        if (this.profileUpdateForm.invalid) {
            this.isLoading = false;
            return;
        }
        this.spinner.show().then();
        this.userPayload = this.profileUpdateForm.value;
        this.userPayload.dob = new Date(
            this.dateModel.year,
            this.dateModel.month - 1,
            this.dateModel.day
        );
        this.userPayload.id = this.user.id;
        this.userPayload.image = this.user.image;
        this.userService.onUpdateProfile(this.userPayload).subscribe(
            (result) => {
                if (result.status === 200) {
                    this.isLoading = false;
                    this.authService.updateUserSubject(this.userPayload);
                    this.spinner.hide().then();
                    this.toast.success('Profile Updated Successfully');
                }
            },
            (error) => {
                this.isLoading = false;
                console.log(error);
                this.spinner.hide().then();
                this.toast.error('Profile Update Failed');
            }
        );
    }

    get rpForm() {
        return this.passwordResetForm.controls;
    }

    onResetPassword() {
        this.resettingPassword = true;
        if (this.passwordResetForm.invalid) {
            return;
        }
        this.isSubmit = true;
        this.passwordResetPayload = this.passwordResetForm.value;
        this.passwordResetPayload.email = this.user.email;
        this.subscription.add(
            this.userService.onResetPassword(this.passwordResetPayload).subscribe(
                (result) => {
                    if (result.status === 201) {
                        this.resettingPassword = false;
                        this.isSubmit = false;
                        this.toast.success('Password Reset Successfully.');
                    }
                },
                (error) => {
                    this.resettingPassword = false;
                    this.isSubmit = false;
                    this.toast.error(`${error.message}`);
                }
            )
        );
    }

    public fileOverBase(e: any): void {
        this.hasBaseDropZoneOver = e;
    }

    onPrepareUploader() {
        const uploaderOptions: FileUploaderOptions = {
            url: `https://api.cloudinary.com/v1_1/${this.cloudinary.config().cloud_name}/upload`,
            autoUpload: true,
            isHTML5: true,
            removeAfterUpload: true,
            headers: [{ name: 'X-Requested-With', value: 'XMLHttpRequest' }],
        };
        this.uploader = new FileUploader(uploaderOptions);
        this.uploader.onBuildItemForm = (fileItem: any, form: FormData): any => {
            form.append('upload_preset', this.cloudinary.config().upload_preset);
            let tags = 'profilepic';
            if (this.title) {
                form.append('context', `photo=${this.title}`);
                tags = `profilepic,${this.title}`;
            }
            form.append('folder', 'property-collabo/user');
            form.append('tags', tags);
            form.append('file', fileItem);
            // Use default "withCredentials" value for CORS requests
            fileItem.withCredentials = false;
            return { fileItem, form };
        };
        this.uploader.onCompleteItem = (
            item: any,
            response: string,
            status: number,
            headers: ParsedResponseHeaders
        ) => {
            const res: ImagePayload = {
                public_id: JSON.parse(response).public_id,
                secure_url: JSON.parse(response).secure_url,
            };
            this.isUploading = false;
            this.subscription.add(
                this.userService.onUpdateProfilePicture(res, this.user.id).subscribe(
                    (result) => {
                        if (result.status === 201) {
                            this.toast.success('Profile Picture Uploaded.');
                            this.user.image = res;
                            this.authService.updateUserSubject(this.user);
                        }
                    },
                    (error) => {
                        this.toast.error('Profile Picture Upload Failed, Try Again');
                    }
                )
            );
        };

        this.uploader.onErrorItem = (
            item: any,
            response: string,
            status: number,
            headers: ParsedResponseHeaders
        ) => {
            this.toast.error(`Upload Failed, Please Try Again! ${response}`);
            this.isUploading = false;
            this.uploader.cancelAll();
            this.uploader.clearQueue();
            this.ngOnInit();
        };

        this.uploader.onAfterAddingFile = (fileItem) => {
            this.isUploading = true;
            this.toast.info('Uploading Image...');
            if (fileItem._file.type.match(/image\/*/) == null) {
                this.toast.warning('Only images are allowed');
                return;
            }
            const reader = new FileReader();
            reader.readAsDataURL(fileItem._file);
            reader.onload = (_event) => {
                this.profilePic = reader.result;
            };
        };
    }

    onPreferenceChange(value: any) {
        clearTimeout(this.timer);
        this.preference.allowProfileView = value.target.value === 'true';
        const payload: User = new User();
        payload.id = this.user.id;
        payload.preference = this.preference;

        this.user.preference = this.preference;
        this.timer = setTimeout(() => {
            this.subscription.add(
                this.userService.onUpdateProfile(payload).subscribe(
                    (result) => {
                        if (result.status === 200) {
                            this.authService.updateUserSubject(this.user);
                            this.toast.success('Preference Updated Successfully.');
                        }
                    },
                    (error) => {
                        this.toast.error('Preference Update Failed!');
                        console.log(error);
                    }
                )
            );
        }, 2000);
    }
}
