import { Component, Input, OnInit } from '@angular/core';
import { DashboardStatsCard } from '@app/common/payload/dashboard-stats-card';

@Component({
    selector: 'app-dashboard-stats-card',
    templateUrl: './dashboard-stats-card.component.html',
    styleUrls: ['./dashboard-stats-card.component.css'],
})
export class DashboardStatsCardComponent implements OnInit {
    @Input() data: DashboardStatsCard;

    constructor() {}

    ngOnInit(): void {}
}
