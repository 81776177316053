<div class="az-signup-wrapper">
    <div class="az-column-signup-left">
        <div>
            <a class="point" routerLink="">
                <cl-image public-id="property-collabo/defaults/logo_c5cpbc">
                    <cl-transformation width="100" crop="scale"> </cl-transformation>
                </cl-image>
            </a>

            <h5 class="space-top-10">Welcome to the future of Modern Real Estate Investing</h5>
            <p>
                Join Us today as we make real estate investing more transparent and accessible to
                individual investors, helping them build wealth for their family and future.
            </p>
        </div>
    </div>
    <div class="az-column-signup">
        <div class="az-signup-header">
            <a class="point logo-m text-center" routerLink="">
                <cl-image public-id="property-collabo/defaults/logo_c5cpbc">
                    <cl-transformation width="100" crop="scale"> </cl-transformation>
                </cl-image>
            </a>
            <h2 class="text-center">Get Started</h2>
            <h4 class="text-center">It's free to signup and only takes a minute.</h4>

            <div class="mg-b-10" *ngIf="isErrors">
                <span>
                    <strong class="text-danger">Oops!, We found some errors:</strong>
                </span>
                <ol type="a" class="mg-b-5 tx-12">
                    <li>
                        <strong class="text-danger">{{ error | titlecase }} </strong>
                    </li>
                    <li *ngFor="let message of messages">
                        <span class="text-danger tx-12">{{ message | titlecase }}</span>
                    </li>
                </ol>
            </div>

            <div *ngIf="isSuccess" class="alert col-primary text-white" role="alert">
                <strong>
                    Sign up successful, Check your email for activation link.
                    <br />
                    <small>Click the activation link in your inbox to activate your account</small>
                </strong>
            </div>

            <form [formGroup]="registerForm" (ngSubmit)="onRegister()">
                <div class="form-group">
                    <label>First Name</label>
                    <input
                        type="text"
                        class="form-control"
                        placeholder="Enter your first name"
                        [formControlName]="'firstName'"
                        [ngClass]="{ 'f-invalid': isSubmitted && rf.firstName.errors }"
                    />
                </div>

                <div class="form-group">
                    <label>Last Name</label>
                    <input
                        type="text"
                        class="form-control"
                        placeholder="Enter your first name"
                        [formControlName]="'lastName'"
                        [ngClass]="{ 'f-invalid': isSubmitted && rf.lastName.errors }"
                    />
                </div>
                <!-- form-group -->
                <div class="form-group">
                    <label>Email</label>
                    <input
                        type="text"
                        class="form-control"
                        placeholder="Enter your email"
                        [formControlName]="'email'"
                        [ngClass]="{ 'f-invalid': isSubmitted && rf.email.errors }"
                    />
                </div>
                <div class="form-group">
                    <label>Phone</label>
                    <input
                        type="tel"
                        class="form-control"
                        placeholder="Enter your phone"
                        [formControlName]="'phone'"
                        [ngClass]="{ 'f-invalid': isSubmitted && rf.phone.errors }"
                    />
                </div>
                <!-- form-group -->
                <div class="form-group">
                    <label>Password</label>
                    <input
                        type="password"
                        class="form-control"
                        placeholder="Enter your password"
                        [formControlName]="'password'"
                        [ngClass]="{ 'f-invalid': isSubmitted && rf.password.errors }"
                    />
                </div>

                <div class="form-group">
                    <ngx-invisible-recaptcha
                        #captchaElem
                        [siteKey]="siteKey"
                        (load)="onCaptchaHandleLoad()"
                        (success)="onCaptchaHandleSuccess($event)"
                        (expire)="onCaptchaHandleExpire()"
                        [ngModel]="recaptcha"
                        [ngModelOptions]="{ standalone: true }"
                    >
                    </ngx-invisible-recaptcha>
                </div>
                <!-- form-group -->
                <button class="btn btn-az-primary btn-block" type="submit" [disabled]="isLoading">
                    Create Account
                    <span *ngIf="isLoading">
                        <i class="fa fa-circle-o-notch fa-spin fa-1x fa-fw"></i>
                    </span>
                </button>
                <div class="row row-xs">
                    <!--                    <div class="col-sm-6">-->
                    <!--                        <button class="btn btn-block">-->
                    <!--                            <i class="fab fa-facebook-f"></i> Signup with Facebook-->
                    <!--                        </button>-->
                    <!--                    </div>-->
                    <!--                    <div class="col-sm-6 mg-t-10 mg-sm-t-0">-->
                    <!--                        <button class="btn btn-primary btn-block">-->
                    <!--                            <i class="fab fa-twitter"></i> Signup with Twitter-->
                    <!--                        </button>-->
                    <!--                    </div>-->
                </div>
            </form>
        </div>
        <div class="az-signup-footer">
            <p>Already have an account? <a routerLink="/sign-in">Sign In</a></p>
        </div>
    </div>
</div>
