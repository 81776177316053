<div class="content-i">
    <div class="content-box">
        <div class="row">
            <div class="col-sm-12">
                <div class="element-wrapper">
                    <div class="element-actions"></div>
                    <h6 class="element-header text-uppercase">Identity Verification</h6>
                </div>
            </div>

            <div class="col-md-8 col-sm-12" *ngIf="identityPayload && !showUploadTypes">
                <div class="element-box">
                    <div class="support-ticket-content">
                        <div class="ticket-thread">
                            <div class="pipelines-w">
                                <div class="pipeline white lined-primary">
                                    <div class="pipeline-header pad-25">
                                        <h5 class="pipeline-name">Uploaded Identity</h5>
                                        <div class="pipeline-header-numbers">
                                            <div class="pipeline-value">
                                                <p class="space-top-10">
                                                    <label class="text-black-50">
                                                        Last Updated:</label
                                                    >
                                                    <strong>
                                                        {{ identityPayload?.updatedAt | date }}
                                                    </strong>
                                                </p>

                                                <p>
                                                    <label class="text-black-50">
                                                        Type:
                                                    </label>

                                                    <strong> {{ identityPayload?.type }} </strong>
                                                </p>

                                                <p>
                                                    <label class="text-black-50">
                                                        Approval Status:
                                                    </label>

                                                    <strong
                                                        [ngClass]="{
                                                            'text-danger':
                                                                identityPayload?.status ===
                                                                rejected,
                                                            'text-success':
                                                                identityPayload?.status === verified
                                                        }"
                                                    >
                                                        {{ identityPayload?.status }}
                                                    </strong>
                                                </p>

                                                <p *ngIf="identityPayload?.status === rejected">
                                                    <label class="text-black-50">
                                                        Reason:
                                                    </label>
                                                    <label class="text-danger">
                                                        {{ identityPayload?.message }}
                                                    </label>
                                                </p>

                                                <p *ngIf="identityPayload">
                                                    <ng-image-slider
                                                        imageSize="{width: '50%'}"
                                                        animationSpeed="1"
                                                        [images]="imageObject"
                                                        [imagePopup]="true"
                                                        [lazyLoad]="'true'"
                                                        #nav
                                                    ></ng-image-slider>
                                                </p>

                                                <p *ngIf="identityPayload?.status !== verified">
                                                    <a
                                                        class="btn btn-lg text-white btn-primary"
                                                        (click)="onUpdateIdentity()"
                                                    >
                                                        Update Identity
                                                    </a>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-8" *ngIf="showUploadTypes">
                <div class="element-box">
                    <div class="pipelines-w">
                        <div class="pipeline white lined-primary">
                            <div class="pipeline-header pad-25">
                                <h5 class="pipeline-name">Choose Identity Type</h5>
                                <div class="pipeline-header-numbers"></div>
                            </div>
                            <div class="support-index">
                                <div class="row">
                                    <div
                                        class="col-4"
                                        (click)="onOpenUploader(driversLicence, command)"
                                    >
                                        <div class="support-tickets">
                                            <div class="support-ticket">
                                                <div class="identity-body">
                                                    <div class="text-center">
                                                        <i class="fa fa-car fa-2x text-primary"></i>
                                                    </div>
                                                    <div class="ticket-content text-center">
                                                        <h6
                                                            class="ticket-title space-top-10 text-uppercase"
                                                        >
                                                            Drivers License
                                                        </h6>
                                                        <div class="ticket-description"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        class="col-4"
                                        (click)="onOpenUploader(intlPassport, command)"
                                    >
                                        <div class="support-tickets">
                                            <div class="support-ticket">
                                                <div class="identity-body">
                                                    <div class="text-center">
                                                        <i
                                                            class="fa fa-globe fa-2x text-primary"
                                                        ></i>
                                                    </div>
                                                    <div class="ticket-content text-center">
                                                        <h6
                                                            class="ticket-title space-top-10 text-uppercase"
                                                        >
                                                            Intl Passport
                                                        </h6>
                                                        <div class="ticket-description"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-4" (click)="onOpenUploader(idCard, command)">
                                        <div class="support-tickets">
                                            <div class="support-ticket">
                                                <div class="identity-body">
                                                    <div class="text-center">
                                                        <i
                                                            class="fa fa-id-card fa-2x text-primary"
                                                        ></i>
                                                    </div>
                                                    <div class="ticket-content text-center">
                                                        <h6
                                                            class="ticket-title space-top-10 text-uppercase"
                                                        >
                                                            Identity Card
                                                        </h6>
                                                        <div class="ticket-description"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
