import { Component, Input, OnInit } from '@angular/core';
import { ICrumbs } from '@app/common/payload/common.payload';

@Component({
    selector: 'app-dashboard-breadcrumbs',
    templateUrl: './dashboard-breadcrumbs.component.html',
    styleUrls: ['./dashboard-breadcrumbs.component.css'],
})
export class DashboardBreadcrumbsComponent implements OnInit {
    @Input() crumbs: ICrumbs[];
    constructor() {}

    ngOnInit(): void {}
}
