import { AfterViewInit, Component, Input, NgZone, OnDestroy, OnInit } from '@angular/core';
import { Cloudinary } from '@cloudinary/angular-5.x';
import { FileUploader, FileUploaderOptions, ParsedResponseHeaders } from 'ng2-file-upload';
import { ImagePayload, PropertyPayload } from '@app/common/payload/property.payload';
import { DomSanitizer } from '@angular/platform-browser';
import { PropertyService } from '@app/common/service/property.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-dashboard-photo-upload',
    templateUrl: './dashboard-photo-upload.component.html',
    styleUrls: ['./dashboard-photo-upload.component.css'],
})
export class DashboardPhotoUploadComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input() props: { folder?: string; tag?: string; property?: PropertyPayload };

    title: string;
    uploader: FileUploader;
    hasBaseDropZoneOver: boolean;
    response: any[];

    // using shared state
    sharedPropertyImage: ImagePayload[];
    subscription: Subscription = new Subscription();

    constructor(
        private cloudinary: Cloudinary,
        private zone: NgZone,
        private sanitizer: DomSanitizer,
        private propertyService: PropertyService
    ) {
        this.response = [];
        this.title = '';
    }
    public fileOverBase(e: any): void {
        this.hasBaseDropZoneOver = e;
    }

    ngOnInit(): void {
        this.subscription.add(
            this.propertyService.imageSubject.subscribe((data) => {
                this.sharedPropertyImage = data;
            })
        );
        // if @input() props is not set it means the [props] property of this component is not defined in parent component
        // check if props is not set initialize props to empty object
        if (!this.props.property) {
            let newProperty = new PropertyPayload();
            newProperty.image = [];
            this.props = { property: newProperty };
        }

        // if props has image push it to shared state
        if (this.props?.property?.image?.length) {
            this.sharedPropertyImage = this.props?.property?.image;
            this.propertyService.imageSubject.next(this.sharedPropertyImage);
        }

        // prepare fileUploaderOptions to use cloudinary details set in environments
        const uploaderOptions: FileUploaderOptions = {
            url: `https://api.cloudinary.com/v1_1/${this.cloudinary.config().cloud_name}/upload`,
            // Upload files automatically upon addition to upload queue
            autoUpload: true,
            // Use xhrTransport in favor of iframeTransport
            isHTML5: true,
            // Calculate progress independently for each uploaded file
            removeAfterUpload: true,
            // XHR request headers
            headers: [
                {
                    name: 'X-Requested-With',
                    value: 'XMLHttpRequest',
                },
            ],
        };
        this.uploader = new FileUploader(uploaderOptions);
        // build form to upload
        this.uploader.onBuildItemForm = (fileItem: any, form: FormData): any => {
            // Add Cloudinary's unsigned upload preset to the upload form
            form.append('upload_preset', this.cloudinary.config().upload_preset);
            // Add built-in and custom tags for displaying the uploaded photo in the list
            let tags = 'myphotoalbum';
            if (this.title) {
                form.append('context', `photo=${this.title}`);
                tags = `myphotoalbum,${this.title}`;
            }
            form.append('folder', 'property-collabo/property');
            // Add custom tags
            form.append('tags', tags);
            // Add file to upload
            form.append('file', fileItem);

            // Use default "withCredentials" value for CORS requests
            fileItem.withCredentials = false;
            return { fileItem, form };
        };

        // after uploading image to cloud
        this.uploader.onCompleteItem = (
            item: any,
            response: string,
            status: number,
            headers: ParsedResponseHeaders
        ) => {
            const res: ImagePayload = {
                public_id: JSON.parse(response).public_id,
                secure_url: JSON.parse(response).secure_url,
            };
            // using shared state via property service
            this.sharedPropertyImage.push(res);
            this.propertyService.imageSubject.next(this.sharedPropertyImage);

            this.props.property.image.forEach((i) => {
                //set the newly uploaded image public_id, so user can remove image from drop area preview if required and also remove image from cloud to clear up storage
                if (i.fileItem == item) {
                    i.public_id = res.public_id;
                }
            });
        };

        //add image to drop area preview before uploading
        this.uploader.onAfterAddingFile = (fileItem) => {
            let image = this.sanitizer.bypassSecurityTrustUrl(
                window.URL.createObjectURL(fileItem._file)
            );
            let tempImage: ImagePayload = {
                public_id: '',
                secure_url: image,
                fileItem: fileItem,
            };
            this.props.property.image.push(tempImage);
        };
    }
    onRemovePreview(image: ImagePayload) {
        // remove image from drop area preview
        this.props.property.image.splice(this.props.property.image.indexOf(image), 1);
        // if image is uploaded
        if (image?.public_id) {
            //delete the image from cloud
            this.subscription.add(
                this.propertyService.onDeletePropertyImage(image.public_id).subscribe()
            );
            // delete the image from shared state
            let imagePayload: ImagePayload = {};
            this.sharedPropertyImage.forEach((i) => {
                if (i.public_id === image.public_id) imagePayload = i;
            });
            const newImageList = this.sharedPropertyImage.filter((item) => item !== imagePayload);
            this.propertyService.imageSubject.next(newImageList);
        }
        if (image.fileItem) {
            this.uploader.removeFromQueue(image?.fileItem);
        }
    }

    ngAfterViewInit(): void {}

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    onUploadImage(image: ImagePayload) {
        if (image.fileItem) {
            this.uploader.uploadItem(image.fileItem);
        }
    }
}
