import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'truncateText',
})
export class TruncateTextPipe implements PipeTransform {
    transform(value: string, limit: number = 30, trail: String = '..'): string {
        let result = value || '';
        if (value) {
            if (value.length > limit) {
                result = value.slice(0, limit) + trail;
            }
        }
        return result;
    }
}
