<div class="az-error-wrapper mg-t-5">
    <s1-lottie
        [options]="lottieParams"
        [width]="400"
        [height]="400"
        [optimize]="true"
        (animationCreated)="onAnimationCreated($event)"
        class="mg-b-0"
    >
    </s1-lottie>

    <div class="container text-center title" *ngIf="!valid">
        <h3>Verifying..</h3>
    </div>

    <div class="container text-center title" *ngIf="valid">
        <h2>We have verified your email successfully.</h2>
        <a
            class="xs-btn btn text-capitalize btn-primary btn-lg round-btn text-white space-top-5"
            routerLink="/sign-in"
            >Continue to Login</a
        >
    </div>
</div>
