import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { PropertyPayload } from '@app/common/payload/property.payload';
import Swal from 'sweetalert2';
import { PropertyService } from '@app/common/service/property.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { PropertyStatusEnum } from '@app/common/enum/property.enums';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '@app/common/service/auth.service';
import { User } from '@app/common/payload/user';
import { InvestmentPayload } from '@app/common/payload/investment.payload';
import { InvestmentService } from '@app/common/service/investment.service';
import { ShortNumberPipe } from '@app/common/pipes/short-number.pipe';
import { InvestmentPaymentOptions, InvestmentStatus } from '@app/common/enum/investment.enums';
import { Router } from '@angular/router';
import { UploaderService } from '@app/common/service/uploader.service';
import { ReturnsService } from '@app/common/service/returns.service';

@Component({
    selector: 'app-dashboard-property-card',
    templateUrl: './dashboard-property-card.component.html',
    styleUrls: ['./dashboard-property-card.component.css'],
    providers: [ShortNumberPipe],
})
export class DashboardPropertyCardComponent implements OnInit, OnDestroy {
    @Input() property: PropertyPayload;
    subscription: Subscription = new Subscription();
    defaultImage: 'assets/image-loading.gif';
    @Output() onDelete: EventEmitter<PropertyPayload> = new EventEmitter<PropertyPayload>();
    user: User;
    investment: InvestmentPayload = new InvestmentPayload();
    currentInvestments: InvestmentPayload[];
    userEarning = 0;

    constructor(
        private propertyService: PropertyService,
        private spinner: NgxSpinnerService,
        private toast: ToastrService,
        private authService: AuthService,
        private investmentService: InvestmentService,
        private shortNumberPipe: ShortNumberPipe,
        private router: Router,
        private uploaderService: UploaderService,
        private returnsService: ReturnsService
    ) {}

    ngOnInit(): void {
        this.uploaderService.onPrepareUploader({ tag: 'pop', folder: 'pop' });
        this.user = this.authService.currentUserValue;
        this.subscription.add(
            this.investmentService.onGetInvestmentByUser(this.user.id).subscribe((result) => {
                if (result.status === 200) {
                    this.currentInvestments = result.body;
                } else {
                    this.toast.error("Couldn't current investments");
                    this.currentInvestments = [];
                }
            })
        );
        this.subscription.add(
            this.returnsService.getUserReturnCountDetails(this.user.id).subscribe(
                (data) => {
                    if (data.status === 200) {
                        this.userEarning = data.body.earningData.earning;
                    }
                },
                (error) => {
                    console.log(`cannot get earning: ${error.message}`);
                }
            )
        );
    }

    onCloseProperty(property: PropertyPayload) {
        if (property.status == PropertyStatusEnum.CLOSED) {
            this.toast.error('This property is already closed');
            return;
        }
        this.spinner.show().then();
        this.subscription.add(
            this.propertyService
                .onUpdatePropertyStatus(property.id, PropertyStatusEnum.CLOSED)
                .subscribe(
                    (result) => {
                        if (result) {
                            property.status = PropertyStatusEnum.CLOSED;
                            this.spinner.hide().then();
                            this.toast.success('Property Closed Successfully');
                        }
                    },
                    (error) => {
                        this.spinner.hide().then();
                        this.toast.error("Couldn't close property, an error occurred");
                        console.log(error);
                    }
                )
        );
    }

    //handle delete property on admin property view (delete button click)
    onDeleteProperty(property: PropertyPayload) {
        this.spinner.show().then();
        this.subscription.add(
            this.propertyService.onDeleteProperty(property.id).subscribe(
                (result) => {
                    if (result.status === 200) {
                        this.spinner.hide().then();
                        this.toast.success('Property Deleted Successfully');
                        this.onDelete.emit(property);
                    }
                },
                (error) => {
                    this.spinner.hide().then();
                    this.toast.success('Property Deleted Successfully');
                    console.log(error);
                }
            )
        );
    }

    //cancel delete action from alert modal (does nothing really)
    onCancel($event: Swal.DismissReason) {}

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    onGetPercentage() {
        return this.propertyService.onGetPercentage(this.property);
    }

    //handle property investment on user property view (Invest now button click event)
    async onInvestInProperty(property: PropertyPayload) {
        //check duplicate investment
        for (let i = 0; i < this.currentInvestments.length; i++) {
            if (this.currentInvestments[i].property.id === property.id) {
                Swal.fire({
                    title: 'Duplicate Investment',
                    icon: 'info',
                    html: `<small>This property is in your investment list already, <br> Click Goto Investment Button to see the property and upgrade the amount</small>`,
                    confirmButtonText: 'Goto Investment',
                    cancelButtonText: 'No, Close',
                    showCancelButton: true,
                }).then((value) => {
                    if (value.isConfirmed) {
                        this.router.navigateByUrl('/app/investment').then();
                    }
                });
                return; //replace break
            }
        }

        if (property.status === PropertyStatusEnum.CLOSED) {
            await this.showPropertyClosedModal();
            return;
        }

        this.router
            .navigateByUrl('/app/invest/' + this.onEncode(property.title) + '/' + property.uud, {
                state: { data: property },
            })
            .then();

        // // ask user if they want to invest from earning
        // if (Number(this.userEarning) > Number(property.minAmount)) {
        //     const investFromEarning = await this.uploaderService.showInvestFromEarningModal();
        //     // user click 'yes' invest from earning
        //     if (investFromEarning === 'yes') {
        //         await this.onInvestInPropertyFromEarning(property);
        //     } else if (investFromEarning === 'no') {
        //         await this.onInvestInPropertyFromBank(property);
        //     }
        // } else {
        //     await this.onInvestInPropertyFromBank(property);
        // }
    }

    async onInvestInPropertyFromEarning(property: PropertyPayload) {
        // enter desired amount to invest
        const amount = await this.uploaderService.showEnterAmountModal(
            property.minAmount,
            property.maxAmount
        );
        if (amount) {
            // check if amount user provided is greater than allowed maximum amount for property
            if (Number(amount) > property.maxAmount) {
                //show invalid feedback
                await this.showMaxAmountModal();
                return;
            }
            const investment = new InvestmentPayload();
            investment.amount = Number(amount);
            investment.propertyId = this.property.id;
            investment.paymentMethod = InvestmentPaymentOptions.PAY_FROM_EARNING;
            investment.status = InvestmentStatus.OPEN;
            investment.userId = this.authService.currentUserValue.id;
            investment.property = new PropertyPayload(this.property.id);
            investment.isInvestingFromEarning = true;
            this.spinner.show().then();
            this.subscription.add(
                this.investmentService.onCreateInvestment(investment).subscribe(
                    async (result) => {
                        if (result.status === 201) {
                            this.spinner.hide().then();
                            this.currentInvestments.push(investment);
                            Swal.fire({
                                icon: 'success',
                                title: 'Investment Successful',
                            }).then();
                        }
                    },
                    (error) => {
                        this.spinner.hide().then();
                        Swal.fire({
                            icon: 'error',
                            title: 'Error',
                            html: `${error.message}`,
                        });
                    }
                )
            );
        }
    }

    async onInvestInPropertyFromBank(property: PropertyPayload) {
        // // user enter investment amount then save to investments so user can see it later and continue
        const value = await this.uploaderService.onOpenInvestNowModal(this.property);
        // if user entered amount then save and proceed to upload pop
        if (value) {
            if (Number(value) > property.maxAmount) {
                await this.showMaxAmountModal();
                return;
            }

            const investment = new InvestmentPayload();
            investment.amount = Number(value);
            investment.propertyId = this.property.id;
            investment.paymentMethod = InvestmentPaymentOptions.BANK_TRANSFER;
            investment.status = InvestmentStatus.OPEN;
            investment.userId = this.authService.currentUserValue.id;
            investment.property = new PropertyPayload(this.property.id);

            this.spinner.show().then();
            this.subscription.add(
                this.investmentService.onCreateInvestment(investment).subscribe(
                    async (result) => {
                        if (result.status === 201) {
                            const newlyCreatedInvestment = result.body;
                            this.spinner.hide().then();
                            this.currentInvestments.push(investment);
                            //upload pop
                            const file = await this.uploaderService.onOpenUploadPOPModal(
                                investment
                            );

                            //if the user selected a file then process upload
                            if (file) {
                                this.subscription.add(
                                    this.uploaderService.onUploadFile(file).subscribe((data) => {
                                        if (data) {
                                            const investmentToUploadPOP = new InvestmentPayload();
                                            investmentToUploadPOP.uploads = data;
                                            investmentToUploadPOP.id = newlyCreatedInvestment.id;
                                            this.subscription.add(
                                                this.investmentService
                                                    .onUpdateInvestment(
                                                        investmentToUploadPOP,
                                                        newlyCreatedInvestment.id
                                                    )
                                                    .subscribe((updated) => {
                                                        this.toast.success(
                                                            'Proof of Payment Uploaded Successfully'
                                                        );
                                                    })
                                            );
                                        }
                                    })
                                );
                            }
                        }
                    },
                    (error) => {
                        this.spinner.hide().then();
                        Swal.fire({
                            title: 'Investment Request Failed',
                            icon: 'error',
                            html: ` <code> ${error.message} </code>`,
                        });
                    }
                )
            );
        }
    }

    async showMaxAmountModal() {
        await Swal.fire({
            title: 'Investment Request Failed',
            icon: 'warning',
            html: ` <code> Your investment amount has exceeded the maximum amount for this property </code>`,
        });
    }

    async showPropertyClosedModal() {
        await Swal.fire({
            title: 'Closed !',
            icon: 'warning',
            html: ` <small> You cannot invest in this property because it is closed. </small>`,
        });
    }

    onEncode(title: string) {
        return encodeURI(title);
    }
}
