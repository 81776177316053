import { Injectable } from '@angular/core';
import { environment } from '@environments/environment.prod';
import { HttpClient } from '@angular/common/http';
import { ReturnCountData } from '@app/common/payload/dashboard-stats-card';
import { BankDetails } from '@app/common/payload/user';
import { WithdrawPayload } from '@app/common/payload/common.payload';

@Injectable({
    providedIn: 'root',
})
export class ReturnsService {
    uriEndpoint: string = environment.url;

    constructor(private http: HttpClient) {}

    public getUserReturnCountDetails(userId: number) {
        return this.http.get<ReturnCountData>(`${this.uriEndpoint}/returns/count-data/${userId}`, {
            observe: 'response',
        });
    }

    public getUserBankDetails(userId: number) {
        return this.http.get<BankDetails>(`${this.uriEndpoint}/returns/bank-details/${userId}`, {
            observe: 'response',
        });
    }

    public updateUserBankDetails(bankDetailsPayload: BankDetails) {
        return this.http.post<BankDetails>(
            `${this.uriEndpoint}/returns/create/bank-details`,
            bankDetailsPayload,
            { observe: 'response' }
        );
    }

    public onRequestWithdraw(userId: number, amount: number) {
        return this.http.post<WithdrawPayload>(
            `${this.uriEndpoint}/returns/withdraw/${userId}`,
            { amount },
            { observe: 'response' }
        );
    }

    public onGetTransactionHistory(userId: number) {
        return this.http.get<WithdrawPayload[]>(
            `${this.uriEndpoint}/returns/transaction-history/${userId}`,
            {
                observe: 'response',
            }
        );
    }

    public getAllWithdraws() {
        return this.http.get<WithdrawPayload[]>(`${this.uriEndpoint}/returns/withdraws`, {
            observe: 'response',
        });
    }

    public onFulfilRequest(withdraw: WithdrawPayload) {
        return this.http.put<{ status: boolean; message: string }>(
            `${this.uriEndpoint}/returns/fulfil-request`,
            withdraw,
            { observe: 'response' }
        );
    }

    public onAdminAddBankDetails(bankDetails: BankDetails) {
        return this.http.post<BankDetails>(
            `${this.uriEndpoint}/returns/adm/add-account`,
            bankDetails,
            {
                observe: 'response',
            }
        );
    }

    public onGetAppBankDetails() {
        return this.http.get<BankDetails[]>(`${this.uriEndpoint}/returns/app-accounts`, {
            observe: 'response',
        });
    }

    public onDeleteAccount(id: number) {
        return this.http.delete(`${this.uriEndpoint}/returns/app-accounts/${id}`, {
            observe: 'response',
        });
    }

    public onActivateAccount(id: number) {
        return this.http.put<{
            deactivatedPrevious: boolean;
            activatedNew: boolean;
            message: string;
        }>(`${this.uriEndpoint}/returns/activate-account/${id}`, {}, { observe: 'response' });
    }

    public onGetActivatedBankDetail() {
        return this.http.get<BankDetails>(`${this.uriEndpoint}/returns/find/activated-account`, {
            observe: 'response',
        });
    }
}
