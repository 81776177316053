import { WithdrawStatusEnum } from '@app/common/enum/command.enum';
import { User } from '@app/common/payload/user';

export interface ICrumbs {
    text: string;
    link?: string;
}

export interface WithdrawPayload {
    balanceBeforeWithdraw: number;
    amount: number;
    status: WithdrawStatusEnum;
    createdAt: Date;
    updatedAt: Date;
    user?: User;
    id?: number;
    userId?: number;
}

export class EmailSettingPayload {
    emailSetting: {
        activateEmail: boolean;
        activateEmailOnModules?: {
            allowInvestment: boolean;
            allowIdentity: boolean;
            allowWithdraw: boolean;
        };
    };
}

export enum SettingKeysEnum {
    EMAIL_SETTING = 'email_setting',
}
