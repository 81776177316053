export enum PropertyTypeEnum {
    ALL = 'ALL',
    LAND = 'LAND',
    HOUSE = 'HOUSE',
    APARTMENT = 'APARTMENT',
}

export enum PropertyCategoryEnum {
    COMMERCIAL = 'COMMERCIAL',
    INDUSTRIAL = 'INDUSTRIAL',
    RESIDENTIAL = 'RESIDENTIAL',
    SHOW_ALL = 'SHOW ALL',
}

export enum PropertyStatusEnum {
    OPEN = 'OPEN INVESTMENT',
    CLOSED = 'CLOSED INVESTMENT',
    PAST = 'PAST INVESTMENT',
    SHOW_ALL = 'SHOW ALL',
}
