import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DashboardStatsCard } from '@app/common/payload/dashboard-stats-card';

@Component({
    selector: 'app-dashboard-stats-card-sm',
    templateUrl: './dashboard-stats-card-sm.component.html',
    styleUrls: ['./dashboard-stats-card-sm.component.css'],
})
export class DashboardStatsCardSmComponent implements OnInit, OnDestroy {
    @Input() data: DashboardStatsCard;

    constructor() {}

    ngOnInit(): void {}

    ngOnDestroy() {}
}
