import { Injectable } from '@angular/core';
import { adminMenu, MenuBag, MenuBox } from '@app/common/constant/menu-bag';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class MenuService {
    clickedMenu: BehaviorSubject<MenuBag>;
    menuBox: BehaviorSubject<MenuBox>;
    menuBox$: Observable<MenuBox>;
    rightContent: BehaviorSubject<boolean>;

    constructor() {
        this.clickedMenu = new BehaviorSubject<MenuBag>(adminMenu[0]);
        this.menuBox = new BehaviorSubject<MenuBox>({ isClicked: false, menu: adminMenu[0] });
        this.menuBox$ = this.menuBox.asObservable();
        this.rightContent = new BehaviorSubject<boolean>(false);
    }

    onClick(menu: MenuBag) {
        this.clickedMenu.next(menu);
        this.menuBox.next({ isClicked: true, menu: menu });
    }

    onOutClick() {
        this.menuBox.next({ isClicked: false, menu: adminMenu[0] });
    }
}
