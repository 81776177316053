<div class="content-panel-close"><i class="os-icon os-icon-close"></i></div>
<div class="element-wrapper">
    <h6 class="element-header">Quick Links</h6>
    <div class="element-box-tp">
        <div class="el-buttons-list full-width">
            <a class="btn btn-white btn-sm" routerLink="/app/admin/property-create"
                ><i class="os-icon os-icon-home"></i><span>Create New Property</span></a
            >
            <a class="btn btn-white btn-sm"
                ><i class="os-icon os-icon-wallet-loaded"></i><span>Approve Investment</span></a
            >
            <a class="btn btn-white btn-sm"
                ><i class="os-icon os-icon-user-check"></i
                ><span> Approve Identity &nbsp; &nbsp;</span></a
            >
        </div>
    </div>
</div>
