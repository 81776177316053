import {
    Component,
    OnInit,
    ElementRef,
    HostListener,
    Input,
    ViewChild,
    Renderer2,
} from '@angular/core';
import { AuthService } from '@app/common/service/auth.service';
import { User } from '@app/common/payload/user';

@Component({
    selector: 'app-website-header',
    templateUrl: './website-header.component.html',
    styleUrls: ['./website-header.component.css'],
})
export class WebsiteHeaderComponent implements OnInit {
    @ViewChild('mobileHeader') mobileHeader: ElementRef;
    @ViewChild('companySubMenu') companySubMenu: ElementRef;
    isMobileHeaderVisible = false;
    isCompanySubMenu = false;

    dropCompany = false;
    dropAccount = false;
    dropManager = false;
    userDetails: User;
    isAuthenticated: boolean;
    isAdministrator: boolean;

    @HostListener('document:click', ['$event'])
    checkouts(event) {
        if (this.eRef.nativeElement.contains(event.target)) {
        } else {
            this.dropCompany = false;
            this.dropAccount = false;
            this.dropManager = false;
        }
    }

    constructor(
        private authService: AuthService,
        private eRef: ElementRef,
        private renderer: Renderer2
    ) {}

    ngOnInit() {
        this.isAuthenticated = this.authService.isAuthenticated();
        this.isAdministrator = this.authService.isAdmin();
        this.userDetails = this.authService.currentUserValue;
    }

    showDropDown(command: string) {
        switch (command) {
            case 'company': {
                this.dropCompany = !this.dropCompany;
                break;
            }
            case 'account': {
                this.dropAccount = !this.dropAccount;
                break;
            }
            case 'manager': {
                this.dropManager = !this.dropManager;
                break;
            }
            default: {
            }
        }
    }

    onSignOut() {
        this.authService.signout();
    }

    onToggleMobileNav(helpMe: string) {
        const ele = this.mobileHeader.nativeElement;
        if (this.isMobileHeaderVisible) {
            this.renderer.setStyle(ele, 'visibility', 'hidden');
            this.renderer.setStyle(ele, 'opacity', 0);
            this.renderer.setStyle(ele, 'transition', ' all .2s ease-in-out');
        } else {
            this.renderer.setStyle(ele, 'visibility', 'visible');
            this.renderer.setStyle(ele, 'opacity', 1);
            this.renderer.setStyle(ele, 'transition', ' all .2s ease-in-out');
        }
        this.isMobileHeaderVisible = !this.isMobileHeaderVisible;
    }

    onToggleCompanySubMenu() {
        const ele = this.companySubMenu.nativeElement;
        if (this.isCompanySubMenu) {
            this.renderer.setStyle(ele, 'display', 'none');
        } else {
            this.renderer.setStyle(ele, 'display', 'block');
        }
        this.isCompanySubMenu = !this.isCompanySubMenu;
    }
}
