import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment.prod';
import { User } from '@app/common/payload/user';
import { UserProfileStatusPayload } from '@app/common/payload/user-profile-status.payload';
import { UserPasswordResetPayload } from '@app/common/payload/user-password-reset.payload';
import { ImagePayload } from '@app/common/payload/property.payload';

@Injectable({
    providedIn: 'root',
})
export class UserService {
    uriEndpoint: string = environment.url;

    constructor(private http: HttpClient) {}

    public onUpdateProfile(userPayload: User) {
        return this.http.patch(`${this.uriEndpoint}/users/update/${userPayload.id}`, userPayload, {
            observe: 'response',
        });
    }

    onGetProfileCompleteStats(userId: number) {
        return this.http.get<UserProfileStatusPayload>(
            `${this.uriEndpoint}/users/profile-status/${userId}`,
            { observe: 'response' }
        );
    }

    onResetPassword(userPasswordResetPayload: UserPasswordResetPayload) {
        return this.http.post(`${this.uriEndpoint}/auth/password/reset`, userPasswordResetPayload, {
            observe: 'response',
        });
    }

    onUpdateProfilePicture(res: ImagePayload, userId: number) {
        return this.http.post(`${this.uriEndpoint}/users/update/profile-picture/${userId}`, res, {
            observe: 'response',
        });
    }

    onGetAllUsers() {
        return this.http.get<User[]>(`${this.uriEndpoint}/users/`, { observe: 'response' });
    }

    onGetUserById(userId: number) {
        return this.http.get<User>(`${this.uriEndpoint}/users/${userId}`, { observe: 'response' });
    }

    onSendContactEmail(payload: { name: string; email: string; subject: string; message: string }) {
        return this.http.post(`${this.uriEndpoint}/auth/contact`, payload, { observe: 'response' });
    }
}
