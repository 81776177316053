<ngx-spinner
    bdColor="rgba(51, 51, 51, 0.8)"
    size="medium"
    color="#ffffff"
    type="ball-spin-fade"
    [fullScreen]="true"
>
    ></ngx-spinner
>
<div class="limiter">
    <div class="container-login100">
        <div class="wrap-login100">
            <div class="login100-pic js-tilt" data-tilt>
                <div class="signup-v2-logo-dv">
                    <a class="point text-center signup-v2-logo" routerLink="">
                        <cl-image public-id="property-collabo/defaults/logo_c5cpbc">
                            <cl-transformation width="100" crop="scale"> </cl-transformation>
                        </cl-image>
                        <h3 class="login100-form-title" style="margin-top: 5px;">Welcome Back!</h3>
                    </a>
                </div>
            </div>

            <form
                class="login100-form validate-form"
                [formGroup]="loginForm"
                (ngSubmit)="onLogin()"
                (keydown.enter)="onSubmitKeyPressed(loginForm.value)"
            >
                <div class="signup-v2-logo-hv">
                    <div class="signup-v2-logo-dv">
                        <a class="point text-center signup-v2-logo" routerLink="">
                            <cl-image public-id="property-collabo/defaults/logo_c5cpbc">
                                <cl-transformation width="50" crop="scale"> </cl-transformation>
                            </cl-image>
                        </a>
                    </div>
                </div>
                <span class="login100-form-title">
                    Sign In To Continue
                </span>

                <div
                    class="alert alert-warning alert-dismissible fade show"
                    style="margin-bottom: 20px; padding: 10px;"
                    *ngIf="isErrors"
                >
                    <strong>Warning!</strong>
                    <br />
                    <span *ngIf="error">{{ error | titlecase }}</span>
                    <br />
                    <span
                        *ngIf="resendEmailVerification"
                        class="text-primary point"
                        (click)="onResendVerificationEmail()"
                    >
                        resend verification email?</span
                    >

                    <button
                        type="button"
                        (click)="onCloseWarning()"
                        class="close"
                        data-dismiss="alert"
                    >
                        &times;
                    </button>
                </div>

                <div
                    class="alert alert-primary alert-dismissible fade show"
                    style="margin-bottom: 20px; padding: 10px;"
                    *ngIf="emailVerificationSent"
                >
                    <strong class="text-white">Success!</strong>
                    <br />
                    <span class="text-white">Email verification sent!</span>
                    <button
                        type="button"
                        (click)="onCloseWarning()"
                        class="close"
                        data-dismiss="alert"
                    >
                        &times;
                    </button>
                </div>

                <small
                    class="text-danger d-block"
                    style="margin-left: 10px;"
                    *ngIf="isSubmitted && lf.email.errors"
                    >Email is invalid</small
                >
                <div class="wrap-input100 validate-input">
                    <input
                        class="input100"
                        type="text"
                        placeholder="Enter Email"
                        #emailField
                        [formControlName]="'email'"
                        [ngClass]="{ 'f-invalid': isSubmitted && lf.email.errors }"
                        [style.border]="isSubmitted && lf.email.errors ? '' : 'none'"
                    />
                    <span class="focus-input100"></span>
                    <span class="symbol-input100">
                        <i
                            class="fa fa-envelope"
                            [ngClass]="{ 'text-danger': isSubmitted && lf.email.errors }"
                            aria-hidden="true"
                        ></i>
                    </span>
                </div>

                <small
                    class="text-danger d-block"
                    style="margin-left: 10px;"
                    *ngIf="isSubmitted && lf.password.errors"
                    >Password is invalid</small
                >
                <div class="wrap-input100 validate-input">
                    <input
                        class="input100"
                        type="password"
                        [formControlName]="'password'"
                        placeholder="Enter Password"
                        [ngClass]="{ 'f-invalid': isSubmitted && lf.password.errors }"
                    />
                    <span class="focus-input100"></span>
                    <span class="symbol-input100">
                        <i
                            class="fa fa-lock"
                            [ngClass]="{ 'text-danger': isSubmitted && lf.password.errors }"
                            aria-hidden="true"
                        ></i>
                    </span>
                </div>

                <div class="container-login100-form-btn">
                    <button class="login100-form-btn" type="submit" [disabled]="isLoading">
                        Sign In &nbsp;
                        <span *ngIf="isLoading">
                            <i class="fa fa-circle-o-notch fa-spin fa-1x fa-fw"></i>
                        </span>
                    </button>
                </div>

                <div class="text-center p-t-12">
                    <span class="txt1">
                        Forgot
                    </span>
                    <a class="txt2" routerLink="/password-reset">
                        Password?
                    </a>
                </div>

                <div class="text-center p-t-136">
                    <a class="txt2" routerLink="/sign-up">
                        Create an Account
                        <i class="fa fa-long-arrow-right m-l-5" aria-hidden="true"></i>
                    </a>
                </div>
            </form>

            <div class="form-group">
                <ngx-invisible-recaptcha
                    #captchaElem
                    [siteKey]="siteKey"
                    (load)="onCaptchaHandleLoad()"
                    (success)="onCaptchaHandleSuccess($event)"
                    (expire)="onCaptchaHandleExpire()"
                    [ngModel]="recaptcha"
                    [ngModelOptions]="{ standalone: true }"
                >
                </ngx-invisible-recaptcha>
            </div>
        </div>
    </div>
</div>
