import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgxSpinnerService } from 'ngx-spinner';
import { User } from '@app/common/payload/user';
import { AuthService } from '@app/common/service/auth.service';
import { adminMenu, MenuBag, userMenu } from '@app/common/constant/menu-bag';
import { MenuService } from '@app/common/service/menu.service';
import { Subscription } from 'rxjs';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
    selector: 'app-dashboard-layout',
    templateUrl: './dashboard-layout.component.html',
    styleUrls: ['./dashboard-layout.component.css'],
})
export class DashboardLayoutComponent implements OnInit, AfterViewInit, OnDestroy {
    style = '/assets/dashboard-assets/css/dash.css';
    user: User;
    menuBag: MenuBag[];
    showRightContent: boolean;
    sub: Subscription = new Subscription();
    isUserMobile: boolean;

    constructor(
        public sanitizer: DomSanitizer,
        private spinner: NgxSpinnerService,
        private authService: AuthService,
        private menuService: MenuService,
        private deviceService: DeviceDetectorService
    ) {}

    ngOnInit(): void {
        this.isUserMobile = this.deviceService.isMobile();
        this.sub.add(
            this.menuService.rightContent.subscribe((a) => {
                this.showRightContent = a;
            })
        );

        this.sub.add(
            this.authService.currentUser.subscribe((result) => {
                this.user = result;
            })
        );
        if (this.authService.isAdmin()) {
            this.menuBag = adminMenu;
        } else this.menuBag = userMenu;
    }

    ngAfterViewInit() {}

    ngOnDestroy(): void {
        this.sub.unsubscribe();
    }
}
