<ngx-spinner [fullScreen]="true" [size]="'small'" type="ball-spin"></ngx-spinner>
<div class="content-i">
    <div class="content-box">
        <div class="row">
            <div class="col-sm-5 space-b-25">
                <div class="user-profile compact">
                    <div
                        class="up-head-w"
                        [ngStyle]="{ 'background-image': 'url(' + profilePic + ')' }"
                    >
                        <div class="up-main-info">
                            <h2 class="up-header">{{ user.firstName }} {{ user.lastName }}</h2>
                            <h6 class="up-sub-header">
                                {{ user.designation ? user.designation : '' }}
                            </h6>
                        </div>
                        <svg
                            class="decor"
                            width="842px"
                            height="219px"
                            viewBox="0 0 842 219"
                            preserveAspectRatio="xMaxYMax meet"
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <g transform="translate(-381.000000, -362.000000)" fill="#FFFFFF">
                                <path
                                    class="decor-path"
                                    d="M1223,362 L1223,581 L381,581 C868.912802,575.666667 1149.57947,502.666667 1223,362 Z"
                                ></path>
                            </g>
                        </svg>
                    </div>

                    <div class="up-contents">
                        <div class="m-b">
                            <div class="padded">
                                <label
                                    for="file"
                                    class="space-top-10 space-b-25 btn btn-primary btn-sm text-white"
                                >
                                    <input
                                        type="file"
                                        class="d-none"
                                        id="file"
                                        ng2FileSelect
                                        [uploader]="uploader"
                                        (change)="fileOverBase($event)"
                                        accept="image/*"
                                    />
                                    <i class="fa fa-camera"></i>
                                    &nbsp;{{ isUploading ? '' : 'Update Picture' }}
                                    <span *ngIf="isUploading">
                                        <i class="fa fa-circle-o-notch fa-spin fa-1x fa-fw"></i>
                                    </span>
                                </label>
                                <div class="os-progress-bar primary">
                                    <div class="bar-labels">
                                        <div class="bar-label-left">
                                            <span>Profile Completion</span>
                                        </div>
                                        <div class="bar-label-right">
                                            <span class="info">{{ profileCompleteStats }}%</span>
                                        </div>
                                    </div>
                                    <div class="bar-level-1" style="width: 100%;">
                                        <div
                                            class="bar-level-2"
                                            [ngStyle]="{ width: profileCompleteStats + '%' }"
                                        >
                                            <div
                                                class="bar-level-3"
                                                style="width: {{ profileCompleteStats }}  %;"
                                            ></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="os-progress-bar primary">
                                    <div class="bar-labels">
                                        <div class="bar-label-left">
                                            <span>Identity Completion</span>
                                        </div>
                                        <div class="bar-label-right">
                                            <span class="info">{{ identityCompleteStats }}%</span>
                                        </div>
                                    </div>
                                    <div class="bar-level-1" style="width: 100%;">
                                        <div
                                            class="bar-level-2"
                                            [ngStyle]="{ width: identityCompleteStats + '%' }"
                                        >
                                            <div
                                                class="bar-level-3"
                                                style="width: {{ identityCompleteStats }}  %;"
                                            ></div>
                                        </div>
                                    </div>
                                    <a
                                        routerLink="/app/identity"
                                        class="space-top-25 btn btn-primary btn-sm text-white"
                                    >
                                        <i class="fa fa-shield"></i>
                                        &nbsp;Update Identity</a
                                    >
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="element-wrapper">
                    <div class="element-box">
                        <h6 class="element-header">User Preference</h6>
                        <div class="form-group">
                            <small class="text-primary text-primary"
                                >Allow other investors to see your profile?</small
                            >
                            <br />
                            <div class="form-check">
                                <input
                                    class="form-check-input"
                                    type="radio"
                                    name="rdAllowProfileView"
                                    id="rdAllowProfileViewYes"
                                    value="true"
                                    (change)="onPreferenceChange($event)"
                                    [checked]="checked == 'yes' ? 'checked' : ''"
                                />
                                <label
                                    class="form-check-label txt-sm space-l-10 text-sm"
                                    for="rdAllowProfileViewYes"
                                >
                                    Yes, Allow
                                </label>
                            </div>
                            <div class="form-check">
                                <input
                                    class="form-check-input"
                                    type="radio"
                                    name="rdAllowProfileView"
                                    id="rdAllowProfileViewNo"
                                    value="false"
                                    (change)="onPreferenceChange($event)"
                                    [checked]="checked == 'no' ? 'checked' : ''"
                                />
                                <label
                                    class="form-check-label txt-sm space-l-10"
                                    for="rdAllowProfileViewNo"
                                >
                                    No, Don't Allow
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="element-box">
                        <app-dashboard-user-update-profile
                            [bankDetails]="userBankDetails"
                        ></app-dashboard-user-update-profile>
                    </div>
                </div>

                <div class="element-wrapper"></div>
            </div>

            <div class="col-sm-7">
                <div class="element-wrapper">
                    <div class="element-box">
                        <form [formGroup]="passwordResetForm" (ngSubmit)="onResetPassword()">
                            <div class="element-info">
                                <div class="element-info-with-icon">
                                    <div class="element-info-icon">
                                        <div class="os-icon os-icon-settings"></div>
                                    </div>
                                    <div class="element-info-text">
                                        <h5 class="element-inner-header">Reset Password</h5>
                                        <div class="element-inner-desc">
                                            <code class="d-none"
                                                >You will have to login again once you reset your
                                                password</code
                                            >
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="oldPassword"> Old Password</label
                                ><input
                                    class="form-control"
                                    placeholder="Enter Old Password"
                                    required="required"
                                    type="password"
                                    id="oldPassword"
                                    formControlName="oldPassword"
                                    [ngClass]="{
                                        'f-invalid': resettingPassword && rpForm.oldPassword.errors
                                    }"
                                />
                                <div
                                    class="help-block form-text with-errors form-control-feedback"
                                ></div>
                            </div>
                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label for="newPassword"> Password</label
                                        ><input
                                            class="form-control"
                                            placeholder="New Password"
                                            required="required"
                                            type="password"
                                            id="newPassword"
                                            formControlName="password"
                                            [ngClass]="{
                                                'f-invalid':
                                                    resettingPassword && rpForm.password.errors
                                            }"
                                        />
                                        <div
                                            class="help-block form-text text-muted form-control-feedback"
                                        >
                                            Minimum of 6 characters
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label for="confirmPassword">Confirm Password</label
                                        ><input
                                            class="form-control"
                                            placeholder="Confirm New Password"
                                            required="required"
                                            type="password"
                                            id="confirmPassword"
                                            [formControlName]="'passwordConfirm'"
                                            [ngClass]="{
                                                'f-invalid':
                                                    resettingPassword &&
                                                    rpForm.passwordConfirm.errors
                                            }"
                                        />
                                        <div
                                            class="help-block form-text with-errors form-control-feedback"
                                        ></div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-buttons-w">
                                <button
                                    class="btn btn-primary"
                                    type="submit"
                                    [disabled]="resettingPassword && isSubmit"
                                >
                                    Reset Password
                                    <span *ngIf="resettingPassword && isSubmit">
                                        <i class="fa fa-circle-o-notch fa-spin fa-1x fa-fw"></i>
                                    </span>
                                </button>
                            </div>
                        </form>
                    </div>

                    <div class="element-box">
                        <form
                            [formGroup]="profileUpdateForm"
                            (ngSubmit)="onUpdateProfile()"
                            autocomplete="off"
                        >
                            <div class="element-info">
                                <div class="element-info-with-icon">
                                    <div class="element-info-icon">
                                        <div class="os-icon os-icon-wallet-loaded"></div>
                                    </div>
                                    <div class="element-info-text">
                                        <h5 class="element-inner-header">Profile Update</h5>
                                        <div class="element-inner-desc">
                                            Use the form below to update your profile information
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="form-group">
                                <div class="row">
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label for="firstName"> First Name</label
                                            ><input
                                                class="form-control"
                                                data-error="Please input your First Name"
                                                placeholder="First Name"
                                                required="required"
                                                id="firstName"
                                                formControlName="firstName"
                                                [ngClass]="{
                                                    'f-invalid': isSubmit && fm.firstName.errors
                                                }"
                                            />
                                            <div
                                                class="help-block form-text with-errors form-control-feedback"
                                                *ngIf="isSubmit && firstName.errors?.minlength"
                                            >
                                                <small>
                                                    Must be at least 3 characters
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label for="lastName">Last Name</label
                                            ><input
                                                class="form-control"
                                                data-error="Please input your Last Name"
                                                placeholder="Last Name"
                                                required="required"
                                                id="lastName"
                                                formControlName="lastName"
                                                [ngClass]="{
                                                    'f-invalid': isSubmit && fm.lastName.errors
                                                }"
                                            />
                                            <div
                                                class="help-block form-text with-errors form-control-feedback"
                                                *ngIf="isSubmit && lastName.errors?.minlength"
                                            >
                                                <small>
                                                    Must be at least 3 characters
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label for="firstName"> Email</label
                                            ><input
                                                class="form-control"
                                                placeholder="Enter Email"
                                                required="required"
                                                id="email"
                                                formControlName="email"
                                                [ngClass]="{
                                                    'f-invalid': isSubmit && fm.email.errors
                                                }"
                                            />
                                            <div
                                                class="help-block form-text with-errors form-control-feedback"
                                            ></div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label for="phone">Phone</label
                                            ><input
                                                class="form-control"
                                                placeholder="Enter Phone"
                                                required="required"
                                                id="phone"
                                                type="tel"
                                                formControlName="phone"
                                                [ngClass]="{
                                                    'f-invalid': isSubmit && fm.phone.errors
                                                }"
                                            />
                                            <div
                                                class="help-block form-text with-errors form-control-feedback"
                                            ></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label for="dob"> Date of Birth</label>
                                            <div class="input-group" (click)="d.toggle()">
                                                <div class="input-group-prepend">
                                                    <div class="input-group-text">
                                                        <i
                                                            class="typcn typcn-calendar-outline tx-24 lh--9 op-6"
                                                        ></i>
                                                    </div>
                                                </div>

                                                <input
                                                    id="dob"
                                                    type="text"
                                                    class="form-control fc-datepicker"
                                                    placeholder="MM/DD/YYYY"
                                                    [(ngModel)]="dateModel"
                                                    [ngModelOptions]="{ standalone: true }"
                                                    #d="ngbDatepicker"
                                                    ngbDatepicker
                                                    [ngClass]="{
                                                        'f-invalid': isSubmit && fm.dob.errors
                                                    }"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label for="Designation">Occupation</label>
                                            <div class="input-group">
                                                <input
                                                    class="form-control"
                                                    placeholder="Designation"
                                                    id="Designation"
                                                    formControlName="designation"
                                                    [ngClass]="{
                                                        'f-invalid':
                                                            isSubmit && fm.designation.errors
                                                    }"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="city">City</label
                                    ><input
                                        id="city"
                                        class="form-control"
                                        formControlName="city"
                                        [ngClass]="{
                                            'f-invalid': isSubmit && fm.city.errors
                                        }"
                                    />
                                </div>
                                <div class="form-group">
                                    <label for="state">State</label
                                    ><input
                                        id="state"
                                        class="form-control"
                                        formControlName="state"
                                        [ngClass]="{
                                            'f-invalid': isSubmit && fm.state.errors
                                        }"
                                    />
                                </div>
                                <div class="form-group">
                                    <label for="country">Country</label
                                    ><input
                                        id="country"
                                        class="form-control"
                                        formControlName="country"
                                        [ngClass]="{
                                            'f-invalid': isSubmit && fm.country.errors
                                        }"
                                    />
                                </div>
                                <div class="form-group">
                                    <label for="address"> Address</label
                                    ><textarea
                                        id="address"
                                        class="form-control"
                                        rows="3"
                                        formControlName="address"
                                        [ngClass]="{
                                            'f-invalid': isSubmit && fm.address.errors
                                        }"
                                    ></textarea>
                                </div>
                            </div>
                            <!--                            <div class="form-check">-->
                            <!--                                <label class="form-check-label"-->
                            <!--                                    ><input class="form-check-input" type="checkbox" />I agree to-->
                            <!--                                    terms and conditions</label-->
                            <!--                                >-->
                            <!--                            </div>-->
                            <div class="form-buttons-w">
                                <button
                                    class="btn btn-primary"
                                    type="submit"
                                    [disabled]="isLoading"
                                >
                                    Update Profile
                                    <span *ngIf="isLoading">
                                        <i class="fa fa-circle-o-notch fa-spin fa-1x fa-fw"></i>
                                    </span>
                                </button>
                                <p *ngIf="isSubmit && profileUpdateForm.invalid">
                                    <code
                                        >Oops! You have an error in your form, scroll up to fix
                                        it.</code
                                    >
                                </p>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
