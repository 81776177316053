import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardPageComponent } from '@app/dashboard/dashboard-pages/dashboard-landing-page/dashboard-page.component';
import { AuthGuard } from '@app/common/guard/auth.guard';
import { AdminGuard } from '@app/common/guard/admin.guard';
import { DashboardPropertyCreateComponent } from '@app/dashboard/dashboard-pages/admin-pages/dashboard-admin-property-pages/dashboard-admin-property-create/dashboard-property-create.component';
import { DashboardAdminPropertyComponent } from '@app/dashboard/dashboard-pages/admin-pages/dashboard-admin-property-pages/dashboard-admin-property-view/dashboard-admin-property.component';
import { DashboardAdminPropertyUpdateComponent } from '@app/dashboard/dashboard-pages/admin-pages/dashboard-admin-property-pages/dashboard-admin-property-update/dashboard-admin-property-update.component';
import { DashboardInvestmentPageComponent } from '@app/dashboard/dashboard-pages/user-pages/dashboard-investment-page/dashboard-investment-page.component';
import { DashboardReturnsPageComponent } from '@app/dashboard/dashboard-pages/user-pages/dashboard-returns-page/dashboard-returns-page.component';
import { DashboardPropertyComponent } from '@app/dashboard/dashboard-pages/user-pages/dashboard-property/dashboard-property.component';
import { DashboardIdentityComponent } from '@app/dashboard/dashboard-pages/user-pages/dashboard-identity/dashboard-identity.component';
import { DashboardProfilePageComponent } from '@app/dashboard/dashboard-pages/user-pages/dashboard-profile-page/dashboard-profile-page.component';
import { DashboardPropertyDetailsComponent } from '@app/dashboard/dashboard-pages/user-pages/dashboard-property-details/dashboard-property-details.component';
import { DashboardFaIconsComponent } from '@app/dashboard/dashboard-pages/components/dashboard-fa-icons/dashboard-fa-icons.component';
import { DashboardAdminInvestmentComponent } from '@app/dashboard/dashboard-pages/admin-pages/dashboard-admin-investment/dashboard-admin-investment.component';
import { DashboardAdminIdentityComponent } from '@app/dashboard/dashboard-pages/admin-pages/dashboard-admin-identity/dashboard-admin-identity.component';
import { DashboardAdminUserComponent } from '@app/dashboard/dashboard-pages/admin-pages/dashboard-admin-user/dashboard-admin-user.component';
import { DashboardViewUsersProfileComponent } from '@app/dashboard/dashboard-pages/admin-pages/dashboard-admin-user/dashoard-view-users-profile/dashboard-view-users-profile.component';
import { DashboardAdminReturnComponent } from '@app/dashboard/dashboard-pages/admin-pages/dashboard-admin-return/dashboard-admin-return.component';
import { DashboardUserCompleteEarningComponent } from '@app/dashboard/dashboard-pages/admin-pages/dashboard-admin-return/dashboard-user-complete-earning/dashboard-user-complete-earning.component';
import { DashboardPropertyInvestCardComponent } from '@app/dashboard/dashboard-pages/components/dashboard-property-invest-card/dashboard-property-invest-card.component';
import { DashboardSettingsComponent } from '@app/dashboard/dashboard-pages/admin-pages/dashboard-settings/dashboard-settings.component';

const routes: Routes = [
    { path: '', component: DashboardPageComponent, canActivate: [AuthGuard] },
    {
        path: 'properties',
        component: DashboardPropertyComponent,
        canActivate: [AuthGuard],
    },
    {
        path: 'property/:title/:id',
        component: DashboardPropertyDetailsComponent,
        canActivate: [AuthGuard],
    },
    {
        path: 'invest/:title/:id',
        component: DashboardPropertyInvestCardComponent,
        canActivate: [AuthGuard],
    },
    { path: 'investment', component: DashboardInvestmentPageComponent, canActivate: [AuthGuard] },
    { path: 'identity', component: DashboardIdentityComponent, canActivate: [AuthGuard] },
    { path: 'returns', component: DashboardReturnsPageComponent, canActivate: [AuthGuard] },
    { path: 'profile', component: DashboardProfilePageComponent, canActivate: [AuthGuard] },

    //admin specific route
    {
        path: 'admin',
        canActivate: [AuthGuard],
        children: [
            { path: '', component: DashboardPageComponent, canActivate: [] },
            {
                path: 'property',
                component: DashboardAdminPropertyComponent,
                canActivate: [AdminGuard],
            },
            {
                path: 'property-create',
                component: DashboardPropertyCreateComponent,
                canActivate: [AdminGuard],
            },
            {
                path: 'property-update/:search/:id',
                component: DashboardAdminPropertyUpdateComponent,
                canActivate: [AdminGuard],
            },
            {
                path: 'investments',
                component: DashboardAdminInvestmentComponent,
                canActivate: [AdminGuard],
            },
            {
                path: 'identities',
                component: DashboardAdminIdentityComponent,
                canActivate: [AdminGuard],
            },
            {
                path: 'users',
                component: DashboardAdminUserComponent,
                canActivate: [AdminGuard],
            },
            {
                path: 'users/investment/:userId',
                component: DashboardAdminInvestmentComponent,
                canActivate: [AdminGuard],
            },
            {
                path: 'users/profile/:userId',
                component: DashboardViewUsersProfileComponent,
                canActivate: [AdminGuard],
            },
            {
                path: 'users/identity/:userId',
                component: DashboardAdminIdentityComponent,
                canActivate: [AdminGuard],
            },
            {
                path: 'users/earning/:userId',
                component: DashboardUserCompleteEarningComponent,
                canActivate: [AdminGuard],
            },
            {
                path: 'returns',
                component: DashboardAdminReturnComponent,
                canActivate: [AdminGuard],
            },
            {
                path: 'settings',
                component: DashboardSettingsComponent,
                canActivate: [AdminGuard],
            },
        ],
    },
    // general
    { path: 'system-icons', component: DashboardFaIconsComponent, canActivate: [AuthGuard] },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    providers: [AdminGuard, AuthGuard],
})
export class DashboardRoutingModule {}
