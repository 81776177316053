import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService } from '@app/common/service/auth.service';
import { fastFood } from 'ionicons/icons';

@Component({
    selector: 'app-password-reset-confirm',
    templateUrl: './password-reset-confirm.component.html',
    styleUrls: ['./password-reset-confirm.component.css'],
})
export class PasswordResetConfirmComponent implements OnInit, OnDestroy {
    isSubmitted: boolean = false;
    showMessage: boolean = false;
    showWarningMessage: boolean = false;
    message: string = '';
    warningMessageTitle: string = '';
    warningMessageSub: string = '';
    isError: boolean = false;
    password: string = '';
    confirmPassword: string = '';
    token: string;
    email: string;
    subscription: Subscription = new Subscription();
    isValid: boolean = false;

    showValidationError = false;
    validationMessage = '';

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private authService: AuthService
    ) {}

    ngOnInit(): void {
        this.subscription.add(
            this.route.params.subscribe((params) => {
                this.token = params['token'];
                this.email = params['email'];
            })
        );

        if (this.token && this.email) {
            this.subscription.add(
                this.authService
                    .onVerifyPasswordResetTokenAndEmail(this.token, this.email)
                    .subscribe(
                        (result) => {
                            if (result.body.code === 200) {
                                this.isValid = true;
                            }
                            if (result.body.code === 400) {
                                this.isValid = false;
                                this.showWarningMessage = true;
                                this.warningMessageTitle = 'Token Expired';
                                this.warningMessageTitle =
                                    'Your reset token has expired, send a new request to continue';
                            }
                            if (result.body.code === 404) {
                                this.router.navigateByUrl('/password-reset').then();
                            }
                        },
                        (error) => {
                            this.router.navigateByUrl('/password-reset').then();
                        }
                    )
            );
        }
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    onResetPassword() {
        this.isSubmitted = true;
        if (this.password.length < 1 || this.confirmPassword.length < 1) {
            this.isError = true;
            this.isSubmitted = false;
            return;
        } else if (this.password !== this.confirmPassword) {
            this.isError = true;
            this.isSubmitted = false;
            this.message = `Password & Confirm Password Don't Match`;
            return;
        }

        this.subscription.add(
            this.authService
                .resetPassword({
                    password: this.password,
                    passwordConfirm: this.confirmPassword,
                    token: this.token,
                    email: this.email,
                })
                .subscribe(
                    (result) => {
                        if (result.status === 201) {
                            this.showMessage = true;
                            this.isSubmitted = false;
                            this.showValidationError = false;
                        }
                    },
                    (error) => {
                        this.showValidationError = true;
                        this.showMessage = false;
                        this.isSubmitted = false;
                        this.validationMessage = error.message;
                    }
                )
        );
    }
}
