import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    QueryList,
    ViewChildren,
} from '@angular/core';
import { environment } from '@environments/environment.prod';
import { MenuBag } from '@app/common/constant/menu-bag';
import { User } from '@app/common/payload/user';
import { MenuService } from '@app/common/service/menu.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-dashboard-mobile-menu',
    templateUrl: './dashboard-mobile-menu.component.html',
    styleUrls: ['./dashboard-mobile-menu.component.css'],
})
export class DashboardMobileMenuComponent implements OnInit, OnDestroy {
    showMenu: boolean = false;
    appName: string = environment.appName || 'Property Collabo';
    @Input() menuBag: MenuBag[];
    @Input() user: User;
    @Output() toggleContent: EventEmitter<any> = new EventEmitter<any>();
    @ViewChildren('myitems') items: QueryList<ElementRef>;
    routeIsHomePage: boolean = true;

    constructor(private menuService: MenuService, private router: Router) {}

    ngOnInit(): void {
        // if (this.router.url !== '/app') {
        //     this.routeIsHomePage = false;
        // }
    }

    onToggleMenu($event: MouseEvent, menu: MenuBag, i: number) {
        this.items.forEach((item) => {
            if (item.nativeElement.id !== `menu-${i}`) {
                item.nativeElement.classList.remove('active');
            } else {
                item.nativeElement.classList.add('active');
            }
        });
    }

    onToggleRightBar() {
        this.menuService.rightContent.next(!this.menuService.rightContent.value);
    }

    ngOnDestroy() {}
}
