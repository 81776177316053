import {
    Component,
    ElementRef,
    Input,
    OnDestroy,
    OnInit,
    QueryList,
    ViewChildren,
} from '@angular/core';
import { DashboardStatsCard, ReturnCountData } from '@app/common/payload/dashboard-stats-card';
import { BankDetails } from '@app/common/payload/user';
import { AuthService } from '@app/common/service/auth.service';
import { Subscription } from 'rxjs';
import { ReturnsService } from '@app/common/service/returns.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import Swal from 'sweetalert2';
import { WithdrawPayload } from '@app/common/payload/common.payload';
import { ToastrService } from 'ngx-toastr';
import { WithdrawStatusEnum } from '@app/common/enum/command.enum';

@Component({
    selector: 'app-dashboard-returns-page',
    templateUrl: './dashboard-returns-page.component.html',
    styleUrls: ['./dashboard-returns-page.component.css'],
    animations: [
        trigger('simpleFadeAnimation', [
            state('in', style({ opacity: 1 })),
            transition(':enter', [style({ opacity: 0 }), animate(600)]),
            transition(':leave', animate(600, style({ opacity: 0 }))),
        ]),
    ],
})
export class DashboardReturnsPageComponent implements OnInit, OnDestroy {
    @Input() props: { isAdminView: boolean; id: number };
    @ViewChildren('fulfilButtons') fulfilButtons: QueryList<ElementRef>;

    totalInvestmentData: DashboardStatsCard;
    totalEarning: DashboardStatsCard;
    totalWithdraw: DashboardStatsCard;

    userId: number;
    subscription: Subscription = new Subscription();
    countData: ReturnCountData = {};
    bankDetails: BankDetails;
    showUpdateBankDetails: boolean = false;
    isRequestingWithdraw: boolean = false;
    withdrawAmount: number = 0;
    withdraws: WithdrawPayload[];
    currentProcessingRequest: number;

    constructor(
        private authService: AuthService,
        private returnsService: ReturnsService,
        private toast: ToastrService
    ) {}

    ngOnInit(): void {
        if (this.props?.isAdminView) {
            this.userId = this.props?.id;
        } else {
            this.userId = this.authService.currentUserValue.id;
        }

        if (this.userId) {
            this.subscription.add(
                this.returnsService.getUserReturnCountDetails(this.userId).subscribe((result) => {
                    if (result.status === 200) {
                        this.countData = result.body;
                        this.totalEarning = {
                            title: 'Total Earning',
                            amount: result.body.earningData.earning,
                            showLink: true,
                            linkText: 'Increase Earning',
                            linkUrl: 'app/properties',
                        };
                        this.totalInvestmentData = {
                            title: 'Total Investment',
                            amount: result.body.totalAmountInvested,
                            showLink: true,
                            linkText: 'See My Investments',
                            linkUrl: 'app/investments',
                        };
                        this.totalWithdraw = {
                            title: 'Total Withdraw',
                            amount: result.body.earningData.withdraw,
                            showLink: false,
                        };
                    }
                })
            );

            this.subscription.add(
                this.returnsService.getUserBankDetails(this.userId).subscribe(
                    (result) => {
                        if (result.status === 200) {
                            this.bankDetails = result.body;
                        }
                    },
                    (error) => {
                        if (error.statusCode === 404) {
                            this.bankDetails = {};
                        }
                    }
                )
            );

            this.subscription.add(
                this.returnsService.onGetTransactionHistory(this.userId).subscribe((result) => {
                    if (result.status === 200) {
                        this.withdraws = result.body;
                    }
                })
            );
        }
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    onToggleUpdate() {
        this.showUpdateBankDetails = !this.showUpdateBankDetails;
    }

    async onRequestWithdraw() {
        this.isRequestingWithdraw = true;
        if (this.withdrawAmount < 1) {
            await Swal.fire({
                title: 'Withdraw request failed',
                icon: 'warning',
                html: ` <code> Your withdraw amount is too small </code>`,
            });
            this.isRequestingWithdraw = false;
            return;
        }

        if (this.withdrawAmount > this.totalEarning.amount) {
            await Swal.fire({
                title: 'Withdraw request failed',
                icon: 'warning',
                html: ` <code> Your earning balance is too low </code>`,
            });
            this.isRequestingWithdraw = false;
            return;
        }

        if (this.withdraws.length) {
            const totalWithdrawSent = this.withdraws
                .map((x) => x.amount)
                .reduce((a, b) => {
                    return Number(a) + Number(b);
                });
            if (totalWithdrawSent > this.totalEarning.amount) {
                await Swal.fire({
                    title: 'Withdraw request failed',
                    icon: 'warning',
                    html: ` <code> You have pending request, try again when those request are completed. </code>`,
                });
                this.isRequestingWithdraw = false;
                return;
            }
        }

        this.subscription.add(
            this.returnsService.onRequestWithdraw(this.userId, this.withdrawAmount).subscribe(
                async (result) => {
                    if (result.status === 201) {
                        this.isRequestingWithdraw = false;
                        this.withdrawAmount = 0;
                        await Swal.fire({
                            title: 'Success',
                            icon: 'success',
                            html: `Your withdraw request has been sent successfully.`,
                        });
                        this.withdraws.push(result.body);
                    }
                },
                async (error) => {
                    this.isRequestingWithdraw = false;
                    await Swal.fire({
                        title: 'Failed',
                        icon: 'error',
                        html: `${error.message}`,
                    });
                }
            )
        );
    }

    async onFulfilRequest(withdraw: WithdrawPayload, index: number) {
        if (withdraw.status === WithdrawStatusEnum.COMPLETED) {
            this.toast.show('This request is already completed');
            return;
        }

        if (Number(withdraw.amount) > this.totalEarning.amount) {
            Swal.fire({
                title: 'Insufficient Earning Balance',
                icon: 'info',
                html: `<small>Earning balance is too low, do you want to continue anyways?</small>`,
                confirmButtonText: 'Yes continue!',
                cancelButtonText: 'No, Go back',
                showCancelButton: true,
                allowOutsideClick: false,
            }).then((value) => {
                if (value.isConfirmed) {
                    this.onProcessRequest(withdraw);
                }
            });
        }

        this.currentProcessingRequest = index;
        Swal.fire({
            title: 'Fulfil Request',
            icon: 'info',
            html: `<small>Are you sure you want to fulfil this request?</small>`,
            confirmButtonText: 'Yes Fulfil!',
            cancelButtonText: 'No, Stop!',
            showCancelButton: true,
            allowOutsideClick: false,
        }).then((value) => {
            if (value.isConfirmed) {
                this.onProcessRequest(withdraw);
            } else {
                this.currentProcessingRequest = null;
            }
        });
    }

    onProcessRequest(withdraw: WithdrawPayload) {
        withdraw.userId = Number(this.userId);
        this.subscription.add(
            this.returnsService.onFulfilRequest(withdraw).subscribe(
                (result) => {
                    if (result.status === 200) {
                        if (result.body.status === true) {
                            this.totalEarning.amount =
                                Number(this.totalEarning.amount) - Number(withdraw.amount);
                            this.totalWithdraw.amount =
                                Number(this.totalWithdraw.amount) + Number(withdraw.amount);
                            withdraw.status = WithdrawStatusEnum.COMPLETED;
                            this.currentProcessingRequest = null;
                            this.toast.success(`${result.body.message}`);
                        }
                        if (result.body.status === false) {
                            this.toast.show(`${result.body.message}`);
                            this.currentProcessingRequest = null;
                        }
                    }
                },
                (error) => {
                    this.currentProcessingRequest = null;
                    this.toast.error(`${error.message}`, `Oops, Something's wrong`);
                }
            )
        );
    }
}
