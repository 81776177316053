import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomePageComponent } from '@app/website/website-pages/home-page/home-page.component';
import { PropertyDetailComponent } from '@app/website/website-pages/property-detail-page/property-detail.component';
import { FaqPageComponent } from '@app/website/website-pages/faq-page/faq-page.component';
import { ContactPageComponent } from '@app/website/website-pages/contact-page/contact-page.component';
import { UserDashboardComponent } from '@app/dashboard/dashboard-pages/user-pages/user-dashboard/user-dashboard.component';
import { AboutUsComponent } from '@app/website/website-pages/about-us/about-us.component';
import { DisclaimerComponent } from '@app/website/website-pages/disclaimer/disclaimer.component';
import { TermsOfUseComponent } from '@app/website/website-pages/terms-of-use/terms-of-use.component';
import { TermsOfSponsorshipComponent } from '@app/website/website-pages/terms-of-sponsorship/terms-of-sponsorship.component';
import { PrivacyPolicyComponent } from '@app/website/website-pages/privacy-policy/privacy-policy.component';

const routes: Routes = [
    { path: '', component: HomePageComponent },
    { path: 'contact', component: ContactPageComponent },
    { path: 'faq', component: FaqPageComponent },
    { path: 'about', component: AboutUsComponent },
    { path: 'disclaimer', component: DisclaimerComponent },
    { path: 'terms-of-use', component: TermsOfUseComponent },
    { path: 'terms-of-sponsorship', component: TermsOfSponsorshipComponent },
    { path: 'privacy-policy', component: PrivacyPolicyComponent },
    { path: 'property/:id', component: PropertyDetailComponent },
    {
        path: 'dashboard',
        component: UserDashboardComponent,
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
})
export class WebsiteRoutingModule {}
