import {
    AfterViewInit,
    Component,
    ElementRef,
    Input,
    OnInit,
    QueryList,
    ViewChildren,
} from '@angular/core';
import { PropertyPayload } from '@app/common/payload/property.payload';
import { PropertyTypeEnum } from '@app/common/enum/property.enums';

@Component({
    selector: 'app-feature-section',
    templateUrl: './feature-section.component.html',
    styleUrls: ['./feature-section.component.css'],
})
export class FeatureSectionComponent implements OnInit, AfterViewInit {
    @ViewChildren('tabs') tabs: QueryList<ElementRef>;

    @Input() data: PropertyPayload[];
    properties: PropertyPayload[] = [];
    propertyTypes: string[] = [];
    toggled: boolean = false;

    constructor() {}

    ngOnInit() {
        for (let val in PropertyTypeEnum) {
            this.propertyTypes.push(val.toString());
        }
    }

    onToggleFilter(type: string, i: number) {
        this.tabs.forEach((item) => {
            if (item.nativeElement.id !== `menu-${i}`) {
                item.nativeElement.classList.remove('active');
            } else {
                item.nativeElement.classList.add('active');
            }
        });

        if (!this.properties.length) {
            this.properties = this.data.map((e) => ({ ...e }));
        }

        let filter: PropertyTypeEnum = PropertyTypeEnum[type];

        if (filter === PropertyTypeEnum.ALL) {
            this.data = this.properties.slice();
        } else {
            const result: PropertyPayload[] = [];
            this.properties.forEach((p) => {
                if (p.type === filter) {
                    result.push(p);
                }
            });
            this.data = result;
        }
    }

    ngAfterViewInit(): void {}

    onEncode(title: string) {
        return encodeURI(title);
    }
}
