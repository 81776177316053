<div class="row">
    <div class="col-sm-12">
        <div class="element-wrapper">
            <div class="element-actions"></div>
            <h6 class="element-header text-uppercase">Dashboard</h6>
            <div class="element-content">
                <div class="row">
                    <div class="col-sm-4 col-xxxl-3">
                        <app-dashboard-stats-card [data]="userCountCard"></app-dashboard-stats-card>
                    </div>
                    <div class="col-sm-4 col-xxxl-3">
                        <app-dashboard-stats-card
                            [data]="propertyCountCard"
                        ></app-dashboard-stats-card>
                    </div>
                    <div class="col-sm-4 col-xxxl-3">
                        <app-dashboard-stats-card
                            [data]="investmentAmountCard"
                        ></app-dashboard-stats-card>
                    </div>
                    <div class="col-sm-4 col-xxxl-3">
                        <app-dashboard-stats-card
                            [data]="pendingInvestmentCount"
                        ></app-dashboard-stats-card>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
