<a class="element-box el-tablo">
    <div class="label">{{ data?.title }}</div>
    <div *ngIf="data; else loading">
        <div class="value" *ngIf="data?.isMoney">
            {{ data.count == null ? 0 : (data.count | shortNumber: '₦') }}
        </div>
        <div class="value" *ngIf="!data?.isMoney">
            {{ data.count == null ? 0 : data.count }}
            <small *ngIf="data?.hasSubText" style="font-size: 30%;">{{ data.subText }}</small>
        </div>
        <div class="trending trending-up-basic" *ngIf="data.showHint">
            <span></span
            ><i
                class="os-icon os-icon-info text-primary"
                [ngbTooltip]="data.hint"
                container="body"
                [placement]="data.hintPosition"
            ></i>
        </div>
        <div class="balance-link">
            <a
                *ngIf="data.showLink"
                [routerLink]="data.linkUrl"
                class="btn btn-link btn-underlined"
            >
                <span>{{ data.linkText }}</span>
                <i class="os-icon os-icon-arrow-right4"></i>
            </a>
        </div>
    </div>
</a>

<ng-template #loading>
    <div class="text-center" style="padding: 13px;">
        <i class="fa fa-spinner fa-spin"></i>
    </div>
</ng-template>
