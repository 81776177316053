<div class="all-wrapper menu-side with-pattern">
    <div class="auth-box-w wider">
        <div class="logo-w">
            <a routerLink="">
                <cl-image public-id="property-collabo/defaults/logo_c5cpbc">
                    <cl-transformation width="100" crop="scale"> </cl-transformation>
                </cl-image>
            </a>
        </div>
        <h4 class="auth-header text-uppercase letter-space-2">Reset Password</h4>
        <div *ngIf="showMessage" class="alert col-primary text-white" role="alert">
            <strong>
                Success
                <br />
                <small>
                    Password reset was successful.
                    <a routerLink="/sign-in" class="text-primary">Sign In Here</a>
                </small>
            </strong>
        </div>

        <form *ngIf="isValid; else warningBlock">
            <div *ngIf="showValidationError" class="alert alert-danger text-white" role="alert">
                <strong>
                    {{ validationMessage }}
                </strong>
            </div>
            <div class="row">
                <div class="col-sm-12">
                    <div class="form-group">
                        <label> Password</label
                        ><input
                            class="form-control"
                            placeholder="Password"
                            name="password"
                            [ngClass]="{ 'f-invalid': isError }"
                            [(ngModel)]="password"
                            ngModelOptions="{standalone: true}"
                            type="password"
                        />
                        <div class="pre-icon os-icon os-icon-fingerprint"></div>
                    </div>
                </div>
                <div class="col-sm-12">
                    <div class="form-group">
                        <label>Confirm Password</label
                        ><input
                            class="form-control"
                            name="confirmPassword"
                            [ngClass]="{ 'f-invalid': isError }"
                            [(ngModel)]="confirmPassword"
                            ngModelOptions="{standalone: true}"
                            placeholder="Confirm Password"
                            type="password"
                        />
                    </div>
                </div>
            </div>
            <div class="buttons-w">
                <button
                    class="btn btn-primary"
                    [disabled]="isSubmitted"
                    (click)="onResetPassword()"
                >
                    Reset Password
                    <span *ngIf="isSubmitted">
                        <i class="fa fa-circle-o-notch fa-spin fa-1x fa-fw"></i>
                    </span>
                </button>
            </div>
        </form>

        <ng-template #warningBlock>
            <div *ngIf="showWarningMessage" class="alert col-danger mg-sm text-white" role="alert">
                <strong>
                    {{ warningMessageTitle }}
                    <br />
                    <small>
                        {{ warningMessageSub }}
                    </small>
                    <a routerLink="/password-reset" class="reset-text reset-link"
                        ><u>Reset Here</u></a
                    >
                </strong>
            </div>
        </ng-template>
    </div>
</div>
