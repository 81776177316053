<div class="all-wrapper menu-side with-pattern">
    <div class="auth-box-w wider">
        <div class="logo-w">
            <a routerLink="">
                <cl-image public-id="property-collabo/defaults/logo_c5cpbc">
                    <cl-transformation width="100" crop="scale"> </cl-transformation>
                </cl-image>
            </a>
        </div>
        <h4 class="auth-header text-uppercase letter-space-2">Reset Password</h4>
        <div *ngIf="showMessage" class="alert col-primary text-white" role="alert">
            <strong>
                Check your email for password reset link.
                <br />
                <small>
                    {{ message }}
                </small>
            </strong>
        </div>

        <form>
            <div class="form-group">
                <label>Enter Your Email Address</label
                ><input
                    name="email"
                    [(ngModel)]="email"
                    ngModelOptions="{standalone: true}"
                    class="form-control"
                    placeholder="Enter email"
                    type="email"
                    [ngClass]="{ 'f-invalid': isError }"
                />

                <div class="pre-icon os-icon os-icon-email-2-at2"></div>
                <div class="invalid-feedback" #feedback>
                    {{ message }}
                </div>
            </div>
            <!--            <div class="row">-->
            <!--                <div class="col-sm-6">-->
            <!--                    <div class="form-group">-->
            <!--                        <label for=""> Password</label-->
            <!--                        ><input class="form-control" placeholder="Password" type="password" />-->
            <!--                        <div class="pre-icon os-icon os-icon-fingerprint"></div>-->
            <!--                    </div>-->
            <!--                </div>-->
            <!--                <div class="col-sm-6">-->
            <!--                    <div class="form-group">-->
            <!--                        <label for="">Confirm Password</label-->
            <!--                        ><input class="form-control" placeholder="Password" type="password" />-->
            <!--                    </div>-->
            <!--                </div>-->
            <!--            </div>-->
            <div class="buttons-w">
                <button class="btn btn-primary" [disabled]="isSubmitted" (click)="onVerifyEmail()">
                    Verify Email
                    <span *ngIf="isSubmitted">
                        <i class="fa fa-circle-o-notch fa-spin fa-1x fa-fw"></i>
                    </span>
                </button>
            </div>
        </form>
    </div>
</div>
