import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@environments/environment.prod';
import { Router } from '@angular/router';
import { AuthPayload } from '@app/common/payload/auth.payload';
import { finalize, map } from 'rxjs/operators';
import { LocalStorageService } from 'ngx-webstorage';
import { BehaviorSubject, Observable } from 'rxjs';
import { User } from '@app/common/payload/user';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    uriEndpoint: string = environment.url;
    storageUserKey = 'uud';

    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;
    constructor(
        private http: HttpClient,
        private router: Router,
        private localStorage: LocalStorageService
    ) {
        this.currentUserSubject = new BehaviorSubject<User>(
            localStorage.retrieve(this.storageUserKey)
        );
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public isAuthenticated(): boolean {
        return this.localStorage.retrieve(this.storageUserKey) != null;
    }

    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }

    public isAdmin(): boolean {
        if (this.currentUserValue) {
            return this.currentUserValue.isAdmin;
        }
        return false;
    }

    public signin(payload: AuthPayload) {
        return this.http
            .post<AuthPayload>(`${this.uriEndpoint}/auth/signin`, payload, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    observe: 'response',
                }),
            })
            .pipe(
                map((user) => {
                    if (user && user.accessToken) {
                        this.localStorage.store(this.storageUserKey, user);
                        this.updateUserSubject(user);
                    }
                    return user;
                })
            );
    }

    public signup(payload: AuthPayload) {
        return this.http.post(`${this.uriEndpoint}/auth/signup`, payload, {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
            observe: 'response',
        });
    }

    public signout() {
        this.localStorage.clear(this.storageUserKey);
        this.currentUserSubject.next(null);
        this.router.navigateByUrl('/sign-in').then();
    }

    public updateUserSubject(userPayload: User | AuthPayload) {
        if (this.localStorage.retrieve(this.storageUserKey) != null) {
            // user is updating profile
            userPayload.isVerified = this.localStorage.retrieve(this.storageUserKey).isVerified;
            userPayload.isAdmin = this.localStorage.retrieve(this.storageUserKey).isAdmin;
            userPayload.id = this.localStorage.retrieve(this.storageUserKey).id;
            userPayload.accessToken = this.localStorage.retrieve(this.storageUserKey).accessToken;
        }
        this.currentUserSubject.next({
            firstName: userPayload.firstName,
            lastName: userPayload.lastName,
            email: userPayload.email,
            phone: userPayload.phone,
            city: userPayload.city,
            state: userPayload.state,
            country: userPayload.country,
            address: userPayload.address,
            designation: userPayload.designation,
            image: userPayload.image,
            dob: userPayload.dob,
            id: userPayload.id,
            isVerified: userPayload.isVerified,
            isAdmin: userPayload.isAdmin,
            accessToken: userPayload.accessToken,
            preference: userPayload.preference,
        });
        this.localStorage.store(this.storageUserKey, userPayload);
    }

    public onValidateEmailToken(token: string, email: string) {
        return this.http.post<boolean>(
            `${this.uriEndpoint}/auth/email/verify`,
            { token: token, email: email },
            { observe: 'response' }
        );
    }

    public onVerifyEmailBeforePasswordReset(email: string) {
        return this.http.get(`${this.uriEndpoint}/auth/password/reset/${email}`, {
            observe: 'response',
        });
    }

    public onVerifyPasswordResetTokenAndEmail(token: string, email: string) {
        return this.http.get<{ message: string; code: number }>(
            `${this.uriEndpoint}/auth/verify/reset-password/${token}/${email}`,
            { observe: 'response' }
        );
    }

    resetPassword(param: {
        passwordConfirm: string;
        password: string;
        token: string;
        email: string;
    }) {
        return this.http.post(
            `${this.uriEndpoint}/auth/password/reset/${param.token}/${param.email}`,
            param,
            { observe: 'response' }
        );
    }

    resendVerificationEmail(emailToVerify: string, observe: string = 'response') {
        return this.http.post<void>(
            `${this.uriEndpoint}/auth/email/resend-verification/`,
            { email: emailToVerify },
            { observe: 'response' }
        );
    }
}
