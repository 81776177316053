<div class="chart">
    <canvas
        baseChart
        [datasets]="lineChartData"
        [labels]="lineChartLabels"
        [options]="lineChartOptions"
        [colors]="lineChartColors"
        [legend]="lineChartLegend"
        [chartType]="lineChartType"
        [plugins]="lineChartPlugins"
        [width]="style?.width"
        [height]="style?.height"
    >
    </canvas>
</div>
