import { Component, Input, OnInit } from '@angular/core';
import { ChartDataSets, ChartType } from 'chart.js';
import { MultiDataSet, Label, Color } from 'ng2-charts';

@Component({
    selector: 'app-dashboard-chart',
    templateUrl: './dashboard-chart.component.html',
    styleUrls: ['./dashboard-chart.component.css'],
})
export class DashboardChartComponent implements OnInit {
    @Input() chart?: any;
    @Input() style: { width: number; height: number };

    // lineChartData: ChartDataSets[] = [
    //     { data: [85, 72, 78, 75, 77, 75], label: 'Crude oil prices' },
    // ];

    lineChartData: ChartDataSets[] = [
        {
            label: '',
            fill: !0,
            lineTension: 0.4,
            backgroundColor: 'linear-gradient(to bottom, #33ccff 0%, #ff99cc 100%)',
            borderColor: '#8f1cad',
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0,
            borderJoinStyle: 'miter',
            pointBorderColor: '#fff',
            pointBackgroundColor: '#2a2f37',
            pointBorderWidth: 2,
            pointHoverRadius: 6,
            pointHoverBackgroundColor: '#FC2055',
            pointHoverBorderColor: '#fff',
            pointHoverBorderWidth: 2,
            pointRadius: 4,
            pointHitRadius: 5,
            data: [13, 28, 19, 24, 43, 49],
            spanGaps: !1,
        },
    ];

    lineChartLabels: Label[] = ['January', 'February', 'March', 'April', 'May', 'June'];

    lineChartOptions = {
        responsive: true,
        showLines: true,
        legend: { display: !1 },
        scales: {
            xAxes: [
                {
                    display: !1,
                    ticks: { fontSize: '11', fontColor: '#969da5' },
                    gridLines: { color: 'rgba(0,0,0,0.0)', zeroLineColor: 'rgba(0,0,0,0.0)' },
                },
            ],
            yAxes: [{ display: !1, ticks: { beginAtZero: !0, max: 55 } }],
        },
    };

    lineChartColors: Color[] = [
        {
            borderColor: 'black',
            backgroundColor: 'rgba(255,255,0,0.28)',
        },
    ];

    lineChartLegend = true;
    lineChartPlugins = [];
    lineChartType: ChartType = 'line';

    constructor() {}

    ngOnInit(): void {}
}
