<footer class="prop-footer-version-2">
    <div class="prop-footer-top-layer">
        <div class="container">
            <div class="row">
                <div class="col-md-6 col-lg-4">
                    <div class="prop-single-footer-v2-content wow fadeInUp" data-wow-duration="1s">
                        <div class="xs-footer-logo xs-mb-40">
                            <app-logo [props]="{ type: 'white' }"></app-logo>
                        </div>
                        <div class="prop-footer-content xs-mb-30">
                            <p class="color-white xs-mb-0 text-justify">
                                At Property Collabo, we are brought together by the shared vision of
                                transforming lives and families though real estate investing.
                            </p>
                        </div>
                        <a
                            routerLink="/app/properties"
                            class="xs-btn btn text-capitalize btn-primary btn-lg round-btn text-white"
                            >Find Property</a
                        >
                    </div>
                </div>
                <div class="col-md-6 col-lg-2">
                    <div class="prop-single-footer-v2 wow fadeInUp" data-wow-duration="1.2s">
                        <div class="xs-footer-title">
                            <h4 class="color-white">Quick Links</h4>
                        </div>
                        <nav class="xs-footer-menu xs-footer-menu-v2">
                            <ul>
                                <li class="text-white point"><a routerLink="/">Home</a></li>
                                <li class="text-white point">
                                    <a routerLink="/contact">Contact us</a>
                                </li>
                                <li class="text-white point"><a routerLink="/faq">FAQ</a></li>
                            </ul>
                        </nav>
                    </div>
                </div>
                <div class="col-md-6 col-lg-2">
                    <div class="prop-single-footer-v2 wow fadeInUp" data-wow-duration="1.4s">
                        <div class="xs-footer-title">
                            <h4 class="color-white">Company</h4>
                        </div>
                        <nav class="xs-footer-menu xs-footer-menu-v2">
                            <ul>
                                <li class="text-white point">
                                    <a routerLink="/about">About Us</a>
                                </li>
                                <!--                                <li class="text-white point"><a routerLink="/why-us">Why Us</a></li>-->
                                <li class="text-white point">
                                    <a routerLink="/disclaimer">Disclaimer</a>
                                </li>
                                <li class="text-white point">
                                    <a routerLink="/privacy-policy">Privacy Policy</a>
                                </li>

                                <li class="text-white point">
                                    <a routerLink="/terms-of-use">Terms of Use</a>
                                </li>
                                <li class="text-white point">
                                    <a routerLink="/terms-of-sponsorship">Terms of Sponsorship</a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
                <div class="col-md-6 col-lg-4">
                    <div class="prop-single-footer-v2 wow fadeInUp" data-wow-duration="1.6s">
                        <div class="xs-footer-title">
                            <h4 class="color-white">Contact</h4>
                        </div>
                        <ul class="xs-list-item-icon-text-v2">
                            <li class="text-white">
                                <i class="icon icon-paper-plane"></i>
                                <div class="xs-contact-contet">
                                    Lekki, Lagos, Nigeria.
                                </div>
                            </li>
                            <li class="text-white">
                                <i class="icon icon-phone-call"></i>
                                <div class="xs-contact-contet">
                                    +234(0)80-9809-9040
                                </div>
                            </li>
                            <li class="text-white">
                                <i class="icon icon-email"></i>
                                <div class="xs-contact-contet">
                                    <a href="mailto:support@propertycollabo.com" class="d-block"
                                        >support@propertycollabo.com</a
                                    >
                                    <a href="mailto:info@propertycollabo.com" class="d-block"
                                        >info@propertycollabo.com</a
                                    >
                                </div>
                            </li>
                            <li class="text-white">
                                <i class="icon icon-internet"></i>
                                <div class="xs-contact-contet">
                                    <a href="https://propertycollabo.com"
                                        >www.propertycollabo.com</a
                                    >
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="prop-footer-bottom-v2">
        <div class="container">
            <div class="row">
                <div class="col-md-6">
                    <div class="prop-copyright-text-v2 wow fadeInUp" data-wow-duration="1.8s">
                        <p>
                            Built with <i class="fa fa-heart"></i>
                            <a></a>
                        </p>
                    </div>
                </div>
                <div class="col-md-6">
                    <div
                        class="xs-footer-bottom-wraper text-right wow fadeInUp"
                        data-wow-duration="2s"
                    >
                        <ul class="xs-social-list xs-social-list-v7">
                            <li class="xs-text-content">Follow us</li>
                            <li>
                                <a class="point"><i class="fa fa-facebook"></i></a>
                            </li>
                            <li>
                                <a class="point"><i class="fa fa-twitter"></i></a>
                            </li>

                            <li>
                                <a class="point"><i class="fa fa-instagram"></i></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>
