<app-website-header></app-website-header>
<div class="container">
    <div class="text-justify">
        <div class="prop-heading-title text-center xs-mb-60 space-top-25">
            <h2 class="color-navy-blue xs-mb-0">Privacy Policy</h2>
        </div>
        <p>
            PropertyCollabo Limited (“PropertyCollabo”, “we”, “us”, “our” “Company”), recognizes
            your privacy rights as guaranteed under the 1999 Constitution of the Federal Republic of
            Nigeria; The Nigerian Data Protection Regulation, and other applicable privacy laws in
            Nigeria.
            <br />
            Thus, it is important to us as a law-abiding organisation that your Personal Data is
            managed, processed and protected in accordance with the provisions of the applicable
            laws. In the course of our business and/or your engagement with us and third parties
            through our Platforms (this includes but are not limited to our websites, digital
            platforms, mobile applications, physical operations/offices, amongst others), we may
            process your Personal Data, subject however to the terms of this Policy. This Privacy
            Policy (“Policy”) therefore explains our privacy practices with respect to how we
            collect, process your Personal Data and describes your rights as a user of any of our
            services and Platforms.
            <br />
            This Policy applies to all our Platforms, and all related sites, applications, services
            and tools regardless of how they are accessed or used. In this Privacy Policy, “we”,
            “us” or “our” refers to PropertyCollabo Limited and its successors, agents and assigns,
            while “you”, “your” refers to you and/or any person who subscribes for, uses or
            authorizes the use of the Service.
            <br />
            As a user of any of our Platforms, you accept this Privacy Policy when you sign up for,
            or use our products, services, content, technologies or functions offered on our
            Platforms and all related sites and services (“Services”).
            <br />
            Our Privacy Policy explains our user’s privacy rights regarding:
            <br />
            What information we collect

            <br />
            Why we collect such information

            <br />
            The use of such information The storage, sharing and protection of such information
        </p>

        <div class="xs-section-heading">
            <div class="xs-heading-title">
                <h2 class="color-navy-blue">
                    Definitions For the purpose of this Policy
                </h2>
                <p>
                    Personal Data/Information means any information relating to you, including your
                    name, identification number, location data, online identifier address, photo,
                    email address, pins, passwords, bank details, date of birth, nationality, trade,
                    and other unique identifiers such as but not limited to MAC address, IP address,
                    Bank Verification Number, SIM and others.
                </p>

                <p>
                    Process/Processing means any operation or set of operations which is performed
                    on your Personal Data or sets of Personal Data, whether or not by automated
                    means, such as collection, recording, organisation, structuring, storage,
                    adaptation or alteration, retrieval, consultation, use, disclosure by
                    transmission, dissemination or otherwise making available, alignment or
                    combination, restriction, erasure or destruction.
                </p>
            </div>
        </div>

        <div class="xs-section-heading">
            <div class="xs-heading-title">
                <h2 class="color-navy-blue">
                    Consent
                </h2>
                <p>
                    We kindly request that you carefully read through this Policy and click on the
                    ‘Agree’ icon below, if you consent to the terms of this Policy, particularly
                    with respect to the processing of your Personal Data. That notwithstanding, if
                    you proceed to use any of our Platforms and services, it will be deemed that you
                    have provided your express consent to the terms of this Policy.
                </p>
            </div>
        </div>

        <div class="xs-section-heading">
            <div class="xs-heading-title">
                <h2 class="color-navy-blue">
                    Age
                </h2>
                <p>
                    You must be at least 18 years old to use our services or any of our Platforms.
                    Individuals under the age of 18, or applicable age of maturity, may utilize our
                    Platforms services only with involvement of a parent or legal guardian, under
                    such a person's account. Regardless, all Personal Data which we may process
                    shall be in accordance with this Policy and other applicable laws.
                </p>
            </div>
        </div>

        <div class="xs-section-heading">
            <div class="xs-heading-title">
                <h2 class="color-navy-blue">
                    Collection of Personal Data
                </h2>
                <p>
                    In the course of your engagement with us or with third parties through our
                    Platforms, we may collect your Personal Data in the following ways:
                </p>
            </div>
        </div>

        <div class="xs-section-heading">
            <div class="xs-heading-title">
                <h2 class="color-navy-blue">
                    Automatic Information collection
                </h2>
                <p>
                    We may automatically collect Information upon your engagement with us or our
                    Platforms through your computer, mobile phones, forms, documents made available
                    to us and other access devices. The information sent includes, but is not
                    limited to, data about our pages you access, geo-location information;
                    statistics on page views; traffic to and from our Platforms.
                </p>
            </div>
        </div>

        <div class="xs-section-heading">
            <div class="xs-heading-title">
                <h2 class="color-navy-blue">
                    Information from downloads
                </h2>
                <p>
                    When you download or use our digital Platforms or access our site, we may
                    receive non personal information from your mobile device about your location,
                    number of downloads, geo location of downloads. You may disable your location in
                    the settings menu of the device.
                </p>
            </div>
        </div>

        <div class="xs-section-heading">
            <div class="xs-heading-title">
                <h2 class="color-navy-blue">
                    Physically or Digitally Provided Information:
                </h2>
                <p>
                    We may collect and process your information when you create and/or update your
                    account on our Platform; complete forms, questionnaires, surveys etc. We may
                    also collect Information from data you provide to us through documents, letters,
                    e-mail, agreements, correspondence, ID cards, passports, or through any other
                    means by which you wilfully provide information to us. When you use
                    PropertyCollabo Services, we may also collect information about your
                    transactions and your activities. In addition, when you open an account on our
                    Platform, we may collect information such as your contact information, which
                    includes your name, address, phone number, email or other similar information;
                    financial information such as your full bank account numbers and/or credit card
                    or debit card numbers.
                </p>
            </div>
        </div>

        <div class="xs-section-heading">
            <div class="xs-heading-title">
                <h2 class="color-navy-blue">
                    Third Parties
                </h2>
                <p>
                    We may also receive your information from third parties such as, guardians,
                    financial institutions, identity verification services, vendors, and service
                    providers etc.
                </p>
            </div>
        </div>

        <div class="xs-section-heading">
            <div class="xs-heading-title">
                <h2 class="color-navy-blue">
                    Social Media
                </h2>
                <p>
                    We also receive Information through your engagements with us on social media
                    sites (e.g., Facebook, Instagram, LinkedIn, Twitter, etc). This includes but are
                    not limited to your replies to our posts, your comments, enquiries, messages to
                    us, etc. We may also collect information from your public profile and updates on
                    social media.
                </p>
            </div>
        </div>

        <div class="xs-section-heading">
            <div class="xs-heading-title">
                <h2 class="color-navy-blue">
                    Accessing your Personal Information
                </h2>
                <p>
                    When accessing our Platforms, we may collect information about you and your
                    interactions with the Services to which we undertake to keep secure and
                    confidential.
                </p>
                <p>
                    We may offer you the ability to connect with our Platforms using a mobile
                    device, either through a mobile application (App), computer, mobile optimized
                    website, or by any other means. The provisions of this Privacy Policy apply to
                    all such mobile access and use of mobile devices.
                </p>
                <p>
                    When you download or use our mobile applications, or access one of our mobile
                    optimized sites, we may receive information about your location and your mobile
                    device, including a unique identifier for your device. We may use this
                    information to provide you with location-based services, such as advertising,
                    search results, and other personalized content. Most mobile devices allow you to
                    control or disable location services in the device's setting's menu. If you have
                    questions about how to disable your device’s location services, we recommend you
                    contact your mobile service carrier or the manufacture of your particular
                    device.
                </p>
            </div>
        </div>

        <div class="xs-section-heading">
            <div class="xs-heading-title">
                <h2 class="color-navy-blue">
                    Using Your Personal Information
                </h2>
                <p>
                    In the course of your engagements with us or through our Platforms, we collect
                    personal information for various legal reasons, largely to enable us to
                    personalize your experience and to provide a more efficient service to you. Some
                    of the reasons we collect Information are to:
                </p>
            </div>
        </div>

        <div class="xs-section-heading">
            <div class="xs-heading-title">
                <h2 class="color-navy-blue">
                    Provide services and customer support
                </h2>
                <p>
                    process transactions, payment requests, and send notices about transactions;
                </p>
                <p>
                    create an account with us for the provision or use of our services;
                </p>
                <p>
                    communicate with you about your account or transactions with us and send you
                    information or request feedback about features on our website and applications
                    or changes to our policies;
                </p>
                <p>
                    send you periodic emails and updates pertaining to our products and services;
                </p>
                <p>
                    verify customers’ identity, including during account creation and password reset
                    processes;
                </p>
                <p>
                    manage your account and provide you with efficient customer service,
                </p>
                <p>
                    send you offers and promotions for our services and investment opportunities
                </p>
                <p>
                    resolve disputes, process payments and troubleshoot problems;
                </p>
                <p>
                    detect, investigate and prevent activities that may violate our policies or be
                    illegal;
                </p>
                <p>
                    manage risks, or to detect, prevent, and/or remediate fraud, violation of
                    policies and applicable user agreements or other potentially prohibited or
                    illegal activities;
                </p>
                <p>
                    execute our contractual obligations to you;
                </p>
                <p>
                    improve our services and functionality by customizing user experience;
                </p>
                <p>
                    improve our services and functionality by customizing user experience;
                </p>
                <p>
                    measure the performance of our services and improve their content and layout;
                </p>
                <p>
                    manage and protect our information technology infrastructure;
                </p>
                <p>
                    provide targeted marketing and advertising, provide service or transaction
                    update notices, and deliver promotional offers based on communication
                    preferences;
                </p>
                <p>
                    obtain a means by which we may contact you; either by telephone, text (SMS),
                    email messaging, social media, etc;
                </p>
                <p>
                    conduct background checks, compare information for accuracy and verify same with
                    third parties;
                </p>
                <p>
                    identify or address a violation and administer our policies and terms of use;
                </p>
                <p>
                    comply with legal, contractual and regulatory obligations;
                </p>
                <p>
                    execute your specific requests or use same for a specific purpose as you may
                    instruct;
                </p>

                <p>
                    investigate and respond to your complaints or enquiries;
                </p>

                <p>
                    process your access to our services, Platforms, or functions from time to time;
                </p>
                <p>
                    If we intend to use any Personal Information in any manner that is not
                    consistent with this Privacy Policy, you will be informed of such anticipated
                    use prior to or at the time at which the Personal information is required and
                    obtain your consent.
                </p>
            </div>
        </div>

        <div class="xs-section-heading">
            <div class="xs-heading-title">
                <h2 class="color-navy-blue">
                    Storage and protection of your Data
                </h2>
                <p>
                    We protect your personal Information using physical, technical, and
                    administrative security measures to reduce the risks of loss, misuse,
                    unauthorized access, unauthorised disclosure and alteration. Some of our
                    safeguards include firewall protection, Virtual Private Network (VPN)
                    protection, encryption of data, authorization keys for authorised access and
                    strong passwords. Access to our various platforms is restricted to authorized
                    users only. Your Information is also stored on our secure servers as well as
                    secure physical locations and cloud infrastructure (where applicable). Please be
                    aware that, despite our best efforts, no security measures are perfect or
                    impenetrable. We will retain your personal information for the length of time
                    needed to fulfil the purposes outlined in this privacy policy unless a longer
                    retention period is required or permitted by law. To dispose of personal data,
                    we may anonymize it, delete it or take other appropriate steps. Data may persist
                    in copies made for backup and business continuity purposes for additional time.
                </p>
                <p>
                    We will take all necessary measures to ensure that your personal Data is safe,
                    however, you are also required to ensure that access codes, PINs, passwords,
                    usernames, emails and all other information or hints that may enable third party
                    access to your accounts on our Platforms are secure. We therefore strongly
                    advise you to keep such information secure and confidential. If you use a third
                    party’s device (laptops, phones, public internet, etc.) to access your account,
                    kindly ensure that you always log out. Kindly note however that certain devices
                    are programmed to save passwords or emails, as such, we advise that you use
                    third party devices with extreme caution. If you believe that an unauthorized
                    person has accessed your information, please contact us immediately.
                </p>
            </div>
        </div>

        <div class="xs-section-heading">
            <div class="xs-heading-title">
                <h2 class="color-navy-blue">
                    Physically or Digitally Provided Information:
                </h2>
                <p>
                    In order to execute our obligations to you or process your transactions, we may
                    be required to process your Information, such as your name, account number,
                    account ID, contact details, address, or other information needed to complete
                    the transaction. We also work with third parties, including financial
                    institutions, vendors, service providers, who at one point or the other
                    facilitate transactions executed on our Platforms. For completeness, in the
                    course of your engagement with us or use of our services and Platforms, we may
                    share your information with different stakeholders, including but not limited to
                    Financial institutions; service providers; Credit bureaus and collection
                    agencies to report account or credit information; PropertyCollabo’s subsidiaries
                    and affiliates; Regulatory or judicial authorities; or other third parties
                    pursuant to a subpoena, court order, or other legal process or requirement
                    applicable to PropertyCollabo. Please note that the aforementioned parties may
                    be within or outside Nigeria.
                </p>
                <p>
                    We may also process your Information when we believe, in our sole discretion,
                    that the disclosure of your Information is necessary to comply with applicable
                    laws and judicial/regulatory orders; conduct investigations; manage existing or
                    imminent risks, prevent fraud, crime or financial loss, or for public safety or
                    to report suspected illegal activity or to investigate violations of our Terms
                    and Conditions.
                </p>

                <p>
                    In all cases, we will ensure that your Information is safe, and notify the
                    receiving party of the confidential nature of your Information, particularly the
                    need to maintain the confidentiality of same and prevent unlawful or
                    unauthorised usage.
                </p>
            </div>
        </div>

        <div class="xs-section-heading">
            <div class="xs-heading-title">
                <h2 class="color-navy-blue">
                    Your Rights
                </h2>
                <p>
                    You have the following rights regarding your personal information collected by
                    us:
                </p>
                <p>
                    Right to access your personal information being held by us.
                </p>

                <p>
                    Right to request that your personal data be made available to you in an
                    electronic format or that it should be sent to a third party (Kindly note that
                    we have the right to decline such request if it is too frequent, unreasonable,
                    and likely to cause substantial cost to us. In any event, we will communicate
                    the reason for our refusal);
                </p>

                <p>
                    Right to rectify any inaccurate, incomplete information. As such, if you
                    discover any inaccuracy in your personal information, kindly notify us promptly
                    and provide us with documentary evidence to enable us to update the requisite
                    changes.
                </p>

                <p>
                    Right to withdraw consent for the processing of your information, provided that
                    such withdrawal shall not invalidate any processing hitherto done based on the
                    consent previously given by you;
                </p>

                <p>
                    Restrict or object to the processing of your personal data provided that we may
                    be compelled to process your data where required under law, regulatory
                    authorities, or court of law.
                </p>

                <p>
                    Right to request that your personal data be deleted. We may however continue to
                    retain the information where required under law, contract, regulatory
                    authorities, or court of law.
                </p>
            </div>
        </div>

        <div class="xs-section-heading">
            <div class="xs-heading-title">
                <h2 class="color-navy-blue">
                    Disclosures
                </h2>
                <p>
                    We may share your personal information with:
                </p>
                <p>
                    Members of the PropertyCollabo to provide joint content, products and services
                    (such as registration, transactions and customer support), to help detect and
                    prevent potentially illegal acts and violations of our policies, and to guide
                    decisions about their products, services, and communications. Members of the
                    group will use this information to send you marketing communications and
                    opportunities only if you have requested their services.
                </p>

                <p>
                    Credit bureaus and collection agencies to report account or credit information,
                    as permitted by law.
                </p>

                <p>
                    Companies that we plan to merge with or are acquired by. (Should such a
                    combination occur, we will require that the new combined entity follow this
                    Privacy Policy with respect to your personal Information. If your personal
                    Information could be used contrary to this policy, you will receive prior
                    notice).
                </p>

                <p>
                    Law enforcement, government officials, or other third parties pursuant to a
                    subpoena, court order, or other legal process or requirement applicable to
                    PropertyCollabo or one of its agents; when we need to do so to comply with law;
                    or when we believe, in our sole discretion, that the disclosure of personal
                    information is necessary to prevent physical harm or financial loss, to report
                    suspected illegal activity or to investigate violations of our User Agreement.
                </p>

                <p>
                    Other unaffiliated third parties, for the following purposes
                </p>

                <p>
                    Fraud Prevention and Risk Management: to help prevent fraud or assess and manage
                    risk.
                </p>

                <p>
                    Customer Service: for customer service purposes, including to help service your
                    accounts or resolve disputes.
                </p>

                <p>
                    Legal Compliance: to help them comply with anti-money laundering and
                    counter-terrorist financing verification requirements.
                </p>

                <p>
                    Service Providers: to enable service providers under contract with us to support
                    our business operations, such as fraud prevention, marketing, customer service
                    and technology services. Our contracts dictate that these service providers only
                    use your information in connection with the services they perform for us and not
                    for their own benefit.
                </p>
            </div>
        </div>

        <div class="xs-section-heading">
            <div class="xs-heading-title">
                <h2 class="color-navy-blue">
                    Software
                </h2>
                <p>
                    If you download or use our software, such as a stand-alone software product, an
                    app, or a browser plugin, you agree that from time to time, the software may
                    download and install upgrades, updates and additional features from us in order
                    to improve, enhance, and further develop the software. We may utilise your
                    personal information to internally evaluate our software.
                </p>
            </div>
        </div>

        <div class="xs-section-heading">
            <div class="xs-heading-title">
                <h2 class="color-navy-blue">
                    Period of Storage
                </h2>
                <p>
                    We retain your information for as long as necessary for the purpose(s) for which
                    it was collected. The period of data storage is also subject to legal,
                    regulatory, administrative and operational requirements.
                </p>
            </div>
        </div>

        <div class="xs-section-heading">
            <div class="xs-heading-title">
                <h2 class="color-navy-blue">
                    Exceptions
                </h2>
                <p>
                    Please note that this Policy does not apply to Information that is already in
                    the public domain through no fault of ours.
                </p>
            </div>
        </div>

        <div class="xs-section-heading">
            <div class="xs-heading-title">
                <h2 class="color-navy-blue">
                    Violation
                </h2>
                <p>
                    If you violate the letter or spirit of this Policy, or otherwise create risk or
                    possible legal exposure for us or attempt to violate the privacy rights of
                    PropertyCollabo and/or its other users, we reserve the right to restrict your
                    access to our Platforms. We will notify you in the event that we are constrained
                    to take such decision.
                </p>
            </div>
        </div>

        <div class="xs-section-heading">
            <div class="xs-heading-title">
                <h2 class="color-navy-blue">
                    Dispute resolution
                </h2>
                <p>
                    We are dedicated to ensuring that you are satisfied with our management of your
                    Information. However, in the unlikely event that you have a complaint, please
                    contact us via the details below, stating your name and details of your
                    complaint. Upon receipt of your complaint we will endeavor to contact you within
                    3 (three) working days with a view to amicably resolving such dispute within the
                    shortest possible time. The foregoing notwithstanding, all disputes arising from
                    this policy shall first be resolved by negotiation. However, if parties fail to
                    resolve the disputes amicably by such mutual consultations, parties shall
                    further attempt to resolve the dispute by mediation.
                </p>
            </div>
        </div>

        <ng-container>
            <app-terms-contact></app-terms-contact>
        </ng-container>

        <div class="xs-section-heading">
            <div class="xs-heading-title">
                <h2 class="color-navy-blue">
                    Amendment
                </h2>
                <p>
                    We may amend or revise this Policy at any time by posting a revised version on
                    our website. Notice of the amendment shall be posted on our website and the
                    revised version will be effective from the date of publication. Kindly, note
                    that your continued use of our website/services after the publication of the
                    revised version constitutes your acceptance of our amended terms of the Policy.
                </p>
            </div>
        </div>
    </div>
</div>
<app-website-footer></app-website-footer>
