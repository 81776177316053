<div class="content-i">
    <div class="content-box">
        <div class="row pt-4">
            <div class="col-sm-12">
                <div class="element-wrapper">
                    <h6 class="element-header">Withdraw Requests</h6>
                    <div class="element-box-tp">
                        <div class="table-responsive">
                            <table class="table table-padded">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>Full Name</th>
                                        <th>Contact</th>
                                        <th>Status</th>
                                        <th>Amount</th>
                                        <th>Date</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        *ngFor="let data of pageOfItems; let i = index"
                                        [@simpleFadeAnimation]="'in'"
                                    >
                                        <td class="text-center">
                                            <input class="form-control" type="checkbox" />
                                        </td>
                                        <td>
                                            <div class="user-with-avatar text-center">
                                                <ng-container *ngIf="data?.user?.image?.public_id">
                                                    <cl-image
                                                        [public-id]="data?.user?.image?.public_id"
                                                    >
                                                        <cl-transformation
                                                            width="400"
                                                            height="400"
                                                            gravity="face"
                                                            radius="max"
                                                            crop="crop"
                                                        >
                                                        </cl-transformation>
                                                        <cl-transformation width="200" crop="scale">
                                                        </cl-transformation>
                                                    </cl-image>
                                                    <br />
                                                </ng-container>

                                                <span
                                                    >{{ data?.user.firstName }}
                                                    {{ data?.user.lastName }}</span
                                                >
                                            </div>
                                        </td>
                                        <td>
                                            <div class="smaller lighter">
                                                <span *ngIf="data?.user?.email">
                                                    <a class="text-primary">Email:</a>
                                                    {{ data?.user?.email }},
                                                </span>
                                                <span *ngIf="data?.user?.phone">
                                                    <a class="text-primary"> Phone:</a>
                                                    {{ data?.user?.phone }},</span
                                                >
                                                <span *ngIf="data?.user?.address">
                                                    <a class="text-primary"> Address:</a>
                                                    {{ data?.user?.address }},</span
                                                >
                                            </div>
                                        </td>
                                        <td>
                                            <span
                                                class="status-pill smaller green"
                                                [ngClass]="{
                                                    green: data?.status === 'COMPLETED',
                                                    yellow: data?.status === 'PENDING'
                                                }"
                                            ></span
                                            ><small> {{ data?.status }}</small>
                                        </td>
                                        <td>{{ data?.amount | shortNumber }}</td>
                                        <td>
                                            <span>{{ data?.createdAt | date }}</span>
                                        </td>

                                        <td class="row-actions">
                                            <a
                                                class="point"
                                                [routerLink]="
                                                    '/app/admin/users/investment/' + data?.user.id
                                                "
                                                ><i
                                                    ngbTooltip="View User Investments"
                                                    placement="left-bottom"
                                                    container="body"
                                                    class="os-icon os-icon-folder-plus"
                                                ></i></a
                                            ><a
                                                [routerLink]="
                                                    '/app/admin/users/earning/' + data?.user.id
                                                "
                                                [state]="{ data: data?.user }"
                                                class="point"
                                                ><i
                                                    ngbTooltip="View Earning"
                                                    placement="left-top"
                                                    container="body"
                                                    class="os-icon os-icon-window-content"
                                                ></i
                                            ></a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="pagination-w space-b-25">
                            <div class="pagination-info">
                                <div class="pagination-links">
                                    <jw-pagination
                                        class="point"
                                        [pageSize]="pageSize"
                                        [items]="items"
                                        (changePage)="onChangePage($event)"
                                    ></jw-pagination>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
