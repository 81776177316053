import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { environment } from '@environments/environment.prod';
import { CoInvestors, DashboardStatsCard } from '@app/common/payload/dashboard-stats-card';
import { PropertyPayload } from '@app/common/payload/property.payload';
import { DashboardService } from '@app/common/service/dashboard.service';
import { Subscription } from 'rxjs';
import { PropertyStatusEnum } from '@app/common/enum/property.enums';
import { AuthService } from '@app/common/service/auth.service';
import { User } from '@app/common/payload/user';
import { InvestmentStats } from '@app/common/payload/investment.payload';

@Component({
    selector: 'app-user-dashboard',
    templateUrl: './user-dashboard.component.html',
    styleUrls: ['./user-dashboard.component.css'],
})
export class UserDashboardComponent implements OnInit, OnDestroy {
    totalAmountInvested: DashboardStatsCard;
    propertyInvestedCount: DashboardStatsCard;
    activeInvestmentCount: DashboardStatsCard;
    latestProperties: PropertyPayload[];
    subs: Subscription = new Subscription();
    propertyStatus: PropertyStatusEnum;
    user: User;

    investors: CoInvestors[];

    investmentsStats: InvestmentStats;
    pieChartData: { label: any; data: any; property: PropertyPayload[] };
    profitsMade: { profitMade: number; amountInvested: number }[];

    constructor(
        private titleService: Title,
        private dashboardService: DashboardService,
        private authService: AuthService
    ) {}

    ngOnInit(): void {
        this.titleService.setTitle(`Dashboard - ${environment.appName}`);
        this.subs.add(
            this.dashboardService.onGetUserDashboardData().subscribe((result) => {
                if (result.status === 200) {
                    this.pieChartData = result.body.accumulatedGrowth;
                    this.totalAmountInvested = {
                        isMoney: true,
                        count: result.body.totalAmountInvested,
                        title: 'Total Amount You Have Invested',
                        showHint: true,
                        hint: ' Verified investments only ',
                        hintPosition: 'right',
                    };
                    this.propertyInvestedCount = {
                        count: result.body.totalPropertyInvestedCount,
                        title: 'Total number of properties invested ',
                        hasSubText: true,
                        subText:
                            result.body.totalPropertyInvestedCount > 1 ? 'Properties' : 'Property',
                    };
                    this.activeInvestmentCount = {
                        count: result.body.activeInvestmentCount,
                        title: 'Total number of investments made ',
                        hasSubText: true,
                        subText:
                            result.body.totalPropertyInvestedCount > 1
                                ? 'Investments'
                                : 'Investment',
                    };
                    this.latestProperties = result.body.latestPropertiesROI;
                    this.profitsMade = result.body.profitMade;
                }
            })
        );
        this.subs.add(
            this.authService.currentUser.subscribe((data) => {
                this.user = data;
            })
        );

        this.subs.add(
            this.dashboardService.onGetCoInvestorsData(this.user.id).subscribe((result) => {
                if (result.status === 200) {
                    this.investors = result.body;
                }
            })
        );
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    onEncode(title: string) {
        return encodeURI(title);
    }
}
