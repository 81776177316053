import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { environment } from '@environments/environment.prod';
import { PropertyPayload } from '@app/common/payload/property.payload';
import { PropertyService } from '@app/common/service/property.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-home-page',
    templateUrl: './home-page.component.html',
    styleUrls: ['./home-page.component.css'],
})
export class HomePageComponent implements OnInit, OnDestroy {
    welcomeSectionProperties: PropertyPayload[];
    subscription: Subscription = new Subscription();
    featureSectionProperties: PropertyPayload[];
    propertySectionProperties: PropertyPayload[];

    constructor(private titleService: Title, private propertyService: PropertyService) {}

    ngOnInit() {
        this.titleService.setTitle(
            `Welcome to Property Collabo | Real estate investment made easy platform - ${environment.appName}`
        );

        this.subscription.add(
            this.propertyService.onGetPropertiesForWebsite().subscribe((result) => {
                if (result) {
                    this.welcomeSectionProperties = result.slice(0, 3);
                    this.featureSectionProperties = result;
                    this.propertySectionProperties = result;
                }
            })
        );
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}
