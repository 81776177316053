<div class="chart">
    <canvas
        baseChart
        [data]="doughnutChartData"
        [labels]="doughnutChartLabels"
        [chartType]="doughnutChartType"
        [options]="doughnutChartOptions"
    >
    </canvas>
</div>
