import { Component, OnDestroy, OnInit, Input } from '@angular/core';
import { AuthService } from '@app/common/service/auth.service';
import { Subscription } from 'rxjs';
import { User } from '@app/common/payload/user';
import { ImagePayload } from '@app/common/payload/property.payload';

@Component({
    selector: 'app-user-profile-avatar',
    templateUrl: './user-profile-avatar.component.html',
    styleUrls: ['./user-profile-avatar.component.css'],
})
export class UserProfileAvatarComponent implements OnInit, OnDestroy {
    subscription: Subscription = new Subscription();
    image: ImagePayload;
    defaultAvatar: string = '/assets/shared/images/user-pic.png';

    constructor(private authService: AuthService) {}

    ngOnInit(): void {
        this.subscription = this.authService.currentUser.subscribe((data) => {
            if (data) {
                this.image = data.image;
            }
        });
    }
    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}
