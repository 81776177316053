import { Component, OnInit } from '@angular/core';
import { AuthService } from '@app/common/service/auth.service';
import { User } from '@app/common/payload/user';
import { DashboardStatsCard } from '@app/common/payload/dashboard-stats-card';
import { ICrumbs } from '@app/common/payload/common.payload';
import { Title } from '@angular/platform-browser';

@Component({
    selector: 'app-dashboard-page',
    templateUrl: './dashboard-page.component.html',
    styleUrls: ['./dashboard-page.component.css'],
})
export class DashboardPageComponent implements OnInit {
    userDetails: User;
    crumbs: ICrumbs[];

    constructor(private authService: AuthService) {}

    ngOnInit() {
        this.userDetails = this.authService.currentUserValue;
        this.crumbs = [
            { text: 'App', link: `/app` },
            {
                text: 'Dashboard',
                link: `${this.userDetails.isAdmin ? '/app/admin/' : '/app/'}`,
            },
        ];
    }
}
