import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { PropertyPayload } from '@app/common/payload/property.payload';
import { Subscription } from 'rxjs';
import { Title } from '@angular/platform-browser';
import { environment } from '@environments/environment.prod';
import { PropertyService } from '@app/common/service/property.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { PropertyFilterPayload } from '@app/common/payload/property-filter.payload';
import { User } from '@app/common/payload/user';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
    selector: 'app-dashboard-property',
    templateUrl: './dashboard-property.component.html',
    styleUrls: ['./dashboard-property.component.css'],
    animations: [
        trigger('simpleFadeAnimation', [
            state('in', style({ opacity: 1 })),
            transition(':enter', [style({ opacity: 0 }), animate(600)]),
            transition(':leave', animate(600, style({ opacity: 0 }))),
        ]),
    ],
})
export class DashboardPropertyComponent implements OnInit {
    isFilter = false;
    @ViewChild('filterWrapper') filterWrapper: ElementRef;

    pageSize = 4;
    collectionSize;
    pageOfItems: Array<any>;
    items: PropertyPayload[] = [];
    subscription: Subscription = new Subscription();
    user: User;
    isLoading = true;

    constructor(
        private titleService: Title,
        private propertyService: PropertyService,
        private renderer: Renderer2,
        private spinnerService: NgxSpinnerService,
        private toastService: ToastrService
    ) {}

    ngOnInit(): void {
        this.titleService.setTitle(`Available Properties - ${environment.appName}`);
        this.subscription.add(
            this.propertyService.onFindAllProperties().subscribe((result) => {
                this.items = result;
                this.collectionSize = result.length;
                this.isLoading = false;
            })
        );
    }

    onToggleFilterWrapper() {
        const ele = this.filterWrapper.nativeElement;
        if (this.isFilter) {
            this.renderer.setStyle(ele, 'visibility', 'hidden');
            this.renderer.setStyle(ele, 'opacity', 0);
        } else {
            this.renderer.setStyle(ele, 'visibility', 'visible');
            this.renderer.setStyle(ele, 'opacity', 1);
        }
        this.isFilter = !this.isFilter;
    }

    onApplyFilter(event: PropertyFilterPayload) {
        this.spinnerService.show().then();
        this.subscription.add(
            this.propertyService.onFilterProperties(event).subscribe(
                (result) => {
                    this.items = result;
                    this.spinnerService.hide().then();
                    if (event.filterType !== 'close') {
                        this.toastService.success('Filter Applied!');
                    }
                },
                (error) => {
                    this.spinnerService.hide().then();
                    this.toastService.error('Oops, Something went wrong');
                    console.log(error);
                }
            )
        );
        this.onToggleFilterWrapper();
        this.isFilter = false;
    }

    onChangePage(pageOfItems: Array<any>) {
        this.pageOfItems = pageOfItems;
    }
}
