<div class="content-w">
    <div class="content-i">
        <div class="content-box">
            <div class="element-wrapper compact pt-4">
                <h6 class="element-header">Returns & Earning</h6>
                <div class="element-box-tp">
                    <div class="row">
                        <div class="col-lg-7 col-xxl-6">
                            <div class="element-balances">
                                <div class="balance hidden-mobile">
                                    <app-dashboard-stats-card-sm
                                        [data]="totalInvestmentData"
                                    ></app-dashboard-stats-card-sm>
                                    <small class="text-muted">
                                        Total approved investment made
                                    </small>
                                </div>
                                <div class="balance">
                                    <app-dashboard-stats-card-sm
                                        [data]="totalEarning"
                                    ></app-dashboard-stats-card-sm>
                                </div>
                                <div class="balance">
                                    <app-dashboard-stats-card-sm
                                        [data]="totalWithdraw"
                                    ></app-dashboard-stats-card-sm>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-5 col-xxl-6">
                            <ng-container *ngIf="bankDetails; else loading">
                                <div
                                    *ngIf="
                                        bankDetails.bankName && !showUpdateBankDetails;
                                        else updateBankDetails
                                    "
                                    class="alert alert-warning borderless"
                                    [@simpleFadeAnimation]="'in'"
                                >
                                    <h5 class="alert-heading text-uppercase">Account Details</h5>
                                    <p>
                                        <span
                                            >BANK NAME:
                                            <strong class="text-uppercase text-primary">{{
                                                bankDetails?.bankName
                                            }}</strong>
                                        </span>
                                    </p>
                                    <p>
                                        <span
                                            >Account Name:
                                            <strong class="text-uppercase text-primary">{{
                                                bankDetails?.accountName
                                            }}</strong>
                                        </span>
                                    </p>
                                    <p>
                                        <span
                                            >ACCOUNT NUMBER:
                                            <strong class="text-uppercase text-primary">
                                                {{ bankDetails?.accountNumber }}
                                            </strong>
                                        </span>
                                    </p>
                                    <div class="alert-btn" *ngIf="!props?.isAdminView">
                                        <a class="btn btn-white-gold" (click)="onToggleUpdate()"
                                            ><i class="os-icon os-icon-edit-1"></i
                                        ></a>
                                    </div>
                                </div>
                            </ng-container>
                            <ng-template #updateBankDetails>
                                <app-dashboard-user-update-profile
                                    [bankDetails]="bankDetails"
                                ></app-dashboard-user-update-profile>
                            </ng-template>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <!--                <div class="col-lg-7 col-xxl-6" *ngIf="!props?.isAdminView">-->
                <!--                    <div class="element-wrapper">-->
                <!--                        <div class="element-box">-->
                <!--                            <div class="element-actions">-->
                <!--                                <div class="form-group">-->
                <!--                                    <select class="form-control form-control-sm">-->
                <!--                                        <option>Today</option>-->
                <!--                                        <option>This Week</option>-->
                <!--                                        <option>This Month</option>-->
                <!--                                        <option selected>Last Month</option>-->
                <!--                                    </select>-->
                <!--                                </div>-->
                <!--                            </div>-->
                <!--                            <h5 class="element-box-header">Balance History</h5>-->
                <!--                            <div class="el-chart-w">-->
                <!--                                <app-dashboard-chart-->
                <!--                                    [style]="{ width: 300, height: 130 }"-->
                <!--                                ></app-dashboard-chart>-->
                <!--                            </div>-->
                <!--                        </div>-->
                <!--                    </div>-->
                <!--                </div>-->
                <div class="col-lg-5 col-xxl-6" *ngIf="!props?.isAdminView">
                    <div class="element-wrapper">
                        <div class="element-box">
                            <form>
                                <h5 class="element-box-header">Request Withdraw</h5>
                                <div class="row">
                                    <div class="col-sm-12">
                                        <div class="form-group">
                                            <label class="lighter">Enter Amount</label>
                                            <div class="input-group mb-2 mr-sm-2 mb-sm-0">
                                                <input
                                                    class="form-control"
                                                    placeholder="Enter Amount..."
                                                    value="0"
                                                    [(ngModel)]="withdrawAmount"
                                                    [ngModelOptions]="{ standalone: true }"
                                                    type="number"
                                                    min="0"
                                                />
                                                <div class="input-group-append">
                                                    <div class="input-group-text">₦</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-buttons-w text-right compact">
                                    <button
                                        [disabled]="isRequestingWithdraw"
                                        class="btn btn-primary text-white"
                                        (click)="onRequestWithdraw()"
                                    >
                                        <span>Send Request </span>
                                        <i
                                            class="os-icon os-icon-grid-18"
                                            *ngIf="!isRequestingWithdraw"
                                        ></i>
                                        <i
                                            class="fa fa-spinner fa-spin"
                                            *ngIf="isRequestingWithdraw"
                                        ></i>
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <div class="element-wrapper">
                <h6 class="element-header">Transaction Records</h6>
                <div class="element-box-tp">
                    <div class="table-responsive">
                        <table class="table table-padded">
                            <thead>
                                <tr>
                                    <th class="text-center">Status</th>
                                    <th class="text-center">Date</th>
                                    <th class="text-center">Balance Before</th>
                                    <th class="text-center">Amount</th>
                                    <th class="text-center" *ngIf="props?.isAdminView">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let withdraw of withdraws; let i = index">
                                    <td class="nowrap text-center">
                                        <span
                                            class="status-pill smaller green"
                                            [ngClass]="{
                                                green: withdraw.status === 'COMPLETED',
                                                yellow: withdraw.status === 'PENDING'
                                            }"
                                        ></span
                                        ><span
                                            ><small>{{ withdraw.status }}</small></span
                                        >
                                    </td>
                                    <td class="text-center">
                                        <span
                                            ><small>{{ withdraw.createdAt | date }}</small></span
                                        >
                                        <!--                                      <span class="smaller lighter">...</span>-->
                                    </td>
                                    <td class="text-center">
                                        {{ withdraw.balanceBeforeWithdraw | shortNumber }}
                                    </td>
                                    <td class="text-center bolder nowrap">
                                        <span class=""
                                            ><small>{{ withdraw.amount | shortNumber }} </small>
                                        </span>
                                    </td>
                                    <td
                                        class="text-center bolder nowrap"
                                        *ngIf="props?.isAdminView"
                                    >
                                        <button
                                            [disabled]="currentProcessingRequest === i"
                                            #fulfilButtons
                                            class="btn btn-sm btn-primary"
                                            (click)="onFulfilRequest(withdraw, i)"
                                            [id]="'button-' + i"
                                        >
                                            Fulfil Request

                                            <i
                                                *ngIf="currentProcessingRequest === i"
                                                class="fa fa-spin fa-spinner"
                                            ></i>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #loading>
    <div class="text-center" style="padding: 13px;">
        <i class="fa fa-spinner fa-spin"></i>
    </div>
</ng-template>
