export enum CommandEnum {
    CREATE = 'CREATE',
    UPDATE = 'UPDATE',
    DELETE = 'DELETE',
}

export enum WithdrawStatusEnum {
    ACTIVE = 'ACTIVE',
    PENDING = 'PENDING',
    COMPLETED = 'COMPLETED',
}
