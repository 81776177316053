<ngx-spinner [fullScreen]="true" [size]="'small'"></ngx-spinner>
<div class="content-i">
    <div class="content-box">
        <div class="support-index" #investmentsCard>
            <!--left side -->
            <div class="support-tickets show-ticket-content">
                <div class="support-tickets-header">
                    <div class="tickets-control">
                        <h5>Investments</h5>
                    </div>
                    <div class="tickets-filter">
                        <div class="form-group mr-3">
                            <label class="d-none d-md-inline-block mr-2">Filter</label>
                            <select class="form-control-sm" (change)="onFilterInvestment($event)">
                                <option value="ALL" selected>ALL INVESTMENT</option>
                                <option value="UNAPPROVED WITHOUT POP"
                                    >UNAPPROVED WITHOUT POP</option
                                >
                                <option value="UNAPPROVED WITH POP">UNAPPROVED WITH POP</option>
                            </select>
                            <div class="d-inline search-btn">
                                <button class="btn btn-primary btn-sm" (click)="onToggleSearch()">
                                    {{ isSearch ? 'Close Search' : 'Open Search' }}
                                </button>
                                <div class="filter-div" #filterWrapper>
                                    <div class="form-inline search-form">
                                        <input
                                            type="text"
                                            placeholder="Reference ID"
                                            class="form-control"
                                            [(ngModel)]="searchTerm"
                                            name="searchTerm"
                                            (focus)="isSearchEmpty = false"
                                            [ngClass]="{ 'f-invalid': isSearchEmpty }"
                                        />
                                        <button
                                            class="btn btn-small btn-primary space-top-2"
                                            (click)="onSearchInvestment()"
                                        >
                                            <i class="fa fa-search"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- if userId was passed in route, display only identities for the user -->
                <ng-container *ngIf="userId">
                    <label class="space-b-15 text-primary">You are viewing investments for:</label>
                    <div class="support-ticket point">
                        <div class="st-body">
                            <div class="picture">
                                <ng-container *ngIf="user?.image?.public_id">
                                    <cl-image [public-id]="user?.image?.public_id">
                                        <cl-transformation
                                            width="400"
                                            height="400"
                                            gravity="face"
                                            radius="max"
                                            crop="crop"
                                        >
                                        </cl-transformation>
                                        <cl-transformation width="400" crop="scale">
                                        </cl-transformation>
                                    </cl-image>
                                </ng-container>
                            </div>
                            <div class="ticket-content">
                                <p class="txt-sm">
                                    <span class="text-black-50"> Full Name: </span>
                                    <span class="text-primary">
                                        {{ user?.firstName }} {{ user?.lastName }}
                                    </span>
                                </p>

                                <p class="txt-sm">
                                    <span class="text-black-50"> Email: </span>
                                    <span class="text-primary">
                                        {{ user?.email }}
                                    </span>
                                </p>
                                <p class="txt-sm">
                                    <span class="text-black-50"> Total Investments: </span>
                                    <span class="text-primary">
                                        {{ user?.investments.length }}
                                    </span>
                                </p>
                                <p class="txt-sm">
                                    <span class="text-black-50">
                                        Total Amount (Approved & Unapproved):
                                    </span>
                                    <span class="text-primary">
                                        {{
                                            userInvestmentsStats?.totalAmountInvested | shortNumber
                                        }}
                                    </span>
                                </p>
                                <p class="txt-sm">
                                    <span class="text-black-50">
                                        Total Amount (Approved Only):
                                    </span>
                                    <span class="text-primary">
                                        {{
                                            userInvestmentsStats?.totalApprovedAmount | shortNumber
                                        }}
                                    </span>
                                </p>
                                <p class="txt-sm">
                                    <span class="text-black-50">
                                        Total Amount (Unapproved Only):
                                    </span>
                                    <span class="text-primary">
                                        {{
                                            userInvestmentsStats?.totalUnapprovedAmount
                                                | shortNumber
                                        }}
                                    </span>
                                </p>
                            </div>
                        </div>
                        <div class="st-foot">
                            <span class="label text-black-50">Approved Investments:</span>
                            <a class="value with-avatar point">
                                <span>{{ userInvestmentsStats?.approvedInvestmentCount }} </span>
                            </a>

                            <span class="label text-black-50">Unapproved Investments:</span>
                            <a class="value with-avatar point">
                                <span>{{ userInvestmentsStats?.unapprovedInvestmentCount }} </span>
                            </a>
                        </div>
                    </div>
                </ng-container>

                <!-- Not userId get all investments-->
                <ng-container *ngIf="investments; else loading">
                    <div
                        class="support-ticket point"
                        *ngFor="let investment of pageOfItems; let i = index"
                        [id]="'menu-' + i"
                        [@simpleFadeAnimation]="'in'"
                        #myInvestments
                        (click)="onSelectInvestment(investment, i)"
                    >
                        <div class="st-meta">
                            <div class="badge badge-primary pad-5">
                                {{ investment.property.category }}
                            </div>
                            <div class="badge badge-primary pad-5">
                                {{ investment.property.type }}
                            </div>

                            <i class="os-icon os-icon-image" *ngIf="investment.uploads"></i>
                            <div
                                class="status-pill"
                                [ngClass]="{
                                    yellow: !investment.isApproved,
                                    green: investment.isApproved
                                }"
                            ></div>
                        </div>
                        <div class="st-body">
                            <div class="avatar">
                                <img
                                    alt="property-image"
                                    style="border-radius: 0;"
                                    [src]="investment.property.image[0].secure_url"
                                />
                            </div>
                            <div class="ticket-content">
                                <h6 class="ticket-title">
                                    {{ investment.amount | shortNumber: '₦' }}
                                </h6>
                                <div class="ticket-description">
                                    {{ investment.property.title | truncateText: 30 }}
                                </div>
                            </div>
                        </div>
                        <div class="st-foot" *ngIf="!userId">
                            <span class="label">Investor:</span
                            ><a class="value with-avatar point">
                                <ng-container *ngIf="investment?.user?.image?.public_id">
                                    <cl-image [public-id]="investment?.user?.image?.public_id">
                                        <cl-transformation
                                            width="400"
                                            height="400"
                                            gravity="face"
                                            radius="max"
                                            crop="crop"
                                        >
                                        </cl-transformation>
                                        <cl-transformation width="200" crop="scale">
                                        </cl-transformation>
                                    </cl-image>
                                </ng-container>
                                <span>{{ investment.user.firstName }} </span></a
                            ><span class="label">Updated:</span>
                            <span class="value">{{ investment.createdAt | date }}</span>
                        </div>
                    </div>
                    <div class="load-more-tickets">
                        <div class="pagination-w space-b-25">
                            <div class="pagination-info">
                                <div class="pagination-links">
                                    <jw-pagination
                                        class="point"
                                        [pageSize]="pageSize"
                                        [items]="items"
                                        (changePage)="onChangePage($event)"
                                    ></jw-pagination>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
                <ng-template #loading>
                    <div class="text-center" style="padding: 13px;">
                        <i class="fa fa-spinner fa-spin"></i>
                    </div>
                </ng-template>
            </div>
            <!-- Right Side -->
            <div class="support-ticket-content-w" #toggleDetailsDiv>
                <ng-container *ngIf="showRightSide">
                    <div class="support-ticket-content">
                        <div class="support-ticket-content-header">
                            <h3 class="ticket-header"></h3>
                            <a class="back-to-index point" (click)="onToggleDetails()"
                                ><i class="os-icon os-icon-arrow-left5"></i><span>Back</span></a
                            >
                            <a class="show-ticket-info point" (click)="onToggleUserPop()"
                                ><span>Show Investor Details</span
                                ><i class="os-icon os-icon-documents-03"></i
                            ></a>
                        </div>
                        <div class="ticket-thread">
                            <div class="pipelines-w">
                                <div class="pipeline white lined-primary">
                                    <div class="pipeline-header pad-25">
                                        <h5 class="pipeline-name">Investment Details</h5>
                                        <div class="pipeline-header-numbers">
                                            <div class="pipeline-value">
                                                <p class="space-top-10">
                                                    Amount:
                                                    <strong>
                                                        {{
                                                            selectedInvestment?.amount
                                                                | shortNumber: '₦'
                                                        }}
                                                    </strong>
                                                </p>
                                                <p>
                                                    Date:
                                                    <strong>
                                                        {{ selectedInvestment?.createdAt | date }}
                                                    </strong>
                                                </p>
                                                <p>
                                                    Status:
                                                    <strong
                                                        class=""
                                                        [ngClass]="
                                                            selectedInvestment?.isApproved
                                                                ? 'text-success'
                                                                : 'text-danger'
                                                        "
                                                    >
                                                        {{
                                                            selectedInvestment?.isApproved
                                                                ? 'Approved'
                                                                : 'Unapproved'
                                                        }}
                                                    </strong>
                                                </p>
                                                <p>
                                                    Reference ID:
                                                    <strong class="text-black-50">{{
                                                        selectedInvestment.reference
                                                    }}</strong>
                                                </p>
                                                <p>
                                                    <a
                                                        *ngIf="!selectedInvestment?.isApproved"
                                                        class="btn btn-primary btn-lg text-white"
                                                        (click)="
                                                            onApproveInvestment(selectedInvestment)
                                                        "
                                                        >Approve Investment</a
                                                    >
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="pipeline-body">
                                        <div class="pipeline-item">
                                            <div class="pi-controls">
                                                <div
                                                    class="status status-green"
                                                    data-placement="top"
                                                    data-toggle="tooltip"
                                                    title="Active Status"
                                                    [ngClass]="{
                                                        'status-green': selectedInvestment?.uploads,
                                                        'status-red': !selectedInvestment?.uploads
                                                    }"
                                                ></div>
                                            </div>
                                            <div class="pad-25">
                                                <div class="pipeline-header">
                                                    <h5 class="pipeline-name">Proof Of Payment</h5>
                                                </div>
                                                <img
                                                    *ngIf="selectedInvestment?.uploads; else noPop"
                                                    [src]="selectedInvestment?.uploads?.secure_url"
                                                    alt="POP"
                                                />
                                                <ng-template #noPop>
                                                    <code>
                                                        No Proof of Payment Uploaded Yet.
                                                    </code>
                                                </ng-template>
                                            </div>
                                            <div class="pad-25">
                                                <div class="pipeline-header">
                                                    <h5 class="pipeline-name">Property Details</h5>
                                                </div>

                                                <ng-image-slider
                                                    imageSize="{width: '100%', height: '100%'}"
                                                    animationSpeed="1"
                                                    [images]="imageObject"
                                                    [imagePopup]="true"
                                                    [lazyLoad]="'true'"
                                                    #nav
                                                ></ng-image-slider>
                                            </div>
                                            <div class="pi-body">
                                                <div class="pi-info">
                                                    <div class="h6 pi-name">
                                                        {{ selectedInvestment?.property?.title }}
                                                    </div>
                                                    <div class="pi-sub">
                                                        {{
                                                            selectedInvestment?.property
                                                                ?.description
                                                        }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="pi-foot">
                                                <div class="tags">
                                                    <a class="tag">{{
                                                        selectedInvestment?.property?.category
                                                    }}</a
                                                    ><a class="tag">{{
                                                        selectedInvestment?.property?.type
                                                    }}</a>
                                                    <a
                                                        target="_blank"
                                                        class="tag"
                                                        [routerLink]="
                                                            '/app/property/' +
                                                            selectedInvestment?.property?.id
                                                        "
                                                        [state]="{
                                                            data: selectedInvestment?.property
                                                        }"
                                                    >
                                                        <i class="fa fa-external-link-square"></i>
                                                    </a>
                                                </div>
                                                <a
                                                    class="extra-info"
                                                    ngbTooltip="Total investment made on this property"
                                                    placement="left-top"
                                                    container="body"
                                                    ><i class="os-icon os-icon-emoticon-smile"></i
                                                    ><span>{{
                                                        selectedInvestment?.property?.investments
                                                            ?.length
                                                    }}</span></a
                                                >
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Investor Details -->
                    <div class="support-ticket-info" *ngIf="!userId">
                        <a class="close-ticket-info point" (click)="onToggleUserPop()"
                            ><i class="os-icon os-icon-ui-23"></i
                        ></a>
                        <div class="customer">
                            <div class="avatar">
                                <ng-container *ngIf="selectedInvestment?.user?.image?.public_id">
                                    <cl-image
                                        [public-id]="selectedInvestment?.user?.image?.public_id"
                                    >
                                        <cl-transformation
                                            width="400"
                                            height="400"
                                            gravity="face"
                                            radius="max"
                                            crop="crop"
                                        >
                                        </cl-transformation>
                                        <cl-transformation width="200" crop="scale">
                                        </cl-transformation>
                                    </cl-image>
                                </ng-container>
                            </div>
                            <h4 class="customer-name">
                                {{ selectedInvestment?.user?.firstName }}
                                {{ selectedInvestment?.user?.lastName }}
                            </h4>
                            <h6 class="customer-name">
                                {{ selectedInvestment?.user?.email }}
                            </h6>
                        </div>
                        <h5 class="info-header">More Details</h5>
                        <div class="info-section text-center">
                            <div class="label">Total Investments:</div>
                            <div class="value">
                                {{ userInvestmentsStats?.totalInvestmentsCount }}
                            </div>

                            <div class="label">Investments Statistics:</div>
                            <div class="value">
                                {{ userInvestmentsStats?.totalApprovedAmount | shortNumber }}
                                <small> Total Approved Amount, </small> <br />
                                {{ userInvestmentsStats?.totalUnapprovedAmount | shortNumber }}
                                <small> Total Unapproved Approved Amount </small> <br />
                            </div>

                            <div class="label">Selected Investment Date:</div>
                            <div class="value">{{ selectedInvestment?.createdAt | date }}</div>

                            <div class="label">
                                Selected Investment Property Category
                            </div>
                            <div class="value">
                                <div class="badge badge-primary">
                                    {{ selectedInvestment?.property?.category }}
                                </div>
                            </div>

                            <div class="label">Selected Investment Property Type</div>
                            <div class="value">
                                <div class="badge badge-primary">
                                    {{ selectedInvestment?.property?.type }}
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>
