import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
    selector: 'app-faq-section',
    templateUrl: './faq-section.component.html',
    styleUrls: ['./faq-section.component.css'],
    animations: [
        trigger('fadeIn', [
            state('in', style({ opacity: 1 })),
            transition(':enter', [style({ opacity: 0 }), animate(600)]),
            transition(':leave', animate(600, style({ opacity: 0 }))),
        ]),
    ],
})
export class FaqSectionComponent implements OnInit {
    @ViewChild('btnAll') btnAll: ElementRef;
    @ViewChild('btnGeneral') btnGeneral: ElementRef;
    @ViewChild('btnAccount') btnAccount: ElementRef;
    @ViewChild('btnInvestment') btnInvestment: ElementRef;
    tabs: {
        general: boolean;
        account: boolean;
        investment: boolean;
        all: boolean;
    } = {
        general: false,
        account: false,
        investment: false,
        all: true,
    };

    constructor(private render: Renderer2) {}

    ngOnInit() {}

    onToggleTab(selected: string) {
        switch (selected) {
            case (selected = 'all'):
                this.tabs.all = true;
                if (!this.btnAll.nativeElement.classList.contains('selected')) {
                    this.render.addClass(this.btnAll.nativeElement, 'selected');
                }
                this.tabs.general = false;
                if (this.btnGeneral.nativeElement.classList.contains('selected')) {
                    this.render.removeClass(this.btnGeneral.nativeElement, 'selected');
                }

                this.tabs.account = false;
                if (this.btnAccount.nativeElement.classList.contains('selected')) {
                    this.render.removeClass(this.btnAccount.nativeElement, 'selected');
                }

                this.tabs.investment = false;
                if (this.btnInvestment.nativeElement.classList.contains('selected')) {
                    this.render.removeClass(this.btnInvestment.nativeElement, 'selected');
                }
                break;
            case (selected = 'general'):
                this.tabs.all = false;
                if (this.btnAll.nativeElement.classList.contains('selected')) {
                    this.render.removeClass(this.btnAll.nativeElement, 'selected');
                }

                this.tabs.general = true;
                if (!this.btnGeneral.nativeElement.classList.contains('selected')) {
                    this.render.addClass(this.btnGeneral.nativeElement, 'selected');
                }

                this.tabs.account = false;
                if (this.btnAccount.nativeElement.classList.contains('selected')) {
                    this.render.removeClass(this.btnAccount.nativeElement, 'selected');
                }

                this.tabs.investment = false;
                if (this.btnInvestment.nativeElement.classList.contains('selected')) {
                    this.render.removeClass(this.btnInvestment.nativeElement, 'selected');
                }
                break;
            case (selected = 'account'):
                this.tabs.all = false;
                if (this.btnAll.nativeElement.classList.contains('selected')) {
                    this.render.removeClass(this.btnAll.nativeElement, 'selected');
                }

                this.tabs.general = false;
                if (this.btnGeneral.nativeElement.classList.contains('selected')) {
                    this.render.removeClass(this.btnGeneral.nativeElement, 'selected');
                }

                this.tabs.account = true;
                if (!this.btnAccount.nativeElement.classList.contains('selected')) {
                    this.render.addClass(this.btnAccount.nativeElement, 'selected');
                }

                this.tabs.investment = false;
                if (this.btnInvestment.nativeElement.classList.contains('selected')) {
                    this.render.removeClass(this.btnInvestment.nativeElement, 'selected');
                }
                break;
            case (selected = 'investment'):
                this.tabs.all = false;
                if (this.btnAll.nativeElement.classList.contains('selected')) {
                    this.render.removeClass(this.btnAll.nativeElement, 'selected');
                }
                this.tabs.general = false;
                if (this.btnGeneral.nativeElement.classList.contains('selected')) {
                    this.render.removeClass(this.btnGeneral.nativeElement, 'selected');
                }
                this.tabs.account = false;
                if (this.btnAccount.nativeElement.classList.contains('selected')) {
                    this.render.removeClass(this.btnAccount.nativeElement, 'selected');
                }

                this.tabs.investment = true;
                if (!this.btnInvestment.nativeElement.classList.contains('selected')) {
                    this.render.addClass(this.btnInvestment.nativeElement, 'selected');
                }
                break;
            default:
                break;
        }
    }
}
