<div class="content-i">
    <div class="content-box">
        <div class="row pt-4">
            <div class="col-sm-12">
                <div class="element-wrapper">
                    <h6 class="element-header">Investment Statistics</h6>
                    <div class="element-content">
                        <div class="row">
                            <div class="row col-sm-6 col-md-10">
                                <div class="col-sm-4 col-xxxl-3">
                                    <app-dashboard-stats-card
                                        [data]="totalCountCard"
                                    ></app-dashboard-stats-card>
                                </div>
                                <div class="col-sm-4 col-xxxl-3">
                                    <app-dashboard-stats-card
                                        [data]="approvedCountCard"
                                    ></app-dashboard-stats-card>
                                </div>
                                <div class="col-sm-4 col-xxxl-3">
                                    <app-dashboard-stats-card
                                        [data]="unapprovedCountCard"
                                    ></app-dashboard-stats-card>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-12 col-md-10 col-lg-10">
                <div class="element-wrapper">
                    <h6 class="element-header">My Investments</h6>
                    <div class="element-box-tp">
                        <div class="table-responsive investment-table">
                            <table class="table table-responsive table-padded">
                                <thead>
                                    <tr>
                                        <th>Property Title</th>
                                        <th>Property Description</th>
                                        <th>
                                            <i
                                                class="fa fa-info-circle text-primary"
                                                ngbTooltip="This is the amount you are investing. "
                                                placement="left-top"
                                                container="body"
                                            ></i>
                                            Investment Amount
                                        </th>
                                        <th>
                                            <i
                                                class="fa fa-info-circle text-primary"
                                                ngbTooltip="This is the target amount specified on the property"
                                                placement="left-top"
                                                container="body"
                                            ></i>
                                            Target Amount
                                        </th>
                                        <th>Date of Investment</th>
                                        <th>
                                            <i
                                                class="fa fa-info-circle text-primary"
                                                ngbTooltip="This is the percentage return you will get from your investment"
                                                placement="top"
                                                container="body"
                                            ></i>
                                            ROI
                                        </th>
                                        <th>
                                            <i
                                                class="fa fa-info-circle text-primary"
                                                ngbTooltip="Duration at which you receive your return of investment"
                                                placement="top"
                                                container="body"
                                            ></i>
                                            ROI Duration
                                        </th>
                                        <th>
                                            <i
                                                class="fa fa-info-circle text-primary"
                                                ngbTooltip="After uploading your proof of payment, your investment will be verified and confirmed."
                                                placement="left-top"
                                                container="body"
                                            ></i>
                                            Approval Status
                                        </th>
                                        <th class="text-center">Reference ID</th>
                                        <th class="text-left">
                                            <i
                                                class="fa fa-info-circle text-primary"
                                                ngbTooltip="Upload Your Proof Of Payment"
                                                placement="left-top"
                                                container="body"
                                            ></i>
                                            Upgrade Amount
                                        </th>
                                        <th class="text-left">
                                            <i
                                                class="fa fa-info-circle text-primary"
                                                ngbTooltip="Upload Your Proof Of Payment"
                                                placement="left-top"
                                                container="body"
                                            ></i>
                                            Upload POP
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let investment of investments">
                                        <td class="text-left">
                                            <div class="user-with-avatar">
                                                <div
                                                    class="text-black-50"
                                                    [title]="investment.property.title"
                                                >
                                                    {{
                                                        investment.property.title | truncateText: 20
                                                    }}
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div
                                                class="smaller lighter"
                                                [title]="investment.property.description"
                                            >
                                                {{
                                                    investment.property.description
                                                        | truncateText: 60
                                                }}
                                            </div>
                                        </td>
                                        <td class="text-center">
                                            {{ investment.amount | shortNumber: '₦' }}
                                        </td>
                                        <td class="text-center">
                                            {{ investment.property.target | shortNumber: '₦' }}
                                        </td>
                                        <td class="text-center">
                                            {{ investment.createdAt | date }}
                                        </td>
                                        <td class="text-center">
                                            <span>
                                                {{
                                                    investment.property.returnOfInvestment
                                                        | shortNumber: ' '
                                                }}%
                                            </span>
                                        </td>
                                        <td class="text-center">
                                            <span>
                                                {{
                                                    investment.property.duration | shortNumber: ' '
                                                }}
                                                Months
                                            </span>
                                        </td>
                                        <td class="nowrap">
                                            <span
                                                class="status-pill smaller {{
                                                    investment.isApproved ? 'green' : 'red'
                                                }}"
                                            ></span
                                            ><span>
                                                {{
                                                    investment.isApproved
                                                        ? 'Verified'
                                                        : 'Unverified'
                                                }}
                                            </span>
                                        </td>
                                        <td class="text-center">{{ investment.reference }}</td>
                                        <td class="row-actions">
                                            <a
                                                class="point btn btn-primary btn-sm text-white"
                                                [ngClass]="{
                                                    disabled: investment.isApproved
                                                }"
                                                (click)="onUpgradeInvestmentAmount(investment)"
                                                ><i
                                                    class="point os-icon os-icon-arrow-up-circle"
                                                    [ngClass]="{
                                                        'text-white':
                                                            investment.property.status ===
                                                            'OPEN INVESTMENT',
                                                        'text-danger':
                                                            investment.property.status !==
                                                            'OPEN INVESTMENT'
                                                    }"
                                                    title="Upgrade investment amount"
                                                ></i
                                                ><small>&nbsp;Upgrade Amount</small></a
                                            >
                                        </td>
                                        <td class="row-actions">
                                            <a
                                                class="point text-white btn btn-sm btn-primary"
                                                [ngClass]="{
                                                    disabled: investment.isApproved
                                                }"
                                                (click)="onUploadPOP(investment)"
                                                ><i
                                                    class="point os-icon os-icon-image"
                                                    title="Upload Proof of payment"
                                                    [ngClass]="{
                                                        'text-success': investment.uploads
                                                    }"
                                                ></i
                                                ><small>&nbsp;Upload POP</small></a
                                            >
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div class="text-center space-top-10" *ngIf="investments?.length < 1">
                                <small class="space-top-10"
                                    >No Investment Found
                                    <a routerLink="/app/properties">Goto Properties</a>
                                </small>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
