import { Component, OnInit } from '@angular/core';
import { ClipboardService } from 'ngx-clipboard';

@Component({
    selector: 'app-dashboard-fa-icons',
    templateUrl: './dashboard-fa-icons.component.html',
    styleUrls: ['./dashboard-fa-icons.component.css'],
})
export class DashboardFaIconsComponent implements OnInit {
    constructor(private _clipboardService: ClipboardService) {}

    ngOnInit(): void {}

    copy(text: string) {
        this._clipboardService.copyFromContent(text);
    }
}
