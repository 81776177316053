export interface MenuBag {
    description?: string;
    title: string;
    icon?: string;
    toolTip?: string;
    link?: string;
    position?: number;
    children?: MenuBag[];
    isOpen?: boolean;
}

export interface MenuBox {
    isClicked: boolean;
    menu?: MenuBag;
}

export const adminMenu: MenuBag[] = [
    {
        title: 'Dashboard',
        icon: 'typcn typcn-device-laptop',
        toolTip: 'Goto Dashboard',
        position: 1,
        link: '/app/admin',
        description: 'Dashboard Details',
        children: [],
    },
    {
        title: 'Users',
        icon: 'typcn typcn-user-add',
        toolTip: 'Manage Users',
        link: '/app/admin/users',
        position: 2,
        children: [],
        description: 'Manage application users',
    },
    {
        title: 'Properties',
        icon: 'typcn typcn-home-outline',
        toolTip: 'Manage Properties',
        position: 3,
        link: '/app/admin/property',
        description: 'Manage properties, update and close past property',
        children: [
            { title: 'View Properties', link: '/app/admin/property' },
            { title: 'Create Property', link: '/app/admin/property-create' },
        ],
    },
    {
        title: 'Investments',
        icon: 'typcn typcn-chart-bar-outline',
        toolTip: 'Manage Investments',
        link: '/app/admin/investments',
        position: 4,
        children: [],
        description: 'Manage investments and its associated property',
    },
    {
        title: 'Returns',
        icon: 'typcn typcn-credit-card',
        toolTip: 'Manage Returns',
        link: '/app/admin/returns',
        position: 5,
        children: [],
        description: 'Manage returns of investment',
    },
    {
        title: 'Identities',
        icon: 'typcn typcn-starburst',
        toolTip: 'Manage Identities',
        link: '/app/admin/identities',
        position: 6,
        children: [],
        description: 'Manage user identity',
    },
    {
        title: 'Settings',
        icon: 'typcn typcn-cog-outline',
        toolTip: 'Setting & More',
        position: 7,
        link: '',
        description: 'Settings & More',
        children: [
            { title: 'Settings', link: '/app/admin/settings' },
            { title: 'Goto Website', link: '' },
        ],
    },
];

export const userMenu: MenuBag[] = [
    {
        title: 'Dashboard',
        icon: 'typcn typcn-device-laptop',
        toolTip: 'View Properties',
        link: '/app',
        position: 1,
        children: [],
        description: 'Dashboard, invest and follow latest property',
    },
    {
        title: 'Properties',
        icon: 'typcn typcn-home-outline',
        toolTip: 'View Properties',
        link: '/app/properties',
        position: 2,
        children: [],
        description: 'View properties, invest and follow latest property',
    },
    {
        title: 'Investment',
        icon: 'typcn typcn-chart-bar-outline',
        toolTip: 'View Investments',
        position: 3,
        link: '/app/investment',
        children: [],
        description: 'View and manage all investments',
    },
    {
        title: 'Returns',
        icon: 'typcn typcn-credit-card',
        toolTip: 'View Return',
        position: 3,
        link: '/app/returns',
        children: [],
        description: 'View and manage returns',
    },
    {
        title: 'Profile',
        icon: 'typcn typcn-user-add',
        toolTip: 'Update Profile',
        position: 3,
        link: '/app/identity',
        children: [
            { title: 'Manage Profile', link: '/app/profile' },
            { title: 'Manage Identity', link: '/app/identity' },
        ],
        description: 'View and update identity',
    },
    {
        title: 'Website',
        icon: 'typcn typcn-device-phone',
        toolTip: 'Goto Website',
        position: 7,
        link: '',
        description: 'Visit Website',
        children: [],
    },
];
