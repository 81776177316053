import { Component, Input, OnInit } from '@angular/core';
import { Label } from 'ng2-charts';
import { ChartOptions, ChartType } from 'chart.js';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { PropertyPayload } from '@app/common/payload/property.payload';

@Component({
    selector: 'app-dashboard-chart-pie',
    templateUrl: './dashboard-chart-pie.component.html',
    styleUrls: ['./dashboard-chart-pie.component.css'],
})
export class DashboardChartPieComponent implements OnInit {
    @Input() data: { label: any; data: any; property: PropertyPayload[] };
    public pieChartOptions: ChartOptions = {
        responsive: true,
        legend: {
            position: 'right',
        },
        tooltips: {
            enabled: true,
            // callbacks: {
            //     label: function (tooltipItem, data) {
            //
            //         let label = data.labels[tooltipItem.index];
            //         let count = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
            //         return label + ':' + count;
            //     },
            // },
        },
        plugins: {
            datalabels: {
                formatter: (value, ctx) => {
                    const label = ctx.chart.data.labels[ctx.dataIndex];
                    return label;
                },
            },
        },
    };

    public pieChartLabels: Label[] = [];
    public pieChartData: number[] = [];
    public pieChartType: ChartType = 'pie';
    public pieChartLegend = true;
    public pieChartPlugins = [pluginDataLabels];
    public pieChartColors = [
        {
            backgroundColor: ['rgba(255,0,0,0.3)', 'rgba(0,255,0,0.3)', 'rgba(0,0,255,0.3)'],
        },
    ];

    constructor() {}

    ngOnInit(): void {
        this.pieChartLabels = this.data.label;
        this.pieChartData = this.data.data;
    }
}
