<!--&lt;!&ndash;in use&ndash;&gt;-->
<!--<ng-container *ngIf="coInvestors">-->
<!--    <div class="user-w with-status status-green">-->
<!--        <ng-container *ngFor="let data of coInvestors?.investors; let i = index">-->
<!--            <ng-container *ngIf="data?.preference?.allowProfileView">-->
<!--                <div class="investor">-->
<!--                    <div class="user-avatar-w" *ngIf="data?.image">-->
<!--                        <div class="user-avatar">-->
<!--                            <img alt="Investor Picture" [src]="data?.image?.secure_url" />-->
<!--                        </div>-->
<!--                    </div>-->
<!--                    <div class="user-name">-->
<!--                        <h6 class="user-title">{{ data.firstName }} {{ data.lastName }}</h6>-->
<!--                        <div class="user-role">-->
<!--                            {{ data.designation }}-->
<!--                        </div>-->
<!--                    </div>-->
<!--                    <a class="user-action point text-primary"-->
<!--                        ><div class="os-icon os-icon-phone-outgoing"></div-->
<!--                    ></a>-->
<!--                    &nbsp;-->
<!--                    <a class="user-action point text-primary"-->
<!--                        ><div class="os-icon os-icon-mail"></div-->
<!--                    ></a>-->
<!--                </div>-->
<!--            </ng-container>-->
<!--        </ng-container>-->
<!--    </div>-->

<!--    <div class="pad-25">-->
<!--        <small class="text-primary">-->
<!--            + {{ coInvestors.investors.length }} Other-->
<!--            {{ coInvestors.investors.length > 1 ? 'Investors' : 'Investor' }}-->
<!--        </small>-->
<!--    </div>-->
<!--</ng-container>-->

<div class="users-list-w">
    <ng-container *ngFor="let data of coInvestors?.investors; let i = index">
        <div class="user-w with-status status-green" *ngIf="data?.preference?.allowProfileView">
            <div class="user-avatar-w">
                <div class="user-avatar">
                    <ng-container *ngIf="data?.image?.public_id">
                        <cl-image [public-id]="data?.image?.public_id">
                            <cl-transformation
                                width="400"
                                height="400"
                                gravity="face"
                                radius="max"
                                crop="crop"
                            >
                            </cl-transformation>
                            <cl-transformation width="200" crop="scale"> </cl-transformation>
                        </cl-image>
                    </ng-container>
                </div>
            </div>
            <div class="user-name">
                <h6 class="user-title">{{ data.firstName }} {{ data.lastName }}</h6>
                <div class="user-role">{{ data.designation }}</div>
            </div>
            <!--            <a class="user-action"><div class="os-icon os-icon-email-forward"></div></a>-->
        </div>
    </ng-container>
</div>
