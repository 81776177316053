import { Component, Input, OnInit } from '@angular/core';
import { CoInvestors } from '@app/common/payload/dashboard-stats-card';

@Component({
    selector: 'app-dashboard-co-investors',
    templateUrl: './dashboard-co-investors.component.html',
    styleUrls: ['./dashboard-co-investors.component.css'],
})
export class DashboardCoInvestorsComponent implements OnInit {
    @Input() coInvestors: CoInvestors;
    constructor() {}

    ngOnInit(): void {}
}
