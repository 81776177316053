import { Injectable } from '@angular/core';
import { environment } from '@environments/environment.prod';
import { HttpClient, HttpParams } from '@angular/common/http';
import { InvestmentPayload, InvestmentStats } from '@app/common/payload/investment.payload';
import { InvestmentSearchPayload } from '@app/common/payload/investment-search.payload';
import { PropertyPayload } from '@app/common/payload/property.payload';

@Injectable({
    providedIn: 'root',
})
export class InvestmentService {
    url: string = `${environment.url}/investment`;

    constructor(private http: HttpClient) {}

    public onFindAllInvestment() {
        return this.http.get<InvestmentPayload[]>(`${this.url}/all`, { observe: 'response' });
    }

    public onCreateInvestment(investmentPayload: InvestmentPayload) {
        return this.http.post<InvestmentPayload>(`${this.url}/create`, investmentPayload, {
            observe: 'response',
        });
    }

    public onFindInvestmentById(id: number) {
        return this.http.get(`${this.url}/find/${id}`);
    }

    public onUpdateInvestment(investmentPayload: InvestmentPayload, investmentId: number) {
        return this.http.patch(`${this.url}/update/${investmentId}`, investmentPayload);
    }

    public onApproveInvestment(id: number) {
        return this.http.post(`${this.url}/approve/${id}`, {}, { observe: 'response' });
    }

    public onSearchInvestment(investmentSearchPayload: InvestmentSearchPayload) {
        let params = new HttpParams();
        params = params.append('searchByUserId', investmentSearchPayload.searchByUserId);
        params = params.append('searchByPropertyId', investmentSearchPayload.searchByPropertyId);
        return this.http.get(`${this.url}/search`, { params: params });
    }

    public onUpgradeInvestmentAmount(investmentId: number, amount: number) {
        return this.http.patch(
            `${this.url}/upgrade-amount/${investmentId}/${amount}`,
            {},
            { observe: 'response' }
        );
    }

    public onGetInvestmentByUser(userId: number) {
        return this.http.get<InvestmentPayload[]>(`${this.url}/user/all/${userId}`, {
            observe: 'response',
        });
    }

    public onPrepareInvestmentsStats(investments: InvestmentPayload[]): InvestmentStats {
        let investmentsStats: InvestmentStats = {
            totalInvestmentsCount: 0,
            approvedInvestmentCount: 0,
            unapprovedInvestmentCount: 0,
            totalAmountInvested: 0,
            totalApprovedAmount: 0,
            totalUnapprovedAmount: 0,
        };
        if (investments) {
            investmentsStats.totalInvestmentsCount = investments.length;

            const approvedInvestments = investments.filter((a) => a.isApproved);
            const unApprovedInvestments = investments.filter((a) => !a.isApproved);

            investmentsStats.approvedInvestmentCount = approvedInvestments.length;
            investmentsStats.unapprovedInvestmentCount = unApprovedInvestments.length;

            investmentsStats.totalAmountInvested = investments
                .map((a) => a.amount)
                .reduce(function (a, b) {
                    return Number(a) + Number(b);
                }, 0);

            if (approvedInvestments.length) {
                investmentsStats.totalApprovedAmount = approvedInvestments
                    .map((a) => a.amount)
                    .reduce(function (a, b) {
                        return Number(a) + Number(b);
                    }, 0);
            }

            if (unApprovedInvestments.length) {
                investmentsStats.totalUnapprovedAmount = unApprovedInvestments
                    .map((a) => a.amount)
                    .reduce(function (a, b) {
                        return Number(a) + Number(b);
                    }, 0);
            }
        }
        return investmentsStats;
    }

    public onGetInvestmentByReference(searchTerm: string) {
        return this.http.get<InvestmentPayload>(`${this.url}/find-investment/${searchTerm}`, {
            observe: 'response',
        });
    }

    public hasDuplicateInvestment(
        investments: InvestmentPayload[],
        property: PropertyPayload
    ): boolean {
        let flag = false;
        for (let i = 0; i < investments.length; i++) {
            if (investments[i].property.id === property.id) {
                flag = true;
            }
        }
        return flag;
    }
}
