<div class="az-signin-wrapper mg-t-10">
    <div class="az-card-signin">
        <div class="az-signin-header">
            <div class="text-center space-b-15">
                <a routerLink="">
                    <cl-image public-id="property-collabo/defaults/logo_c5cpbc">
                        <cl-transformation width="100" crop="scale"> </cl-transformation>
                    </cl-image>
                </a>
            </div>
            <div class="text-center">
                <h2>Welcome back!</h2>
                <h4>Please sign in to continue</h4>
            </div>

            <div class="mg-b-10" *ngIf="isErrors">
                <span>
                    <strong class="text-danger">Oops!, Something isn't right:</strong>
                </span>
                <ol type="a" class="mg-b-5 tx-12">
                    <li *ngIf="error">
                        <span class="text-danger">{{ error | titlecase }} </span>
                    </li>
                    <li *ngFor="let message of messages">
                        <span class="text-danger tx-12">{{ message | titlecase }}</span>
                    </li>
                </ol>
            </div>

            <form
                [formGroup]="loginForm"
                (ngSubmit)="onLogin()"
                (keydown.enter)="onSubmitKeyPressed(loginForm.value)"
            >
                <div class="form-group">
                    <label for="email">Email</label>
                    <input
                        #emailField
                        id="email"
                        type="text"
                        class="form-control"
                        placeholder="Enter your email"
                        [formControlName]="'email'"
                        [ngClass]="{ 'f-invalid': isSubmitted && lf.email.errors }"
                    />
                </div>
                <!-- form-group -->
                <div class="form-group">
                    <label for="password">Password</label>
                    <input
                        id="password"
                        type="password"
                        class="form-control"
                        [formControlName]="'password'"
                        placeholder="Enter your password"
                        [ngClass]="{ 'f-invalid': isSubmitted && lf.password.errors }"
                    />
                </div>
                <div class="form-group">
                    <ngx-invisible-recaptcha
                        #captchaElem
                        [siteKey]="siteKey"
                        (load)="onCaptchaHandleLoad()"
                        (success)="onCaptchaHandleSuccess($event)"
                        (expire)="onCaptchaHandleExpire()"
                        [ngModel]="recaptcha"
                        [ngModelOptions]="{ standalone: true }"
                    >
                    </ngx-invisible-recaptcha>
                </div>
                <button class="btn btn-az-primary btn-block" type="submit" [disabled]="isLoading">
                    Sign In
                    <span *ngIf="isLoading">
                        <i class="fa fa-circle-o-notch fa-spin fa-1x fa-fw"></i>
                    </span>
                </button>
            </form>
        </div>
        <div class="az-signin-footer space-m-5">
            <p>Forgot password? <a routerLink="/password-reset">Reset Password</a></p>
            <p>Don't have an account? <a routerLink="/sign-up">Sign Up</a></p>
        </div>
    </div>
</div>
