import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { environment } from '@environments/environment.prod';

@Component({
    selector: 'app-contact-page',
    templateUrl: './contact-page.component.html',
    styleUrls: ['./contact-page.component.css'],
})
export class ContactPageComponent implements OnInit {
    constructor(private titleService: Title) {}

    ngOnInit() {
        this.titleService.setTitle(`Contact Us - ${environment.appName}`);
    }
}
