<div class="item-wrapper one">
    <div class="item">
        <div class="item-inner">
            <div class="item-content">
                <div
                    class="image-upload"
                    ng2FileDrop
                    [ngClass]="{ 'image-upload-over': hasBaseDropZoneOver }"
                    (fileOver)="fileOverBase($event)"
                    [uploader]="uploader"
                >
                    <label
                        class="point zone-text"
                        for="file_upload"
                        *ngIf="props?.property?.image?.length < 1"
                    >
                        <div class="drop">
                            <div *ngIf="uploader?.queue?.length < 1">
                                <i class="fa fa-cloud-upload fa-2x text-primary"></i>
                            </div>
                            <p class="drop-files-text txt-sm">
                                Drop files here or click to upload.
                            </p>
                        </div>
                        <!--upload-content-->
                        <input
                            id="file_upload"
                            data-required="image"
                            type="file"
                            class="image-input"
                            ng2FileSelect
                            [uploader]="uploader"
                            (change)="fileOverBase($event)"
                            multiple
                        />
                    </label>

                    <div class="row" *ngIf="props?.property && props?.property?.image?.length">
                        <div *ngFor="let image of props.property.image; let i = index">
                            <i
                                *ngIf="image.public_id"
                                class="fa fa-trash-o text-danger point"
                                title="Remove Image"
                                (click)="onRemovePreview(image)"
                            ></i>

                            <i
                                (click)="onUploadImage(image)"
                                *ngIf="image.fileItem.isError"
                                class="fa fa-upload text-primary point space-l-5"
                            ></i>

                            <i
                                *ngIf="image.fileItem.isUploading"
                                class="space-l-5 fa fa-spinner fa-spin text-primary txt-sm"
                            ></i>
                            <div class="dz-image col-xs-4">
                                <img alt="" [src]="image.secure_url" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="m-b-2">
                    <small class="text-primary">
                        <code> Upload progress: </code>
                    </small>
                    <div class="progress">
                        <div
                            class="progress-bar"
                            role="progressbar"
                            [ngStyle]="{ width: uploader.progress + '%' }"
                        ></div>
                    </div>
                </div>
            </div>
        </div>
        <!--item-->
    </div>
</div>
