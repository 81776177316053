import { Component, Input, OnInit } from '@angular/core';
import { PropertyPayload } from '@app/common/payload/property.payload';
import { PropertyService } from '@app/common/service/property.service';

@Component({
    selector: 'app-welcome-section',
    templateUrl: './welcome-section.component.html',
    styleUrls: ['./welcome-section.component.css'],
})
export class WelcomeSectionComponent implements OnInit {
    @Input() property: PropertyPayload;

    constructor(private propertyService: PropertyService) {}

    ngOnInit() {}

    onGetPercentage(property: PropertyPayload) {
        const value = this.propertyService.onGetPercentage(property);
        if (value >= 100) {
            return 100;
        }
        return value;
    }

    onEncode(title: string) {
        return encodeURI(title);
    }
}
