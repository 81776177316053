import { Component, OnInit } from '@angular/core';
import { environment } from '@environments/environment.prod';

@Component({
    selector: 'app-dashboard-logo',
    templateUrl: './dashboard-logo.component.html',
    styleUrls: ['./dashboard-logo.component.css'],
})
export class DashboardLogoComponent implements OnInit {
    appName = environment.appName || 'Property Collabo';

    constructor() {}

    ngOnInit(): void {}
}
