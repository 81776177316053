import { Component, Input, OnInit } from '@angular/core';
import { User } from '@app/common/payload/user';
import { InvestmentService } from '@app/common/service/investment.service';
import { InvestmentStats } from '@app/common/payload/investment.payload';
import { DashboardStatsCard } from '@app/common/payload/dashboard-stats-card';

@Component({
    selector: 'app-dashboard-users-complete-profile',
    templateUrl: './dashboard-users-complete-profile.component.html',
    styleUrls: ['./dashboard-users-complete-profile.component.css'],
})
export class DashboardUsersCompleteProfileComponent implements OnInit {
    @Input() user: User;

    userInvestmentsStats: InvestmentStats;

    totalInvestmentsCount: DashboardStatsCard;
    totalApprovedCount: DashboardStatsCard;
    totalApprovedAmount: DashboardStatsCard;
    totalUnapprovedCount: DashboardStatsCard;
    totalUnApprovedAmount: DashboardStatsCard;
    totalAmountInvested: DashboardStatsCard;

    constructor(private investmentService: InvestmentService) {}

    ngOnInit(): void {
        if (this.user) {
            this.userInvestmentsStats = this.investmentService.onPrepareInvestmentsStats(
                this.user.investments
            );
            if (this.userInvestmentsStats) {
                this.onPrepareProfileStatistics();
            }
        }
    }

    onPrepareProfileStatistics() {
        this.totalInvestmentsCount = {
            title: 'Total Investments Count',
            count: this.userInvestmentsStats.totalInvestmentsCount,
        };

        this.totalApprovedCount = {
            title: 'Total Approved Investment Count',
            count: this.userInvestmentsStats.approvedInvestmentCount,
        };

        this.totalApprovedAmount = {
            title: 'Total Approved Investment Amount',
            count: this.userInvestmentsStats.totalApprovedAmount,
            isMoney: true,
        };

        this.totalUnapprovedCount = {
            title: 'Total Unapproved Investment Count',
            count: this.userInvestmentsStats.unapprovedInvestmentCount,
        };

        this.totalUnApprovedAmount = {
            title: 'Total Unapproved Investment Amount',
            count: this.userInvestmentsStats.totalUnapprovedAmount,
            isMoney: true,
        };

        this.totalAmountInvested = {
            title: 'Total Investment Amount',
            count: this.userInvestmentsStats.totalAmountInvested,
            isMoney: true,
        };
    }
}
