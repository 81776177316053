import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LogoComponent } from './component/logo/logo.component';
import { UserProfileAvatarComponent } from './component/user-profile-avatar/user-profile-avatar.component';
import { RouterModule } from '@angular/router';
import { ShortNumberPipe } from './pipes/short-number.pipe';
import { TruncateTextPipe } from './pipes/truncate-text.pipe';
import { TruncateWordPipe } from './pipes/truncate-word.pipe';
import { CloudinaryModule } from '@cloudinary/angular-5.x';

@NgModule({
    declarations: [
        LogoComponent,
        UserProfileAvatarComponent,
        ShortNumberPipe,
        TruncateTextPipe,
        TruncateWordPipe,
    ],
    exports: [LogoComponent, UserProfileAvatarComponent, ShortNumberPipe, TruncateTextPipe],
    imports: [CommonModule, RouterModule, CloudinaryModule],
    providers: [ShortNumberPipe],
})
export class AppCommonModule {}
