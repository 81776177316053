export enum IdentityTypeEnum {
    DRIVERS_LICENCE = 'DRIVERS LICENCE',
    INTL_PASSPORT = 'INTL PASSPORT',
    ID_CARD = 'ID CARD',
}

export enum IdentityStatusEnum {
    VERIFIED = 'VERIFIED',
    REJECTED = 'REJECTED',
    PENDING = 'PENDING',
}
