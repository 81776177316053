import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { AuthService } from '@app/common/service/auth.service';
import { catchError } from 'rxjs/operators';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private authService: AuthService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            catchError((err) => {
                if (err.status === 401 && !window.location.href.includes('/sign-in')) {
                    // auto logout if 401 response returned from api
                    console.log('not authenticated');
                    this.authService.signout();
                    // location.reload();
                }
                const error = err.error.error || err.error.message || err.statusText;
                console.log(error);
                return throwError(err.error);
            })
        );
    }
}
