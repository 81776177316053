import { Component, OnDestroy, OnInit } from '@angular/core';
import { InvestmentService } from '@app/common/service/investment.service';
import { InvestmentPayload } from '@app/common/payload/investment.payload';
import { Subscription } from 'rxjs';
import { User } from '@app/common/payload/user';
import { AuthService } from '@app/common/service/auth.service';
import { ToastrService } from 'ngx-toastr';
import { PropertyStatusEnum } from '@app/common/enum/property.enums';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { DashboardStatsCard } from '@app/common/payload/dashboard-stats-card';
import { UploaderService } from '@app/common/service/uploader.service';

@Component({
    selector: 'app-dashboard-investment-page',
    templateUrl: './dashboard-investment-page.component.html',
    styleUrls: ['./dashboard-investment-page.component.css'],
})
export class DashboardInvestmentPageComponent implements OnInit, OnDestroy {
    user: User;
    investments: InvestmentPayload[];
    subscription: Subscription = new Subscription();
    totalCountCard: DashboardStatsCard;
    approvedCountCard: DashboardStatsCard;
    unapprovedCountCard: DashboardStatsCard;

    constructor(
        private toast: ToastrService,
        private investmentService: InvestmentService,
        private authService: AuthService,
        private spinner: NgxSpinnerService,
        private uploaderService: UploaderService
    ) {}

    ngOnInit(): void {
        this.uploaderService.onPrepareUploader({ tag: 'pop', folder: 'pop' });
        this.user = this.authService.currentUserValue;
        this.subscription.add(
            this.investmentService.onGetInvestmentByUser(this.user.id).subscribe(
                (result) => {
                    if (result.status === 200) {
                        this.investments = result.body;
                        this.totalCountCard = {
                            title: 'Total Investment Count',
                            isMoney: false,
                            count: this.investments.length,
                        };

                        this.unapprovedCountCard = {
                            title: 'Unapproved Investment Count',
                            isMoney: false,
                            count: this.onGetInvestmentCount('unapproved'),
                        };
                        this.approvedCountCard = {
                            title: 'Approved Investment Count',
                            isMoney: false,
                            count: this.onGetInvestmentCount('approved'),
                        };
                    }
                },
                (error) => {
                    console.log(error.message);
                    this.toast.error(`An error occurred, but we are working on it`);
                }
            )
        );
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    async onUpgradeInvestmentAmount(investment: InvestmentPayload) {
        if (investment.property.status === PropertyStatusEnum.CLOSED) {
            await Swal.fire({
                title: 'ACTION FAILED',
                html: `<code>You can not upgrade your investment amount <br> because the property has been closed </code>`,
                icon: 'warning',
            });
        } else if (investment.property.status === PropertyStatusEnum.OPEN) {
            const { value } = await Swal.fire({
                title: 'UPGRADE INVESTMENT',
                html: `<code class="text-primary">Please enter the additional amount
                        </code>`,
                icon: 'info',
                showCancelButton: true,
                cancelButtonText: 'NO! Go Back',
                input: 'number',
                inputPlaceholder: 'Enter amount',
                inputValidator: (value) => {
                    if (!value) {
                        return 'Enter the amount you want add';
                    }
                },
            });

            if (value) {
                const alreadyInvested = Math.floor(investment.amount);
                const additionalInvestment = Number(value);
                const propertyMaxAmount = Math.floor(investment.property.maxAmount);
                if (alreadyInvested + additionalInvestment > propertyMaxAmount) {
                    await Swal.fire({
                        title: 'ACTION FAILED',
                        html: `<code>You can not upgrade your investment <br> because your sum amount has exceeded the maximum amount allowed for investment per investor </code>`,
                        icon: 'warning',
                    });
                    return;
                }

                this.spinner.show().then();
                this.subscription.add(
                    this.investmentService
                        .onUpgradeInvestmentAmount(investment.id, Number(value))
                        .subscribe(
                            (result) => {
                                if (result.status === 200) {
                                    this.spinner.hide();
                                    Swal.fire({
                                        title: 'Successful',
                                        icon: 'success',
                                        html:
                                            '<small>Investment amount upgraded successfully</small> ',
                                    });
                                    this.ngOnInit();
                                }
                            },
                            (error) => {
                                Swal.fire({
                                    title: 'Error ',
                                    icon: 'error',
                                    html: `<small>${error.message}</small> `,
                                });
                            }
                        )
                );
            }
        }
    }

    async onUploadPOP(investment: InvestmentPayload) {
        const file = await this.uploaderService.onOpenUploadPOPModal(investment);
        if (file) {
            this.subscription.add(
                this.uploaderService.onUploadFile(file).subscribe((data) => {
                    if (data) {
                        const investmentToUploadPOP = new InvestmentPayload();
                        investmentToUploadPOP.uploads = data;
                        investmentToUploadPOP.id = investment.id;
                        this.subscription.add(
                            this.investmentService
                                .onUpdateInvestment(investmentToUploadPOP, investment.id)
                                .subscribe((updated) => {
                                    this.toast.success('Proof of Payment Uploaded Successfully');
                                })
                        );
                    }
                })
            );
        } else {
            this.toast.info('No file was added.');
        }
    }

    onGetInvestmentCount(type: string) {
        let count: number = 0;
        this.investments.forEach((investment) => {
            if (type == 'approved') {
                if (investment.isApproved) {
                    count++;
                }
            }

            if (type === 'unapproved') {
                if (!investment.isApproved) {
                    count++;
                }
            }
        });
        return count;
    }
}
