<main class="xs-all-content-wrapper">
    <section
        class="xs-content-section-padding prop-contact-details-section xs-contact-details-section"
    >
        <div class="container">
            <div class="prop-heading-title text-center xs-mb-60">
                <h2 class="color-navy-blue xs-mb-0">We are everywhere to support you</h2>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-10 content-center space-top-15">
                                <div class="xs-section-heading">
                                    <div class="xs-heading-title">
                                        <h2 class="color-navy-blue">Get in touch</h2>

                                        <p>
                                            Use the form below to send us a message.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-8 content-center">
                                <div class="xs-contact-form-wraper">
                                    <form
                                        name="form"
                                        (ngSubmit)="f.form.valid && onSubmit()"
                                        #f="ngForm"
                                    >
                                        <div class="row xs-margin-0 xs-mb-30">
                                            <div class="col-md-6 xs-padding-0 xs-pr-15">
                                                <div class="xs-input-group">
                                                    <label for="xs_contact_name">Name</label>
                                                    <div class="xs-input-group-addon">
                                                        <i
                                                            class="fa fa-user color-green xs-input-addon"
                                                        ></i>
                                                        <input
                                                            type="text"
                                                            name="name"
                                                            id="xs_contact_name"
                                                            class="xs-input-control"
                                                            placeholder="Enter your name..."
                                                            [(ngModel)]="model.name"
                                                            #name="ngModel"
                                                            [ngClass]="{
                                                                'is-invalid':
                                                                    f.submitted && name.invalid
                                                            }"
                                                            required
                                                        />
                                                    </div>
                                                    <div
                                                        *ngIf="f.submitted && name.invalid"
                                                        class="invalid-res"
                                                    >
                                                        <div *ngIf="name.errors.required">
                                                            Name is required
                                                        </div>
                                                        <div *ngIf="name.errors.name">
                                                            Name must be valid
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6 xs-padding-0 xs-pl-15">
                                                <div class="xs-input-group">
                                                    <label for="xs_contact_email">email</label>
                                                    <div class="xs-input-group-addon">
                                                        <i
                                                            class="fa fa-envelope-o color-green xs-input-addon"
                                                        ></i>

                                                        <input
                                                            type="email"
                                                            name="email"
                                                            id="xs_contact_email"
                                                            class="xs-input-control"
                                                            placeholder="Enter your email..."
                                                            [(ngModel)]="model.email"
                                                            #email="ngModel"
                                                            [ngClass]="{
                                                                'is-invalid':
                                                                    f.submitted && email.invalid
                                                            }"
                                                            required
                                                        />
                                                    </div>
                                                    <div
                                                        *ngIf="f.submitted && email.invalid"
                                                        class="invalid-res"
                                                    >
                                                        <div *ngIf="email.errors.required">
                                                            Email is required
                                                        </div>
                                                        <div *ngIf="email.errors.email">
                                                            Email must be a valid email address
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="xs-input-group xs-mb-30">
                                            <label for="xs_contact_subject">subject</label>

                                            <div class="xs-input-group-addon">
                                                <i
                                                    class="fa fa-sticky-note color-green xs-input-addon"
                                                ></i>
                                                <input
                                                    type="text"
                                                    name="subject"
                                                    id="xs_contact_subject"
                                                    class="xs-input-control"
                                                    placeholder="Subject"
                                                    [(ngModel)]="model.subject"
                                                    #subject="ngModel"
                                                    [ngClass]="{
                                                        'is-invalid': f.submitted && subject.invalid
                                                    }"
                                                    required
                                                />
                                            </div>
                                            <div
                                                *ngIf="f.submitted && subject.invalid"
                                                class="invalid-res"
                                            >
                                                <div *ngIf="subject.errors.required">
                                                    Subject is required
                                                </div>
                                                <div *ngIf="subject.errors.email">
                                                    Subject must be
                                                </div>
                                            </div>
                                        </div>
                                        <div class="xs-massage-group xs-mb-30">
                                            <label for="x_contact_massage">Message</label>
                                            <div class="xs-massage-group-addon">
                                                <i
                                                    class="fa fa-pencil color-green xs-input-addon"
                                                ></i>
                                                <textarea
                                                    name="massage"
                                                    id="x_contact_massage"
                                                    class="xs-textarea-control"
                                                    cols="30"
                                                    rows="10"
                                                    placeholder="Enter your message..."
                                                    [(ngModel)]="model.message"
                                                    #message="ngModel"
                                                    [ngClass]="{
                                                        'is-invalid': f.submitted && message.invalid
                                                    }"
                                                    required
                                                ></textarea>
                                            </div>
                                            <div
                                                *ngIf="f.submitted && message.invalid"
                                                class="invalid-res"
                                            >
                                                <div *ngIf="message.errors.required">
                                                    Message is required
                                                </div>
                                                <div *ngIf="message.errors.email">
                                                    Message must be valid
                                                </div>
                                            </div>
                                        </div>
                                        <div class="xs-submit-wraper content-center">
                                            <button class="xs-submit-btn" [disabled]="isLoading">
                                                Send Message &nbsp;
                                                <i
                                                    class="fa fa-spin fa-spinner"
                                                    *ngIf="isLoading"
                                                ></i>
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3"></div>
                <div class="col-md-6 space-top-40">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-10 content-center space-top-25">
                                <div class="xs-section-heading">
                                    <div class="xs-heading-title">
                                        <h2 class="color-navy-blue">Or Contact Us Quickly</h2>

                                        <p>
                                            Pick up the phone and call or Email Directly.
                                        </p>
                                        <ul class="xs-list-item-icon-text">
                                            <li class="space-top-10">
                                                <i class="fa fa-phone color-green"></i
                                                >+234(0)80-9809-9040
                                            </li>
                                            <li class="space-top-10">
                                                <i class="fa fa-envelope-o color-green"></i
                                                >support@propertycollabo.com
                                            </li>
                                            <li class="space-top-10">
                                                <i class="fa fa-map-marker color-green"></i>Lekki,
                                                Lagos, Nigeria.
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3"></div>
            </div>
        </div>
    </section>
</main>
