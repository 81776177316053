<ng-container *ngIf="image?.public_id; else defaultImage">
    <cl-image [public-id]="image.public_id">
        <cl-transformation width="400" height="400" gravity="face" radius="max" crop="crop">
        </cl-transformation>
        <cl-transformation width="200" crop="scale"> </cl-transformation>
    </cl-image>
</ng-container>

<ng-template #defaultImage>
    <img [src]="defaultAvatar" width="200" alt="Profile Pic" />
</ng-template>
