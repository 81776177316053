<ngx-spinner></ngx-spinner>
<app-dashboard-breadcrumbs [crumbs]="crumbs"></app-dashboard-breadcrumbs>

<div class="content-i">
    <div class="content-box">
        <div class="row">
            <div class="col-lg-10">
                <div class="element-wrapper">
                    <h6 class="element-header">Update Property</h6>

                    <div class="element-box">
                        <h5 class="form-header">Update a property</h5>
                        <div class="form-desc">
                            <small
                                ><code
                                    >property images are required, you can upload at least one
                                    image</code
                                ></small
                            >
                        </div>
                        <app-dashboard-admin-property-form
                            [command]="command"
                        ></app-dashboard-admin-property-form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
