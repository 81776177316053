<!-- feature section -->
<section class="xs-gray-bg-2 xs-section-padding" data-scrollax-parent="true">
    <div class="container">
        <div class="prop-heading-title-v3 text-center wow fadeInUp" data-wow-duration="1s">
            <div class="prop-heading-title-content">
                <h2>A Different kind of <span> Real Estate </span> Investing</h2>
                <p>
                    We make investing in real estate simple, transparent and easily accessible.
                    Invest in a landed property, house or apartment today, from as low as N50,000.
                    Check out our offerings below
                </p>
            </div>
            <span class="parallax-title" data-scrollax="properties: { translateY: '-250px' }"
                >GAIN</span
            >
        </div>
        <!-- section heading -->
        <div class="prop-tab-wraper">
            <div class="prop-tab-nav-v3 xs-tab-nav wow fadeInUp" data-wow-duration="1.3s">
                <ul class="nav nav-tabs" role="tablist">
                    <li
                        class="nav-item"
                        (click)="onToggleFilter(type, i)"
                        *ngFor="let type of propertyTypes; let i = index"
                    >
                        <a
                            #tabs
                            [id]="'menu-' + i"
                            class="nav-link point"
                            [ngClass]="{ active: i == 0 }"
                            role="tab"
                            data-toggle="tab"
                            >{{ type }}</a
                        >
                    </li>
                </ul>
            </div>

            <div class="tab-content wow fadeInUp" data-wow-duration="1.5s">
                <div role="tabpanel" class="tab-pane fade in active show" id="comics">
                    <div class="row">
                        <ng-container *ngIf="data; else loading">
                            <ng-container *ngFor="let property of data | slice: 0:3; let i = index">
                                <div class="col-lg-4">
                                    <div class="prop-grid-item-content-v2">
                                        <div class="prop-item-header">
                                            <img
                                                *ngIf="property.image[0]?.secure_url"
                                                class="feature-prop-image"
                                                [src]="property.image[0].secure_url"
                                                [alt]="property.title"
                                            />
                                            <div class="xs-item-header-content">
                                                <a
                                                    class="badge badge-v1 badge-pill badge-primary"
                                                    >{{ property.type }}</a
                                                >
                                            </div>
                                        </div>
                                        <div class="prop-item-content text-center">
                                            <div
                                                class="row xs-margin-0 justify-content-center xs-mb-20"
                                            >
                                                <div class="full-round prop-avatar"></div>
                                                <div class="xs-avatar-title"></div>
                                            </div>
                                            <a class="d-block color-navy-blue prop-post-title">{{
                                                property.title | truncateText: 20
                                            }}</a>
                                            <p>
                                                {{ property.description | truncateText: 40 }}
                                                <span class="text-primary d-block">
                                                    <a
                                                        [routerLink]="
                                                            '/app/property/' +
                                                            onEncode(property.title) +
                                                            '/' +
                                                            property.uud
                                                        "
                                                        >view</a
                                                    >
                                                </span>
                                            </p>

                                            <span class="xs-separetor"></span>

                                            <div class="prop-list-cat">
                                                <span
                                                    ><i class="icon icon-chart22"></i
                                                    ><a
                                                        >{{
                                                            property.returnOfInvestment
                                                                | shortNumber: ' '
                                                        }}% ROI</a
                                                    ></span
                                                >
                                                <span
                                                    ><i class="icon icon-money-3"></i
                                                    ><a
                                                        >{{
                                                            property.donation | shortNumber
                                                        }}
                                                        Raised</a
                                                    ></span
                                                >
                                                <span
                                                    class="badge badge-v2 badge-pill badge-primary"
                                                    >{{ property.target | shortNumber }}</span
                                                >
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<ng-template #loading>
    <div class="text-center space-top-25 pad-15 loading">
        <i class="fa fa-spinner fa-spin"></i>
    </div>
</ng-template>
