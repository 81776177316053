import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-terms-contact',
  templateUrl: './terms-contact.component.html',
  styleUrls: ['./terms-contact.component.css']
})
export class TermsContactComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
