import { InvestmentPaymentOptions, InvestmentStatus } from '@app/common/enum/investment.enums';
import { ImagePayload, PropertyPayload } from '@app/common/payload/property.payload';
import { User } from '@app/common/payload/user';

export class InvestmentPayload {
    id: number;
    amount?: number;
    isApproved?: boolean;
    status?: InvestmentStatus;
    uploads?: ImagePayload | ImagePayload[];
    paymentMethod?: InvestmentPaymentOptions;
    createdAt: Date;
    updatedAt: Date;
    user?: User;
    property?: PropertyPayload;
    userId?: number;
    propertyId?: number;
    isInvestingFromEarning?: boolean;
    reference?: string;
}

export enum InvestmentFilterEnum {
    ALL = 'ALL',
    UNAPPROVED_WITHOUT_POP = 'UNAPPROVED WITHOUT POP',
    UNAPPROVED_WITH_POP = 'UNAPPROVED WITH POP',
}

export interface InvestmentStats {
    totalInvestmentsCount: number;
    approvedInvestmentCount: number;
    unapprovedInvestmentCount: number;
    totalAmountInvested: number;
    totalApprovedAmount: number;
    totalUnapprovedAmount: number;
}
