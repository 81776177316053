<div class="top-bar color-scheme-transparent">
    <div class="top-menu-controls">
        <!--top bar search input -->
        <!-- top bar setting quick link -->
        <div class="top-icon top-settings os-dropdown-trigger os-dropdown-position-left">
            <!--            <i class="os-icon os-icon-ui-46"></i>-->
            <div class="os-dropdown">
                <div class="icon-w"><i class="os-icon os-icon-ui-46"></i></div>
            </div>
        </div>
        <!-- top bar user card -->
        <div class="logged-user-w">
            <div class="logged-user-i">
                <div class="avatar-w">
                    <app-user-profile-avatar></app-user-profile-avatar>
                </div>

                <app-dashboard-usercard-menu></app-dashboard-usercard-menu>
            </div>
        </div>
    </div>
</div>
