import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { environment } from '@environments/environment.prod';

@Component({
    selector: 'app-faq-page',
    templateUrl: './faq-page.component.html',
    styleUrls: ['./faq-page.component.css'],
})
export class FaqPageComponent implements OnInit {
    constructor(private titleService: Title) {}

    ngOnInit() {
        this.titleService.setTitle(`FAQ - ${environment.appName}`);
    }
}
