import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { User, BankDetails } from '@app/common/payload/user';
import { ReturnsService } from '@app/common/service/returns.service';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '@app/common/service/auth.service';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-dashboard-user-update-profile',
    templateUrl: './dashboard-user-update-profile.component.html',
    styleUrls: ['./dashboard-user-update-profile.component.css'],
})
export class DashboardUserUpdateProfileComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input() bankDetails: BankDetails;
    subscription: Subscription = new Subscription();
    emitter: EventEmitter<BankDetails> = new EventEmitter<BankDetails>();
    isLoading: boolean = false;

    constructor(
        private returnsService: ReturnsService,
        private cd: ChangeDetectorRef,
        private toast: ToastrService,
        private authService: AuthService
    ) {}

    ngOnInit(): void {
        if (!this.bankDetails) {
            this.bankDetails = {};
        }
    }

    onUpdateBankDetails() {
        this.isLoading = true;
        this.bankDetails.userId = this.authService.currentUserValue.id;
        this.subscription.add(
            this.returnsService.updateUserBankDetails(this.bankDetails).subscribe(
                (result) => {
                    if (result.status === 201) {
                        this.toast.success('Account Updated', 'Success', {});
                        this.isLoading = false;
                        this.emitter.emit(result.body);
                    }
                },
                (error) => {
                    this.isLoading = false;
                    this.toast.error(`${error.message}`, 'Failed');
                }
            )
        );
    }

    ngAfterViewInit(): void {}

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}
