import {
    Component,
    ElementRef,
    HostListener,
    Input,
    OnInit,
    QueryList,
    ViewChildren,
} from '@angular/core';
import { adminMenu, MenuBag } from '@app/common/constant/menu-bag';

@Component({
    selector: 'app-dashboard-menu-sidebar',
    templateUrl: './dashboard-menu-sidebar.component.html',
    styleUrls: ['./dashboard-menu-sidebar.component.css'],
})
export class DashboardMenuSidebarComponent implements OnInit {
    @Input() menuBag: MenuBag[];

    @ViewChildren('myitems') items: QueryList<ElementRef>;

    constructor() {}

    ngOnInit(): void {}

    closeMenu() {
        this.items.forEach((item) => {
            item.nativeElement.classList.remove('active');
        });
    }

    openMenu($event: MouseEvent, menu: MenuBag, index: number) {
        this.items.forEach((item) => {
            if (item.nativeElement.id !== `menu-${index}`) {
                item.nativeElement.classList.remove('active');
            } else {
                item.nativeElement.classList.add('active');
            }
        });
    }

    // @HostListener('document:click', ['$event'])
    // clickOut(event) {
    //     console.log('clicked out');
    //     const elem = document.getElementsByClassName('point has-sub-menu active');
    //     console.log(elem);
    // }
}
