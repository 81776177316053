import { NgModule } from '@angular/core';
import { HomePageComponent } from '@app/website/website-pages/home-page/home-page.component';
import { PropertyDetailComponent } from './property-detail-page/property-detail.component';
import { ContactPageComponent } from './contact-page/contact-page.component';
import { WebsiteLayoutModule } from '@app/website/website-layout/website-layout.module';
import { FaqPageComponent } from './faq-page/faq-page.component';
import { NgbCarouselModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserModule } from '@angular/platform-browser';
import { TermsOfUseComponent } from './terms-of-use/terms-of-use.component';
import { TermsOfSponsorshipComponent } from './terms-of-sponsorship/terms-of-sponsorship.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { DisclaimerComponent } from './disclaimer/disclaimer.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { TermsContactComponent } from './terms-contact/terms-contact.component';
import { RouterModule } from '@angular/router';

@NgModule({
    declarations: [
        HomePageComponent,
        PropertyDetailComponent,
        ContactPageComponent,
        FaqPageComponent,
        TermsOfUseComponent,
        TermsOfSponsorshipComponent,
        PrivacyPolicyComponent,
        DisclaimerComponent,
        AboutUsComponent,
        TermsContactComponent,
    ],
    providers: [],
    imports: [WebsiteLayoutModule, NgbCarouselModule, BrowserModule, RouterModule],
    exports: [HomePageComponent, PropertyDetailComponent, ContactPageComponent],
})
export class WebsitePagesModule {}
