import { Injectable } from '@angular/core';
import { environment } from '@environments/environment.prod';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ImagePayload, PropertyPayload } from '@app/common/payload/property.payload';
import { PropertyFilterPayload } from '@app/common/payload/property-filter.payload';
import { PropertyStatusEnum } from '@app/common/enum/property.enums';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class PropertyService {
    private url: string = `${environment.url}/property`;
    public imageSubject: BehaviorSubject<ImagePayload[]>;
    public imageSubject$: Observable<ImagePayload[]>;

    constructor(private http: HttpClient) {
        this.imageSubject = new BehaviorSubject<ImagePayload[]>([]);
        this.imageSubject$ = this.imageSubject.asObservable();
    }

    public onFindAllProperties() {
        return this.http.get<PropertyPayload[]>(`${this.url}`);
    }

    public onFindPropertyById(id: number) {
        return this.http.get<PropertyPayload>(`${this.url}/find/${id}`, { observe: 'response' });
    }

    public onFindPropertyByUUD(id: string) {
        let params = new HttpParams().set('search', id);
        return this.http.get<PropertyPayload>(`${this.url}/find/`, {
            params: params,
            observe: 'response',
        });
    }

    public onCreateProperty(propertyPayload: PropertyPayload) {
        return this.http.post(`${this.url}/create`, propertyPayload, { observe: 'response' });
    }

    public onUpdateProperty(id: number, propertyPayload: PropertyPayload) {
        return this.http.patch(`${this.url}/update/${id}`, propertyPayload, {
            observe: 'response',
        });
    }

    public onDeleteProperty(id: number) {
        return this.http.delete(`${this.url}/delete/${id}`, { observe: 'response' });
    }

    public onUpdatePropertyStatus(id: number, status: PropertyStatusEnum) {
        const payload: PropertyPayload = {
            status: status,
        };
        return this.http.patch<PropertyPayload>(`${this.url}/update/${id}`, payload);
    }

    public onFilterProperties(propertyFilterPayload: PropertyFilterPayload) {
        return this.http.post<PropertyPayload[]>(`${this.url}/search`, propertyFilterPayload);
    }

    public onDeletePropertyImage(public_id: string) {
        return this.http.post(`${this.url}/image`, { public_id: public_id });
    }

    public onGetPropertiesForWebsite() {
        return this.http.get<PropertyPayload[]>(`${this.url}/website-properties`);
    }

    public onGetPercentage(property: PropertyPayload) {
        return Math.round((property.donation / property.target) * 100);
    }
}
