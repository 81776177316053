import { Component, Input, OnInit } from '@angular/core';
import { User } from '@app/common/payload/user';
import { AuthService } from '@app/common/service/auth.service';

@Component({
    selector: 'app-dashboard-usercard',
    templateUrl: './dashboard-usercard.component.html',
    styleUrls: ['./dashboard-usercard.component.css'],
})
export class DashboardUsercardComponent implements OnInit {
    @Input() user: User;

    constructor(private authService: AuthService) {}

    ngOnInit(): void {}

    onSignOut() {
        this.authService.signout();
    }
}
