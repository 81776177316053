import { Injectable } from '@angular/core';
import {
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    UrlTree,
    Router,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '@app/common/service/auth.service';

@Injectable({
    providedIn: 'root',
})
export class AuthGuard implements CanActivate {
    constructor(private router: Router, private authService: AuthService) {}
    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        const currentUser = this.authService.isAuthenticated();
        if (currentUser) {
            return true;
        }
        this.router.navigate(['/sign-in']).then();
        // this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } }).then();
        return false;
    }
}
