import { Component, OnDestroy, OnInit } from '@angular/core';
import { UserService } from '@app/common/service/user.service';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-contact-section',
    templateUrl: './contact-section.component.html',
    styleUrls: ['./contact-section.component.css'],
})
export class ContactSectionComponent implements OnInit, OnDestroy {
    model: { name: string; email: string; subject: string; message: string } = {
        email: '',
        message: '',
        subject: '',
        name: '',
    };
    isLoading: boolean = false;

    subscription: Subscription = new Subscription();

    constructor(private service: UserService, private toast: ToastrService) {}

    ngOnInit() {}

    onSubmit() {
        this.isLoading = true;
        this.subscription = this.service.onSendContactEmail(this.model).subscribe(
            (result) => {
                this.toast.success('Message sent successfully', 'Email Sent');
                this.isLoading = false;
                this.onResetForm();
            },
            (error) => {
                this.toast.error('Failed, Try Again!', 'Try Again Later');
                this.isLoading = false;
                this.onResetForm();
            }
        );
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    onResetForm() {}
}
