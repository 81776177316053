import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ImagePayload, PropertyPayload } from '@app/common/payload/property.payload';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { PropertyService } from '@app/common/service/property.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { CommandEnum } from '@app/common/enum/command.enum';

@Component({
    selector: 'app-dashboard-admin-property-form',
    templateUrl: './dashboard-admin-property-form.component.html',
    styleUrls: ['./dashboard-admin-property-form.component.css'],
})
export class DashboardAdminPropertyFormComponent implements OnInit, OnDestroy {
    @ViewChild('errorDiv') target: ElementRef;
    @Input() property: PropertyPayload;
    @Input() command: CommandEnum;

    model: NgbDateStruct;
    form: FormGroup;
    payload: PropertyPayload;
    isSubmitted = false;
    hasErrors = false;
    errorMessages: any[] = [];

    subscription: Subscription = new Subscription();
    routeState: any;
    sharedPropertyImage: ImagePayload[];

    //features
    features: { icon?: string; text?: string }[] = [];
    feature: { icon?: string; text?: string } = {};
    showInWebsite: string = 'no';
    //
    // id: number;
    propertyUUD: string;

    constructor(
        private propertyService: PropertyService,
        private spinner: NgxSpinnerService,
        private toast: ToastrService,
        private router: Router,
        private route: ActivatedRoute
    ) {
        if (this.router.getCurrentNavigation()?.extras?.state) {
            this.routeState = this.router.getCurrentNavigation().extras?.state;
            if (this.routeState) {
                this.property = this.routeState.data;
                if (this.property.others?.features) this.features = this.property.others.features;
                if (this.property.others?.display) {
                    this.showInWebsite = 'yes';
                } else {
                    this.showInWebsite = 'no';
                }
            }
        }
    }

    ngOnInit(): void {
        this.subscription.add(
            this.route.params.subscribe((params) => {
                this.propertyUUD = params['id'];
            })
        );

        if (!this.property && this.command === CommandEnum.UPDATE) {
            this.subscription.add(
                this.propertyService.onFindPropertyByUUD(this.propertyUUD).subscribe((result) => {
                    if (result.status === 200) {
                        this.property = result.body;
                        if (result.body.others) {
                            this.features = result.body.others.features;
                        }
                        if (result.body.others?.display) {
                            this.showInWebsite = 'yes';
                        } else {
                            this.showInWebsite = 'no';
                        }
                        this.onSetForm();
                    }
                })
            );
        }

        this.propertyService.imageSubject.subscribe((c) => {
            this.sharedPropertyImage = c;
        });

        if (this.property?.closeDate) {
            const date = new Date(this.property?.closeDate);
            this.model = {
                day: date.getUTCDate(),
                month: date.getUTCMonth() + 1,
                year: date.getUTCFullYear(),
            };
        }
        this.form = new FormGroup({
            title: new FormControl(this.property?.title, [Validators.required]),
            description: new FormControl(this.property?.description, [Validators.required]),
            price: new FormControl(this.property?.price, [Validators.required]),
            address: new FormControl(this.property?.address, [Validators.required]),
            location: new FormControl(this.property?.location, [Validators.required]),
            duration: new FormControl(this.property?.duration, [Validators.required]),
            returnOfInvestment: new FormControl(this.property?.returnOfInvestment, [
                Validators.required,
                Validators.max(100),
            ]),
            type: new FormControl(this.property?.type, [Validators.required]),
            category: new FormControl(this.property?.category, [Validators.required]),
            status: new FormControl(this.property?.status, [Validators.required]),
            minAmount: new FormControl(this.property?.minAmount, [Validators.required]),
            maxAmount: new FormControl(this.property?.maxAmount, [Validators.required]),
            target: new FormControl(this.property?.target, [Validators.required]),
            closeDate: new FormControl(this.model, [Validators.required]),
        });
        this.payload = {};
    }
    get pf() {
        return this.form.controls;
    }

    onSetForm() {
        const date = new Date(this.property?.closeDate);
        const model = {
            day: date.getUTCDate(),
            month: date.getUTCMonth() + 1,
            year: date.getUTCFullYear(),
        };
        this.form.controls['title'].setValue(this.property.title);
        this.form.controls['description'].setValue(this.property.description);
        this.form.controls['price'].setValue(this.property.price);
        this.form.controls['address'].setValue(this.property.address);
        this.form.controls['location'].setValue(this.property.location);
        this.form.controls['duration'].setValue(this.property.duration);
        this.form.controls['returnOfInvestment'].setValue(this.property.returnOfInvestment);
        this.form.controls['type'].setValue(this.property.type);
        this.form.controls['category'].setValue(this.property.category);
        this.form.controls['status'].setValue(this.property.status);
        this.form.controls['minAmount'].setValue(this.property.minAmount);
        this.form.controls['maxAmount'].setValue(this.property.maxAmount);
        this.form.controls['target'].setValue(this.property.target);
        this.form.controls['closeDate'].setValue(model);
    }

    async onSubmitProperty() {
        this.isSubmitted = true;

        if (this.form.invalid) {
            return;
        }
        if (this.sharedPropertyImage.length < 1 && this.command === CommandEnum.CREATE) {
            this.hasErrors = true;
            this.errorMessages.push('Upload at least one property image first');
            this.scroll(this.target.nativeElement);
            return;
        }

        this.hasErrors = false;
        this.errorMessages = [];

        this.toast.show('Preparing Uploaded Images');
        this.payload = this.form.value;
        this.payload.closeDate = new Date(this.model.year, this.model.month - 1, this.model.day);
        this.payload.image = this.sharedPropertyImage;
        this.payload.others = {};
        this.payload.others.features = this.features;
        if (this.showInWebsite === 'yes') {
            this.payload.others.display = true;
        }

        if (this.command === CommandEnum.CREATE) {
            this.onCreateProperty();
        } else if (this.command === CommandEnum.UPDATE) {
            this.onUpdateProperty();
        }
    }

    onCreateProperty() {
        this.spinner.show().then();
        this.toast.show('Creating Property');
        this.subscription = this.propertyService.onCreateProperty(this.payload).subscribe(
            (result) => {
                if (result.status === 201) {
                    this.spinner.hide().then();
                    this.toast.success('Property Created Successfully.', 'Success!');
                    this.isSubmitted = false;
                }
            },
            (error) => {
                this.isSubmitted = false;
                this.hasErrors = true;
                this.errorMessages = error.message;
                this.spinner.hide().then();
                this.toast.error(`Could'nt create property <br> ${error} `, 'Error Occurred!');
            }
        );
    }

    onUpdateProperty() {
        this.spinner.show().then();
        this.toast.show('Updating Property');
        this.payload.id = this.property.id;
        this.subscription = this.propertyService
            .onUpdateProperty(this.property.id, this.payload)
            .subscribe(
                (result) => {
                    if (result.status === 200) {
                        this.spinner.hide().then();
                        this.toast.success('Property Updated Successfully.', 'Success!');
                        this.isSubmitted = false;
                        this.propertyService.imageSubject.next([]);
                    }
                },
                (error) => {
                    this.isSubmitted = false;
                    this.hasErrors = true;
                    this.errorMessages = error.message;
                    this.spinner.hide().then();
                    this.toast.error("Could'nt update property", 'Error Occurred!');
                }
            );
    }

    onRemoveError() {
        this.errorMessages = [];
        this.hasErrors = false;
    }

    scroll(el: HTMLElement) {
        el.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }

    ngOnDestroy(): void {
        this.property = undefined;
        this.subscription.unsubscribe();
    }

    onAddFeature() {
        if (!this.feature.text || !this.feature.icon) {
            this.toast.error('Add Features First');
            return;
        }
        this.features.push(this.feature);
        this.feature = {};
    }

    onRemoveFeature(i: number) {
        this.features.splice(i, 1);
    }
}
