<div class="content-i">
    <div class="content-box">
        <div class="row pt-4">
            <div class="col-sm-12">
                <div class="element-wrapper">
                    <h6 class="element-header">Manage Users</h6>
                    <div class="element-box-tp">
                        <div class="table-responsive">
                            <table class="table table-padded">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th class="text-center">Full Name</th>
                                        <th>Date Joined</th>
                                        <th class="text-center">Investments</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        *ngFor="let user of pageOfItems; let i = index"
                                        [@simpleFadeAnimation]="'in'"
                                    >
                                        <td class="text-center">
                                            <input class="form-control" type="checkbox" />
                                        </td>
                                        <td>
                                            <div class="user-with-avatar text-center">
                                                <ng-container *ngIf="user?.image?.public_id">
                                                    <cl-image [public-id]="user?.image?.public_id">
                                                        <cl-transformation
                                                            width="400"
                                                            height="400"
                                                            gravity="face"
                                                            radius="max"
                                                            crop="crop"
                                                        >
                                                        </cl-transformation>
                                                        <cl-transformation width="200" crop="scale">
                                                        </cl-transformation>
                                                    </cl-image>
                                                    <br />
                                                </ng-container>

                                                <span
                                                    >{{ user.firstName }} {{ user.lastName }}</span
                                                >
                                                <div class="smaller lighter">
                                                    <span *ngIf="user.email">
                                                        <a class="text-primary">Email:</a>
                                                        {{ user?.email }},
                                                    </span>
                                                    <span *ngIf="user.phone">
                                                        <a class="text-primary"> Phone:</a>
                                                        {{ user?.phone }},</span
                                                    >
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <span>{{ user.createdAt | date }}</span>
                                        </td>
                                        <td>
                                            <span> {{ user.investments.length }}</span
                                            ><span class="smaller lighter">
                                                {{
                                                    user.investments.length <= 1
                                                        ? 'Investment'
                                                        : 'Investments'
                                                }}
                                            </span>
                                        </td>
                                        <td class="row-actions">
                                            <a class="point" (click)="onGetUserIdentity(user)">
                                                <i
                                                    ngbTooltip="View User Identity"
                                                    placement="left-top"
                                                    container="body"
                                                    class="os-icon os-icon-image"
                                                ></i></a
                                            ><a
                                                *ngIf="user.investments.length"
                                                class="point"
                                                [routerLink]="
                                                    '/app/admin/users/investment/' + user.id
                                                "
                                                ><i
                                                    ngbTooltip="View User Investments"
                                                    placement="left-bottom"
                                                    container="body"
                                                    class="os-icon os-icon-folder-plus"
                                                ></i></a
                                            ><a
                                                [routerLink]="'/app/admin/users/profile/' + user.id"
                                                [state]="{ data: user }"
                                                class="point"
                                                ><i
                                                    ngbTooltip="View Complete Profile"
                                                    placement="left-top"
                                                    container="body"
                                                    class="os-icon os-icon-window-content"
                                                ></i
                                            ></a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="pagination-w space-b-25">
                            <div class="pagination-info">
                                <div class="pagination-links">
                                    <jw-pagination
                                        class="point"
                                        [pageSize]="pageSize"
                                        [items]="items"
                                        (changePage)="onChangePage($event)"
                                    ></jw-pagination>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
