<app-website-header></app-website-header>
<div class="container">
    <div class="text-justify">
        <div class="prop-heading-title text-center xs-mb-60 space-top-25">
            <h2 class="color-navy-blue xs-mb-0">Terms of Sponsorship</h2>
        </div>
        <p>
            At PropetyCollabo we make investing in real estate simple, transparent, and easily
            accessible. This website is brought to you by PropertyCollabo Limited
            (“PropertyCollabo”, “we”, “us”, or “our”).
            <br />
        </p>

        <div class="xs-section-heading">
            <div class="xs-heading-title">
                <h2 class="color-navy-blue">Introduction</h2>
                <p>
                    This Terms of Sponsorship constitutes a legally binding contract between you
                    (“Sponsor”) and PropertyCollabo Limited in your access and use of the website.
                    The use of the website subject to the acceptance of all terms and conditions
                    contained in this Terms of Sponsorship.
                </p>
                <p>
                    This Terms of Sponsorship (TOS) outlines the terms and conditions under which
                    you agree to sponsor investment opportunities as advertised on the website and
                    which you must adhere to as a Sponsor. We therefore kindly request that you
                    carefully read through these terms before using the website and click on the
                    Agree icon if you consent and agree to be bound by this Terms of Sponsorship. If
                    you do not agree with the terms hereunder, we kindly advise that you refrain
                    from using the website. Kindly note that if you proceed to use the website
                    without accepting the Terms herein, you will be deemed to have agreed to these
                    Terms and to be bound by the same.
                </p>
            </div>
        </div>

        <div class="xs-section-heading">
            <div class="xs-heading-title">
                <h2 class="color-navy-blue">Sponsorship Terms</h2>
                <p>
                    You will receive periodic updates by email of the status/progress of any
                    investments you make. The updates are also available through your account
                    accessible via the website. You are expected to review all notifications,
                    updates, and reports from PropertyCollabo as they represent a record of
                    information pertaining to your sponsorship contribution. You can send your
                    comments, inquiries, or reviews to us by mail.
                </p>
                <p>
                    You acknowledge and agree that PropertyCollabo may at its absolute discretion
                    accept or refuse your application for sponsorship.
                </p>
                <p>
                    You agree that by choosing to sponsor on our website, your sponsorship is fixed
                    and unavailable for the entire duration of the sponsorship cycle, and
                    PropertyCollabo shall not be obliged to return your sponsorship until the
                    successful completion of a sponsorship cycle.
                </p>
                <p>
                    Any return on investment upon the successful conclusion of a sponsorship cycle
                    will be remitted to your bank account.
                </p>
                <p>
                    At the end of a sponsorship cycle, the sum of sponsorship and returns earned is
                    transferred to your nominated bank account which you are to provide on your
                    account profile. Bank account details are expected to be provided before you can
                    make a request to withdraw funds earned.
                </p>
            </div>
        </div>

        <div class="xs-section-heading">
            <div class="xs-heading-title">
                <h2 class="color-navy-blue">Sponsor Representations And Warranties</h2>
                <p>
                    Representations and warranties represent assurances made by you to us, which we
                    rely on as being accurate in our dealings with you. You make the following
                    representations and warranties to us at the time of agreeing to these ToS and
                    every time you use the website:
                </p>
                <p>
                    you are over the age of 18 years, of sound mind, and have the capacity to enter
                    into this TOS and be matched to a scheme;
                </p>
                <p>you have read and agreed with our terms and conditions</p>
                <p>
                    all personal information that you provide about yourself is accurate and true to
                    the best of your knowledge;
                </p>
                <p>
                    you hold a bank account in Nigeria in your own name and will use this account to
                    transfer money to our account when you sponsor a property/properties and to
                    receive refunds of contributions made and profits earned;
                </p>

                <p>
                    you have sought independent professional/legal advice and carefully considered
                    the risks involved with using the website, and associated with your
                    contribution, including but not limited to the possibility of incurring a loss
                    if the occasion arises;
                </p>

                <p>
                    you understand that any information made available on the website is not in any
                    way tailored to reflect your personal financial circumstances or investment
                    objectives;
                </p>
                <p>
                    you will only sponsor with your own money unless you are authorized on behalf of
                    a third party (“Third Party”). Whist you may act on behalf of any Third Party,
                    PropertyCollabo shall only deal with and be responsible to you and shall have no
                    obligation or liability to any unknown third party;
                </p>
                <p>
                    you are not using any illegal funds or engaging in any fraudulent activity by
                    sponsoring on this website;
                </p>
                <p>
                    if you are registering or accepting this TOS on behalf of a legal entity such as
                    a company, trust, or partnership, and you are legally authorized to do so. In
                    such a case, you shall register and/or sponsor in the name of such an entity. We
                    may request evidence of such legal entitlement at any time (by way of a copy of
                    any document which shows the valid and subsisting authorization); and
                </p>
                <p>
                    you are not breaking any laws or regulations that are applicable to you or any
                    company, trust, or partnership upon whose instructions you are acting.
                </p>
            </div>
        </div>

        <div class="xs-section-heading">
            <div class="xs-heading-title">
                <h2 class="color-navy-blue">Access and Use</h2>
                <p>
                    The website is, at all times, subject to our Terms of Use, available here.
                    Kindly visit and review our Terms of Use before using the website.
                </p>
            </div>
        </div>

        <div class="xs-section-heading">
            <div class="xs-heading-title">
                <h2 class="color-navy-blue">Anti-Money Laundering/Fraud Prevention</h2>
                <p>
                    In using this website, you undertake that you shall comply with all applicable
                    laws, including anti-money laundering and anti-corruption laws, regulations, and
                    codes in Nigeria. We reserve the right to at any time, suspend or withdraw the
                    use of this website when we believe in our sole discretion that the website is
                    being used for fraudulent purposes or for any suspicious transactions. In
                    addition to the suspension or withdrawal of services, we also have an obligation
                    to report your activities to the relevant authorities without any recourse to
                    you.
                </p>

                <p>
                    You consent to us carrying out identity and fraud prevention checks and sharing
                    information relating to your use of the website with any regulatory body, and
                    fraud prevention, law enforcement, or security agency.
                </p>

                <p>
                    You consent to us providing details to the Nigerian police or any fraud
                    prevention, law enforcement, or security agency, of any conduct on your account
                    that gives reasonable cause to suspect that the account is being used for
                    improper purposes.
                </p>
            </div>
        </div>

        <div class="xs-section-heading">
            <div class="xs-heading-title">
                <h2 class="color-navy-blue">Foreign Exchange Risk</h2>
                <p>
                    Where a Sponsor contributes in a currency other than the Naira, foreign exchange
                    gain or loss and fluctuations in currency rates may have an impact on the profit
                    made on such contribution by the Sponsor.
                </p>

                <p>
                    All profits on sponsorships and return on investment shall be converted and paid
                    in Naira based on the Central Bank of Nigeria (CBN) official exchange rate. You,
                    therefore, enter into any transactions in currencies other than the Naira at
                    your sole risk and shall bear all responsibilities for any profit or losses as a
                    result.
                </p>

                <p>
                    Unexpected changes in currency exchange market conditions may have an impact on
                    the extent of profit available to a Sponsor, where there is a fluctuation in the
                    relevant rates.
                </p>

                <p>
                    You understand and agree to assume the risk of loss associated with the use of
                    foreign exchange and agree to be liable for any resulting deficit this might
                    have on contributions made or profits earned.
                </p>
            </div>
        </div>

        <div class="xs-section-heading">
            <div class="xs-heading-title">
                <h2 class="color-navy-blue">Confidentiality</h2>
                <p>
                    Communications, Confidential Information or other information, and materials
                    between PropertyCollabo and the Sponsor, either marked 'confidential' or is by
                    its nature intended to be for the knowledge of the Sponsor alone, shall be kept
                    confidential by the Sponsor unless or until the Sponsor can reasonably
                    demonstrate that such communication, information, and material is, or part of it
                    is, in the public domain through no fault of its own, whereupon to the extent
                    that it is in the public domain or is required to be disclosed by an order of a
                    law court of competent jurisdiction or a tax authority, or by directive of a
                    government or government authorized agencies or a lawfully constituted
                    commission of inquiry, provided that prior to making such disclosures, written
                    notification of the demand received by such Party has been given to the other
                    Party and thereafter the Party is compelled to make the disclosure can do so.
                </p>
            </div>
        </div>

        <div class="xs-section-heading">
            <div class="xs-heading-title">
                <h2 class="color-navy-blue">Force Majeure</h2>
                <p>
                    In the event of a controversy, claim or dispute arising out of or relating to
                    this ToS, the Parties shall attempt in good faith to resolve such controversy,
                    claim or dispute promptly by negotiation between the parties or their authorized
                    representatives. You shall, before exploring any other remedy in law, notify
                    PropertyCollabo of the dispute or complaint through the contact details below.
                    If parties are unable to resolve the controversy, claim or dispute, the parties
                    shall be at liberty to explore any other dispute resolution mechanism known to
                    Law including mediation, arbitration, or litigation.
                </p>
            </div>
        </div>

        <div class="xs-section-heading">
            <div class="xs-heading-title">
                <h2 class="color-navy-blue">Dispute Resolution</h2>
                <p>
                    In the event of a controversy, claim or dispute arising out of or relating to
                    this ToS, the Parties shall attempt in good faith to resolve such controversy,
                    claim or dispute promptly by negotiation between the parties or their authorized
                    representatives. You shall, before exploring any other remedy in law, notify
                    PropertyCollabo of the dispute or complaint through the contact details below.
                    If parties are unable to resolve the controversy, claim or dispute, the parties
                    shall be at liberty to explore any other dispute resolution mechanism known to
                    Law including mediation, arbitration, or litigation.
                </p>
            </div>
        </div>

        <div class="xs-section-heading">
            <div class="xs-heading-title">
                <h2 class="color-navy-blue">Governing Law</h2>
                <p>
                    This TOS and all matters arising from it (including non-contractual claims and
                    disputes) and any dispute resolutions referred to above shall be governed by and
                    construed in accordance with the Laws of the Federal Republic of Nigeria.
                </p>
            </div>
        </div>

        <div class="xs-section-heading">
            <div class="xs-heading-title">
                <h2 class="color-navy-blue">Limitation of Liability and Indemnity</h2>
                <p>
                    PropertyCollabo shall not be liable to the Sponsor under this TOS in contract,
                    tort (including negligence and breach of statutory duty) or otherwise for any
                    loss of profits (whether direct or indirect), revenue, goods, use, anticipated
                    savings, goodwill, reputation or business opportunity or for any indirect,
                    incidental special or consequential loss arising under the sponsorship (whether
                    or not reasonably foreseeable and even if it had been advised of the other
                    incurring the same).
                </p>
                <p>
                    PropertyCollabo’s total liability (if any) in contract, tort (including
                    negligence or breach of statutory duty) or otherwise arising in connection with
                    this Agreement shall be limited to the Sponsorship fees paid by the Sponsor.
                </p>

                <p>
                    You hereby release PropertyCollabo and hold it and its parents, subsidiaries,
                    affiliates, officers, directors, agents, and employees harmless from any and all
                    claims, demands, and damages of every kind and nature (including, without
                    limitation, actual, special, incidental and consequential), known and unknown,
                    suspected and unsuspected, disclosed and undisclosed, arising out of or in any
                    way connected with your use of our website. You waive the provisions of any
                    state or local law limiting or prohibiting a general release.
                </p>

                <p>
                    The foregoing limitation of liability shall apply to the fullest extent
                    permitted by law in Nigeria and shall remain force notwithstanding the
                    termination of your sponsorship.
                </p>
            </div>
        </div>

        <div class="xs-section-heading">
            <div class="xs-heading-title">
                <h2 class="color-navy-blue">Indemnity</h2>
                <p>
                    You agree to indemnify and hold harmless PropertyCollabo and all our other
                    agents or sub-contractors, against all liabilities, costs, expenses, damages,
                    and losses (including any direct, indirect, or consequential losses, loss of
                    profit, loss of reputation, and all interest penalties and legal and other
                    reasonable professional costs and expenses) suffered or incurred as a result of:
                </p>

                <p>
                    your fraudulent or illegal use of the website, the Services, or the Website;
                </p>

                <p>
                    your negligence or any default by you of any terms of this TOS;
                </p>

                <p>
                    any inaccurate or incomplete information that you have knowingly provided to us;
                </p>

                <p>
                    you allow any other person to access your account either with your permission or
                    as a result of your failure to keep your username and password private;
                </p>

                <p>
                    any service that you have offered, whether with or without our permission, to
                    another third party using our website;
                </p>
                <p>
                    any claim made against you for actual or alleged infringement of the Company’s
                    Intellectual Property Rights or any actual or alleged infringement of a third
                    party’s Intellectual Property Rights arising out of or in connection with the
                    Services or your use of the website.
                </p>
            </div>
        </div>

        <div class="xs-section-heading">
            <div class="xs-heading-title">
                <h2 class="color-navy-blue">General Assignment</h2>
                <p>
                    You cannot, without the written consent of PropertyCollabo assign, transfer, or
                    delegate your rights or obligations hereunder, in whole or in part.
                </p>
            </div>
        </div>

        <div class="xs-section-heading">
            <div class="xs-heading-title">
                <h2 class="color-navy-blue">Relationship</h2>
                <p>
                    Nothing in this TOS shall be construed to create a partnership as defined under
                    the Companies and Allied Matters Act 2004, joint venture, employment
                    relationship, or agency relationship between PropertyCollabo and the Sponsor.
                    You agree that you are fully responsible for your voluntary investments as well
                    as past and future activities outside this TOS.
                </p>
            </div>
        </div>

        <div class="xs-section-heading">
            <div class="xs-heading-title">
                <h2 class="color-navy-blue">Disclaimer</h2>
                <p>
                    No communication by PropertyCollabo, through the website or any other medium,
                    should be construed or is intended to be a recommendation or advice to subscribe
                    to any sponsorship scheme. PropertyCollabo does not hold itself out to be an
                    investment, tax, financial, accounting, legal, regulatory, or compliance expert.
                    The investment opportunities herein do not take into account the particular
                    financial circumstances of individual Sponsors. Investing in a sponsorship
                    scheme on the website poses risks, including but not limited to credit risk,
                    interest rate risk, and the risk of losing some or all of the money you invest.
                    Before investing in any scheme, you should:
                </p>
            </div>
        </div>

        <div class="xs-section-heading">
            <div class="xs-heading-title">
                <h2 class="color-navy-blue">Conduct your own investigation and analysis;</h2>
                <p>
                    carefully consider the investment and all related charges, expenses,
                    uncertainties, and risks, including all uncertainties and risks associated with
                    the scheme; and
                </p>
                <p>
                    consult with your own investment, tax, financial and legal advisors. The schemes
                    provided by PropertyCollabo are only suitable for investors who understand, are
                    willing and able to accept the high risks associated with investments.
                </p>

                <p>
                    PropertyCollabo does not make any representation or warranty to any prospective
                    sponsor regarding the suitability of an investment in any scheme. We do not
                    warrant that your sponsorship would always yield expected profits. Any returns
                    of investment shown on the website are target returns and estimated projections.
                    You agree that the actual returns at the end of a sponsorship cycle may be
                    materially different from the advertised projections on the website. You
                    understand that these risks are inherent in any investment.
                </p>
                <p>
                    We do not provide any advice, nor do we make any recommendations for you. The
                    Company solely allows you to sponsor a scheme on the website. It is your
                    responsibility to assess whether the website is suitable or appropriate for your
                    needs and any decision made to use our Services is done so entirely at your own
                    risk. Please be aware that the execution of this TOS does not imply that we have
                    confirmed that the use of the website and our services are suitable or
                    appropriate for you. We advise you to consider whether sponsoring a scheme
                    through the website meets your required risk levels and objectives, and you
                    should only commit such funds that you are able to financially bear the risk of
                    a loss considering your other financial commitments. By using the website, you
                    confirm that you have the necessary experience and knowledge to understand the
                    risks involved.
                </p>

                <p>
                    You confirm your agreement to this TOS either by registering as a sponsor on our
                    website or by signing a copy of this TOS. By continuing on our website, you
                    agree to the following terms and conditions together with the Terms of Use,
                    consent to our privacy policy, agree to transact business with us, and receive
                    communications relating to the sponsorship electronically.
                </p>
            </div>
        </div>

        <div class="xs-section-heading">
            <div class="xs-heading-title">
                <h2 class="color-navy-blue">Privacy</h2>
                <p>
                    You confirm that you have read and understood the Company’s Privacy Policy which
                    provides information on how we use and store the personal information that you
                    provide to us. You further acknowledge that the Company may amend and update
                    this policy from time to time.
                </p>
            </div>
        </div>

        <ng-container>
            <app-terms-contact></app-terms-contact>
        </ng-container>

        <div class="xs-section-heading">
            <div class="xs-heading-title">
                <h2 class="color-navy-blue">Amendment</h2>
                <p>
                    We may amend or revise this Policy at any time by posting a revised version on
                    our website. Notice of the amendment shall be posted on our website and the
                    revised version will be effective from the date of publication. Kindly, note
                    that your continued use of our website after the publication of the revised
                    version constitutes your acceptance of our amended terms of the Policy.
                </p>
            </div>
        </div>
    </div>
</div>
<app-website-footer></app-website-footer>
