import { Injectable } from '@angular/core';
import { Cloudinary } from '@cloudinary/angular-5.x';
import { FileUploader, FileUploaderOptions, ParsedResponseHeaders } from 'ng2-file-upload';
import { ImagePayload, PropertyPayload } from '@app/common/payload/property.payload';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable } from 'rxjs';
import { InvestmentPayload } from '@app/common/payload/investment.payload';
import Swal from 'sweetalert2';
import { ShortNumberPipe } from '@app/common/pipes/short-number.pipe';
import { IdentityTypeEnum } from '@app/common/enum/identity-type.enum';

@Injectable({
    providedIn: 'root',
})
export class UploaderService {
    private uploader: FileUploader;
    private isUploading: boolean;
    response: BehaviorSubject<ImagePayload> = new BehaviorSubject<ImagePayload>({});
    responseArr: BehaviorSubject<ImagePayload[]> = new BehaviorSubject<ImagePayload[]>([]);
    isArr = false;
    tempResponseArr = [];

    get uploaderO() {
        return this.uploader;
    }

    constructor(
        private cloudinary: Cloudinary,
        private toast: ToastrService,
        private shortNumberPipe: ShortNumberPipe
    ) {}

    onPrepareUploader(data: { tag: string; folder: string }) {
        const uploaderOptions: FileUploaderOptions = {
            url: `https://api.cloudinary.com/v1_1/${this.cloudinary.config().cloud_name}/upload`,
            autoUpload: true,
            isHTML5: true,
            removeAfterUpload: true,
            headers: [{ name: 'X-Requested-With', value: 'XMLHttpRequest' }],
        };
        this.uploader = new FileUploader(uploaderOptions);
        this.uploader.onBuildItemForm = (fileItem: any, form: FormData): any => {
            form.append('upload_preset', this.cloudinary.config().upload_preset);
            form.append('folder', `property-collabo/${data.folder}`);
            form.append('tags', data.tag);
            form.append('file', fileItem);
            // Use default "withCredentials" value for CORS requests
            fileItem.withCredentials = false;
            return { fileItem, form };
        };
        this.uploader.onCompleteItem = (
            item: any,
            response: string,
            status: number,
            headers: ParsedResponseHeaders
        ) => {
            if (!this.isArr) {
                const res: ImagePayload = {
                    public_id: JSON.parse(response).public_id,
                    secure_url: JSON.parse(response).secure_url,
                };
                this.response.next(res);
            } else {
                const res: ImagePayload = {
                    public_id: JSON.parse(response).public_id,
                    secure_url: JSON.parse(response).secure_url,
                };
                this.tempResponseArr.push(res);
                this.responseArr.next(this.tempResponseArr);
            }
        };
        this.uploader.onErrorItem = (
            item: any,
            response: string,
            status: number,
            headers: ParsedResponseHeaders
        ) => {
            this.toast.error(`Upload Failed, Please Try Again! ${response}`);
            this.isUploading = false;
            this.uploader.cancelAll();
            this.uploader.clearQueue();
        };
        this.uploader.onAfterAddingFile = (fileItem) => {};
    }

    /**
     * Must prepareUploader first.
     * Use this in component onInit block: this.uploaderService.onPrepareUploader({ tag: 'tag_name', folder: 'folder_name' });
     * @param file
     */
    onUploadFile(file: any): Observable<ImagePayload> {
        this.uploader.addToQueue([file], { autoUpload: true });
        this.uploader.uploadAll();
        return this.response.asObservable();
    }

    /**
     * Must prepareUploader first.
     * upload image and return array
     * @param file
     */
    onUploadFileArr(file: any): Observable<ImagePayload[]> {
        this.isArr = true;
        this.tempResponseArr = [];
        this.uploader.addToQueue(file, { autoUpload: true });
        return this.responseArr.asObservable();
    }

    /**
     * Must prepareUploader first.
     * Use this in component onInit block: this.uploaderService.onPrepareUploader({ tag: 'tag_name', folder: 'folder_name' });
     * @param investment
     */
    async onOpenUploadPOPModal(investment?: InvestmentPayload) {
        const { value: file } = await Swal.fire({
            title: 'UPLOAD PROOF OF PAYMENT',
            icon: 'info',
            html: `
                <div class="form-group row">
                    <small class="col-sm-6 col text-right black-text">Bank Name:</small>
                    <div class="col-sm-6 col">
                    <small class="text-primary text-left">  Gorilla Bank  </small>
                    </div>
                </div>
                <div class="form-group row">
                    <small class="col-sm-6 col text-right black-text">Account No:</small>
                    <div class="col-sm-6 col">
                    <small class="text-primary text-left">  58855960220  </small>
                    </div>
                </div>
                <div class="form-group row">
                    <code>
                        You can also upload your proof of payment later, visit
                        <a>My Investment Page</a> to see this property again.
                    </code>
                </div>
            `,
            confirmButtonText: 'Upload Now',
            showCancelButton: true,
            cancelButtonText: 'Upload Later',
            input: 'file',
            inputAttributes: {
                accept: 'image/*',
                'aria-label': 'Upload your proof of payment',
                class: 'form-control',
            },
            inputValidator: (value) => {
                if (!value) {
                    return 'Add image file first';
                }
            },
            customClass: {
                input: 'form-control',
            },
        });

        //if the user selected a file then process upload
        if (file) {
            this.toast.show('Preparing Upload..');
            return file;
        }
    }

    async onOpenInvestNowModal(property: PropertyPayload, canInvestFromEarning?: boolean) {
        const { value } = await Swal.fire({
            title: 'CONFIRM INVESTMENT',
            html: `
                <div class="payment-details">
                    <div class="form-group row">
                        <small class="col-sm-6 col text-right black-text">Bank Name:</small>
                        <div class="col-sm-6 col">
                            <small class="text-primary text-left"> ${
                                canInvestFromEarning ? 'Can Invest' : 'Cannot Invest'
                            }  Gorilla Bank  </small>
                        </div>
                    </div>
                    <div class="form-group row">
                        <small class="col-sm-6 col text-right black-text">Account No:</small>
                        <div class="col-sm-6 col">
                            <small class="text-primary text-left">  58855960220  </small>
                        </div>
                    </div>
                    <div class="form-group row">
                        <small class="col-sm-6 col text-right black-text" >Minimum amount you can invest:</small>
                        <div class="col-sm-6 col">
                            <small class="text-primary text-left">
                                ${this.shortNumberPipe.transform(property.minAmount, '₦')}
                            </small>
                        </div>
                    </div>
                    <div class="form-group row">
                        <small class="col-sm-6 col text-right black-text" >Maximum amount you can invest:</small>
                        <div class="col-sm-6 col">
                            <small class="text-primary text-left">
                                ${this.shortNumberPipe.transform(property.maxAmount, '₦')}
                            </small>
                        </div>
                    </div>
                    <small class="text-justify">
                        <code class="space-top-5">
                          NB: <br>
                          Once you click on  INVEST button You will be provided with an option to upload your Proof of Payment (POP). <br> <br>
                          This property will be added to your investments, goto <b class="text-primary">My Investment Page</b> to view it later.
                        </code>
                    </small>
                </div>
                `,
            icon: 'info',
            showCancelButton: true,
            confirmButtonColor: '#047bf8',
            confirmButtonText: '<i class="fa fa-thumbs-up"></i> Yes, Invest',
            cancelButtonText: 'No, Not Yet',
            cancelButtonColor: '#FF747884',
            allowOutsideClick: false,
            input: 'number',
            inputPlaceholder: 'Enter Amount',
            inputValidator: (value) => {
                if (!value) {
                    return 'Enter the amount you want to invest';
                }
            },
        });

        return value;
    }

    async onOpenUploadIdentity(identityType: IdentityTypeEnum, fileLimit?: number) {
        const { value }: any = await Swal.fire({
            title: `UPLOAD ${identityType}`,
            html: `
                <div class="payment-details">
                    <small class="text-justify">
                        <code class="space-top-5">
                          Choose your file to upload <br> You can choose multiple images if required. (Back & Front View)
                    </small>
                </div>
                `,
            confirmButtonText: 'Upload Now',
            showCancelButton: true,
            cancelButtonText: 'Upload Later',
            input: 'file',
            inputAttributes: {
                accept: 'image/*',
                'aria-label': 'Upload your file',
                class: 'form-control',
                multiple: 'multiple',
            },
            inputValidator: (value) => {
                if (!value) {
                    return 'Add image file first';
                }
            },
            customClass: {
                input: 'form-control',
            },
        });
        if (value) {
            if (fileLimit) {
                const filesAdded = Object.keys(value).length;
                if (filesAdded > fileLimit) {
                    Swal.fire({
                        icon: 'error',
                        title: `Only ${fileLimit} files allowed`,
                        html: 'You can only select 2 files, try again',
                    }).then((r) => {});
                } else {
                    return value;
                }
            } else {
                return value;
            }
        }
    }

    async showInvestFromEarningModal() {
        const { value } = await Swal.fire({
            title: 'Invest From Earning?',
            icon: 'question',
            html:
                '<small> You have made some profits from your previous investments, <br> would you like to invest from your earning amount? </small>',
            input: 'radio',
            inputOptions: {
                yes: 'Yes',
                no: 'No',
            },
            inputPlaceholder: 'Invest From Earning',
            showCancelButton: true,
            allowOutsideClick: false,
            inputValidator: function (value) {
                if (!value) {
                    return 'You need to choose :)';
                }
            },
        });
        return value;
    }

    async showEnterAmountModal(minAmount: number, maxAmount: number) {
        const { value } = await Swal.fire({
            title: 'Enter Amount',
            icon: 'info',
            html: ` <div class="col-sm-12 ">
                    <label>The minimum amount you can invest is: <strong> ${this.shortNumberPipe.transform(
                        minAmount,
                        '₦'
                    )} </strong> </label>
                    <label>The maximum amount you can invest is: <strong> ${this.shortNumberPipe.transform(
                        maxAmount,
                        '₦'
                    )} </strong> </label>
              </div>`,
            input: 'number',
            inputPlaceholder: 'Enter Amount',
            inputValidator: (value) => {
                if (!value) {
                    return 'Enter the amount you want to invest';
                }
                if (Number(value) < Number(minAmount)) {
                    return 'Amount is too small';
                }
                if (Number(value) > Number(maxAmount)) {
                    return 'Amount exceeds the allowed maximum ';
                }
            },
            showCancelButton: true,
            confirmButtonColor: '#047bf8',
            confirmButtonText: 'Invest',
            allowOutsideClick: false,
        });
        return value;
    }
}
