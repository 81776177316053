import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PageNotFoundComponent } from '@app/dashboard/dashboard-pages/page-not-found/page-not-found.component';
import { LoginComponent } from '@app/dashboard/dashboard-pages/login/login.component';
import { RegisterComponent } from '@app/dashboard/dashboard-pages/register/register.component';
import { VerificationSuccessComponent } from '@app/dashboard/dashboard-pages/verification-success/verification-success.component';
import { DashboardLayoutComponent } from '@app/dashboard/dashboard-pages/dashboard-layout/dashboard-layout.component';
import { PasswordResetComponent } from '@app/dashboard/dashboard-pages/password-reset/password-reset.component';
import { PasswordResetConfirmComponent } from '@app/dashboard/dashboard-pages/password-reset/password-reset-confirm/password-reset-confirm.component';
import { LoginTwoComponent } from '@app/dashboard/dashboard-pages/login-two/login-two.component';
import { RegisterTwoComponent } from '@app/dashboard/dashboard-pages/register-two/register-two.component';

const routes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        loadChildren: () => import('src/app/website/website.module').then((m) => m.WebsiteModule),
    },

    {
        path: 'app',
        component: DashboardLayoutComponent,
        loadChildren: () =>
            import('src/app/dashboard/dashboard.module').then((m) => m.DashboardModule),
    },
    { path: 'v1-sign-in', component: LoginComponent },
    { path: 'sign-in', component: LoginTwoComponent },
    { path: 'v1-sign-up', component: RegisterComponent },
    { path: 'sign-up', component: RegisterTwoComponent },
    { path: 'verification/:token/:email', component: VerificationSuccessComponent },
    { path: 'password-reset', component: PasswordResetComponent },
    { path: 'verification/password/reset/:token/:email', component: PasswordResetConfirmComponent },
    { path: '**', component: PageNotFoundComponent },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
    exports: [RouterModule],
})
export class AppRoutingModule {}
