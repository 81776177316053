import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AuthService } from '@app/common/service/auth.service';
import { AuthPayload } from '@app/common/payload/auth.payload';
import { Form, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { environment } from '@environments/environment.prod';
import { Title } from '@angular/platform-browser';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild('emailField') emailField: ElementRef;
    loginPayload: AuthPayload;
    loginForm: FormGroup;
    isSubmitted = false;
    isLoading = false;
    subscription = new Subscription();
    isErrors = false;
    error: string;
    messages: string[] = [];
    siteKey: string = environment.gr_site_key;
    isCaptchaChecked = false;
    isCaptchaLoaded = false;
    recaptcha: string = '';

    constructor(
        private authService: AuthService,
        private router: Router,
        private toast: ToastrService,
        private title: Title
    ) {}

    ngOnInit() {
        this.title.setTitle(`Sign In - ${environment.appName}`);
        this.loginForm = new FormGroup({
            email: new FormControl('', {
                validators: [Validators.required, Validators.email],
                updateOn: 'blur',
            }),
            password: new FormControl('', {
                validators: [Validators.required],
                updateOn: 'blur',
            }),
            recaptcha: new FormControl(['', Validators.required]),
        });
        this.loginPayload = {
            email: '',
            password: '',
            captcha: '',
        };
    }

    get lf() {
        return this.loginForm.controls;
    }

    onLogin() {
        this.isSubmitted = true;
        if (this.loginForm.invalid) {
            return;
        }
        this.isLoading = true;
        this.loginPayload.email = this.loginForm.get('email').value;
        this.loginPayload.password = this.loginForm.get('password').value;
        this.loginPayload.captcha = this.loginForm.get('recaptcha').value;

        this.subscription = this.authService.signin(this.loginPayload).subscribe(
            (result) => {
                if (result) {
                    if (result.isAdmin) {
                        this.router.navigateByUrl('app/admin').then();
                    } else {
                        this.router.navigateByUrl('app').then();
                    }
                }
            },
            (error) => {
                this.clearErrorMessages();
                Array.isArray(error.message)
                    ? (this.messages = error.message)
                    : (this.error = error.message);
                this.isErrors = true;
                this.isSubmitted = false;
                this.isLoading = false;
            }
        );
    }
    clearErrorMessages() {
        this.error = null;
        this.messages = [];
        this.isErrors = false;
    }

    onSubmitKeyPressed(form) {}

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    ngAfterViewInit(): void {
        this.emailField.nativeElement.focus();
    }

    onCaptchaHandleExpire() {
        this.isCaptchaChecked = false;
    }

    onCaptchaHandleSuccess($event: any) {
        this.isCaptchaChecked = true;
    }

    onCaptchaHandleLoad() {
        this.isCaptchaLoaded = true;
    }
}
