<section class="xs-screen-height xs-welcome-section xs-bg prop-welcome-section ng-slider">
    <div class="xs-banner-slider owl-carousel">
        <div
            class="xs-banner-slider-item"
            style="
                background-image: url(assets/website-assets/image/background/welcome-bg-back-overlay.png);
            "
        >
            <div class="container">
                <div class="row">
                    <div class="col-lg-6 prop-img-mobile">
                        <ng-container *ngTemplateOutlet="sliderImage"></ng-container>
                    </div>
                    <div class="col-lg-6">
                        <div class="xs-welcome-content version-3">
                            <div class="xs-welcome-wraper">
                                <h2>{{ property?.title | truncateText: 20 }}</h2>
                                <div class="xs-skill-bar-v2">
                                    <div
                                        class="xs-skill-track"
                                        [style.width]="onGetPercentage(property) + '%'"
                                    >
                                        <p>
                                            <span class="number-percentage-count">{{
                                                onGetPercentage(property)
                                            }}</span
                                            >%
                                        </p>
                                    </div>
                                </div>
                                <ul class="prop-welcome-list-content list-inline">
                                    <li>
                                        {{ property.donation | shortNumber }}
                                        <span class="d-block">Invested</span>
                                    </li>
                                    <li>
                                        {{ property.target | shortNumber
                                        }}<span class="d-block">Price Target</span>
                                    </li>
                                    <li>
                                        {{ property.returnOfInvestment | shortNumber: ' ' }}%<span
                                            class="d-block"
                                            >Return</span
                                        >
                                    </li>
                                </ul>
                                <div class="xs-btn-wraper space-b-35">
                                    <a
                                        [routerLink]="
                                            '/app/property/' +
                                            onEncode(property.title) +
                                            '/' +
                                            property.uud
                                        "
                                        [state]="{ data: property }"
                                        class="xs-btn btn btn-primary text-white round-btn"
                                        >Invest Now</a
                                    >
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 prop-img-desk">
                        <ng-container *ngTemplateOutlet="sliderImage"></ng-container>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<ng-template #sliderImage>
    <div class="xs-welcome-content slide-main-wrapper">
        <div class="xs-welcome-wraper" *ngIf="property.image[0]?.secure_url">
            <img
                class="slider-image"
                [src]="property.image[0]?.secure_url"
                [alt]="property.title"
            />
        </div>
    </div>
</ng-template>
