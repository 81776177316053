import { Component, OnDestroy, OnInit } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { User } from '@app/common/payload/user';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Title } from '@angular/platform-browser';
import { environment } from '@environments/environment.prod';
import { Subscription } from 'rxjs';
import { UserService } from '@app/common/service/user.service';
import { IdentityService } from '@app/common/service/identity.service';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';

@Component({
    selector: 'app-dashboard-admin-user',
    templateUrl: './dashboard-admin-user.component.html',
    styleUrls: ['./dashboard-admin-user.component.css'],
    animations: [
        trigger('simpleFadeAnimation', [
            state('in', style({ opacity: 1 })),
            transition(':enter', [style({ opacity: 0 }), animate(600)]),
            transition(':leave', animate(600, style({ opacity: 0 }))),
        ]),
    ],
})
export class DashboardAdminUserComponent implements OnInit, OnDestroy {
    users: User[];
    pageSize = 6;
    collectionSize;
    pageOfItems: User[] = [];
    items: User[] = [];

    subscription: Subscription = new Subscription();

    constructor(
        private spinner: NgxSpinnerService,
        private toast: ToastrService,
        private titleService: Title,
        private userService: UserService,
        private identityService: IdentityService,
        private router: Router
    ) {}

    ngOnInit(): void {
        this.titleService.setTitle(`Manager Users - ${environment.appName}`);
        this.subscription.add(
            this.userService.onGetAllUsers().subscribe((result) => {
                if (result.status === 200) {
                    this.users = result.body;
                    this.items = result.body;
                    this.collectionSize = result.body.length;
                }
            })
        );
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    onChangePage(pageOfItems: Array<any>) {
        this.pageOfItems = pageOfItems;
    }

    getTotalInvestment(user: User) {
        let totalApproved = 0;
        user.investments.forEach((investment) => {
            if (investment.isApproved) {
                totalApproved += investment.amount;
            }
        });
        return totalApproved;
    }

    onGetUserIdentity(selectedUser: User) {
        this.spinner.show().then();
        this.subscription.add(
            this.identityService.onGetIdentityByUserId(selectedUser.id).subscribe(
                (result) => {
                    if (result.status === 200) {
                        this.spinner.hide().then();
                        this.router
                            .navigateByUrl(`/app/admin/users/identity/${selectedUser.id}`, {
                                state: { identity: result.body, user: selectedUser },
                            })
                            .then((r) => {});
                    }
                },
                (error) => {
                    this.spinner.hide().then();
                    Swal.fire({
                        title: `No Identity`,
                        html: 'This user has not uploaded any identity',
                        icon: 'warning',
                    }).then((r) => {});
                }
            )
        );
    }
}
