import { Component, OnInit } from '@angular/core';
import { AnimationItem } from 'lottie-web';
// import { AnimationOptions } from 'ngx-lottie';

@Component({
    selector: 'app-verification-failed',
    templateUrl: './verification-failed.component.html',
    styleUrls: ['./verification-failed.component.css'],
})
export class VerificationFailedComponent implements OnInit {
    // options: AnimationOptions = {
    //     path: '/assets/shared/graphs/failed.json',
    // };

    constructor() {}

    ngOnInit(): void {}
    animationCreated(animationItem: AnimationItem): void {
        // console.log(animationItem);
    }
}
