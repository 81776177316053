<div class="property-single">
    <div class="property-media" [ngStyle]="{ 'background-image': 'url(' + mainImage + ')' }">
        <div class="media-buttons">
            <a><i class="os-icon os-icon-documents-07"></i><span>Save For Later</span></a>
            <a><i class="os-icon os-icon-ui-03"></i><span>Like</span></a>
        </div>
    </div>
    <div class="property-info-w">
        <div class="property-info-main">
            <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-12">
                    <ng-container>
                        <div class="property-images">
                            <ng-image-slider
                                imageSize="{width: '100%', height: '100%'}"
                                animationSpeed="1"
                                [images]="imageObject"
                                [imagePopup]="false"
                                (imageClick)="imageClicked($event)"
                                #nav
                            ></ng-image-slider>
                        </div>
                    </ng-container>
                    <div
                        class="badge space-b-15"
                        [ngClass]="{
                            'badge-blue': property.status === 'OPEN INVESTMENT',
                            'badge-red': property.status === 'CLOSED INVESTMENT'
                        }"
                    >
                        {{ property.status }}
                    </div>

                    <div class="property-section">
                        <div
                            class="property-section-header point"
                            (click)="toggle('propertyDetails')"
                        >
                            Property Details
                            <div class="filter-toggle">
                                <i class="os-icon-minus os-icon"></i>
                            </div>
                        </div>
                        <div class="property-section-body" #propertyDetails>
                            <div class="item-features"></div>
                            <h4>{{ property.title }}</h4>

                            <div class="property-description">
                                <p class="text-justify">
                                    {{ property.description }}
                                </p>
                            </div>
                            <div class="property-features-highlight">
                                <div class="property-description">
                                    <i class="fa fa-map-marker"> </i>
                                    {{ property.address }} {{ property.location }}
                                </div>
                            </div>

                            <div class="item-reviews">
                                <div class="reviews-stars">
                                    <div class="feature">{{ property.category }}</div>
                                </div>

                                <div class="reviews-count space-l-5">
                                    <div class="feature">{{ property.type }}</div>
                                </div>

                                <div class="reviews-count">
                                    <a
                                        class="btn btn-sm text-white {{
                                            property.returnOfInvestment > 20
                                                ? 'btn-primary'
                                                : 'btn-dark'
                                        }} "
                                    >
                                        ROI: {{ property.returnOfInvestment | shortNumber: ' ' }}%
                                    </a>
                                </div>
                            </div>

                            <div class="property-progress text-center space-top-15">
                                <circle-progress
                                    [outerStrokeColor]="'#047bf8'"
                                    [responsive]="true"
                                    [percent]="onGetPercentage()"
                                    maxPercent="100"
                                    titleFontSize="30"
                                    titleFontWeight="400"
                                    subtitle="INVESTMENT"
                                    subtitleFontSize="12"
                                ></circle-progress>
                            </div>

                            <!--duration & others -->
                            <div class="row space-top-15">
                                <div class="col-6">
                                    <ul>
                                        <li
                                            ngbTooltip="Minimum amount you can invest in this property"
                                            placement="top-left"
                                            container="body"
                                        >
                                            Minimum Amount:
                                            <strong class="text-primary">{{
                                                property.minAmount | shortNumber: '₦'
                                            }}</strong>
                                        </li>
                                        <li
                                            ngbTooltip="Duration at which you receive your return of investment."
                                            placement="top-left"
                                            container="body"
                                        >
                                            ROI Duration:
                                            <strong class="text-primary"
                                                >{{ property.duration }}
                                                {{
                                                    property.duration > 1 ? 'Months' : 'Month'
                                                }}</strong
                                            >
                                        </li>
                                    </ul>
                                </div>
                                <div class="col-6">
                                    <ul>
                                        <li
                                            ngbTooltip="Maximum amount you can invest in this property"
                                            placement="top-left"
                                            container="body"
                                        >
                                            Maximum Amount:
                                            <strong class="text-primary">{{
                                                property.maxAmount | shortNumber: '₦'
                                            }}</strong>
                                        </li>
                                        <li
                                            ngbTooltip="Date in which this property will no longer accept investment"
                                            placement="top-left"
                                            container="body"
                                        >
                                            Close Date:
                                            <strong class="text-primary text-uppercase">{{
                                                property.closeDate | date
                                            }}</strong>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <!--Price & Target -->
                            <div class="row space-top-15 text-center">
                                <div class="col-6">
                                    <div class="el-tablo highlight">
                                        <div class="label">
                                            Price: <br />
                                            <span
                                                class="value text-primary"
                                                style="font-size: 2rem;"
                                            >
                                                {{ property.price | shortNumber: '₦' }}</span
                                            >
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6 text-center">
                                    <div class="el-tablo highlight">
                                        <div class="label">
                                            Target: <br />
                                            <span
                                                class="value text-primary"
                                                style="font-size: 2rem;"
                                            >
                                                {{ property.target | shortNumber: '₦' }}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="form-group space-top-15 text-center">
                                <a
                                    (click)="onInvestInProperty(property)"
                                    class="btn btn-primary btn-lg text-white text-uppercase w-80"
                                    ><span>Invest Now</span
                                    ><i class="os-icon os-icon-user-check"></i
                                ></a>
                            </div>
                            <div class="property-note">
                                <h6>Always communicate through</h6>
                                <p>
                                    To protect your payment, never transfer money or communicate
                                    outside of our website or app.
                                </p>

                                <div class="space-top-15">
                                    Share This Property:
                                    <a class="point space-l-5"><i class="fa fa-whatsapp"></i></a>
                                    <a class="point space-l-5"><i class="fa fa-instagram"></i></a>
                                    <a class="point space-l-5"
                                        ><i class="fa fa-facebook-official"></i
                                    ></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-md-6 col-sm-12">
                    <div class="property-info-side">
                        <div class="side-section">
                            <div class="side-section-header">Request Information</div>
                            <div class="side-section-content">
                                <form action="#" class="side-action-form">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <div class="input-group-prepend">
                                                <div class="input-group-text">
                                                    <i class="os-icon os-icon-mail-01"></i>
                                                </div>
                                            </div>
                                            <input
                                                class="form-control"
                                                placeholder="Email Address..."
                                            />
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <div class="input-group">
                                            <div class="input-group-prepend">
                                                <div class="input-group-text">
                                                    <i class="os-icon os-icon-mail-01"></i>
                                                </div>
                                            </div>
                                            <input class="form-control" placeholder="Subject" />
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <textarea
                                            name=""
                                            placeholder="Message"
                                            class="form-control"
                                            cols="30"
                                            rows="5"
                                        ></textarea>
                                    </div>
                                    <div class="form-group">
                                        <a class="btn btn-primary text-white"
                                            ><span>Send Enquiry</span
                                            ><i class="os-icon os-icon-mail"></i
                                        ></a>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div class="side-section" *ngIf="property?.others?.features?.length">
                            <div class="side-section-header">Facts and Features</div>
                            <div class="side-section-content">
                                <div class="property-side-features">
                                    <div
                                        class="feature"
                                        *ngFor="let fact of property?.others?.features"
                                    >
                                        <i [ngClass]="fact.icon" aria-hidden="true"></i>
                                        <span>{{ fact.text }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            class="side-magic"
                            [ngStyle]="{ 'background-image': 'url(' + mainImage + ')' }"
                        >
                            <div class="fader"></div>
                            <h4 class="side-magic-title">Do you have a listing to offer?</h4>
                            <div class="side-magic-desc">
                                Tell us about your property to get it listed on our site.
                            </div>
                            <a class="side-magic-btn" routerLink="/contact">Let Us Know </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
