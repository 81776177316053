<div class="xs-section-heading">
    <div class="xs-heading-title">
        <h2 class="color-navy-blue">Notice/Contact Details</h2>
        <p>
            In the case of any complaints or notice of a dispute or any other notification, please
            contact us at:
            <br />
            PropertyCollabo Limited
            <br />
            Address:
            <br />
            Email: support@propertycollabo.com / info@propertycollabo.com
            <br />
            Telephone:
        </p>

        <p>
            Please allow up to 3 (three) working days for requests to be processed. The Company
            reserves the right to charge a reasonable fee to process excessive or repeated requests.
        </p>
    </div>
</div>
