<form name="form" (ngSubmit)="f.form.valid && onUpdateBankDetails()" #f="ngForm" novalidate>
    <h5 class="alert-heading text-uppercase">Bank Account Details</h5>

    <div class="form-group">
        <label>Bank Name</label>
        <input
            type="text"
            class="form-control"
            placeholder="Enter Bank Name"
            [(ngModel)]="bankDetails.bankName"
            #bankName="ngModel"
            name="bankName"
            [ngClass]="{ 'f-invalid': f.submitted && bankName.invalid }"
            required
        />
    </div>
    <div class="form-group">
        <label>Account Name</label>
        <input
            type="text"
            class="form-control"
            placeholder="Enter Account Name"
            [(ngModel)]="bankDetails.accountName"
            #accountName="ngModel"
            name="accountName"
            [ngClass]="{ 'f-invalid': f.submitted && accountName.invalid }"
            required
        />
    </div>
    <div class="form-group">
        <label>Account Number</label>
        <input
            type="text"
            class="form-control"
            placeholder="Enter Account Number"
            [(ngModel)]="bankDetails.accountNumber"
            #accountNumber="ngModel"
            name="accountNumber"
            [ngClass]="{ 'f-invalid': f.submitted && accountNumber.invalid }"
            required
        />
    </div>
    <div class="alert-btn">
        <button class="btn btn-primary text-white" [disabled]="isLoading">
            <span *ngIf="!isLoading"><i class="os-icon os-icon-save"></i></span>
            <span *ngIf="isLoading">
                <i class="fa fa-circle-o-notch fa-spin fa-1x fa-fw"></i>
            </span>
        </button>
    </div>
</form>
