import { Component, Input, OnInit } from '@angular/core';
import { Label, MultiDataSet } from 'ng2-charts';
import { ChartOptions, ChartType } from 'chart.js';
import { ShortNumberPipe } from '@app/common/pipes/short-number.pipe';

@Component({
    selector: 'app-dashboard-chart-doughnut',
    templateUrl: './dashboard-chart-doughnut.component.html',
    styleUrls: ['./dashboard-chart-doughnut.component.css'],
    providers: [ShortNumberPipe],
})
export class DashboardChartDoughnutComponent implements OnInit {
    @Input() data: { profitMade: number; amountInvested: number }[];
    // Doughnut
    public doughnutChartOptions: ChartOptions = {
        responsive: true,
        legend: {
            position: 'right',
        },
    };
    public doughnutChartLabels: Label[] = ['Download Sales', 'In-Store Sales', 'Mail-Order Sales'];
    public doughnutChartData: number[] = [350, 450, 100];
    public doughnutChartType: ChartType = 'doughnut';
    constructor(private shortNumberPipe: ShortNumberPipe) {}

    ngOnInit(): void {
        if (this.data) {
            this.doughnutChartLabels = [];
            this.doughnutChartData = [];
            this.data.forEach((x) => {
                const amountInvested = this.shortNumberPipe.transform(x.amountInvested);
                const profits = this.shortNumberPipe.transform(x.profitMade);
                this.doughnutChartData.push(x.profitMade);
                this.doughnutChartLabels.push(
                    `Investment: ${amountInvested} : Profit: ${profits} `
                );
            });
        }
    }
}
