import { NgcCookieConsentConfig } from 'ngx-cookieconsent';
import { environment } from '@environments/environment.prod';

export const cookieConfig: NgcCookieConsentConfig = {
    cookie: {
        domain: window.location.hostname,
    },
    position: 'bottom',
    theme: 'edgeless',
    palette: {
        popup: {
            background: '#000000',
            text: '#ffffff',
            link: '#ffffff',
        },
        button: {
            background: '#f1d600',
            text: '#000000',
            border: 'transparent',
        },
    },
    type: 'info',
    content: {
        message:
            'We use cookies to provide our services and enhance your experience. By using this website, you agree with our Privacy & Cookie Policy',
        dismiss: 'Got it!',
        deny: 'Refuse cookies',
        link: 'Learn more',
        href: `${environment.site_url}/privacy-policy`,
        policy: 'Cookie Policy',
    },
};
