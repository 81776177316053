import { IdentityStatusEnum, IdentityTypeEnum } from '@app/common/enum/identity-type.enum';
import { ImagePayload } from '@app/common/payload/property.payload';
import { User } from '@app/common/payload/user';

export class IdentityPayload {
    id?: number;

    message?: string;

    type?: IdentityTypeEnum;

    user?: User;

    image?: ImagePayload[];

    isVerified?: boolean;

    createdAt?: Date;

    updatedAt?: Date;

    status?: IdentityStatusEnum;
}
