import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WebsiteLayoutComponent } from './website-layout/website-layout.component';
import { WebsiteHeaderComponent } from './website-header/website-header.component';
import { WebsiteFooterComponent } from './website-footer/website-footer.component';
import { WebsiteContentComponent } from './website-content/website-content.component';
import { WelcomeSectionComponent } from './website-content/welcome-section/welcome-section.component';
import { IntroSectionComponent } from './website-content/intro-section/intro-section.component';
import { FeatureSectionComponent } from './website-content/feature-section/feature-section.component';
import { PropertySectionComponent } from './website-content/property-section/property-section.component';
import { RouterModule } from '@angular/router';
import { FaqSectionComponent } from './website-content/faq-section/faq-section.component';
import { ContactSectionComponent } from './website-content/contact-section/contact-section.component';
import { AppCommonModule } from '@app/common/common.module';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { CloudinaryModule } from '@cloudinary/angular-5.x';
import { HeaderLinkComponent } from './website-header/header-link/header-link.component';
import { FormsModule } from '@angular/forms';

@NgModule({
    declarations: [
        WebsiteLayoutComponent,
        WebsiteHeaderComponent,
        WebsiteFooterComponent,
        WebsiteContentComponent,
        WelcomeSectionComponent,
        IntroSectionComponent,
        FeatureSectionComponent,
        PropertySectionComponent,
        FaqSectionComponent,
        ContactSectionComponent,
        HeaderLinkComponent,
    ],
    imports: [
        CommonModule,
        RouterModule,
        AppCommonModule,
        NgCircleProgressModule,
        CloudinaryModule,
        FormsModule,
    ],
    exports: [
        WelcomeSectionComponent,
        IntroSectionComponent,
        FeatureSectionComponent,
        PropertySectionComponent,
        FaqSectionComponent,
        ContactSectionComponent,
        WebsiteHeaderComponent,
        WebsiteFooterComponent,
        WebsiteLayoutComponent,
    ],
})
export class WebsiteLayoutModule {}
