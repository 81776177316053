<div
    class="color-scheme-light color-style-default menu-position-side menu-side-left menu-layout-compact sub-menu-style-inside sub-menu-color-light selected-menu-color-light menu-activated-on-click menu-has-selected-link"
>
    <h1 class="menu-page-header">Page Header</h1>
    <ul class="main-menu">
        <li class="sub-header">
            <span>Modules</span>
        </li>

        <li
            class="point has-sub-menu"
            #myitems
            *ngFor="let menu of menuBag; let i = index"
            (mouseleave)="closeMenu()"
            (mouseover)="openMenu($event, menu, i)"
            [id]="'menu-' + i"
        >
            <a *ngIf="menu.children.length; else noChild">
                <div class="icon-w"><div [ngClass]="menu.icon"></div></div>
                <span>{{ menu.title }}</span>
            </a>

            <ng-template #noChild>
                <a [routerLink]="menu.link">
                    <div class="icon-w"><div [ngClass]="menu.icon"></div></div>
                    <span>{{ menu.title }}</span>
                </a>
            </ng-template>

            <div class="sub-menu-w" *ngIf="menu.children.length">
                <div class="sub-menu-header">{{ menu.title }}</div>
                <div class="sub-menu-icon"><i class="os-icon os-icon-layout"></i></div>
                <div class="sub-menu-i">
                    <ul class="sub-menu">
                        <li *ngFor="let item of menu.children">
                            <a [routerLink]="item.link">{{ item.title }}</a>
                        </li>
                    </ul>
                </div>
            </div>
        </li>
    </ul>
    <div class="side-menu-magic">
        <h4>Property Collabo</h4>
        <p>Easiest way to invest in real estate</p>
        <div class="btn-w">
            <a class="btn btn-white btn-rounded" target="_blank">Start Now</a>
        </div>
    </div>
</div>
