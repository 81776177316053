<app-website-header></app-website-header>
<div class="container">
    <div class="text-justify">
        <div class="prop-heading-title text-center xs-mb-60 space-top-25">
            <h2 class="color-navy-blue xs-mb-0">Disclaimer</h2>
        </div>
        <p>
            Welcome to PropertyCollabo, At PropertyCollabo we make investing in real estate simple,
            transparent, and easily accessible. This website is brought to you by PropertyCollabo
            Limited (“PropertyCollabo”, “we”, “us”, or “our”).
            <br />
        </p>

        <div class="xs-section-heading">
            <div class="xs-heading-title">
                <h2 class="color-navy-blue">Consent</h2>
                <p>
                    This Terms of Use constitutes a legally binding contract between you, (whether
                    as a guest or registered user) and PropertyCollabo in your access and use of the
                    website as well as its content, services, and functionality made available on or
                    through the website (collectively referred to as “the Services”). We therefore
                    kindly request that you carefully read through these Terms and Conditions of Use
                    (the “Terms of Use” or “User Agreement”) before using the website and click on
                    the Agree icon if you consent and agree to be bound by (1) this Terms of Use and
                    (2) the Privacy Policy (“the Policy”). By using the website, you accept the
                    terms and conditions contained on this website, which shall govern your use of
                    this website, including all pages or links on the website. If you do not agree
                    to these Terms of Use or the Privacy Policy, please do not use the website
                    and/or the Services and exit immediately. In addition, if you do not agree to
                    these Terms of Use or the Policy, you agree that your sole and exclusive remedy
                    is to discontinue using this website. That notwithstanding, if you proceed to
                    browse, accessing, or otherwise using the Services in any manner, it will be
                    deemed that you have accepted, without limitation or qualification, both the
                    Terms of Use and the Privacy Policy for the website (the “Policy”).
                </p>
            </div>
        </div>

        <div class="xs-section-heading">
            <div class="xs-heading-title">
                <h2 class="color-navy-blue">Review of Terms of Use</h2>
                <p>
                    We may, at our sole discretion, review, revise, and/or update this Terms of Use
                    and the website at any time. We, therefore, advise that you read through this
                    Terms of Use periodically. You further agree that we will have no further
                    obligation to notify you of any modifications and such updates or modifications
                    shall become effective immediately upon the posting thereof or on any specified
                    date. The most current version of the Terms of Use can be accessed at any time
                    by selecting the Terms of Use link on the bottom of the home page for the
                    website.
                </p>
            </div>
        </div>

        <div class="xs-section-heading">
            <div class="xs-heading-title">
                <h2 class="color-navy-blue">Age</h2>
                <p>
                    You must be at least 18 (eighteen) years old to use our website or any of our
                    Services; by using our website or agreeing to these terms and conditions, you
                    represent and warrant to us that you are at least 18 years of age. Individuals
                    under the age of 18, or the applicable age of maturity, may utilize our Services
                    only with the involvement of a parent or legal guardian, under such person’s
                    account.
                </p>
            </div>
        </div>

        <div class="xs-section-heading">
            <div class="xs-heading-title">
                <h2 class="color-navy-blue">Security of Password</h2>
                <p>
                    To fully enjoy our Services, you may be required to create an account and in
                    which case you will be given (or you may provide) an email and password. Certain
                    areas of our Services are only accessible with the use of email and passwords
                    (“User Restricted Areas”). Please note that you are fully and solely responsible
                    for any and all use of the Services and such, you are required to keep your
                    password secret at all times. Do not share your password with any third party or
                    allow another person to access the User Restricted Areas using your password.
                    Kindly notify us immediately if you have any reason to believe that your email
                    or password has been lost or compromised or misused in any way. Also immediately
                    report any unauthorized or suspicious activity in your account.
                </p>
            </div>
        </div>

        <div class="xs-section-heading">
            <div class="xs-heading-title">
                <h2 class="color-navy-blue">Available Content and Use</h2>
                <h2 class="color-navy-blue">Content Description:</h2>
                <p>
                    The website contains a variety of contents, including but not limited to text,
                    data, files, documents, software, scripts, layout, design, function, aesthetics,
                    graphics, images, audio, videos, audiovisual combinations, interactive features,
                    and any other materials that you may view, access or download (but only as
                    expressly permitted in paragraph C below) on the website.
                </p>
            </div>
        </div>

        <div class="xs-section-heading">
            <div class="xs-heading-title">
                <h2 class="color-navy-blue">Proprietary Rights</h2>
                <p>
                    All the contents of the website, whether publicly posted or privately
                    transmitted, as well as all derivative works thereof, are owned by
                    PropertyCollabo, its affiliates, and where applicable, to third parties who have
                    licensed such rights to PropertyCollabo (“Licensors”). Hence, the website is
                    protected by copyright, trademark, and other applicable intellectual property
                    rights/laws. Except as specifically permitted herein, you shall not copy,
                    reproduce, republish, upload, post, transmit, modify or distribute the website
                    or any part of it in any way, including by e-mail or other electronic means,
                    without the prior consent of PropertyCollabo or its Licensors.
                </p>
            </div>
        </div>

        <div class="xs-section-heading">
            <div class="xs-heading-title">
                <h2 class="color-navy-blue">Restrictions on use of the website</h2>
                <p>
                    Please note that the contents of this website are solely for your information
                    and personal use, as intended through the provided functionality of the Services
                    and permitted under this User Agreement.
                    <br />
                    As such you:,
                    <br />
                    are prohibited from modifying or using the Content on any web website or
                    networked computer environment or using the Content for any purpose other than
                    personal, non-commercial use, without the consent of PropertyCollabo or its
                    licensors, first had and obtained, where applicable.
                    <br />
                    Such use or modification is a violation of the copyright, trademark, and other
                    proprietary rights in the Content.
                    <br />
                    shall not use our website in any way or take any action that causes or may cause
                    damage to the website or impairment of the performance, availability, or
                    accessibility of the website;
                    <br />
                    shall not use our website in any way that is unlawful, illegal, fraudulent or
                    harmful, or in connection with any unlawful, illegal, fraudulent or harmful
                    purpose or activity or in any way that breaches any applicable law or
                    regulations;
                    <br />
                    shall not circumvent, disable, or otherwise interfere with security-related
                    features of the Services; including security features that prevent or restrict
                    the use or copying of any content.
                    <br />
                    shall not use data collected from our website for any direct marketing activity
                    (including without limitation email marketing, SMS marketing, telemarketing, or
                    direct mailing).
                    <br />
                    shall not alter, remove, or falsify any attributions or other proprietary
                    designations of origin or source of any Content appearing on the website.
                    <br />
                    Shall not use the website in any way to create liability, or in any way that
                    causes us to lose the services of our Internet Service Providers or other
                    suppliers.
                    <br />
                    shall not download any content on the website unless you see a “download” or
                    similar link displayed by us on the website for such content.
                    <br />
                    You further agree that:
                    <br />
                    you are responsible for your own conduct while using the website or Services and
                    for any consequences thereof;
                    <br />
                    You shall use this website and all Services on the website only for purposes
                    that are legal, proper, and in accordance with this Terms of Use, the Privacy
                    Policy, and any applicable law, rules or regulations, any consumer protection,
                    unfair competition, and anti-discrimination laws or regulations and any
                    applicable foreign laws).
                    <br />
                    You are solely responsible for your interactions with other users of the website
                    (“Users”) both within the website and outside of the website. PropertyCollabo
                    expressly disclaims any responsibility for interactions, loss, or liabilities
                    between users or between you and any third party outside of the website.
                    <br />
                </p>
            </div>
        </div>

        <div class="xs-section-heading">
            <div class="xs-heading-title">
                <h2 class="color-navy-blue">Disclaimer</h2>
                <p>
                    You, therefore, agree to evaluate, seek independent advice and bear all risks
                    associated with the use of the website.
                    <br />
                    Nothing on the website should be deemed to constitute a recommendation to
                    purchase, sell or hold, or otherwise to constitute advice regarding, any
                    investment, secured or otherwise.
                    <br />
                    We will not be liable for any damages (including, without limitation, damages
                    for any consequential loss or loss of business opportunities or projects, or
                    loss of profits) howsoever arising and whether in contract, tort, or otherwise
                    from the use of or inability to use our website or any of its contents and
                    materials, or from any action or omission has taken as a result of using the
                    website or any such contents. In any event, our liability for any and all proven
                    damages or losses shall not in any circumstances exceed the amount paid by you,
                    if any, for accessing this website.
                    <br />
                    All liability is excluded to the fullest extent permitted by applicable law
                    including any implied terms as the content of this website “as is” without
                    warranties of any kind. We reserve the right to change all the contents of this
                    website at any time without notice to you.
                </p>
            </div>
        </div>

        <div class="xs-section-heading">
            <div class="xs-heading-title">
                <h2 class="color-navy-blue">Confidentiality</h2>
                <p>
                    You undertake that all communication, content, intellectual property or other
                    information, and materials on the website, either marked ‘confidential’ or is by
                    its nature intended to be for your knowledge alone, shall be kept confidential
                    unless or until you can reasonably demonstrate that such communication,
                    information and material is, or part of it is, in the public domain through no
                    fault of yours. Furthermore, any communication, content, intellectual property
                    or other information, and materials you obtain in terms of or arising from the
                    use of this website shall be treated as confidential and shall not be divulged
                    or permitted to be divulged to third parties, without our prior written consent.
                    <br />
                    Please note that all obligations relating to Confidential Information under this
                    Terms of Use will continue after termination of the Terms of Use and termination
                    of access rights hereunder.
                </p>
            </div>
        </div>

        <div class="xs-section-heading">
            <div class="xs-heading-title">
                <h2 class="color-navy-blue">Use of suggestions</h2>
                <p>
                    You acknowledge and agree that any questions, comments, suggestions, ideas,
                    feedback, or other information provided by you to PropertyCollabo (collectively,
                    “Feedback”) are not confidential and you hereby grant to PropertyCollabo a
                    worldwide, perpetual, irrevocable, royalty-free license to reproduce, display,
                    perform, distribute, publish, modify, edit or otherwise use such Feedback as it
                    deems appropriate, for any and all commercial or non-commercial purposes, in its
                    sole discretion.
                </p>
            </div>
        </div>

        <div class="xs-section-heading">
            <div class="xs-heading-title">
                <h2 class="color-navy-blue">Undertakings</h2>
                <p>
                    You hereby agree as follows:
                    <br />
                    not to access or use the Services in an unlawful way or for an unlawful or
                    illegitimate purpose, including without limitation any violation of any criminal
                    or anti-money laundering laws of the Federal Republic of Nigeria or any other
                    jurisdiction where you are resident;
                    <br />
                    not to post, publish or transmit on the website (a) any message or information
                    under a false name; (b) information that is unlawful, malicious, libelous,
                    defamatory, obscene, fraudulent, predatory of minors, harassing, discriminatory,
                    threatening or hateful to any person; or (c) information that infringes or
                    violates any contractual or intellectual property rights of others or the
                    privacy or publicity rights of others;
                    <br />
                    not to post, process, publish or transmit any content (including works, data,
                    materials (including without limitation), text, graphics, images, information,
                    audio material, audio-visual material, scripts, software and files) that is
                    illegal or unlawful; or infringe any person’s legal rights; or capable of giving
                    rise to legal action against us or any person (in any jurisdiction and under any
                    applicable law).
                    <br />
                    not to transmit, distribute, introduce or otherwise make available in any manner
                    through the Services any computer virus, keyloggers, malware, spyware, worms,
                    Trojan horses, time bombs or other malicious or harmful code (collectively,
                    “Harmful Code”) or to do any act capable of disrupting the operation of the
                    website; Please note that we do not have an obligation to detect the presence of
                    Harmful Code (as defined in “Restrictions on the use of your Services” below.
                    Please note that if you download any Content from the website, you do so at your
                    own risk.
                    <br />
                    not to use the website in any manner that could damage, disable or impair our
                    services or networks;
                    <br />
                    not to attempt to gain unauthorized access to the website or any user accounts
                    or computer systems or networks, through hacking, password mining or any other
                    unlawful means;
                </p>
            </div>
        </div>

        <div class="xs-section-heading">
            <div class="xs-heading-title">
                <h2 class="color-navy-blue">Intellectual Property</h2>
                <p>
                    PropertyCollabo shall remain the owner of the know-how, trademarks, service
                    marks, logos, slogans, patents, copyright or other intellectual property rights
                    belonging to it within or outside the website. The use of the website, and
                    nothing contained herein shall create nor transfer any intellectual property
                    right belonging to PropertyCollabo, and you are not allowed to use any such
                    rights belonging to PropertyCollabo without our written consent.
                    <br />
                    If you believe that any content on the website infringes upon your copyrights,
                    please contact us through the contact details below.
                </p>
            </div>
        </div>

        <div class="xs-section-heading">
            <div class="xs-heading-title">
                <h2 class="color-navy-blue">Financial Partners</h2>
                <p>
                    At PropertyCollabo, we partner with other financial/payment service providers in
                    providing our Services to you. In order to enable you make payments on/through
                    the website, we may share your information with these financial partners. You
                    therefore authorize us to share your identity and banking information with
                    partner financial institutions in respect of our Services. PropertyCollabo shall
                    not be liable for any default, negligence or breach of the financial partners.
                </p>
            </div>
        </div>

        <div class="xs-section-heading">
            <div class="xs-heading-title">
                <h2 class="color-navy-blue">Termination/Suspension</h2>
                <p>
                    In the event of any breach of this Terms of Use, PropertyCollabo shall have the
                    right to suspend or terminate all or a portion of the Services to you in our
                    discretion, or restrict your access to the website. We reserve the right to
                    revoke, terminate or suspend any privileges associated with accessing the
                    Services for any reason (including regulatory instruction) or for no reason
                    whatsoever. You agree that PropertyCollabo shall not be liable to you or any
                    third party for any termination or suspension of your access to the website.
                    <br />
                    Termination of your access to the website shall be without prejudice to any
                    payment or sum due to you as at the date of termination or suspension of your
                    account. Provided that if the termination or suspension of your account was a
                    result of your breach of this Terms of Use which resulted in any loss, penalty
                    or damage to PropertyCollabo, we shall be entitled to set off such loss from any
                    sum due to you. You shall be notified of the extent of the loss caused as a
                    result of your breach (where quantifiable) and the extent of the set off applied
                    to any sum due to you from PropertyCollabo. Any balance after the set off shall
                    be remitted to you.
                    <br />
                    If you wish to close your account, you may do so by contacting PropertyCollabo’s
                    customer service through the contact details below. In the event of an abrupt
                    request closure of your account before the end of a sponsorship cycle,
                    PropertyCollabo shall be at liberty to charge or deduct any assessed charges,
                    costs, taxes and any assessed damages (where applicable) prior to the refund of
                    any sum due to you. Unless otherwise specified and agreed, you shall remain
                    obligated to pay all outstanding charges, if any, relating to your use of the
                    website prior to such closure request.
                </p>
            </div>
        </div>

        <div class="xs-section-heading">
            <div class="xs-heading-title">
                <h2 class="color-navy-blue">Links to other websites</h2>
                <p>
                    The PropertyCollabo website may contain links to other websites or resources on
                    the internet. When you access third party website/websites, you do so at your
                    own risk. Links to other websites/websites do not constitute an endorsement,
                    control, guarantee, authorization or representation of our affiliation with
                    those third parties. Kindly note that this Terms of Use or our Privacy Policy do
                    not apply to those third-party websites, as such, you agree that PropertyCollabo
                    shall not be liable under any circumstances in relation to your use of such
                    other websites/websites.
                    <br />
                    No financial, investment or sponsorship advice
                    <br />
                    This website provides preliminary and general information about investment
                    opportunities and is intended for initial reference purposes only.
                    <br />
                    Nothing on the website should be interpreted as providing or intending to
                    provide financial or investment advice. You acknowledge that PropertyCollabo has
                    not provided any financial, investment, legal or tax advice and/or any other
                    advice of any kind. You further agree that your use of the website or any of the
                    Services on the website is based on your careful consideration, due diligence
                    and/or based on the independent advice sought from your advisors.
                    <br />
                    Any questions regarding anything on the website should be addressed to
                    PropertyCollabo using the contact information set below. Where PropertyCollabo
                    publishes information from independent or third-party sources (including without
                    limitation, newspapers, magazines and the internet) such publication is for
                    informational purposes, without more.
                </p>
            </div>
        </div>

        <div class="xs-section-heading">
            <div class="xs-heading-title">
                <h2 class="color-navy-blue">Disclaimer of Warranty</h2>
                <p>
                    The website, its content and all services on the website are provided “as is”
                    and “as available” without any warranty of any kind, express or implied. To the
                    fullest extent permissible pursuant to applicable law, PropertyCollabo, its
                    affiliates and any person associated with PropertyCollabo and its affiliates,
                    disclaims all warranties of any kind, either express or implied, statutory or
                    otherwise, including but not limited to any implied warranties of title,
                    merchantability, fitness for a particular purpose or non-infringement.
                    <br />
                    Without limiting the foregoing, PropertyCollabo does not warrant that access to
                    the website will be uninterrupted or error-free, or that defects, if any, will
                    be corrected within any specific timeframe; nor does PropertyCollabo, its
                    affiliates, nor any person associated with PropertyCollabo make any
                    representations about the accuracy, standard, reliability, currency, quality,
                    completeness, usefulness, performance, security, or suitability of the website.
                    PropertyCollabo does not warrant that the website is free of viruses or other
                    harmful components. You shall be solely and fully responsible for any damage to
                    any computer system, any loss of data, or any improper use or improper
                    disclosure of information caused by you or any person using your login
                    credentials. PropertyCollabo cannot and does not assume any responsibility for
                    any loss, damages or liabilities arising from the failure of any
                    telecommunications infrastructure, or the internet or for your misuse of any of
                    advice, ideas, information, instructions or guidelines accessed through the
                    Services. It is your responsibility to install or download sufficient software
                    or hardware protection for your device or computer.
                </p>
            </div>
        </div>

        <div class="xs-section-heading">
            <div class="xs-heading-title">
                <h2 class="color-navy-blue">Limitation of liability</h2>
                <p>
                    PropertyCollabo and its subsidiaries, affiliates, officers, directors, agents or
                    employees are not liable to you or any other person for any punitive, exemplary,
                    consequential, incidental, indirect or special damages (including, without
                    limitation, any personal injury, lost profits, business interruption, loss of
                    programs or other data on your computer or otherwise) arising from or in
                    connection with your use of the website, whether under a theory of breach of
                    contract, tort, negligence, strict liability, malpractice, any other legal or
                    equitable theory or otherwise, even if PropertyCollabo has been advised of the
                    possibility of such damages. You hereby release PropertyCollabo and hold it and
                    its parents, subsidiaries, affiliates, officers, directors, agents and employees
                    harmless from any and all claims, demands, and damages of every kind and nature
                    (including, without limitation, actual, special, incidental and consequential),
                    known and unknown, suspected and unsuspected, disclosed and undisclosed, arising
                    out of or in any way connected with your use of the Services. You waive the
                    provisions of any state or local law limiting or prohibiting a general release.
                    <br />
                    The foregoing limitation of liability shall apply to the fullest extent
                    permitted by law in the applicable jurisdiction. This limitation of liability
                    also applies with respect to damages incurred by you by reason of any products
                    or services sold or provided on any linked websites or otherwise by third
                    parties other than PropertyCollabo and received through the Services or any
                    linked websites.
                    <br />
                    Without prejudice to the limitation of liability above, in the event of any
                    problem with the website or any of the services on the website, you agree that
                    your sole and exclusive remedy is to cease using the website. Under no
                    circumstances shall PropertyCollabo, its subsidiaries, affiliates, officers,
                    directors, agents or employees be liable in any way for your use of Services,
                    including, but not limited to, any errors or omissions in the website, downtime,
                    any infringement by the website of the intellectual property rights or other
                    rights of third parties, or for any loss or damage of any kind incurred as a
                    result of the use of the Services.
                </p>
            </div>
        </div>

        <div class="xs-section-heading">
            <div class="xs-heading-title">
                <h2 class="color-navy-blue">Breaches of Terms and Conditions</h2>
                <p>
                    Without prejudice to our other rights under these terms and conditions, if you
                    breach these terms and conditions in any way, or if we reasonably suspect that
                    you have breached these terms and conditions in any way, we shall:
                    <br />
                    send you one or more formal warnings in writing to this effect;
                    <br />
                    temporarily suspend your access to our website;
                    <br />
                    permanently prohibit you from accessing our website;
                    <br />
                    block your access to the website;
                    <br />
                    commence legal action against you, whether for breach of contract or otherwise;
                    and/or
                    <br />
                    suspend or delete your account on our website.
                    <br />
                    Where we suspend or prohibit or block your access to our website or a part of
                    our website, you must not take any action to circumvent such suspension or
                    prohibition or blocking (including without limitation, creating and/or using a
                    different account).
                </p>
            </div>
        </div>

        <div class="xs-section-heading">
            <div class="xs-heading-title">
                <h2 class="color-navy-blue">Indemnification</h2>
                <p>
                    Without limiting the generality or effect of other provisions of this Terms of
                    Use, you agree to indemnify, hold harmless, and defend PropertyCollabo and its
                    subsidiaries, affiliates, officers, directors, affiliates, agents and employees,
                    parent companies, (collectively, “Indemnified Parties” and each, individually,
                    an “Indemnified Party”) against all costs, expenses, liabilities and damages
                    (including reasonable attorney’s fees) incurred by any Indemnified Party in
                    connection with any third-party claims arising out of: (i) your use of the
                    website or any part of it; (ii) your failure to comply with any applicable laws
                    and regulations; and (iii) your breach of any obligations set forth in this
                    Terms of Use.
                    <br />
                    This indemnity obligation shall survive this Terms of Use and your use of the
                    website. PropertyCollabo reserves the right to assume the exclusive defense and
                    control of any matter otherwise subject to indemnification by you, in which
                    event you will assist and cooperate with PropertyCollabo in asserting any
                    available defenses.
                </p>
            </div>
        </div>

        <div class="xs-section-heading">
            <div class="xs-heading-title">
                <h2 class="color-navy-blue">Governing law and dispute resolution</h2>
                <p>
                    This Terms of Use and all disputes and matters arising from the website (or its
                    use) shall be governed by the laws of the Federal Republic of Nigeria.
                    <br />
                    In the event of a controversy, claim or dispute arising out of or relating to
                    this Terms of Use, the Parties shall attempt in good faith to resolve such
                    controversy, claim or dispute promptly by negotiation between the parties or
                    their authorized representatives. You shall, before exploring any other remedy
                    in law, notify PropertyCollabo of the dispute or complaint through the contact
                    details below. If parties are unable to resolve the controversy, claim or
                    dispute, the parties shall be at liberty to explore any other dispute resolution
                    mechanism known to Law including mediation, arbitration or litigation.
                </p>
            </div>
        </div>

        <div class="xs-section-heading">
            <div class="xs-heading-title">
                <h2 class="color-navy-blue">Miscellaneous</h2>
                <p>
                    <strong>Entire Agreement:</strong>
                    <br />
                    This User Agreement constitutes the sole agreement between you and
                    PropertyCollabo for your use and the provision of the Services and the subject
                    matter hereof, and no representations, statements or inducements, oral or
                    written, not contained in this Terms of Use shall bind either you or
                    PropertyCollabo.
                    <br />
                    <strong>Severance:</strong>
                    If any provision of this Terms of Use is prohibited by law or judged by a court
                    to be unlawful, void or unenforceable, the provision shall, to the extent
                    required, be severed from this Terms of Use and rendered ineffective as far as
                    possible without modifying the remaining provisions of this Terms of Use, and
                    shall not in any way affect any other circumstances of or the validity or
                    enforcement of this Terms of Use.
                </p>
            </div>
        </div>

        <div class="xs-section-heading">
            <div class="xs-heading-title">
                <h2 class="color-navy-blue">Waiver:</h2>
                <p>
                    Failure to insist on performance of any of the terms of this Terms of Use will
                    not operate as a waiver of any subsequent default. No waiver by PropertyCollabo
                    of any right under this Terms of Use will be deemed to be either a waiver of any
                    other right or provision or a waiver of that same right or provision at any
                    other time.
                </p>
            </div>
        </div>

        <div class="xs-section-heading">
            <div class="xs-heading-title">
                <h2 class="color-navy-blue">Assignment:</h2>
                <p>
                    You may not assign, transfer or delegate your rights or obligations hereunder,
                    in whole or in part.
                </p>
            </div>
        </div>

        <div class="xs-section-heading">
            <div class="xs-heading-title">
                <h2 class="color-navy-blue">Bindingness:</h2>
                <p>
                    This Terms of Use shall be binding upon and inure to the benefit of each of the
                    parties and the parties’ respective successors and permitted assigns. A printed
                    version of this Terms of Use and of any related notice given in electronic form
                    shall be admissible in judicial or administrative proceedings based upon or
                    relating to this Terms of Use to the same extent and subject to the same
                    conditions as other business documents and records originally generated and
                    maintained in printed form.
                </p>
            </div>
        </div>

        <ng-container>
            <app-terms-contact></app-terms-contact>
        </ng-container>
    </div>
</div>
<app-website-footer></app-website-footer>
