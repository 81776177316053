import { Component, OnInit, ViewChild } from '@angular/core';

import { ToastrService } from 'ngx-toastr';
import { ConnectionService } from 'ng-connection-service';
import { ICrumbs } from '@app/common/payload/common.payload';
import { Title } from '@angular/platform-browser';
import { environment } from '@environments/environment.prod';
import { CommandEnum } from '@app/common/enum/command.enum';

@Component({
    selector: 'app-dashboard-property-create',
    templateUrl: './dashboard-property-create.component.html',
    styleUrls: ['./dashboard-property-create.component.css'],
})
export class DashboardPropertyCreateComponent implements OnInit {
    @ViewChild('myCarousel', { static: false }) myCarousel;
    command: CommandEnum = CommandEnum.CREATE;

    isConnected = true;
    crumbs: ICrumbs[] = [
        { text: 'App', link: `/app` },
        {
            text: 'Dashboard',
        },
        {
            text: 'Create Property',
            link: '/app/admin/property-create/',
        },
    ];

    constructor(
        private titleService: Title,
        private toast: ToastrService,
        private connectionService: ConnectionService
    ) {
        this.connectionService.monitor().subscribe((isConnected) => {
            this.isConnected = isConnected;
            if (this.isConnected) {
                this.toast.success('Connected');
            } else {
                this.toast.show('Disconnected');
            }
        });
    }

    ngOnInit(): void {
        this.titleService.setTitle(`Create Property - ${environment.appName}`);
    }

    next() {
        this.myCarousel.next();
    }
}
