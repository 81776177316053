<div class="limiter">
    <div class="container-login100">
        <div class="wrap-login100">
            <div class="login100-pic js-tilt" data-tilt>
                <div>
                    <a class="point mg-b-5" routerLink="">
                        <cl-image public-id="property-collabo/defaults/logo_c5cpbc">
                            <cl-transformation width="100" crop="scale"> </cl-transformation>
                        </cl-image>
                    </a>

                    <h5 class="mg-t-10 signup-v2-title">
                        Welcome to the future of Modern Real Estate Investing
                    </h5>
                    <p>
                        Join Us today as we make real estate investing more transparent and
                        accessible to individual investors, helping them build wealth for their
                        family and future.
                    </p>
                </div>
            </div>

            <form
                class="login100-form validate-form"
                [formGroup]="registerForm"
                (ngSubmit)="onRegister()"
            >
                <div class="signup-v2-logo-hv">
                    <div class="signup-v2-logo-dv">
                        <a class="point text-center signup-v2-logo" routerLink="">
                            <cl-image public-id="property-collabo/defaults/logo_c5cpbc">
                                <cl-transformation width="50" crop="scale"> </cl-transformation>
                            </cl-image>
                        </a>
                    </div>
                </div>

                <span class="login100-form-title">
                    Create an Account
                    <h4 class="text-center">It's free to signup and only takes a minute.</h4>
                </span>

                <div
                    class="alert alert-warning alert-dismissible fade show"
                    style="margin-bottom: 20px; padding: 10px;"
                    *ngIf="isErrors"
                >
                    <strong>Warning!</strong>
                    <br />
                    <span *ngIf="error">{{ error | titlecase }}</span>
                    <ng-container *ngIf="messages">
                        <ol *ngFor="let message of messages" class="signup-v2-li">
                            <li style="margin-bottom: 1px;">{{ message | titlecase }}.</li>
                        </ol>
                    </ng-container>

                    <button
                        type="button"
                        (click)="clearErrorMessages()"
                        class="close"
                        data-dismiss="alert"
                    >
                        &times;
                    </button>
                </div>

                <div
                    class="alert alert-success alert-dismissible fade show"
                    style="margin-bottom: 20px; padding: 10px;"
                    *ngIf="isSuccess"
                >
                    <strong>Account Created Successfully!</strong>
                    <br />
                    <span>Click the activation link in your inbox to activate your account</span>
                </div>

                <small class="text-danger d-block" *ngIf="isSubmitted && rf.firstName.errors"
                    >First name is invalid</small
                >
                <div class="wrap-input100 validate-input">
                    <input
                        class="input100"
                        type="text"
                        placeholder="Enter first name"
                        [formControlName]="'firstName'"
                    />
                    <span class="focus-input100"></span>
                    <span class="symbol-input100">
                        <i
                            class="fa fa-user"
                            [ngClass]="{ 'text-danger': isSubmitted && rf.firstName.errors }"
                            aria-hidden="true"
                        ></i>
                    </span>
                </div>

                <small class="text-danger d-block" *ngIf="isSubmitted && rf.lastName.errors"
                    >Last name is invalid</small
                >
                <div class="wrap-input100 validate-input">
                    <input
                        class="input100"
                        type="text"
                        placeholder="Enter last name"
                        [formControlName]="'lastName'"
                    />
                    <span class="focus-input100"></span>
                    <span class="symbol-input100">
                        <i
                            class="fa fa-user"
                            [ngClass]="{ 'text-danger': isSubmitted && rf.lastName.errors }"
                            aria-hidden="true"
                        ></i>
                    </span>
                </div>

                <small class="text-danger d-block" *ngIf="isSubmitted && rf.email.errors"
                    >Email is invalid</small
                >
                <div class="wrap-input100 validate-input">
                    <input
                        class="input100"
                        type="text"
                        placeholder="Enter email"
                        [formControlName]="'email'"
                    />
                    <span class="focus-input100"></span>
                    <span class="symbol-input100">
                        <i
                            class="fa fa-envelope"
                            [ngClass]="{ 'text-danger': isSubmitted && rf.email.errors }"
                            aria-hidden="true"
                        ></i>
                    </span>
                </div>

                <small class="text-danger d-block" *ngIf="isSubmitted && rf.phone.errors"
                    >Phone is invalid</small
                >
                <div class="wrap-input100 validate-input">
                    <input
                        class="input100"
                        type="text"
                        placeholder="Enter phone"
                        [formControlName]="'phone'"
                        [ngClass]="{ 'f-invalid': isSubmitted && rf.phone.errors }"
                    />
                    <span class="focus-input100"></span>
                    <span class="symbol-input100">
                        <i
                            class="fa fa-phone"
                            [ngClass]="{ 'text-danger': isSubmitted && rf.phone.errors }"
                            aria-hidden="true"
                        ></i>
                    </span>
                </div>

                <small class="text-danger d-block" *ngIf="isSubmitted && rf.password.errors"
                    >Password is invalid</small
                >
                <div class="wrap-input100 validate-input">
                    <input
                        class="input100"
                        type="password"
                        placeholder="Enter password"
                        [formControlName]="'password'"
                    />
                    <span class="focus-input100"></span>
                    <span class="symbol-input100">
                        <i
                            class="fa fa-lock"
                            [ngClass]="{ 'text-danger': isSubmitted && rf.password.errors }"
                            aria-hidden="true"
                        ></i>
                    </span>
                </div>

                <div class="container-login100-form-btn">
                    <button class="login100-form-btn" type="submit" [disabled]="isLoading">
                        Create Account
                        <span *ngIf="isLoading">
                            <i class="fa fa-circle-o-notch fa-spin fa-1x fa-fw"></i>
                        </span>
                    </button>
                </div>

                <div class="text-center p-t-12">
                    <span class="txt1">
                        Already a member?
                    </span>
                    <a class="txt2" routerLink="/sign-in">
                        Sign In
                    </a>
                </div>

                <div class="text-center p-t-136">
                    <a class="txt2" routerLink="/password-reset">
                        Recover Account
                        <i class="fa fa-long-arrow-right m-l-5" aria-hidden="true"></i>
                    </a>
                </div>
                <div class="form-group">
                    <ngx-invisible-recaptcha
                        #captchaElem
                        [siteKey]="siteKey"
                        (load)="onCaptchaHandleLoad()"
                        (success)="onCaptchaHandleSuccess($event)"
                        (expire)="onCaptchaHandleExpire()"
                        [ngModel]="recaptcha"
                        [ngModelOptions]="{ standalone: true }"
                    >
                    </ngx-invisible-recaptcha>
                </div>
            </form>
        </div>
    </div>
</div>
