import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardPageComponent } from './dashboard-landing-page/dashboard-page.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { VerificationSuccessComponent } from './verification-success/verification-success.component';
import { VerificationFailedComponent } from './verification-failed/verification-failed.component';
import { DashboardProfilePageComponent } from './user-pages/dashboard-profile-page/dashboard-profile-page.component';
import { DashboardStatsCardComponent } from './components/dashboard-stats-card/dashboard-stats-card.component';
import { DashboardStatsCardSmComponent } from './components/dashboard-stats-card-sm/dashboard-stats-card-sm.component';
import { DashboardCoInvestorsComponent } from './components/dashboard-co-investors/dashboard-co-investors.component';
import { DashboardInvestmentPageComponent } from './user-pages/dashboard-investment-page/dashboard-investment-page.component';
import { DashboardReturnsPageComponent } from './user-pages/dashboard-returns-page/dashboard-returns-page.component';
import { DashboardAdminPropertyComponent } from './admin-pages/dashboard-admin-property-pages/dashboard-admin-property-view/dashboard-admin-property.component';
import { DashboardAdminReturnComponent } from './admin-pages/dashboard-admin-return/dashboard-admin-return.component';
import { DashboardAdminUserComponent } from './admin-pages/dashboard-admin-user/dashboard-admin-user.component';
import { DashboardAdminInvestmentComponent } from './admin-pages/dashboard-admin-investment/dashboard-admin-investment.component';
import { DashboardAdminIdentityComponent } from './admin-pages/dashboard-admin-identity/dashboard-admin-identity.component';
import { DashboardPropertyCreateComponent } from './admin-pages/dashboard-admin-property-pages/dashboard-admin-property-create/dashboard-property-create.component';
import {
    NgbButtonsModule,
    NgbDatepickerModule,
    NgbDropdownModule,
    NgbPaginationModule,
    NgbTooltipModule,
} from '@ng-bootstrap/ng-bootstrap';
import { FileUploadModule } from 'ng2-file-upload';
import { DashboardPhotoUploadComponent } from './components/dashboard-photo-upload/dashboard-photo-upload.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { S1LottieModule } from '@sentinel-one/s1-lottie';
import { DashboardEmptyAnimComponent } from './components/dashboard-empty-anim/dashboard-empty-anim.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { DashboardLogoComponent } from './components/dashboard-logo/dashboard-logo.component';
import { DashboardUsercardComponent } from './components/dashboard-usercard-sidebar/dashboard-usercard.component';
import { DashboardMenuSidebarComponent } from './components/dashboard-menu-sidebar/dashboard-menu-sidebar.component';
import { DashboardContentTopbarComponent } from './components/dashboard-content-topbar/dashboard-content-topbar.component';
import { DashboardBreadcrumbsComponent } from './components/dashboard-breadcrumbs/dashboard-breadcrumbs.component';
import { DashboardMobileMenuComponent } from './components/dashboard-mobile-menu/dashboard-mobile-menu.component';
import { AppCommonModule } from '@app/common/common.module';
import { DashboardUsercardMenuComponent } from './components/dashboard-usercard-sidebar/dashboard-usercard-menu/dashboard-usercard-menu.component';
import { UserDashboardComponent } from './user-pages/user-dashboard/user-dashboard.component';
import { DashboardLayoutComponent } from '@app/dashboard/dashboard-pages/dashboard-layout/dashboard-layout.component';
import { AdminDashboardComponent } from './admin-pages/admin-dashboard/admin-dashboard.component';
import { AdminDashboardRightComponent } from './admin-pages/admin-dashboard/admin-dashboard-right/admin-dashboard-right.component';
import { DashboardPropertyCardComponent } from './components/dashboard-property-card/dashboard-property-card.component';
import { JwPaginationModule } from 'jw-angular-pagination';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { DashboardAdminPropertyFormComponent } from './admin-pages/dashboard-admin-property-pages/dashboard-admin-property-form/dashboard-admin-property-form.component';
import { DashboardAdminPropertyUpdateComponent } from './admin-pages/dashboard-admin-property-pages/dashboard-admin-property-update/dashboard-admin-property-update.component';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { DashboardPropertyComponent } from './user-pages/dashboard-property/dashboard-property.component';
import { DashboardIdentityComponent } from './user-pages/dashboard-identity/dashboard-identity.component';
import { UserDashboardRightComponent } from './user-pages/user-dashboard/user-dashboard-right/user-dashboard-right.component';
import { DashboardChartComponent } from './components/dashboard-chart/dashboard-chart.component';
import { DashboardPropertyFilterComponent } from './components/dashboard-property-filter/dashboard-property-filter.component';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { DashboardPropertyDetailComponent } from './components/dashboard-property-detail/dashboard-property-detail.component';
import { NgImageSliderModule } from 'ng-image-slider';
import { DashboardPropertyDetailsComponent } from './user-pages/dashboard-property-details/dashboard-property-details.component';
import { DashboardFaIconsComponent } from './components/dashboard-fa-icons/dashboard-fa-icons.component';
import { CloudinaryModule } from '@cloudinary/angular-5.x';
import { DashboardUsersCompleteProfileComponent } from './components/dashboard-users-complete-profile/dashboard-users-complete-profile.component';
import { DashboardViewUsersProfileComponent } from './admin-pages/dashboard-admin-user/dashoard-view-users-profile/dashboard-view-users-profile.component';
import { ChartsModule } from 'ng2-charts';
import { NgxCaptchaModule } from 'ngx-captcha';
import { DashboardUserUpdateProfileComponent } from './components/dashboard-user-update-profile/dashboard-user-update-profile.component';
import { DashboardChartPieComponent } from './components/dashboard-chart-pie/dashboard-chart-pie.component';
import { DashboardChartDoughnutComponent } from './components/dashboard-chart-doughnut/dashboard-chart-doughnut.component';
import { DashboardUserCompleteEarningComponent } from './admin-pages/dashboard-admin-return/dashboard-user-complete-earning/dashboard-user-complete-earning.component';
import { DashboardPropertyInvestCardComponent } from './components/dashboard-property-invest-card/dashboard-property-invest-card.component';
import { DashboardSettingsComponent } from './admin-pages/dashboard-settings/dashboard-settings.component';
import { PasswordResetComponent } from './password-reset/password-reset.component';
import { PasswordResetConfirmComponent } from './password-reset/password-reset-confirm/password-reset-confirm.component';
import { LoginTwoComponent } from './login-two/login-two.component';
import { RegisterTwoComponent } from './register-two/register-two.component';

@NgModule({
    declarations: [
        DashboardLayoutComponent,
        DashboardPageComponent,
        PageNotFoundComponent,
        LoginComponent,
        RegisterComponent,
        VerificationSuccessComponent,
        VerificationFailedComponent,
        DashboardProfilePageComponent,
        DashboardStatsCardComponent,
        DashboardStatsCardSmComponent,
        DashboardCoInvestorsComponent,
        DashboardInvestmentPageComponent,
        DashboardReturnsPageComponent,
        DashboardAdminPropertyComponent,
        DashboardAdminReturnComponent,
        DashboardAdminUserComponent,
        DashboardAdminInvestmentComponent,
        DashboardAdminIdentityComponent,
        DashboardPropertyCreateComponent,
        DashboardPhotoUploadComponent,
        DashboardEmptyAnimComponent,
        DashboardLogoComponent,
        DashboardUsercardComponent,
        DashboardMenuSidebarComponent,
        DashboardContentTopbarComponent,
        DashboardBreadcrumbsComponent,
        DashboardMobileMenuComponent,
        DashboardUsercardMenuComponent,
        UserDashboardComponent,
        AdminDashboardComponent,
        AdminDashboardRightComponent,
        DashboardPropertyCardComponent,
        DashboardAdminPropertyFormComponent,
        DashboardAdminPropertyUpdateComponent,
        DashboardPropertyComponent,
        DashboardIdentityComponent,
        UserDashboardRightComponent,
        DashboardChartComponent,
        DashboardPropertyFilterComponent,
        DashboardPropertyDetailComponent,
        DashboardPropertyDetailsComponent,
        DashboardFaIconsComponent,
        DashboardUsersCompleteProfileComponent,
        DashboardViewUsersProfileComponent,
        DashboardUserUpdateProfileComponent,
        DashboardChartPieComponent,
        DashboardChartDoughnutComponent,
        DashboardUserCompleteEarningComponent,
        DashboardPropertyInvestCardComponent,
        DashboardSettingsComponent,
        PasswordResetComponent,
        PasswordResetConfirmComponent,
        LoginTwoComponent,
        RegisterTwoComponent,
    ],
    imports: [
        CommonModule,
        RouterModule,
        ReactiveFormsModule,
        NgbDatepickerModule,
        FormsModule,
        FileUploadModule,
        NgbTooltipModule,
        NgxSpinnerModule,
        S1LottieModule,
        CarouselModule,
        AppCommonModule,
        NgbPaginationModule,
        JwPaginationModule,
        SweetAlert2Module,
        LazyLoadImageModule,
        NgCircleProgressModule,
        NgImageSliderModule,
        CloudinaryModule,
        ChartsModule,
        NgxCaptchaModule,
        NgbDropdownModule,
        NgbButtonsModule,
    ],
    exports: [
        DashboardLayoutComponent,
        DashboardPageComponent,
        PageNotFoundComponent,
        LoginComponent,
        RegisterComponent,
        VerificationSuccessComponent,
        VerificationFailedComponent,
        DashboardProfilePageComponent,
        DashboardStatsCardComponent,
        DashboardStatsCardSmComponent,
        DashboardCoInvestorsComponent,
        DashboardInvestmentPageComponent,
        DashboardReturnsPageComponent,
        DashboardAdminPropertyComponent,
        DashboardAdminReturnComponent,
        DashboardAdminUserComponent,
        DashboardAdminInvestmentComponent,
        DashboardAdminIdentityComponent,
        DashboardPropertyCreateComponent,
        NgbDatepickerModule,
        DashboardPhotoUploadComponent,
        DashboardLogoComponent,
        DashboardUsercardComponent,
        DashboardMenuSidebarComponent,
        DashboardContentTopbarComponent,
        DashboardBreadcrumbsComponent,
        DashboardMobileMenuComponent,
        DashboardPropertyDetailsComponent,
    ],
})
export class DashboardPagesModule {}
