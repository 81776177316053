<div class="menu-mobile menu-activated-on-click color-scheme-dark">
    <div class="mm-logo-buttons-w">
        <a class="mm-logo">
            <app-logo [props]="{ type: 'white' }"></app-logo>
        </a>
        <div class="mm-buttons">
            <!--            <div class="content-panel-open">-->
            <!--                <div-->
            <!--                    class="os-icon os-icon-grid-circles"-->
            <!--                    (click)="onToggleRightBar()"-->
            <!--                ></div>-->
            <!--            </div>-->
            <div class="mobile-menu-trigger">
                <div class="os-icon os-icon-hamburger-menu-1" (click)="showMenu = !showMenu"></div>
            </div>
        </div>
    </div>
    <div class="menu-and-user" [ngStyle]="{ display: showMenu ? 'block' : 'none' }">
        <div class="logged-user-w">
            <div class="avatar-w">
                <app-user-profile-avatar></app-user-profile-avatar>
            </div>
            <div class="logged-user-info-w">
                <div class="logged-user-name">{{ user?.firstName }} {{ user?.lastName }}</div>
                <div class="logged-user-role">
                    {{ user.isAdmin ? ' Administrator' : 'Investor' }}
                </div>
            </div>
        </div>
        <ul class="main-menu">
            <li
                class="has-sub-menu"
                (click)="onToggleMenu($event, menu, i)"
                #myitems
                *ngFor="let menu of menuBag; let i = index"
                [id]="'menu-' + i"
            >
                <ng-container *ngIf="menu?.children.length < 1; else noChild">
                    <a [routerLink]="menu?.link" (click)="showMenu = !showMenu">
                        <div class="icon-w"><div [ngClass]="menu.icon"></div></div>
                        <span> {{ menu?.title }} </span>
                    </a>
                </ng-container>
                <ng-template #noChild>
                    <a>
                        <div class="icon-w"><div [ngClass]="menu.icon"></div></div>
                        <span> {{ menu?.title }} </span>
                    </a>
                    <ul class="sub-menu">
                        <li *ngFor="let child of menu.children">
                            <a [routerLink]="child.link" (click)="showMenu = !showMenu">
                                {{ child.title }}
                            </a>
                        </li>
                    </ul>
                </ng-template>
            </li>
        </ul>
    </div>
</div>
