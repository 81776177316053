<ng-container>
    <div class="az-header mg-b-20">
        <div class="container mg-t-50">
            <div class="az-header-left mg-t-10">
                <a routerLink="">
                    <cl-image public-id="property-collabo/defaults/logo_c5cpbc">
                        <cl-transformation width="50" crop="scale"> </cl-transformation>
                    </cl-image>
                </a>
            </div>

            <div class="az-header-right">
                <a
                    id="azMenuShow"
                    (click)="onToggleMobileNav('help me')"
                    class="az-header-menu-icon d-lg-none"
                    ><span></span>
                </a>
            </div>

            <!-- header-left -->
            <div class="az-header-menu">
                <div class="az-header-menu-header">
                    <a routerLink="" class="az-logo"><span></span> Prop Collabo</a>
                    <a class="close">&times;</a>
                </div>
                <ul class="nav">
                    <li
                        class="nav-item point sp-r-40"
                        routerLinkActive="active"
                        [routerLinkActiveOptions]="{ exact: true }"
                    >
                        <a routerLink="" class="nav-link text-uppercase">Home</a>
                    </li>
                    <!--                    <li-->
                    <!--                        class="nav-item point sp-r-30"-->
                    <!--                        [ngClass]="{ show: dropCompany }"-->
                    <!--                        routerLinkActive="active"-->
                    <!--                        (click)="showDropDown('company')"-->
                    <!--                    >-->
                    <!--                        <a class="nav-link with-sub text-uppercase"> Company</a>-->
                    <!--                        <nav class="az-menu-sub">-->
                    <!--                            <a class="nav-link point">About Us</a>-->
                    <!--                            <a class="nav-link point">Why Us</a>-->
                    <!--                        </nav>-->
                    <!--                    </li>-->
                    <li
                        class="nav-item point sp-r-30"
                        routerLinkActive="active"
                        [routerLinkActiveOptions]="{ exact: true }"
                    >
                        <a routerLink="/about" class="nav-link text-uppercase"> About Us</a>
                    </li>
                    <li
                        class="nav-item point sp-r-30"
                        routerLinkActive="active"
                        [routerLinkActiveOptions]="{ exact: true }"
                    >
                        <a routerLink="/contact" class="nav-link text-uppercase"> Contact Us</a>
                    </li>
                    <li
                        class="nav-item point sp-r-30"
                        routerLinkActive="active"
                        [routerLinkActiveOptions]="{ exact: true }"
                    >
                        <a routerLink="/faq" class="nav-link text-uppercase"> FAQ</a>
                    </li>
                </ul>
            </div>

            <div class="az-header-right">
                <div class="az-header-menu">
                    <ul class="nav">
                        <li
                            *ngIf="!isAuthenticated"
                            class="nav-item point"
                            [ngClass]="{ show: dropAccount }"
                            routerLinkActive="active"
                            (click)="showDropDown('account')"
                        >
                            <a class="nav-link with-sub text-uppercase"
                                ><i class="typcn typcn-user-outline"></i> Account</a
                            >
                            <nav class="az-menu-sub">
                                <a routerLink="/sign-in" class="nav-link point">Sign In</a>
                                <a routerLink="/sign-up" class="nav-link point">Sign Up</a>
                            </nav>
                        </li>
                        <li
                            *ngIf="isAuthenticated"
                            class="nav-item point"
                            [ngClass]="{ show: dropAccount }"
                            routerLinkActive="active"
                            (click)="showDropDown('account')"
                        >
                            <a class="nav-link with-sub"
                                ><i class="typcn typcn-user-outline"></i>
                                {{ userDetails.lastName | titlecase }}</a
                            >
                            <nav class="az-menu-sub">
                                <a routerLink="/app" class="nav-link point">Dashboard</a>
                                <a routerLink="/app/profile" class="nav-link point">Profile</a>
                                <a (click)="onSignOut()" class="nav-link point">Sign Out</a>
                            </nav>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <ng-container>
            <div class="mobile-header" style="visibility: hidden;" #mobileHeader>
                <ul class="navbar-nav nav">
                    <li class="nav-item dot-fix">
                        <a class="nav-link" routerLink="">Home</a>
                    </li>
                    <!--                    <li class="nav-item dropdown-holder">-->
                    <!--                        <a class="nav-link" (click)="onToggleCompanySubMenu()">Company</a>-->
                    <!--                        <ng-container *ngIf="!isCompanySubMenu; else arrow">-->
                    <!--                            <i-->
                    <!--                                class="nav-fa fa fa-angle-down point"-->
                    <!--                                (click)="onToggleCompanySubMenu()"-->
                    <!--                                aria-hidden="true"-->
                    <!--                            ></i>-->
                    <!--                        </ng-container>-->

                    <!--                        <ng-template #arrow>-->
                    <!--                            <i-->
                    <!--                                class="nav-fa fa fa-angle-up point"-->
                    <!--                                (click)="onToggleCompanySubMenu()"-->
                    <!--                                aria-hidden="true"-->
                    <!--                            ></i>-->
                    <!--                        </ng-template>-->
                    <!--                        <ul class="navbar-nav nav nav-ul" style="display: none;" #companySubMenu>-->
                    <!--                            <li class="nav-item dot-fix">-->
                    <!--                                <a class="nav-link">About us</a>-->
                    <!--                            </li>-->
                    <!--                            <li class="nav-item dot-fix">-->
                    <!--                                <a class="nav-link">Why us</a>-->
                    <!--                            </li>-->
                    <!--                        </ul>-->
                    <!--                    </li>-->
                    <li class="nav-item dot-fix">
                        <a class="nav-link" routerLink="/about">About</a>
                    </li>
                    <li class="nav-item dot-fix">
                        <a class="nav-link" routerLink="/contact">Contact</a>
                    </li>
                    <li class="nav-item dot-fix">
                        <a class="nav-link" routerLink="/faq">FAQ</a>
                    </li>
                </ul>
                <div class="action-btn" *ngIf="!isAuthenticated">
                    <a class="btn btn-sm btn-primary text-white" routerLink="/sign-in">Sign in</a>
                    <a class="btn btn-sm btn-primary text-white" routerLink="/sign-up">Sign up</a>
                </div>

                <div class="action-btn" *ngIf="isAuthenticated">
                    <a class="btn btn-sm btn-primary text-white" routerLink="/app">Dashboard</a>
                    <a class="btn btn-sm btn-primary text-white" (click)="onSignOut()">Sign out</a>
                </div>
            </div>
        </ng-container>
    </div>
</ng-container>
