import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { User } from '@app/common/payload/user';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'app-dashboard-user-complete-earning',
    templateUrl: './dashboard-user-complete-earning.component.html',
    styleUrls: ['./dashboard-user-complete-earning.component.css'],
})
export class DashboardUserCompleteEarningComponent implements OnInit, OnDestroy {
    routeState: any;
    user: User;
    id: number;

    subscription: Subscription = new Subscription();
    constructor(private router: Router, private route: ActivatedRoute) {
        if (this.router.getCurrentNavigation()?.extras?.state) {
            this.routeState = this.router.getCurrentNavigation().extras?.state;
            if (this.routeState) {
                //'Retrieving From Route...;
                this.user = this.routeState.data;
            }
        }
    }

    ngOnInit(): void {
        this.subscription.add(
            this.route.params.subscribe((params) => {
                this.id = params['userId'];
            })
        );
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}
