<ng-container #formContent>
    <form autocomplete="off" [formGroup]="form" (ngSubmit)="onSubmitProperty()" #errorDiv>
        <div class="alert alert-danger text-white" role="alert" *ngIf="hasErrors">
            <button
                type="button"
                class="close"
                data-dismiss="alert"
                aria-label="Close"
                (click)="onRemoveError()"
            >
                <span aria-hidden="true">&times;</span>
            </button>
            <ol type="a" class="mg-b-5 tx-12">
                <li *ngFor="let message of errorMessages">
                    <span class="text-white tx-12">{{ message | titlecase }}</span>
                </li>
            </ol>
        </div>
        <app-dashboard-photo-upload
            [props]="{ folder: 'properties', tag: 'property', property: property }"
        ></app-dashboard-photo-upload>
        <div class="container">
            <div class="form-group">
                <label for="title">Title</label>
                <input
                    id="title"
                    class="form-control"
                    formControlName="title"
                    placeholder=""
                    type="text"
                    [ngClass]="{ 'f-invalid': isSubmitted && pf.title.errors }"
                />
            </div>
            <div class="row">
                <div class="col-sm-6">
                    <div class="form-group">
                        <label for="price">Price</label>
                        <input
                            id="price"
                            class="form-control"
                            formControlName="price"
                            placeholder=""
                            type="number"
                            [ngClass]="{
                                'f-invalid': isSubmitted && pf.price.errors
                            }"
                        />
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="form-group">
                        <label for="target">Target Amount</label>
                        <input
                            type="number"
                            class="form-control"
                            formControlName="target"
                            id="target"
                            [ngClass]="{
                                'f-invalid': isSubmitted && pf.target.errors
                            }"
                        />
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-6">
                    <div class="form-group">
                        <label for="category"> Category</label>
                        <select
                            id="category"
                            class="form-control"
                            formControlName="category"
                            [ngClass]="{
                                'f-invalid': isSubmitted && pf.category.errors
                            }"
                        >
                            <option selected value="COMMERCIAL">Commercial</option>
                            <option value="INDUSTRIAL">Industrial</option>
                            <option value="PRIVATE">Private</option>
                            <option value="RESIDENTIAL">Residential</option>
                        </select>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="form-group">
                        <label for="status">Property Status</label>
                        <select
                            id="status"
                            class="form-control"
                            formControlName="status"
                            [ngClass]="{
                                'f-invalid': isSubmitted && pf.status.errors
                            }"
                        >
                            <option value="OPEN INVESTMENT" selected>Open</option>
                            <option value="CLOSED INVESTMENT">Closed</option>
                            <option value="PAST INVESTMENT">Past</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-6">
                    <div class="form-group">
                        <label for="duration"
                            >Duration (in months)
                            <small
                                ><i
                                    class="fa fa-info-circle"
                                    ngbTooltip="This is the duration of return of investment. (3months, 6months, 12months)"
                                ></i></small
                        ></label>
                        <input
                            type="number"
                            max="100"
                            class="form-control"
                            id="duration"
                            formControlName="duration"
                            [ngClass]="{
                                'f-invalid': isSubmitted && pf.duration.errors
                            }"
                        />
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="form-group">
                        <label for="type">Type</label>
                        <select
                            id="type"
                            class="form-control"
                            formControlName="type"
                            [ngClass]="{
                                'f-invalid': isSubmitted && pf.type.errors
                            }"
                        >
                            <option value="LAND" selected>Land</option>
                            <option value="APARTMENT">Apartment</option>
                            <option value="HOUSE">House</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-6">
                    <div class="form-group">
                        <label for="minAmount">Min Amount </label>
                        <input
                            type="number"
                            class="form-control"
                            formControlName="minAmount"
                            id="minAmount"
                            [ngClass]="{
                                'f-invalid': isSubmitted && pf.minAmount.errors
                            }"
                        />
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="form-group">
                        <label for="maxAmount">Max Amount </label>
                        <input
                            type="number"
                            class="form-control"
                            formControlName="maxAmount"
                            id="maxAmount"
                            [ngClass]="{
                                'f-invalid': isSubmitted && pf.maxAmount.errors
                            }"
                        />
                    </div>
                </div>
            </div>
            <div class="form-group">
                <label for="location"
                    >Location
                    <small>
                        <i
                            class="fa fa-info-circle"
                            ngbTooltip="'Provide the Location of the property: Country/City only.'"
                            placement="right-top"
                            container="body"
                        ></i>
                    </small>
                </label>
                <input
                    id="location"
                    formControlName="location"
                    class="form-control"
                    placeholder=""
                    type="text"
                    [ngClass]="{ 'f-invalid': isSubmitted && pf.location.errors }"
                />
            </div>
            <div class="form-group">
                <label for="roi">Return of Investment (%)</label>
                <input
                    id="roi"
                    class="form-control"
                    placeholder=""
                    type="number"
                    formControlName="returnOfInvestment"
                    [ngClass]="{
                        'f-invalid': isSubmitted && pf.returnOfInvestment.errors
                    }"
                />
            </div>
            <div class="form-group">
                <label for="close_date"
                    >Closed Date
                    <small>
                        <i
                            class="fa fa-info-circle"
                            placement="right-top"
                            ngbTooltip="'On this date, this property will become closed and no longer available for new investment'"
                            container="body"
                        ></i>
                    </small>
                </label>
                <div class="input-group" (click)="d.toggle()">
                    <div class="input-group-prepend">
                        <div class="input-group-text">
                            <i class="typcn typcn-calendar-outline tx-24 lh--9 op-6"></i>
                        </div>
                    </div>

                    <input
                        id="close_date"
                        type="text"
                        class="form-control fc-datepicker"
                        placeholder="MM/DD/YYYY"
                        [(ngModel)]="model"
                        #d="ngbDatepicker"
                        ngbDatepicker
                        formControlName="closeDate"
                        [ngClass]="{
                            'f-invalid': isSubmitted && pf.closeDate.errors
                        }"
                    />
                </div>
            </div>
            <div class="row">
                <div class="col-sm-6">
                    <div class="form-group">
                        <label for="address">Address</label>
                        <textarea
                            id="address"
                            rows="3"
                            class="form-control"
                            placeholder="Address"
                            formControlName="address"
                            [ngClass]="{
                                'f-invalid': isSubmitted && pf.address.errors
                            }"
                        ></textarea>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="form-group">
                        <label for="description">Description</label>
                        <textarea
                            id="description"
                            rows="3"
                            class="form-control"
                            placeholder="Description"
                            formControlName="description"
                            [ngClass]="{
                                'f-invalid': isSubmitted && pf.description.errors
                            }"
                        ></textarea>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="form-group">
                        <label>Property Facts </label>
                        <small class="pull-right"
                            ><a target="_blank" routerLink="/app/system-icons">
                                Find Icons
                            </a></small
                        >
                        <div class="table-responsive">
                            <table class="table table-striped">
                                <thead>
                                    <tr>
                                        <th width="30%">Icon</th>
                                        <th width="40%">Feature</th>
                                        <th width="30%"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <input
                                                type="text"
                                                class="form-control"
                                                placeholder="Icon"
                                                [(ngModel)]="feature.icon"
                                                [ngModelOptions]="{ standalone: true }"
                                            />
                                        </td>
                                        <td>
                                            <input
                                                type="text"
                                                class="form-control"
                                                placeholder="Feature"
                                                [(ngModel)]="feature.text"
                                                [ngModelOptions]="{ standalone: true }"
                                            />
                                        </td>
                                        <td>
                                            <a
                                                (click)="onAddFeature()"
                                                class="btn btn-sm btn-primary text-white"
                                                ><i class="fa fa-plus-circle"></i>
                                            </a>
                                        </td>
                                    </tr>
                                    <tr *ngFor="let item of features; let i = index">
                                        <td>
                                            <div class="text-center">
                                                <i
                                                    class="text-primary fa-2x"
                                                    [ngClass]="item.icon"
                                                ></i>
                                            </div>
                                        </td>
                                        <td>
                                            <label> {{ item.text }} </label>
                                        </td>
                                        <td>
                                            <a
                                                (click)="onRemoveFeature(i)"
                                                class="btn btn-sm btn-danger text-white"
                                                ><i class="fa fa-trash-o"></i>
                                            </a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div class="form-group">
                        <div class="row">
                            <div class="col-md-6 col-sm-12">
                                <label for="displayOnWebsite">Show On Website ?: </label>
                                <select
                                    name="showInWebsite"
                                    [(ngModel)]="showInWebsite"
                                    id="displayOnWebsite"
                                    class="form-control"
                                    [ngModelOptions]="{ standalone: true }"
                                >
                                    <option value="yes">Yes</option>
                                    <option selected value="no">No</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="form-buttons-w">
                <button class="btn btn-primary" type="submit">
                    {{ command === 'CREATE' ? 'Create Property' : 'Update Property' }}
                </button>
            </div>
        </div>
    </form>
</ng-container>
