<div class="content-i" *ngIf="property; else loaderTemplate">
    <div class="content-box">
        <div class="row pt-4">
            <div class="col-sm-12">
                <app-dashboard-property-detail
                    [property]="property"
                ></app-dashboard-property-detail>
            </div>
        </div>
    </div>
</div>

<ng-template #loaderTemplate>
    <div class="text-center centered"><i class="fa fa-spinner fa-spin fa-2x"></i> <br /></div>
</ng-template>
