<app-website-header></app-website-header>
<main class="xs-all-content-wrapper">
    <section
        class="xs-about-us-promo-section xs-content-section-padding fundpress-about-us-promo-section"
        style="
            background-image: url(assets/website-assets/image/background/icons-background-1.png);
        "
    >
        <div class="container">
            <div class="prop-heading-title text-center xs-mb-60">
                <h2 class="color-navy-blue xs-mb-0">About Us</h2>
            </div>

            <div class="row">
                <div class="col-md-12 col-lg-6">
                    <div class="fundpress-inner-about-content">
                        <h2 class="color-navy-blue about-welcome-text">
                            Welcome to the future of Modern Real Estate Investing
                        </h2>
                        <p class="space-top-2">
                            Real estate has and will continue to remain an extremely valuable asset,
                            and this is why it is considered one of the most secure investments in
                            the journey to building generational wealth.
                        </p>

                        <p>
                            Property Collabo will help you to multiply your investments and grow
                            your portfolio without the steep entrance fees and legal paperwork that
                            is typical with real estate investments.
                        </p>
                    </div>
                </div>
                <div class="col-md-12 col-lg-6">
                    <div class="fundpress-about-promo-image xs-box-shadow">
                        <img
                            src="assets/website-assets/image/about/about-us-a.jpg"
                            alt="Property Collabo About"
                            class="img-100"
                        />
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="fundpress-video-popup-with-text">
        <div class="fundpress-video-top-section xs-navy-blue-bg">
            <div class="container">
                <div class="row">
                    <div class="col-md-10 col-sm-12 col-lg-10 content-center">
                        <div class="xs-heading-title fundpress-heading-title">
                            <h2 class="color-white about-h2">
                                The Road to Financial Freedom - Creating Wealth for the Everyday
                                Investor
                            </h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="xs-gray-bg fundpress-text-with-image-side-by-side-1 space-top-25">
        <div class="container">
            <div class="row">
                <div class="col-md-12 col-lg-6">
                    <div class="xs-box-shadow-2">
                        <img
                            src="assets/website-assets/image/about/about-us-e.jpg"
                            alt=""
                            class="img-100"
                        />
                    </div>
                </div>
                <div class="col-md-12 col-lg-6">
                    <div class="fundpress-side-by-side-image-with-text">
                        <h5 class="color-green space-top-25">Our Vision</h5>
                        <div class="xs-inner-title fundpress-inner-title">
                            <h2 class="color-navy-blue">
                                Our vision is to help Everyone Generate Passive Income - that money
                                you earn without doing anything.
                            </h2>
                        </div>

                        <p class="space-top-10">
                            The World’s Wealthiest have invested in real estate for decades. Now
                            it’s your turn. With a click of your button, you can own a piece of
                            multiple properties. We design your portfolio to
                        </p>
                        <ul class="xs-list-item-icon-text xs-side-by-side-list">
                            <li>
                                <i class="fa fa-check color-green"></i>
                                Unlock access to investments.
                            </li>
                            <li>
                                <i class="fa fa-check color-green"></i>
                                Diversify your portfolio.
                            </li>
                            <li>
                                <i class="fa fa-check color-green"></i>
                                Protect your financial future.
                            </li>
                            <li><i class="fa fa-check color-green"></i> Get high returns.</li>
                        </ul>
                        <a routerLink="/contact" class="xs-btn round-btn navy-blue-btn"
                            >Get Started</a
                        >
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section
        class="xs-bg xs-content-section-padding fundpress-text-with-image-side-by-side-2"
        style="
            background-image: url(assets/website-assets/image/background/icons-background-1.png);
        "
    >
        <div class="container">
            <div class="row">
                <div class="col-md-12 col-lg-6">
                    <div class="fundpress-side-by-side-image-with-text padding-right-100">
                        <h5 class="color-green">Our Mission</h5>
                        <div class="xs-inner-title fundpress-inner-title">
                            <h2 class="color-navy-blue">
                                Making it Easy for Anyone to Build Wealth Through Real Estate
                                Investing
                            </h2>
                        </div>
                        <p>
                            At Property Collabo, we have created a 100% safe, fully-automated,
                            self-service platform where anyone can invest in real estate online. In
                            the rare cases you get stuck, our customer success team is always on the
                            line ready and willing to assist you
                        </p>
                        <a routerLink="/contact" class="xs-btn round-btn navy-blue-btn"
                            >Contact Us Now</a
                        >
                    </div>
                </div>
                <div class="col-md-12 col-lg-6">
                    <div class="xs-box-shadow-2">
                        <img
                            src="assets/website-assets/image/about/about-us-welcome.jpg"
                            alt=""
                            class="img-100"
                        />
                    </div>
                </div>
            </div>
        </div>
    </section>

    <div class="fundpress-simple-promo-text-content">
        <div class="container">
            <div class="row">
                <div class="col-md-10 col-sm-12 col-lg-9 content-center">
                    <div class="fundpress-title-text-content">
                        <h4 class="about-welcome-text">
                            We Deliver the best real estate investing experience and make it easy
                            for individual investors to diversify their portfolios.
                        </h4>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 col-sm-12 col-lg-6">
                    <div class="xs-text-content fundpress-text-content">
                        <p>
                            At Property Collabo, we are brought together by the shared vision of
                            transforming lives and families though real estate investing. We are
                            founded on the strong belief that everyone deserves access to the same
                            wealth building strategies that have been used to create and sustain
                            generational wealth.
                        </p>
                        <p>
                            <strong>
                                Join the Wealth Builders on Property Collabo
                            </strong>
                        </p>
                    </div>
                </div>
                <div class="col-md-12 col-sm-12 col-lg-6">
                    <div class="xs-text-content fundpress-text-content space-b-25">
                        <p>
                            We are all about empowering the everyday investor. With Property
                            Collabo, you can invest in real estate from as low as 50,000 naira.
                            Starting at the minimum investment of a property and zero management
                            fees, we make it possible for you to diversify your portfolio with one
                            of the most attractive - and historically one of the most profitable -
                            forms of investment: real estate.
                        </p>
                        <p>
                            When it comes to real estate, we have the best professionals in the
                            industry guiding us through everyday property management. Our trusted
                            team members know exactly how to reduce costs and risks to achieve the
                            best returns. We manage all properties with the utmost care, while we
                            identify new properties with high return on investment (ROI) potential.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>
<app-website-footer></app-website-footer>
