import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { PropertyPayload } from '@app/common/payload/property.payload';
import { PropertyService } from '@app/common/service/property.service';

@Component({
    selector: 'app-dashboard-property-details',
    templateUrl: './dashboard-property-details.component.html',
    styleUrls: ['./dashboard-property-details.component.css'],
})
export class DashboardPropertyDetailsComponent implements OnInit, OnDestroy {
    private subscription: Subscription = new Subscription();
    routeState: any;
    property: PropertyPayload;
    id: string;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private propertyService: PropertyService
    ) {
        if (this.router.getCurrentNavigation()?.extras?.state) {
            this.routeState = this.router.getCurrentNavigation().extras?.state;
            if (this.routeState) {
                this.property = this.routeState.data;
            }
        }
    }

    ngOnInit(): void {
        // const id = this.route.snapshot.paramMap.get('id');
        this.subscription.add(
            this.route.params.subscribe((params) => {
                this.id = params['id'];
            })
        );

        if (!this.property) {
            this.subscription.add(
                this.propertyService.onFindPropertyByUUD(this.id).subscribe((result) => {
                    if (result.status === 200) {
                        this.property = result.body;
                    }
                })
            );
        }
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}
