import { Component, OnDestroy, OnInit } from '@angular/core';
import { fadeOutDownOnLeaveAnimation, slideInRightOnEnterAnimation } from 'angular-animations';
import { ActivatedRoute, Router } from '@angular/router';
import { ImagePayload, PropertyPayload } from '@app/common/payload/property.payload';
import { Title } from '@angular/platform-browser';
import { environment } from '@environments/environment.prod';
import { Subscription } from 'rxjs';
import { PropertyService } from '@app/common/service/property.service';
import { ReturnsService } from '@app/common/service/returns.service';
import { AuthService } from '@app/common/service/auth.service';
import { InvestmentService } from '@app/common/service/investment.service';
import { ToastrService } from 'ngx-toastr';
import { InvestmentPayload } from '@app/common/payload/investment.payload';
import { InvestmentPaymentOptions, InvestmentStatus } from '@app/common/enum/investment.enums';
import { BankDetails } from '@app/common/payload/user';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-dashboard-property-invest-card',
    templateUrl: './dashboard-property-invest-card.component.html',
    styleUrls: ['./dashboard-property-invest-card.component.css'],
    animations: [slideInRightOnEnterAnimation(), fadeOutDownOnLeaveAnimation()],
})
export class DashboardPropertyInvestCardComponent implements OnInit, OnDestroy {
    imageObject: { image: string; thumbImage: string; alt: string }[] = [];
    mainImage: string = '';
    routeState: any;
    property: PropertyPayload;
    subscription: Subscription = new Subscription();
    propertyId: number;
    propertyUud: string;
    slide = {
        currentPosition: 'firstSlide',
        buttonText: 'Next',
        firstSlide: true,
        secondSlide: false,
        thirdSlide: false,
        isProcessing: false,
    };
    formInput = {
        amount: 0,
        paymentOption: InvestmentPaymentOptions.BANK_TRANSFER,
        hasError: false,
    };
    sharedPropertyImage: ImagePayload[];
    userId: number;
    canUserPayFromEarning: { isLoading: boolean; value: boolean } = {
        isLoading: true,
        value: false,
    };
    referenceNumber: string = undefined;
    bankDetails: BankDetails;
    investment: InvestmentPayload;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private titleService: Title,
        private propertyService: PropertyService,
        private returnsService: ReturnsService,
        private authService: AuthService,
        private investmentService: InvestmentService,
        private toast: ToastrService
    ) {
        if (this.router.getCurrentNavigation()?.extras?.state) {
            this.routeState = this.router.getCurrentNavigation().extras?.state;
            if (this.routeState) {
                this.onCheckDuplicate(this.routeState.data);
                this.property = this.routeState.data;
                this.onPrepareImages();
            }
        }
    }

    ngOnInit(): void {
        // set page/component title
        this.titleService.setTitle(`Invest in Property - ${environment.appName}`);

        // assign user id from auth service
        this.userId = this.authService.currentUserValue.id;

        // get property uud from route params
        this.subscription.add(
            this.route.params.subscribe((params) => {
                this.propertyUud = params['id'];
            })
        );

        //if property is not passed from route state, find property from api
        if (!this.property) {
            this.subscription.add(
                this.propertyService.onFindPropertyByUUD(this.propertyUud).subscribe((result) => {
                    if (result.status === 200) {
                        //check duplicate
                        this.onCheckDuplicate(result.body);
                        this.propertyId = result.body.id;
                        this.property = result.body;
                        this.onPrepareImages();
                    }
                })
            );
        }

        // image subject to get uploaded pop
        this.propertyService.imageSubject.subscribe((c) => {
            this.sharedPropertyImage = c;
        });

        //get user return count data to check for earning
        this.subscription.add(
            this.returnsService.getUserReturnCountDetails(this.userId).subscribe((result) => {
                if (result.status === 200) {
                    if (
                        Number(result.body.earningData.earning) > Number(this.property?.minAmount)
                    ) {
                        this.canUserPayFromEarning.value = true;
                        this.canUserPayFromEarning.isLoading = false;
                    } else {
                        this.canUserPayFromEarning.isLoading = false;
                        this.canUserPayFromEarning.value = false;
                    }
                }
            })
        );

        // get app bank details
        this.subscription.add(
            this.returnsService.onGetActivatedBankDetail().subscribe((result) => {
                if (result.status === 200) {
                    this.bankDetails = result.body;
                }
            })
        );
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
        this.imageObject = [];
    }

    imageClicked(event: number) {
        this.mainImage = this.imageObject[event].image;
    }

    onCheckDuplicate(body: PropertyPayload) {
        this.subscription.add(
            this.investmentService
                .onGetInvestmentByUser(this.authService.currentUserValue.id)
                .subscribe((result) => {
                    if (result.status === 200) {
                        if (this.investmentService.hasDuplicateInvestment(result.body, body)) {
                            this.toast.error('Duplicate Investment', 'Duplicate Error');
                            this.router.navigateByUrl('/app/investment').then();
                        }
                    }
                })
        );
    }

    onPrepareImages() {
        if (this.property.image[0].secure_url) {
            this.mainImage = this.property.image[0].secure_url;
        }

        this.property.image.forEach((im) => {
            if (im.secure_url) {
                let imageObj = { image: '', thumbImage: '', alt: '' };
                imageObj.image = im.secure_url;
                imageObj.thumbImage = im.secure_url;
                imageObj.alt = this.property.title;
                this.imageObject.push(imageObj);
            }
        });
    }

    onNextSlide() {
        if (this.slide.currentPosition === 'firstSlide') {
            return this.onProcessFirstSlide();
        }

        if (this.slide.currentPosition === 'secondSlide') {
            return this.onProcessSecondSlide();
        }

        if (this.slide.currentPosition === 'thirdSlide') {
            return this.onProcessThirdSlide();
        }
    }

    private onProcessFirstSlide() {
        //process inputmargin-right
        if (this.formInput.amount < this.property.minAmount) {
            this.formInput.hasError = true;
            this.toast.error('Amount is too small', ' Amount Error ');
            return;
        } else {
            this.slide.isProcessing = true;
            const investmentPayload = new InvestmentPayload();
            investmentPayload.amount = Number(this.formInput.amount);
            investmentPayload.propertyId = this.property.id;
            if (this.formInput.paymentOption === InvestmentPaymentOptions.BANK_TRANSFER) {
                investmentPayload.paymentMethod = InvestmentPaymentOptions.BANK_TRANSFER;
            } else {
                investmentPayload.paymentMethod = InvestmentPaymentOptions.PAY_FROM_EARNING;
            }
            investmentPayload.status = InvestmentStatus.OPEN;
            investmentPayload.userId = this.authService.currentUserValue.id;
            investmentPayload.property = new PropertyPayload(this.property.id);
            this.subscription.add(
                this.investmentService.onCreateInvestment(investmentPayload).subscribe(
                    (result) => {
                        if (result.status === 201) {
                            this.referenceNumber = result.body.reference;
                            this.investment = result.body;
                            this.slide.isProcessing = false;
                            //push next slide
                            this.slide.firstSlide = false;
                            this.slide.thirdSlide = false;
                            this.slide.buttonText = 'I have made payment';
                            this.slide.currentPosition = 'secondSlide';
                            setTimeout(() => {
                                this.slide.secondSlide = true;
                            }, 1000);
                            return;
                        }
                    },
                    (error) => {
                        this.slide.isProcessing = false;
                        this.toast.error(error.message, 'Try Again');
                        return;
                    }
                )
            );
        }
    }

    private onProcessSecondSlide() {
        this.slide.firstSlide = false;
        this.slide.secondSlide = false;
        this.slide.currentPosition = 'thirdSlide';
        this.slide.buttonText = 'Upload Proof of Payment';
        setTimeout(() => {
            this.slide.thirdSlide = true;
        }, 950);
        return;
    }

    private onProcessThirdSlide() {
        if (this.sharedPropertyImage.length < 1) {
            this.toast.error('Upload Your POP', 'No Image Uploaded');
            return;
        }

        const investmentToUploadPOP = new InvestmentPayload();
        investmentToUploadPOP.uploads = this.sharedPropertyImage;
        investmentToUploadPOP.id = this.investment.id;
        this.subscription.add(
            this.investmentService
                .onUpdateInvestment(investmentToUploadPOP, this.investment.id)
                .subscribe(
                    (updated) => {
                        this.toast.success('Proof of Payment Uploaded Successfully');
                        Swal.fire({
                            title: 'Payment Completed',
                            icon: 'success',
                            html:
                                ' <small> Your proof of payment was sent successfully <br> You will be notified once your payment is verified. </small>',
                        }).then();
                    },
                    (error) => {
                        this.toast.error(error.message, 'Upload Error');
                    }
                )
        );
        setTimeout(() => {
            this.router.navigateByUrl('/app/investment').then();
        }, 4000);
        return;
    }
}
