import {
    PropertyCategoryEnum,
    PropertyStatusEnum,
    PropertyTypeEnum,
} from '@app/common/enum/property.enums';
import { FileItem } from 'ng2-file-upload';
import { User } from '@app/common/payload/user';

export class PropertyPayload {
    id?: number;
    title?: string;
    description?: string;
    price?: number;
    address?: string;
    location?: string;
    duration?: number;
    returnOfInvestment?: number;
    type?: PropertyTypeEnum;
    category?: PropertyCategoryEnum;
    status?: PropertyStatusEnum;
    minAmount?: number;
    maxAmount?: number;
    target?: number;
    closeDate?: Date;
    image?: ImagePayload[];
    investments?: any[];
    donation?: number;
    others?: PropertyOthers;
    user?: User;
    uud?: string;

    constructor(id?: number) {
        this.id = id;
    }
}

export interface ImagePayload {
    public_id?: string;
    secure_url?: any;
    fileName?: string;
    status?: number;
    uploadedUrl?: string;
    fileItem?: FileItem;
}

export interface PropertyOthers {
    features?: { icon?: string; text?: string }[];
    display?: boolean;
    section?: PropertyDisplaySection;
}

export enum PropertyDisplaySection {
    ANY_SECTION = 'ANY SECTION',
    WELCOME_SECTION = 'WELCOME SECTION',
    FEATURE_SECTION = 'FEATURE SECTION',
    PROPERTY_SECTION = 'PROPERTY SECTION',
}
