import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthPayload } from '@app/common/payload/auth.payload';
import { Subscription } from 'rxjs';
import { environment } from '@environments/environment.prod';
import { AuthService } from '@app/common/service/auth.service';
import { ToastrService } from 'ngx-toastr';
import { Title } from '@angular/platform-browser';

@Component({
    selector: 'app-register-two',
    templateUrl: './register-two.component.html',
    styleUrls: ['./register-two.component.css'],
})
export class RegisterTwoComponent implements OnInit, OnDestroy {
    registerForm: FormGroup;
    isSubmitted = false;
    isLoading = false;
    authPayload: AuthPayload;
    subscription = new Subscription();
    isErrors = false;
    error: string;
    messages: string[] = [];
    isCaptchaChecked = false;
    isCaptchaLoaded = false;
    siteKey: string = environment.gr_site_key;
    isSuccess: boolean = false;
    recaptcha: string = '';

    constructor(
        private authService: AuthService,
        private toast: ToastrService,
        private title: Title
    ) {}

    ngOnInit() {
        this.title.setTitle(`Sign Up - ${environment.appName}`);
        this.registerForm = new FormGroup(
            {
                firstName: new FormControl('', [Validators.required]),
                lastName: new FormControl('', [Validators.required]),
                email: new FormControl('', [Validators.required]),
                phone: new FormControl('', [Validators.required]),
                password: new FormControl('', [Validators.required, Validators.min(4)]),
            },
            { updateOn: 'blur' }
        );
        this.authPayload = {
            firstName: '',
            lastName: '',
            email: '',
            password: '',
            phone: '',
        };
    }

    get rf() {
        return this.registerForm.controls;
    }

    onRegister() {
        this.isSubmitted = true;
        if (this.registerForm.invalid) {
            return;
        }
        this.isLoading = true;
        this.authPayload.firstName = this.registerForm.get('firstName').value;
        this.authPayload.lastName = this.registerForm.get('lastName').value;
        this.authPayload.email = this.registerForm.get('email').value;
        this.authPayload.phone = this.registerForm.get('phone').value;
        this.authPayload.password = this.registerForm.get('password').value;

        this.subscription = this.authService.signup(this.authPayload).subscribe(
            (result) => {
                if (result.status === 201) {
                    this.isErrors = false;
                    this.isLoading = false;
                    this.isSubmitted = false;
                    this.messages = [];
                    this.isSuccess = true;
                    this.toast.success(
                        'Check your email for activation link',
                        'Registration Successful',
                        {
                            closeButton: true,
                            timeOut: 36000,
                        }
                    );
                }
            },
            (error) => {
                this.clearErrorMessages();
                Array.isArray(error.message)
                    ? (this.messages = error.message)
                    : (this.error = error.message);
                this.isErrors = true;
                this.isSubmitted = false;
                this.isLoading = false;
                this.isSuccess = false;
            }
        );
    }

    clearErrorMessages() {
        this.error = null;
        this.messages = [];
        this.isErrors = false;
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    onCaptchaHandleExpire() {
        this.isCaptchaChecked = false;
    }

    onCaptchaHandleSuccess($event: any) {
        this.isCaptchaChecked = true;
    }

    onCaptchaHandleLoad() {
        this.isCaptchaLoaded = true;
    }
}
