<!-- intro/cause section -->
<section class="xs-section-padding prop-cause-matters-v2" data-scrollax-parent="true">
    <div class="container">
        <div class="prop-heading-title-v3 text-center wow fadeInUp" data-wow-duration="1s">
            <div class="prop-heading-title-content">
                <h2>Growing Wealth is <span>Easier </span> than you think</h2>
                <p>
                    Real estate has and will continue to remain an extremely valuable asset, and
                    this is why it is considered one of the most secure investments in the journey
                    to building generational wealth. We help you to multiply your investments and
                    grow your portfolio without the steep entrance fees and legal paperwork that is
                    typical with real estate investments.
                </p>
            </div>
            <span class="parallax-title" data-scrollax="properties: { translateY: '-250px' }"
                >Easy</span
            >
        </div>
        <!-- section heading -->
        <div class="row">
            <div class="col-md-4">
                <div class="prop-icon-with-square-service-v2 wow fadeInUp" data-wow-duration="1.2s">
                    <!--                    <i class="icon icon-cogwheel-outline xs-check-mark"></i>-->
                    <!--                    <i class="icon fa fa-cog  xs-check-mark"></i>-->
                    <i class="icon icon-symbol xs-check-mark"></i>

                    <h4>Secure Investment</h4>
                    <p>
                        Real estate is one of the safest investments. It is resistant to inflation
                        and creates additional income. What’s more, it is tangible! You can actually
                        see what and where your money is going into
                    </p>
                </div>
                <!-- .prop-icon-with-square-service-v2 END -->
            </div>
            <div class="col-md-4">
                <div class="prop-icon-with-square-service-v2 wow fadeInUp" data-wow-duration="1.4s">
                    <i class="icon icon-agenda xs-check-mark"></i>
                    <h4>
                        Transparent Platform
                    </h4>
                    <p>
                        Our platform is secure and transparent. Easily Access all the necessary
                        information about the properties and contact our support team if you need
                        any additional information.
                    </p>
                </div>
                <!-- .prop-icon-with-square-service-v2 END -->
            </div>
            <div class="col-md-4">
                <div class="prop-icon-with-square-service-v2 wow fadeInUp" data-wow-duration="1.6s">
                    <i class="icon icon-calculator xs-check-mark"></i>
                    <h4>Detailed Records Management</h4>
                    <p>
                        We show you everything from the date you last invested to when you made
                        withdrawals to ensure that you remain in control of your portfolio.
                    </p>
                </div>
                <!-- .prop-icon-with-square-service-v2 END -->
            </div>
            <div class="col-md-4">
                <div class="prop-icon-with-square-service-v2 wow fadeInUp" data-wow-duration="1.8s">
                    <i class="icon icon-chart-bars xs-check-mark"></i>
                    <h4>Easy to Use Dashboard</h4>
                    <p>
                        It is incredibly easy to manage your investments, funds and withdrawals, all
                        from the same place. You can clearly see the properties you’ve invested in,
                        account balance, withdrawals, and opportunities to make new investments.
                    </p>
                </div>
                <!-- .prop-icon-with-square-service-v2 END -->
            </div>
            <div class="col-md-4">
                <div class="prop-icon-with-square-service-v2 wow fadeInUp" data-wow-duration="2s">
                    <i class="icon icon-money-3 xs-check-mark"></i>
                    <h4>Cash Out</h4>
                    <p>
                        You can start to earn from your investment on any given property after the
                        duration period and the capital plus earned dividend will be paid into your
                        account when you withdraw.
                    </p>
                </div>
                <!-- .prop-icon-with-square-service-v2 END -->
            </div>
            <div class="col-md-4">
                <div class="prop-icon-with-square-service-v2 wow fadeInUp" data-wow-duration="2.2s">
                    <i class="icon icon-support xs-check-mark"></i>
                    <h4>24/7 Online Support</h4>
                    <p>
                        We are always available to help you, if you have any question or you need
                        any technical help our technical supports staffs are always ready to have a
                        chat with you.
                    </p>
                </div>
                <!-- .prop-icon-with-square-service-v2 END -->
            </div>
        </div>
    </div>
</section>
