import { Component, OnDestroy, OnInit } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Subscription } from 'rxjs';
import { WithdrawPayload } from '@app/common/payload/common.payload';
import { Title } from '@angular/platform-browser';
import { environment } from '@environments/environment.prod';
import { ReturnsService } from '@app/common/service/returns.service';

@Component({
    selector: 'app-dashboard-admin-return',
    templateUrl: './dashboard-admin-return.component.html',
    styleUrls: ['./dashboard-admin-return.component.css'],
    animations: [
        trigger('simpleFadeAnimation', [
            state('in', style({ opacity: 1 })),
            transition(':enter', [style({ opacity: 0 }), animate(600)]),
            transition(':leave', animate(600, style({ opacity: 0 }))),
        ]),
    ],
})
export class DashboardAdminReturnComponent implements OnInit, OnDestroy {
    withdraws: WithdrawPayload[];
    pageSize = 6;
    collectionSize;
    pageOfItems: WithdrawPayload[] = [];
    items: WithdrawPayload[] = [];

    subscription: Subscription = new Subscription();

    constructor(private titleService: Title, private returnsService: ReturnsService) {}

    ngOnInit(): void {
        this.titleService.setTitle(`Manager Investor's Return - ${environment.appName}`);
        this.subscription.add(
            this.returnsService.getAllWithdraws().subscribe((res) => {
                if (res.status === 200) {
                    this.withdraws = res.body;
                    this.collectionSize = res.body.length;
                    this.items = res.body;
                }
            })
        );
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    onChangePage(pageOfItems: Array<any>) {
        this.pageOfItems = pageOfItems;
    }
}
