<!--<app-dashboard-breadcrumbs [crumbs]="crumbs"></app-dashboard-breadcrumbs>-->

<ng-container *ngIf="userDetails?.isAdmin; else userDashboard">
    <div class="content-i">
        <div class="content-box">
            <app-admin-dashboard></app-admin-dashboard>
        </div>
        <!-- right side panel -->
        <div class="content-panel">
            <app-admin-dashboard-right></app-admin-dashboard-right>
        </div>
    </div>
</ng-container>

<ng-template #userDashboard>
    <div class="content-i">
        <div class="content-box">
            <app-user-dashboard></app-user-dashboard>
        </div>
        <!-- right side panel -->
        <div class="content-panel">
            <app-user-dashboard-right></app-user-dashboard-right>
        </div>
    </div>
</ng-template>
