<main class="xs-all-content-wrapper">
    <section class="xs-content-section-padding xs-faq-section prop-faq-section">
        <div class="container">
            <div class="xs-isotope-grid-wraper">
                <div class="xs-isotope-nav prop-isotope-nav">
                    <ul id="filters" class="option-set clearfix">
                        <li>
                            <a #btnAll class="selected point" (click)="onToggleTab('all')">All</a>
                        </li>
                        <li>
                            <a #btnGeneral class="point" (click)="onToggleTab('general')"
                                >General</a
                            >
                        </li>
                        <li>
                            <a #btnAccount class="point" (click)="onToggleTab('account')"
                                >Account</a
                            >
                        </li>
                        <li>
                            <a #btnInvestment class="point" (click)="onToggleTab('investment')"
                                >Investment</a
                            >
                        </li>
                    </ul>
                </div>
                <div class="xs-col-1-isotope-grid">
                    <div
                        [@fadeIn]="'in'"
                        *ngIf="tabs.general || tabs.all"
                        class="xs-col-1-isotope-grid-item f-center"
                    >
                        <div class="prop-grid-item-content row xs-marign-0">
                            <div class="prop-faq-wraper col-md-12">
                                <div class="props-ingle-faq">
                                    <h4 class="color-navy-blue clearfix">
                                        <span class="xs-firstcharacter color-navy-blue">Q.</span>
                                        What is Property Collabo?
                                    </h4>
                                    <p class="clearfix text-justify">
                                        <span class="xs-firstcharacter color-green">A.</span>
                                        Property Collabo is a real estate crowdfunding platform that
                                        allows users to invest in multiple properties with a low
                                        amount.
                                        <br />
                                        Our Platform allows you to invest into properties in smaller
                                        amounts than it would be required,
                                        <br />
                                        if you would be a sole investor and start benefiting from
                                        your investments immediately.
                                        <br />You also have a better opportunity to diversify your
                                        investments between different properties and keep your
                                        investments safe.
                                    </p>
                                    <span
                                        class="xs-separetor border-color-green border-separetor xs-separetor-v2 prop-separetor"
                                    ></span>
                                </div>

                                <div class="props-ingle-faq">
                                    <h4 class="color-navy-blue clearfix">
                                        <span class="xs-firstcharacter color-navy-blue">Q.</span>
                                        Crowdfunding - What Does It Mean?
                                    </h4>
                                    <p class="clearfix text-justify">
                                        <span class="xs-firstcharacter color-green">A.</span>
                                        Crowdfunding means coming together with other people to
                                        fundraise money for a particular project.
                                        <br />
                                        At Property Collabo, we understand that not everyone can
                                        afford to invest 1 billion naira for a particular property.
                                        <br />So, we make that property available for several others
                                        to invest in. <br />You can invest 100,000 naira in the
                                        property while another person invests 50,000 naira and so on
                                        till the 1 billion cap is reached.
                                    </p>
                                    <span
                                        class="xs-separetor border-color-green border-separetor xs-separetor-v2 prop-separetor"
                                    ></span>
                                </div>

                                <div class="props-ingle-faq">
                                    <h4 class="color-navy-blue clearfix">
                                        <span class="xs-firstcharacter color-navy-blue">Q.</span>
                                        Is my investment affected by other investors in any way?
                                    </h4>
                                    <p class="clearfix text-justify">
                                        <span class="xs-firstcharacter color-green">A.</span>
                                        The investment of others does not affect yours. <br />The
                                        minute you make your investment, you start generating
                                        earnings. <br />And at the scheduled repayment time, you can
                                        withdraw all your funds, without any fault.
                                    </p>
                                    <span
                                        class="xs-separetor border-color-green border-separetor xs-separetor-v2 prop-separetor"
                                    ></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--                  -->
                    <div
                        [@fadeIn]="'in'"
                        *ngIf="tabs.account || tabs.all"
                        class="xs-col-1-isotope-grid-item f-center"
                    >
                        <div class="prop-grid-item-content row xs-marign-0 faq-a">
                            <div class="prop-faq-wraper col-md-12">
                                <div class="props-ingle-faq">
                                    <h4 class="color-navy-blue clearfix">
                                        <span class="xs-firstcharacter color-navy-blue">Q.</span>
                                        How can I invest a property on Property Collabo?
                                    </h4>
                                    <p class="clearfix text-justify">
                                        <span class="xs-firstcharacter color-green">A.</span>
                                        To invest in a property, find properties with high returns
                                        from your dashboard, Click on Invest in Property, follow the
                                        easy steps on how to invest provided, make your payment and
                                        after making payment, upload a proof of payment. This is
                                        confirmed immediately and your investment approved.
                                    </p>
                                    <span
                                        class="xs-separetor border-color-green border-separetor xs-separetor-v2 prop-separetor"
                                    ></span>
                                </div>

                                <div class="props-ingle-faq">
                                    <h4 class="color-navy-blue clearfix">
                                        <span class="xs-firstcharacter color-navy-blue">Q.</span>
                                        How do I withdraw money from my Property Collabo account?
                                    </h4>
                                    <p class="clearfix text-justify">
                                        <span class="xs-firstcharacter color-green">A.</span> You
                                        can withdraw your earnings at any time. To withdraw from
                                        your account, choose an option “Withdraw Funds” and set the
                                        amount of money to transfer to your bank account. Once you
                                        complete the withdrawal steps, we will receive your request,
                                        and immediately process the money to arrive in your account.
                                    </p>
                                    <span
                                        class="xs-separetor border-color-green border-separetor xs-separetor-v2 prop-separetor"
                                    ></span>
                                </div>

                                <div class="props-ingle-faq">
                                    <h4 class="color-navy-blue clearfix">
                                        <span class="xs-firstcharacter color-navy-blue">Q.</span>
                                        Where can I find my existing investments?
                                    </h4>
                                    <p class="clearfix text-justify">
                                        <span class="xs-firstcharacter color-green">A.</span> You
                                        You can keep an eye on your investments in your Property
                                        Collabo account dashboard. Your account displays all
                                        pending/on-going investments under the section "My
                                        Investments".
                                    </p>
                                    <span
                                        class="xs-separetor border-color-green border-separetor xs-separetor-v2 prop-separetor"
                                    ></span>
                                </div>

                                <div class="props-ingle-faq">
                                    <h4 class="color-navy-blue clearfix">
                                        <span class="xs-firstcharacter color-navy-blue">Q.</span>
                                        How can I view my full transaction history?
                                    </h4>
                                    <p class="clearfix text-justify">
                                        <span class="xs-firstcharacter color-green">A.</span> To see
                                        past transactions, visit the "Transactions" section in your
                                        user account.
                                    </p>
                                    <span
                                        class="xs-separetor border-color-green border-separetor xs-separetor-v2 prop-separetor"
                                    ></span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div
                        [@fadeIn]="'in'"
                        *ngIf="tabs.investment || tabs.all"
                        class="xs-col-1-isotope-grid-item f-center"
                    >
                        <div class="prop-grid-item-content row xs-marign-0 faq-a">
                            <div class="prop-faq-wraper col-md-12">
                                <div class="props-ingle-faq">
                                    <h4 class="color-navy-blue clearfix">
                                        <span class="xs-firstcharacter color-navy-blue">Q.</span>
                                        Who can invest?
                                    </h4>
                                    <p class="clearfix text-justify">
                                        <span class="xs-firstcharacter color-green">A.</span>
                                        The Everyday Investor (
                                        <a routerLink="/about" class="text-primary"
                                            >see about section</a
                                        >
                                        ) Any person with a valid identification card (Driver’s
                                        License, Passport ID, Voter’s Card) can invest in any of the
                                        active investment opportunities on the Property Collabo
                                        platform. Once verified, we send you a notification email
                                        regarding successful verification and you can see it on your
                                        dashboard under the “Profile” section.
                                    </p>
                                    <span
                                        class="xs-separetor border-color-green border-separetor xs-separetor-v2 prop-separetor"
                                    ></span>
                                </div>

                                <div class="props-ingle-faq">
                                    <h4 class="color-navy-blue clearfix">
                                        <span class="xs-firstcharacter color-navy-blue">Q.</span>
                                        What is the minimum investment amount?
                                    </h4>
                                    <p class="clearfix text-justify">
                                        <span class="xs-firstcharacter color-green">A.</span>
                                        The minimum investment varies from property to property.
                                        Check out the list of properties
                                        <a routerLink="/app/properties" class="text-primary">here</a
                                        >.
                                        <br />
                                        The minimum investment for Property A could be 50,000 naira
                                        while that for Property B could be 70,000 naira.
                                    </p>
                                    <span
                                        class="xs-separetor border-color-green border-separetor xs-separetor-v2 prop-separetor"
                                    ></span>
                                </div>

                                <div class="props-ingle-faq">
                                    <h4 class="color-navy-blue clearfix">
                                        <span class="xs-firstcharacter color-navy-blue">Q.</span>
                                        What is the maximum investment amount?
                                    </h4>
                                    <p class="clearfix text-justify">
                                        <span class="xs-firstcharacter color-green">A.</span>
                                        You The maximum investment varies from property to property.
                                        Check out the list of properties
                                        <a routerLink="/app/properties" class="text-primary">here</a
                                        >. <br />
                                        The maximum investment for Property A could be one million
                                        naira while that for Property B could be 500,000 naira.
                                    </p>
                                    <span
                                        class="xs-separetor border-color-green border-separetor xs-separetor-v2 prop-separetor"
                                    ></span>
                                </div>

                                <div class="props-ingle-faq">
                                    <h4 class="color-navy-blue clearfix">
                                        <span class="xs-firstcharacter color-navy-blue">Q.</span>
                                        Is there a limit on the number of properties you can invest
                                        in?
                                    </h4>
                                    <p class="clearfix text-justify">
                                        <span class="xs-firstcharacter color-green">A.</span>
                                        You Not at all. You can invest in as many properties as you
                                        want. The more, the merrier.
                                    </p>
                                    <span
                                        class="xs-separetor border-color-green border-separetor xs-separetor-v2 prop-separetor"
                                    ></span>
                                </div>

                                <div class="props-ingle-faq">
                                    <h4 class="color-navy-blue clearfix">
                                        <span class="xs-firstcharacter color-navy-blue">Q.</span>
                                        Is it possible to invest more than once in a particular
                                        investment object?
                                    </h4>
                                    <p class="clearfix text-justify">
                                        <span class="xs-firstcharacter color-green">A.</span>
                                        You It is only one investment per property. While you can
                                        invest in 100 properties at a time, you can’t invest more
                                        than once in a single property, However, you can upgrade
                                        your investment amount on a property while the property is
                                        still open for investment
                                    </p>
                                    <span
                                        class="xs-separetor border-color-green border-separetor xs-separetor-v2 prop-separetor"
                                    ></span>
                                </div>

                                <div class="props-ingle-faq">
                                    <h4 class="color-navy-blue clearfix">
                                        <span class="xs-firstcharacter color-navy-blue">Q.</span>
                                        Is it possible to withdraw the money before the end of the
                                        investment period?
                                    </h4>
                                    <p class="clearfix text-justify">
                                        <span class="xs-firstcharacter color-green">A.</span>
                                        You can withdraw your earnings on a particular investment or
                                        reinvest your earnings into other properties before the
                                        scheduled repayment date. But, we do not offer the
                                        possibility to withdraw the invested money before the
                                        scheduled repayment date.
                                    </p>
                                    <span
                                        class="xs-separetor border-color-green border-separetor xs-separetor-v2 prop-separetor"
                                    ></span>
                                </div>

                                <div class="props-ingle-faq">
                                    <h4 class="color-navy-blue clearfix">
                                        <span class="xs-firstcharacter color-navy-blue">Q.</span>
                                        Is it possible to withdraw the money before the end of the
                                        investment period?
                                    </h4>
                                    <p class="clearfix text-justify">
                                        <span class="xs-firstcharacter color-green">A.</span>
                                        You can withdraw your earnings on a particular investment or
                                        reinvest your earnings into other properties before the
                                        scheduled repayment date. But, we do not offer the
                                        possibility to withdraw the invested money before the
                                        scheduled repayment date.
                                    </p>
                                    <span
                                        class="xs-separetor border-color-green border-separetor xs-separetor-v2 prop-separetor"
                                    ></span>
                                </div>

                                <div class="props-ingle-faq">
                                    <h4 class="color-navy-blue clearfix">
                                        <span class="xs-firstcharacter color-navy-blue">Q.</span>
                                        What are the guarantees for investing?
                                    </h4>
                                    <p class="clearfix text-justify">
                                        <span class="xs-firstcharacter color-green">A.</span>
                                        All investment projects are carefully selected before being
                                        offered to our investors. Every Property Collabo deal goes
                                        through a rigorous and consistent evaluation process by the
                                        Investment Committee, which includes highly experienced real
                                        estate, investment and finance experts.
                                    </p>
                                    <span
                                        class="xs-separetor border-color-green border-separetor xs-separetor-v2 prop-separetor"
                                    ></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="xs-btn-wraper text-center">
                <a routerLink="/contact" class="xs-btn round-btn navy-blue-btn">More Questions ?</a>
            </div>
        </div>
    </section>
</main>
