<div (click)="closeRightContent()" class="content-panel-close">
    <i class="os-icon os-icon-close text-danger"></i>
</div>
<!--quick links-->
<div class="element-wrapper">
    <h6 class="element-header">Quick Links</h6>
    <div class="element-box-tp">
        <div class="el-buttons-list full-width">
            <a class="btn btn-white btn-sm" routerLink="/app/investment"
                ><i class="os-icon os-icon-home"></i><span>My Investments</span></a
            >
            <a class="btn btn-white btn-sm" routerLink="/app/properties"
                ><i class="os-icon os-icon-wallet-loaded"></i><span>Properties</span></a
            >
            <a class="btn btn-white btn-sm" routerLink="/app/profile"
                ><i class="os-icon os-icon-user-check"></i><span> Update Profile&nbsp;</span></a
            >
        </div>
    </div>
</div>

<!--Real Estate Agents-->
<!--<div class="element-wrapper">-->
<!--    <h6 class="element-header">Lead Agents</h6>-->
<!--    <div class="element-box-tp">-->
<!--        <div class="profile-tile">-->
<!--            <a class="profile-tile-box">-->
<!--                <div class="pt-avatar-w">-->
<!--                    <img alt="" src="/assets/website-assets/image/avatar/avatar_3.jpg" />-->
<!--                </div>-->
<!--                <div class="pt-user-name">John Tobe</div>-->
<!--            </a>-->
<!--            <div class="profile-tile-meta">-->
<!--                <ul>-->
<!--                    <li>Last Login:<strong>Online Now</strong></li>-->
<!--                    <li>-->
<!--                        Properties:<strong><a>12</a></strong>-->
<!--                    </li>-->
<!--                    <li>Response Time:<strong>2 hours</strong></li>-->
<!--                </ul>-->
<!--                <div class="pt-btn">-->
<!--                    <a class="btn btn-success btn-sm">Send Message</a>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
<!--    </div>-->
<!--</div>-->
<!--co investors-->
<!--<div class="element-wrapper">-->
<!--    <h6 class="element-header">Co-Investors</h6>-->
<!--    <div class="element-box-tp">-->
<!--        &lt;!&ndash;        <ng-template *ngIf="coInvestors; else noData">&ndash;&gt;-->
<!--        &lt;!&ndash;            <div class="users-list-w">&ndash;&gt;-->
<!--        &lt;!&ndash;                <ng-container *ngFor="let result of coInvestors">&ndash;&gt;-->
<!--        &lt;!&ndash;                    <div&ndash;&gt;-->
<!--        &lt;!&ndash;                        class="user-w with-status status-green"&ndash;&gt;-->
<!--        &lt;!&ndash;                        *ngFor="let data of result.investors; let i = index"&ndash;&gt;-->
<!--        &lt;!&ndash;                    >&ndash;&gt;-->
<!--        &lt;!&ndash;                        <div class="user-avatar-w">&ndash;&gt;-->
<!--        &lt;!&ndash;                            <div class="user-avatar">&ndash;&gt;-->
<!--        &lt;!&ndash;                                <img alt="Investor Picture" [src]="data.image.secure_url" />&ndash;&gt;-->
<!--        &lt;!&ndash;                            </div>&ndash;&gt;-->
<!--        &lt;!&ndash;                        </div>&ndash;&gt;-->
<!--        &lt;!&ndash;                        <div class="user-name">&ndash;&gt;-->
<!--        &lt;!&ndash;                            <h6 class="user-title">{{ data.firstName }} {{ data.lastName }}</h6>&ndash;&gt;-->
<!--        &lt;!&ndash;                            <div class="user-role">{{ data.designation }}</div>&ndash;&gt;-->
<!--        &lt;!&ndash;                        </div>&ndash;&gt;-->
<!--        &lt;!&ndash;                        <a class="user-action point text-primary"&ndash;&gt;-->
<!--        &lt;!&ndash;                            ><div class="os-icon os-icon-phone-outgoing"></div&ndash;&gt;-->
<!--        &lt;!&ndash;                        ></a>&ndash;&gt;-->
<!--        &lt;!&ndash;                    </div>&ndash;&gt;-->
<!--        &lt;!&ndash;                </ng-container>&ndash;&gt;-->
<!--        &lt;!&ndash;            </div>&ndash;&gt;-->
<!--        &lt;!&ndash;        </ng-template>&ndash;&gt;-->

<!--        &lt;!&ndash;        <ng-template #noData>&ndash;&gt;-->
<!--        &lt;!&ndash;            <div class="users-list-w">&ndash;&gt;-->
<!--        &lt;!&ndash;                <small>You have no co-investors yet</small>&ndash;&gt;-->
<!--        &lt;!&ndash;            </div>&ndash;&gt;-->
<!--        &lt;!&ndash;        </ng-template>&ndash;&gt;-->
<!--    </div>-->
<!--</div>-->
