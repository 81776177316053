<ng-container *ngIf="props?.type !== 'white'; else logoWhite">
    <a routerLink="/app">
        <img class="az-logo" src="assets/shared/images/logo.png" alt="" width="50%" height="50%" />
    </a>
</ng-container>

<ng-template #logoWhite>
    <a routerLink="">
        <img
            class="az-logo"
            src="assets/shared/images/logo-white.png"
            alt=""
            width="50%"
            height="50%"
        />
    </a>
</ng-template>
