<div class="logged-user-w avatar-inline">
    <div class="logged-user-i">
        <div class="avatar-w">
            <app-user-profile-avatar></app-user-profile-avatar>
        </div>
        <div class="logged-user-info-w">
            <div class="logged-user-name">{{ user.firstName }} {{ user.lastName }}</div>
            <div class="logged-user-role">{{ user.isAdmin ? ' Administrator' : 'Investor' }}</div>
        </div>
        <div class="logged-user-toggler-arrow">
            <div class="os-icon os-icon-chevron-down"></div>
        </div>
        <app-dashboard-usercard-menu></app-dashboard-usercard-menu>
    </div>
</div>
