import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardRoutingModule } from '@app/dashboard/dashboard-routing.module';
import { DashboardPagesModule } from '@app/dashboard/dashboard-pages/dashboard-pages.module';

@NgModule({
    providers: [],
    declarations: [],
    imports: [CommonModule, DashboardRoutingModule, DashboardPagesModule],
})
export class DashboardModule {}
