import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { WebsiteModule } from './website/website.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthService } from '@app/common/service/auth.service';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { JwtInterceptor } from '@app/common/interceptors/jwt.interceptor';
import { AuthGuard } from '@app/common/guard/auth.guard';
import { AdminGuard } from '@app/common/guard/admin.guard';
import { ErrorInterceptor } from '@app/common/interceptors/error.interceptor';
import { AppCommonModule } from '@app/common/common.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CloudinaryConfiguration, CloudinaryModule } from '@cloudinary/angular-5.x';
import { Cloudinary } from 'cloudinary-core/cloudinary-core-shrinkwrap';
import { environment } from '@environments/environment.prod';
import { FileUploadModule } from 'ng2-file-upload';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ToastrModule } from 'ngx-toastr';
import { S1LottieModule } from '@sentinel-one/s1-lottie';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { JwPaginationModule } from 'jw-angular-pagination';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { MenuService } from '@app/common/service/menu.service';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { NgImageSliderModule } from 'ng-image-slider';
import { ClipboardModule } from 'ngx-clipboard';
import { UploaderService } from '@app/common/service/uploader.service';
import { ChartsModule } from 'ng2-charts';
import { NgxCaptchaModule } from 'ngx-captcha';
import { SettingService } from '@app/common/service/setting.service';
import { NgcCookieConsentModule } from 'ngx-cookieconsent';
import { cookieConfig } from '@app/common/payload/cookie';

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        ToastrModule.forRoot({
            timeOut: 10000,
            positionClass: 'toast-bottom-right',
            preventDuplicates: true,
            progressBar: true,
            closeButton: true,
        }),
        ReactiveFormsModule,
        NgxCaptchaModule,
        FormsModule,
        WebsiteModule,
        DashboardModule,
        AppCommonModule,
        NgxWebstorageModule.forRoot(),
        AppRoutingModule,
        NgbModule,
        FileUploadModule,
        CloudinaryModule.forRoot({ Cloudinary }, {
            cloud_name: environment.cloud_name,
            upload_preset: environment.upload_preset,
        } as CloudinaryConfiguration),
        NgxSpinnerModule,
        S1LottieModule,
        CarouselModule,
        JwPaginationModule,
        SweetAlert2Module.forRoot(),
        LazyLoadImageModule,
        NgCircleProgressModule.forRoot({
            // set defaults here
            radius: 100,
            outerStrokeWidth: 6,
            innerStrokeWidth: 2,
            outerStrokeColor: '#1643a3',
            innerStrokeColor: '#1643a3',
            animationDuration: 300,
        }),
        NgImageSliderModule,
        ClipboardModule,
        ChartsModule,
        NgcCookieConsentModule.forRoot(cookieConfig),
    ],
    providers: [
        AuthService,
        AuthGuard,
        AdminGuard,
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        Title,
        MenuService,
        UploaderService,
        SettingService,
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
