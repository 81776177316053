<div class="content-i">
    <div class="content-box">
        <div class="element-wrapper">
            <div class="element-box">
                <h5 class="form-header">App Icons</h5>
                <div class="form-desc text-primary">Click on any of the icon to copy</div>
                <ul class="demo-icons-list">
                    <li>
                        <a class="point" (click)="copy('fa fa-glass')"
                            ><i class="fa fa-glass"></i><span>fa fa-glass</span></a
                        >
                    </li>
                    <li>
                        <a class="point" (click)="copy('fa fa-music')"
                            ><i class="fa fa-music"></i><span>fa fa-music</span></a
                        >
                    </li>
                    <li>
                        <a class="point" (click)="copy('fa fa-search')"
                            ><i class="fa fa-search"></i><span>fa fa-search</span></a
                        >
                    </li>
                    <li>
                        <a class="point" (click)="copy('fa fa-envelope-o')"
                            ><i class="fa fa-envelope-o"></i><span>fa fa-envelope-o</span></a
                        >
                    </li>
                    <li>
                        <a class="point" (click)="copy('fa fa-heart')"
                            ><i class="fa fa-heart"></i><span>fa fa-heart</span></a
                        >
                    </li>
                    <li>
                        <a class="point" (click)="copy('fa fa-star')"
                            ><i class="fa fa-star"></i><span>fa fa-star</span></a
                        >
                    </li>
                    <li>
                        <a class="point" (click)="copy('fa fa-star-o')"
                            ><i class="fa fa-star-o"></i><span>fa fa-star-o</span></a
                        >
                    </li>
                    <li>
                        <a class="point" (click)="copy('fa fa-user')"
                            ><i class="fa fa-user"></i><span>fa fa-user</span></a
                        >
                    </li>
                    <li>
                        <a class="point" (click)="copy('fa fa-film')"
                            ><i class="fa fa-film"></i><span>fa fa-film</span></a
                        >
                    </li>
                    <li>
                        <a class="point" (click)="copy('fa fa-th-large')"
                            ><i class="fa fa-th-large"></i><span>fa fa-th-large</span></a
                        >
                    </li>
                    <li>
                        <a class="point" (click)="copy('fa fa-th')"
                            ><i class="fa fa-th"></i><span>fa fa-th</span></a
                        >
                    </li>
                    <li>
                        <a class="point" (click)="copy('fa fa-th-list')"
                            ><i class="fa fa-th-list"></i><span>fa fa-th-list</span></a
                        >
                    </li>
                    <li>
                        <a class="point" (click)="copy('fa fa-check')"
                            ><i class="fa fa-check"></i><span>fa fa-check</span></a
                        >
                    </li>
                    <li>
                        <a class="point" (click)="copy('fa fa-times')"
                            ><i class="fa fa-times"></i><span>fa fa-times</span></a
                        >
                    </li>
                    <li>
                        <a class="point" (click)="copy('fa fa-search-plus')"
                            ><i class="fa fa-search-plus"></i><span>fa fa-search-plus</span></a
                        >
                    </li>
                    <li>
                        <a class="point" (click)="copy('fa fa-search-minus')"
                            ><i class="fa fa-search-minus"></i><span>fa fa-search-minus</span></a
                        >
                    </li>
                    <li>
                        <a class="point" (click)="copy('fa fa-power-off')"
                            ><i class="fa fa-power-off"></i><span>fa fa-power-off</span></a
                        >
                    </li>
                    <li>
                        <a class="point" (click)="copy('fa fa-signal')"
                            ><i class="fa fa-signal"></i><span>fa fa-signal</span></a
                        >
                    </li>
                    <li>
                        <a class="point" (click)="copy('fa fa-cog')"
                            ><i class="fa fa-cog"></i><span>fa fa-cog</span></a
                        >
                    </li>
                    <li>
                        <a class="point" (click)="copy('fa fa-trash-o')"
                            ><i class="fa fa-trash-o"></i><span>fa fa-trash-o</span></a
                        >
                    </li>
                    <li>
                        <a class="point" (click)="copy('fa fa-home')"
                            ><i class="fa fa-home"></i><span>fa fa-home</span></a
                        >
                    </li>
                    <li>
                        <a class="point" (click)="copy('fa fa-file-o')"
                            ><i class="fa fa-file-o"></i><span>fa fa-file-o</span></a
                        >
                    </li>
                    <li>
                        <a class="point" (click)="copy('fa fa-clock-o')"
                            ><i class="fa fa-clock-o"></i> <span>fa fa-clock-o</span></a
                        >
                    </li>
                    <li>
                        <a class="point" (click)="copy('fa fa-road')"
                            ><i class="fa fa-road"></i><span>fa fa-road</span></a
                        >
                    </li>
                    <li>
                        <a class="point" (click)="copy('fa fa-download')"
                            ><i class="fa fa-download"></i><span>fa fa-download</span></a
                        >
                    </li>
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"-->
                    <!--                            ><i class="fa fa-arrow-circle-o-down"></i-->
                    <!--                            ><span>fa fa-arrow-circle-o-down</span></a-->
                    <!--                        >-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"-->
                    <!--                            ><i class="fa fa-arrow-circle-o-up"></i-->
                    <!--                            ><span>fa fa-arrow-circle-o-up</span></a-->
                    <!--                        >-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"><i class="fa fa-inbox"></i><span>fa fa-inbox</span></a>-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"-->
                    <!--                            ><i class="fa fa-play-circle-o"></i><span>fa fa-play-circle-o</span></a-->
                    <!--                        >-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"><i class="fa fa-repeat"></i><span>fa fa-repeat</span></a>-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"><i class="fa fa-refresh"></i><span>fa fa-refresh</span></a>-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"><i class="fa fa-list-alt"></i><span>fa fa-list-alt</span></a>-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"><i class="fa fa-lock"></i><span>fa fa-lock</span></a>-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"><i class="fa fa-flag"></i><span>fa fa-flag</span></a>-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"-->
                    <!--                            ><i class="fa fa-headphones"></i><span>fa fa-headphones</span></a-->
                    <!--                        >-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"-->
                    <!--                            ><i class="fa fa-volume-off"></i><span>fa fa-volume-off</span></a-->
                    <!--                        >-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"-->
                    <!--                            ><i class="fa fa-volume-down"></i><span>fa fa-volume-down</span></a-->
                    <!--                        >-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"><i class="fa fa-volume-up"></i><span>fa fa-volume-up</span></a>-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"><i class="fa fa-qrcode"></i><span>fa fa-qrcode</span></a>-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"><i class="fa fa-barcode"></i><span>fa fa-barcode</span></a>-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"><i class="fa fa-tag"></i><span>fa fa-tag</span></a>-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"><i class="fa fa-tags"></i><span>fa fa-tags</span></a>-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"><i class="fa fa-book"></i><span>fa fa-book</span></a>-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"><i class="fa fa-bookmark"></i><span>fa fa-bookmark</span></a>-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"><i class="fa fa-print"></i><span>fa fa-print</span></a>-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"><i class="fa fa-camera"></i><span>fa fa-camera</span></a>-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"><i class="fa fa-font"></i><span>fa fa-font</span></a>-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"><i class="fa fa-bold"></i><span>fa fa-bold</span></a>-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"><i class="fa fa-italic"></i><span>fa fa-italic</span></a>-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"-->
                    <!--                            ><i class="fa fa-text-height"></i><span>fa fa-text-height</span></a-->
                    <!--                        >-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"-->
                    <!--                            ><i class="fa fa-text-width"></i><span>fa fa-text-width</span></a-->
                    <!--                        >-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"-->
                    <!--                            ><i class="fa fa-align-left"></i><span>fa fa-align-left</span></a-->
                    <!--                        >-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"-->
                    <!--                            ><i class="fa fa-align-center"></i><span>fa fa-align-center</span></a-->
                    <!--                        >-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"-->
                    <!--                            ><i class="fa fa-align-right"></i><span>fa fa-align-right</span></a-->
                    <!--                        >-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"-->
                    <!--                            ><i class="fa fa-align-justify"></i><span>fa fa-align-justify</span></a-->
                    <!--                        >-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"><i class="fa fa-list"></i><span>fa fa-list</span></a>-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"><i class="fa fa-outdent"></i><span>fa fa-outdent</span></a>-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"><i class="fa fa-indent"></i><span>fa fa-indent</span></a>-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"-->
                    <!--                            ><i class="fa fa-video-camera"></i><span>fa fa-video-camera</span></a-->
                    <!--                        >-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"><i class="fa fa-picture-o"></i><span>fa fa-picture-o</span></a>-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"><i class="fa fa-pencil"></i><span>fa fa-pencil</span></a>-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"-->
                    <!--                            ><i class="fa fa-map-marker"></i><span>fa fa-map-marker</span></a-->
                    <!--                        >-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"><i class="fa fa-adjust"></i><span>fa fa-adjust</span></a>-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"><i class="fa fa-tint"></i><span>fa fa-tint</span></a>-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"-->
                    <!--                            ><i class="fa fa-pencil-square-o"></i-->
                    <!--                            ><span>fa fa-pencil-square-o</span></a-->
                    <!--                        >-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"-->
                    <!--                            ><i class="fa fa-share-square-o"></i-->
                    <!--                            ><span>fa fa-share-square-o</span></a-->
                    <!--                        >-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"-->
                    <!--                            ><i class="fa fa-check-square-o"></i-->
                    <!--                            ><span>fa fa-check-square-o</span></a-->
                    <!--                        >-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"><i class="fa fa-arrows"></i><span>fa fa-arrows</span></a>-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"-->
                    <!--                            ><i class="fa fa-step-backward"></i><span>fa fa-step-backward</span></a-->
                    <!--                        >-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"-->
                    <!--                            ><i class="fa fa-fast-backward"></i><span>fa fa-fast-backward</span></a-->
                    <!--                        >-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"><i class="fa fa-backward"></i><span>fa fa-backward</span></a>-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"><i class="fa fa-play"></i><span>fa fa-play</span></a>-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"><i class="fa fa-pause"></i><span>fa fa-pause</span></a>-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"><i class="fa fa-stop"></i><span>fa fa-stop</span></a>-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"><i class="fa fa-forward"></i><span>fa fa-forward</span></a>-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"-->
                    <!--                            ><i class="fa fa-fast-forward"></i><span>fa fa-fast-forward</span></a-->
                    <!--                        >-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"-->
                    <!--                            ><i class="fa fa-step-forward"></i><span>fa fa-step-forward</span></a-->
                    <!--                        >-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"><i class="fa fa-eject"></i><span>fa fa-eject</span></a>-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"-->
                    <!--                            ><i class="fa fa-chevron-left"></i><span>fa fa-chevron-left</span></a-->
                    <!--                        >-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"-->
                    <!--                            ><i class="fa fa-chevron-right"></i><span>fa fa-chevron-right</span></a-->
                    <!--                        >-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"-->
                    <!--                            ><i class="fa fa-plus-circle"></i><span>fa fa-plus-circle</span></a-->
                    <!--                        >-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"-->
                    <!--                            ><i class="fa fa-minus-circle"></i><span>fa fa-minus-circle</span></a-->
                    <!--                        >-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"-->
                    <!--                            ><i class="fa fa-times-circle"></i><span>fa fa-times-circle</span></a-->
                    <!--                        >-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"-->
                    <!--                            ><i class="fa fa-check-circle"></i><span>fa fa-check-circle</span></a-->
                    <!--                        >-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"-->
                    <!--                            ><i class="fa fa-question-circle"></i-->
                    <!--                            ><span>fa fa-question-circle</span></a-->
                    <!--                        >-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"-->
                    <!--                            ><i class="fa fa-info-circle"></i><span>fa fa-info-circle</span></a-->
                    <!--                        >-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"-->
                    <!--                            ><i class="fa fa-crosshairs"></i><span>fa fa-crosshairs</span></a-->
                    <!--                        >-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"-->
                    <!--                            ><i class="fa fa-times-circle-o"></i-->
                    <!--                            ><span>fa fa-times-circle-o</span></a-->
                    <!--                        >-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"-->
                    <!--                            ><i class="fa fa-check-circle-o"></i-->
                    <!--                            ><span>fa fa-check-circle-o</span></a-->
                    <!--                        >-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"><i class="fa fa-ban"></i><span>fa fa-ban</span></a>-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"-->
                    <!--                            ><i class="fa fa-arrow-left"></i><span>fa fa-arrow-left</span></a-->
                    <!--                        >-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"-->
                    <!--                            ><i class="fa fa-arrow-right"></i><span>fa fa-arrow-right</span></a-->
                    <!--                        >-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"><i class="fa fa-arrow-up"></i><span>fa fa-arrow-up</span></a>-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"-->
                    <!--                            ><i class="fa fa-arrow-down"></i><span>fa fa-arrow-down</span></a-->
                    <!--                        >-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"><i class="fa fa-share"></i><span>fa fa-share</span></a>-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"><i class="fa fa-expand"></i><span>fa fa-expand</span></a>-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"><i class="fa fa-compress"></i><span>fa fa-compress</span></a>-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"><i class="fa fa-plus"></i><span>fa fa-plus</span></a>-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"><i class="fa fa-minus"></i><span>fa fa-minus</span></a>-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"><i class="fa fa-asterisk"></i><span>fa fa-asterisk</span></a>-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"-->
                    <!--                            ><i class="fa fa-exclamation-circle"></i-->
                    <!--                            ><span>fa fa-exclamation-circle</span></a-->
                    <!--                        >-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"><i class="fa fa-gift"></i><span>fa fa-gift</span></a>-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"><i class="fa fa-leaf"></i><span>fa fa-leaf</span></a>-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"><i class="fa fa-fire"></i><span>fa fa-fire</span></a>-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"><i class="fa fa-eye"></i><span>fa fa-eye</span></a>-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"><i class="fa fa-eye-slash"></i><span>fa fa-eye-slash</span></a>-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"-->
                    <!--                            ><i class="fa fa-exclamation-triangle"></i-->
                    <!--                            ><span>fa fa-exclamation-triangle</span></a-->
                    <!--                        >-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"><i class="fa fa-plane"></i><span>fa fa-plane</span></a>-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"><i class="fa fa-calendar"></i><span>fa fa-calendar</span></a>-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"><i class="fa fa-random"></i><span>fa fa-random</span></a>-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"><i class="fa fa-comment"></i><span>fa fa-comment</span></a>-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"><i class="fa fa-magnet"></i><span>fa fa-magnet</span></a>-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"-->
                    <!--                            ><i class="fa fa-chevron-up"></i><span>fa fa-chevron-up</span></a-->
                    <!--                        >-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"-->
                    <!--                            ><i class="fa fa-chevron-down"></i><span>fa fa-chevron-down</span></a-->
                    <!--                        >-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"><i class="fa fa-retweet"></i><span>fa fa-retweet</span></a>-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"-->
                    <!--                            ><i class="fa fa-shopping-cart"></i><span>fa fa-shopping-cart</span></a-->
                    <!--                        >-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"><i class="fa fa-folder"></i><span>fa fa-folder</span></a>-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"-->
                    <!--                            ><i class="fa fa-folder-open"></i><span>fa fa-folder-open</span></a-->
                    <!--                        >-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"><i class="fa fa-arrows-v"></i><span>fa fa-arrows-v</span></a>-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"><i class="fa fa-arrows-h"></i><span>fa fa-arrows-h</span></a>-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"><i class="fa fa-bar-chart"></i><span>fa fa-bar-chart</span></a>-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"-->
                    <!--                            ><i class="fa fa-twitter-square"></i-->
                    <!--                            ><span>fa fa-twitter-square</span></a-->
                    <!--                        >-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"-->
                    <!--                            ><i class="fa fa-facebook-square"></i-->
                    <!--                            ><span>fa fa-facebook-square</span></a-->
                    <!--                        >-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"-->
                    <!--                            ><i class="fa fa-camera-retro"></i><span>fa fa-camera-retro</span></a-->
                    <!--                        >-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"><i class="fa fa-key"></i><span>fa fa-key</span></a>-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"><i class="fa fa-cogs"></i><span>fa fa-cogs</span></a>-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"><i class="fa fa-comments"></i><span>fa fa-comments</span></a>-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"-->
                    <!--                            ><i class="fa fa-thumbs-o-up"></i><span>fa fa-thumbs-o-up</span></a-->
                    <!--                        >-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"-->
                    <!--                            ><i class="fa fa-thumbs-o-down"></i><span>fa fa-thumbs-o-down</span></a-->
                    <!--                        >-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"><i class="fa fa-star-half"></i><span>fa fa-star-half</span></a>-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"><i class="fa fa-heart-o"></i><span>fa fa-heart-o</span></a>-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"><i class="fa fa-sign-out"></i><span>fa fa-sign-out</span></a>-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"-->
                    <!--                            ><i class="fa fa-linkedin-square"></i-->
                    <!--                            ><span>fa fa-linkedin-square</span></a-->
                    <!--                        >-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"-->
                    <!--                            ><i class="fa fa-thumb-tack"></i><span>fa fa-thumb-tack</span></a-->
                    <!--                        >-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"-->
                    <!--                            ><i class="fa fa-external-link"></i><span>fa fa-external-link</span></a-->
                    <!--                        >-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"><i class="fa fa-sign-in"></i><span>fa fa-sign-in</span></a>-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"><i class="fa fa-trophy"></i><span>fa fa-trophy</span></a>-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"-->
                    <!--                            ><i class="fa fa-github-square"></i><span>fa fa-github-square</span></a-->
                    <!--                        >-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"><i class="fa fa-upload"></i><span>fa fa-upload</span></a>-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"><i class="fa fa-lemon-o"></i><span>fa fa-lemon-o</span></a>-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"><i class="fa fa-phone"></i><span>fa fa-phone</span></a>-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"><i class="fa fa-square-o"></i><span>fa fa-square-o</span></a>-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"-->
                    <!--                            ><i class="fa fa-bookmark-o"></i><span>fa fa-bookmark-o</span></a-->
                    <!--                        >-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"-->
                    <!--                            ><i class="fa fa-phone-square"></i><span>fa fa-phone-square</span></a-->
                    <!--                        >-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"><i class="fa fa-twitter"></i><span>fa fa-twitter</span></a>-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"><i class="fa fa-facebook"></i><span>fa fa-facebook</span></a>-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"><i class="fa fa-github"></i><span>fa fa-github</span></a>-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"><i class="fa fa-unlock"></i><span>fa fa-unlock</span></a>-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"-->
                    <!--                            ><i class="fa fa-credit-card"></i><span>fa fa-credit-card</span></a-->
                    <!--                        >-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"><i class="fa fa-rss"></i><span>fa fa-rss</span></a>-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"><i class="fa fa-hdd-o"></i><span>fa fa-hdd-o</span></a>-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"><i class="fa fa-bullhorn"></i><span>fa fa-bullhorn</span></a>-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"><i class="fa fa-bell"></i><span>fa fa-bell</span></a>-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"-->
                    <!--                            ><i class="fa fa-certificate"></i><span>fa fa-certificate</span></a-->
                    <!--                        >-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"-->
                    <!--                            ><i class="fa fa-hand-o-right"></i><span>fa fa-hand-o-right</span></a-->
                    <!--                        >-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"-->
                    <!--                            ><i class="fa fa-hand-o-left"></i><span>fa fa-hand-o-left</span></a-->
                    <!--                        >-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"><i class="fa fa-hand-o-up"></i><span>fa fa-hand-o-up</span></a>-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"-->
                    <!--                            ><i class="fa fa-hand-o-down"></i><span>fa fa-hand-o-down</span></a-->
                    <!--                        >-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"-->
                    <!--                            ><i class="fa fa-arrow-circle-left"></i-->
                    <!--                            ><span>fa fa-arrow-circle-left</span></a-->
                    <!--                        >-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"-->
                    <!--                            ><i class="fa fa-arrow-circle-right"></i-->
                    <!--                            ><span>fa fa-arrow-circle-right</span></a-->
                    <!--                        >-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"-->
                    <!--                            ><i class="fa fa-arrow-circle-up"></i-->
                    <!--                            ><span>fa fa-arrow-circle-up</span></a-->
                    <!--                        >-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"-->
                    <!--                            ><i class="fa fa-arrow-circle-down"></i-->
                    <!--                            ><span>fa fa-arrow-circle-down</span></a-->
                    <!--                        >-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"><i class="fa fa-globe"></i><span>fa fa-globe</span></a>-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"><i class="fa fa-wrench"></i><span>fa fa-wrench</span></a>-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"><i class="fa fa-tasks"></i><span>fa fa-tasks</span></a>-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"><i class="fa fa-filter"></i><span>fa fa-filter</span></a>-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"><i class="fa fa-briefcase"></i><span>fa fa-briefcase</span></a>-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"-->
                    <!--                            ><i class="fa fa-arrows-alt"></i><span>fa fa-arrows-alt</span></a-->
                    <!--                        >-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"><i class="fa fa-users"></i><span>fa fa-users</span></a>-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"><i class="fa fa-link"></i><span>fa fa-link</span></a>-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"><i class="fa fa-cloud"></i><span>fa fa-cloud</span></a>-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"><i class="fa fa-flask"></i><span>fa fa-flask</span></a>-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"><i class="fa fa-scissors"></i><span>fa fa-scissors</span></a>-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"><i class="fa fa-files-o"></i><span>fa fa-files-o</span></a>-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"><i class="fa fa-paperclip"></i><span>fa fa-paperclip</span></a>-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"><i class="fa fa-floppy-o"></i><span>fa fa-floppy-o</span></a>-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"><i class="fa fa-square"></i><span>fa fa-square</span></a>-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"><i class="fa fa-bars"></i><span>fa fa-bars</span></a>-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"><i class="fa fa-list-ul"></i><span>fa fa-list-ul</span></a>-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"><i class="fa fa-list-ol"></i><span>fa fa-list-ol</span></a>-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"-->
                    <!--                            ><i class="fa fa-strikethrough"></i><span>fa fa-strikethrough</span></a-->
                    <!--                        >-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"><i class="fa fa-underline"></i><span>fa fa-underline</span></a>-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"><i class="fa fa-table"></i><span>fa fa-table</span></a>-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"><i class="fa fa-magic"></i><span>fa fa-magic</span></a>-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"><i class="fa fa-truck"></i><span>fa fa-truck</span></a>-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"><i class="fa fa-pinterest"></i><span>fa fa-pinterest</span></a>-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"-->
                    <!--                            ><i class="fa fa-pinterest-square"></i-->
                    <!--                            ><span>fa fa-pinterest-square</span></a-->
                    <!--                        >-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"-->
                    <!--                            ><i class="fa fa-google-plus-square"></i-->
                    <!--                            ><span>fa fa-google-plus-square</span></a-->
                    <!--                        >-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"-->
                    <!--                            ><i class="fa fa-google-plus"></i><span>fa fa-google-plus</span></a-->
                    <!--                        >-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"><i class="fa fa-money"></i><span>fa fa-money</span></a>-->
                    <!--                    </li>-->

                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"-->
                    <!--                            ><i class="fa fa-caret-left"></i><span>fa fa-caret-left</span></a-->
                    <!--                        >-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"-->
                    <!--                            ><i class="fa fa-caret-right"></i><span>fa fa-caret-right</span></a-->
                    <!--                        >-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"><i class="fa fa-columns"></i><span>fa fa-columns</span></a>-->
                    <!--                    </li>-->

                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"-->
                    <!--                            ><i class="fa fa-tachometer"></i><span>fa fa-tachometer</span></a-->
                    <!--                        >-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"><i class="fa fa-comment-o"></i><span>fa fa-comment-o</span></a>-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"-->
                    <!--                            ><i class="fa fa-comments-o"></i><span>fa fa-comments-o</span></a-->
                    <!--                        >-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"><i class="fa fa-bolt"></i><span>fa fa-bolt</span></a>-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"><i class="fa fa-sitemap"></i><span>fa fa-sitemap</span></a>-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"><i class="fa fa-umbrella"></i><span>fa fa-umbrella</span></a>-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"><i class="fa fa-clipboard"></i><span>fa fa-clipboard</span></a>-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"-->
                    <!--                            ><i class="fa fa-lightbulb-o"></i><span>fa fa-lightbulb-o</span></a-->
                    <!--                        >-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"><i class="fa fa-exchange"></i><span>fa fa-exchange</span></a>-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"-->
                    <!--                            ><i class="fa fa-cloud-download"></i-->
                    <!--                            ><span>fa fa-cloud-download</span></a-->
                    <!--                        >-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"-->
                    <!--                            ><i class="fa fa-cloud-upload"></i><span>fa fa-cloud-upload</span></a-->
                    <!--                        >-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"><i class="fa fa-user-md"></i><span>fa fa-user-md</span></a>-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"-->
                    <!--                            ><i class="fa fa-stethoscope"></i><span>fa fa-stethoscope</span></a-->
                    <!--                        >-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"><i class="fa fa-suitcase"></i><span>fa fa-suitcase</span></a>-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"><i class="fa fa-bell-o"></i><span>fa fa-bell-o</span></a>-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"><i class="fa fa-coffee"></i><span>fa fa-coffee</span></a>-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"><i class="fa fa-cutlery"></i><span>fa fa-cutlery</span></a>-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"-->
                    <!--                            ><i class="fa fa-file-text-o"></i><span>fa fa-file-text-o</span></a-->
                    <!--                        >-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"-->
                    <!--                            ><i class="fa fa-building-o"></i><span>fa fa-building-o</span></a-->
                    <!--                        >-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"-->
                    <!--                            ><i class="fa fa-hospital-o"></i><span>fa fa-hospital-o</span></a-->
                    <!--                        >-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"><i class="fa fa-ambulance"></i><span>fa fa-ambulance</span></a>-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"><i class="fa fa-medkit"></i><span>fa fa-medkit</span></a>-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"-->
                    <!--                            ><i class="fa fa-fighter-jet"></i><span>fa fa-fighter-jet</span></a-->
                    <!--                        >-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"><i class="fa fa-beer"></i><span>fa fa-beer</span></a>-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="point" (click)="copy('')"><i class="fa fa-h-square"></i><span>fa fa-h-square</span></a>-->
                    <!--                    </li>-->
                </ul>
            </div>
        </div>
    </div>
</div>
