import {
    Component,
    ElementRef,
    OnDestroy,
    OnInit,
    QueryList,
    Renderer2,
    ViewChild,
    ViewChildren,
} from '@angular/core';
import { InvestmentService } from '@app/common/service/investment.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import {
    InvestmentFilterEnum,
    InvestmentPayload,
    InvestmentStats,
} from '@app/common/payload/investment.payload';
import { Subscription } from 'rxjs';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Title } from '@angular/platform-browser';
import { environment } from '@environments/environment.prod';
import Swal from 'sweetalert2';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from '@app/common/payload/user';
import { UserService } from '@app/common/service/user.service';

@Component({
    selector: 'app-dashboard-admin-investment',
    templateUrl: './dashboard-admin-investment.component.html',
    styleUrls: ['./dashboard-admin-investment.component.css'],
    animations: [
        trigger('simpleFadeAnimation', [
            state('in', style({ opacity: 1 })),
            transition(':enter', [style({ opacity: 0 }), animate(600)]),
            transition(':leave', animate(600, style({ opacity: 0 }))),
        ]),
    ],
})
export class DashboardAdminInvestmentComponent implements OnInit, OnDestroy {
    @ViewChild('filterWrapper') filterWrapper: ElementRef;
    isSearch: boolean = false;

    investments: InvestmentPayload[];
    subscription: Subscription = new Subscription();
    pageSize = 6;
    collectionSize;
    pageOfItems: InvestmentPayload[] = [];
    items: InvestmentPayload[] = [];
    @ViewChildren('myInvestments') myInvestments: QueryList<ElementRef>;
    @ViewChild('investmentsCard') investmentsCard: ElementRef;
    @ViewChild('toggleDetailsDiv') toggleDetailsDiv: ElementRef;
    isUserDetailOpen = false;
    showRightSide = false;
    showUserPOP = false;
    selectedInvestment: InvestmentPayload;
    imageObject: { image: string; thumbImage: string; alt: string }[] = [];

    userInvestmentsStats: InvestmentStats;

    userId: number;
    user: User;
    searchTerm: string = '';
    isSearchEmpty: boolean = false;

    constructor(
        private investmentService: InvestmentService,
        private toast: ToastrService,
        private titleService: Title,
        private renderer: Renderer2,
        private spinner: NgxSpinnerService,
        private route: ActivatedRoute,
        private router: Router,
        private userService: UserService
    ) {}

    ngOnInit(): void {
        this.titleService.setTitle(`Manage Investments - ${environment.appName}`);

        this.subscription.add(
            this.route.params.subscribe((params) => {
                this.userId = params['userId'];
            })
        );

        if (this.userId) {
            this.subscription.add(
                this.userService.onGetUserById(this.userId).subscribe((result) => {
                    if (result.status === 200) {
                        this.user = result.body;
                        this.onGetApprovedInvestments(result.body.investments, true);
                    }
                })
            );
            this.subscription.add(
                this.investmentService.onGetInvestmentByUser(this.userId).subscribe((result) => {
                    if (result.status === 200) {
                        this.investments = result.body;
                        this.items = result.body;
                        this.collectionSize = result.body.length;
                    }
                })
            );
        } else {
            this.subscription.add(
                this.investmentService.onFindAllInvestment().subscribe((result) => {
                    if (result.status === 200) {
                        this.investments = result.body;
                        this.items = result.body;
                        this.collectionSize = result.body.length;
                    }
                })
            );
        }
        this.selectedInvestment = new InvestmentPayload();
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    onChangePage(pageOfItems: Array<any>) {
        this.pageOfItems = pageOfItems;
    }

    onSelectInvestment(investment: InvestmentPayload, i: number) {
        this.myInvestments.forEach((item) => {
            if (item.nativeElement.id !== `menu-${i}`) {
                item.nativeElement.classList.remove('active');
            } else {
                item.nativeElement.classList.add('active');
            }
        });
        this.selectedInvestment = investment;
        investment.property?.image?.forEach((im) => {
            let imageObj = { image: '', thumbImage: '', alt: '' };
            imageObj.image = im.secure_url;
            imageObj.thumbImage = im.secure_url;
            imageObj.alt = investment.property?.title;
            this.imageObject.push(imageObj);
        });

        this.onGetApprovedInvestments();
        this.onToggleDetails();
    }

    onGetApprovedInvestments(investments?: InvestmentPayload[], flag?: boolean) {
        if (investments && flag) {
            this.userInvestmentsStats = this.investmentService.onPrepareInvestmentsStats(
                investments
            );
            return;
        }

        if (this.selectedInvestment?.user?.investments) {
            this.userInvestmentsStats = this.investmentService.onPrepareInvestmentsStats(
                this.selectedInvestment?.user?.investments
            );
            return;
        }
    }

    onToggleDetails(value?: string) {
        this.showRightSide = true;

        const ele = this.toggleDetailsDiv.nativeElement;
        const element = this.investmentsCard.nativeElement;

        if (this.isUserDetailOpen) {
            this.renderer.removeClass(ele, 'folded-info');
            this.renderer.removeClass(ele, 'force-show-folded-info');
            this.renderer.removeClass(element, 'show-ticket-content');
        } else {
            this.renderer.addClass(ele, 'folded-info');
            this.renderer.addClass(ele, 'force-show-folded-info');
            this.renderer.addClass(element, 'show-ticket-content');
        }
        this.isUserDetailOpen = !this.isUserDetailOpen;
    }

    onToggleUserPop() {
        const ele = this.toggleDetailsDiv.nativeElement;
        if (this.showUserPOP) {
            this.renderer.removeClass(ele, 'folded-info');
            this.renderer.removeClass(ele, 'force-show-folded-info');
        } else {
            this.renderer.addClass(ele, 'folded-info');
            this.renderer.addClass(ele, 'force-show-folded-info');
        }
        this.showUserPOP = !this.showUserPOP;
    }

    async onApproveInvestment(selectedInvestment: InvestmentPayload) {
        const { value } = await Swal.fire({
            title: 'APPROVE INVESTMENT ?',
            html: 'Are you sure you want to approve this investment ?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#047bf8',
            confirmButtonText: '<i class="fa fa-thumbs-up"></i> Yes, Approve',
            cancelButtonText: 'No, Go Back',
            cancelButtonColor: '#FF747884',
            allowOutsideClick: false,
        });

        if (value) {
            this.spinner.show().then();
            this.subscription.add(
                this.investmentService.onApproveInvestment(selectedInvestment.id).subscribe(
                    (result) => {
                        if (result.status === 201) {
                            this.spinner.hide().then();
                            Swal.fire({
                                title: 'Investment Approved Successfully.',
                                icon: 'success',
                            });
                            selectedInvestment.isApproved = true;

                            this.showRightSide = false;
                            this.onToggleDetails();
                            if (this.userId) {
                                this.items.forEach((item) => {
                                    if (item.id === selectedInvestment.id) item.isApproved = true;
                                });
                                this.onGetApprovedInvestments(this.items, true);
                            }
                        }
                    },
                    (error) => {
                        this.spinner.hide().then();
                        Swal.fire({
                            title: 'Investment Approval Failed.',
                            icon: 'error',
                            html: `${error.message}`,
                        });
                    }
                )
            );
        }
    }

    onFilterInvestment(event: any) {
        const value = event.target.value;
        let result: InvestmentPayload[] = [];
        switch (value) {
            case InvestmentFilterEnum.ALL:
                result = this.investments;
                break;
            case InvestmentFilterEnum.UNAPPROVED_WITH_POP:
                this.investments.forEach((investment) => {
                    if (!investment.isApproved && investment.uploads) {
                        result.push(investment);
                    }
                });
                break;
            case InvestmentFilterEnum.UNAPPROVED_WITHOUT_POP:
                this.investments.forEach((investment) => {
                    if (!investment.isApproved && investment.uploads === null) {
                        result.push(investment);
                    }
                });
                break;
            default:
                break;
        }

        this.items = result;
        this.collectionSize = result.length;
    }

    onToggleSearch() {
        const ele = this.filterWrapper.nativeElement;
        if (this.isSearch) {
            this.renderer.setStyle(ele, 'visibility', 'hidden');
            this.renderer.setStyle(ele, 'opacity', 0);
        } else {
            this.renderer.setStyle(ele, 'visibility', 'visible');
            this.renderer.setStyle(ele, 'opacity', 1);
        }
        this.isSearch = !this.isSearch;
    }

    onSearchInvestment() {
        if (this.searchTerm.length < 1) {
            this.isSearchEmpty = true;
            return;
        }
        this.spinner.show().then();

        this.subscription.add(
            this.investmentService.onGetInvestmentByReference(this.searchTerm).subscribe(
                (result) => {
                    this.spinner.hide().then();
                    if (result.status === 200) {
                        this.onSelectInvestment(result.body, result.body.id);
                        this.onToggleSearch();
                    }
                },
                (error) => {
                    this.toast.error('Invalid, Try Again');
                    this.spinner.hide().then();
                }
            )
        );
    }
}
