<ng-container *ngIf="data; else loading">
    <div class="balance-title">{{ data.title }}</div>
    <div class="balance-value">
        <span>{{ data.amount | shortNumber }}</span>
        <span *ngIf="data.showArrow" class="trending trending-down-basic">
            <span>% {{ data.count }} </span>
            <i class="os-icon os-icon-arrow-2-down"></i>
        </span>
    </div>
    <div class="balance-link">
        <a *ngIf="data.showLink" [routerLink]="data.linkUrl" class="btn btn-link btn-underlined">
            <span>{{ data.linkText }}</span>
            <i class="os-icon os-icon-arrow-right4"></i>
        </a>
    </div>
</ng-container>

<ng-template #loading>
    <div class="text-center" style="padding: 13px;">
        <i class="fa fa-spinner fa-spin"></i>
    </div>
</ng-template>
