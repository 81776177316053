import {
    Component,
    ElementRef,
    OnDestroy,
    OnInit,
    QueryList,
    Renderer2,
    ViewChild,
    ViewChildren,
} from '@angular/core';
import { IdentityService } from '@app/common/service/identity.service';
import { IdentityPayload } from '@app/common/payload/identity.payload';
import { Subscription } from 'rxjs';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ToastrService } from 'ngx-toastr';
import { Title } from '@angular/platform-browser';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from '@environments/environment.prod';
import Swal from 'sweetalert2';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '@app/common/service/user.service';
import { User } from '@app/common/payload/user';
import { IdentityStatusEnum } from '@app/common/enum/identity-type.enum';

@Component({
    selector: 'app-dashboard-admin-identity',
    templateUrl: './dashboard-admin-identity.component.html',
    styleUrls: ['./dashboard-admin-identity.component.css'],
    animations: [
        trigger('simpleFadeAnimation', [
            state('in', style({ opacity: 1 })),
            transition(':enter', [style({ opacity: 0 }), animate(600)]),
            transition(':leave', animate(600, style({ opacity: 0 }))),
        ]),
    ],
})
export class DashboardAdminIdentityComponent implements OnInit, OnDestroy {
    identities: IdentityPayload[];
    subscription: Subscription = new Subscription();
    selectedIdentity: IdentityPayload;

    verifiedStatus: IdentityStatusEnum = IdentityStatusEnum.VERIFIED;
    rejectedStatus: IdentityStatusEnum = IdentityStatusEnum.REJECTED;
    pendingStatus: IdentityStatusEnum = IdentityStatusEnum.PENDING;

    pageSize = 6;
    collectionSize;
    pageOfItems: IdentityPayload[] = [];
    items: IdentityPayload[] = [];

    showRightSide = false;
    isUserDetailOpen = false;

    userId: number;
    user: User;
    identity: IdentityPayload;
    routeState: any;

    @ViewChildren('myIdentities') myIdentities: QueryList<ElementRef>;
    @ViewChild('identityCard') identityCard: ElementRef;
    @ViewChild('toggleDetailsDiv') toggleDetailsDiv: ElementRef;
    imageObject: { image: string; thumbImage: string; alt: string }[] = [];

    constructor(
        private identityService: IdentityService,
        private toast: ToastrService,
        private titleService: Title,
        private renderer: Renderer2,
        private spinner: NgxSpinnerService,
        private route: ActivatedRoute,
        private router: Router,
        private userService: UserService
    ) {
        if (this.router.getCurrentNavigation()?.extras?.state) {
            this.routeState = this.router.getCurrentNavigation().extras?.state;
            if (this.routeState) {
                //Retrieving From Route State...
                this.user = this.routeState.user;
                this.identity = this.routeState.identity;
            }
        }
    }

    ngOnInit() {
        this.titleService.setTitle(`Approve Identity - ${environment.appName}`);

        this.subscription.add(
            this.route.params.subscribe((params) => {
                this.userId = params['userId'];
                this.onAfterInit();
            })
        );

        this.selectedIdentity = new IdentityPayload();
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    onChangePage(pageOfItems: Array<any>) {
        this.pageOfItems = pageOfItems;
    }

    onAfterInit() {
        if (this.userId) {
            if (!this.user) {
                // Retrieving usr from API...
                this.subscription.add(
                    this.userService.onGetUserById(this.userId).subscribe((result) => {
                        if (result.status === 200) {
                            this.user = result.body;
                        }
                    })
                );
            }
            if (!this.identity) {
                // Retrieving idt from API..
                this.subscription.add(
                    this.identityService.onGetIdentityByUserId(this.userId).subscribe((result) => {
                        if (result.status === 200) {
                            this.identity = result.body;
                        }
                    })
                );
            }
        } else {
            //'Retrieving all idt from API...
            this.subscription.add(
                this.identityService.onGetAllIdentities().subscribe((result) => {
                    if (result.status === 200) {
                        this.identities = result.body;
                        this.items = result.body;
                        this.collectionSize = result.body.length;
                    }
                })
            );
        }
    }

    onToggleDetails(value?: string) {
        this.showRightSide = true;
        const ele = this.toggleDetailsDiv.nativeElement;
        const element = this.identityCard.nativeElement;

        if (this.isUserDetailOpen) {
            this.renderer.removeClass(ele, 'folded-info');
            this.renderer.removeClass(ele, 'force-show-folded-info');
            this.renderer.removeClass(element, 'show-ticket-content');
        } else {
            this.renderer.addClass(ele, 'folded-info');
            this.renderer.addClass(ele, 'force-show-folded-info');
            this.renderer.addClass(element, 'show-ticket-content');
        }
        this.isUserDetailOpen = !this.isUserDetailOpen;
    }

    onSelectIdentity(identity: IdentityPayload, i?: number) {
        this.myIdentities.forEach((item) => {
            if (item.nativeElement.id !== `menu-${i}`) {
                item.nativeElement.classList.remove('active');
            } else {
                item.nativeElement.classList.add('active');
            }
        });
        this.imageObject = [];
        this.selectedIdentity = identity;

        identity?.image?.forEach((im) => {
            let imageObj = { image: '', thumbImage: '', alt: '' };
            imageObj.image = im.secure_url;
            imageObj.thumbImage = im.secure_url;
            imageObj.alt = identity.type;
            this.imageObject.push(imageObj);
        });
        this.onToggleDetails();
    }

    async onApproveIdentity(selectedIdentity: IdentityPayload, status?: IdentityStatusEnum) {
        let sValue: any;
        if (status == IdentityStatusEnum.VERIFIED) {
            const { value } = await Swal.fire({
                title: 'APPROVE IDENTITY ?',
                html: 'Are you sure ?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#047bf8',
                confirmButtonText: '<i class="fa fa-thumbs-up"></i> Yes, Approve',
                cancelButtonText: 'No, Go Back',
                cancelButtonColor: '#FF747884',
                allowOutsideClick: false,
            });
            sValue = value;
        } else if (status == IdentityStatusEnum.REJECTED) {
            const { value } = await Swal.fire({
                title: 'REJECT IDENTITY ?',
                html: '<small>Enter your reason in the field below</small>',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#047bf8',
                confirmButtonText: '<i class="fa fa-thumbs-down"></i> Yes, Reject',
                cancelButtonText: 'No, Go Back',
                cancelButtonColor: '#FF747884',
                allowOutsideClick: false,
                input: 'textarea',
                inputAttributes: {
                    class: 'form-control',
                },
                inputValidator: (value) => {
                    if (!value) {
                        return 'Add a reason for rejection first';
                    }
                },
            });
            sValue = value;
        }
        if (sValue) {
            this.spinner.show().then();
            const identityToUpdate = new IdentityPayload();
            identityToUpdate.id = selectedIdentity.id;
            identityToUpdate.status = status;
            if (status === IdentityStatusEnum.REJECTED) {
                identityToUpdate.message = sValue;
            }
            this.subscription.add(
                this.identityService.onUpdateIdentity(identityToUpdate).subscribe(
                    (result) => {
                        if (result.statusText === 'OK') {
                            this.selectedIdentity.status = status;
                            this.spinner.hide().then();
                            Swal.fire({
                                title: `${status} SUCCESSFULLY.`,
                                icon: 'success',
                            });
                            this.showRightSide = false;
                            this.onToggleDetails();
                        }
                    },
                    (error) => {
                        this.spinner.hide().then();
                        Swal.fire({
                            title: `Verification Failed.`,
                            icon: 'error',
                            html: `${error.message}`,
                        });
                    }
                )
            );
        }
    }

    onFilterIdentities(event: any) {
        const value = event.target.value;
        let result: IdentityPayload[] = [];
        switch (value) {
            case 'ALL':
                result = this.identities;
                break;
            case 'UNAPPROVED':
                this.identities.forEach((identity) => {
                    if (identity.status !== IdentityStatusEnum.VERIFIED) {
                        result.push(identity);
                    }
                });
                break;
            default:
                break;
        }

        this.items = result;
        this.collectionSize = result.length;
    }
}
