<div class="content-i">
    <div class="content-box">
        <div class="element-wrapper">
            <div class="user-profile">
                <div
                    class="up-head-w"
                    [ngStyle]="{ 'background-image': 'url(' + user.image.secure_url + ')' }"
                >
                    <div class="up-social">
                        <a><i class="os-icon os-icon-twitter"></i></a
                        ><a><i class="os-icon os-icon-facebook"></i></a>
                    </div>
                    <div class="up-main-info">
                        <div class="user-avatar-w">
                            <div class="user-avatar">
                                <ng-container *ngIf="user?.image?.public_id">
                                    <cl-image [public-id]="user?.image?.public_id">
                                        <cl-transformation
                                            width="400"
                                            height="400"
                                            gravity="face"
                                            radius="max"
                                            crop="crop"
                                        >
                                        </cl-transformation>
                                        <cl-transformation width="200" crop="scale">
                                        </cl-transformation>
                                    </cl-image>
                                </ng-container>
                            </div>
                        </div>
                        <h1 class="up-header">{{ user.firstName }} {{ user.lastName }}</h1>
                        <h5 class="up-sub-header">
                            {{ user.designation }}
                        </h5>
                    </div>
                    <svg
                        class="decor"
                        width="842px"
                        height="219px"
                        viewBox="0 0 842 219"
                        preserveAspectRatio="xMaxYMax meet"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                    >
                        <g transform="translate(-381.000000, -362.000000)" fill="#FFFFFF">
                            <path
                                class="decor-path"
                                d="M1223,362 L1223,581 L381,581 C868.912802,575.666667 1149.57947,502.666667 1223,362 Z"
                            ></path>
                        </g>
                    </svg>
                </div>
                <div class="up-controls">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="value-pair">
                                <div class="label">Status:</div>
                                <div class="space-l-5 value badge badge-pill badge-success">
                                    {{ user?.isAdmin ? 'Administrator' : 'Investor' }}
                                </div>
                            </div>
                            <div class="value-pair">
                                <div class="label">Joined:</div>
                                <div class="space-l-5 value">{{ user?.createdAt | date }}</div>
                            </div>
                        </div>
                        <div class="col-lg-6 text-right text-white"></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="up-contents">
            <h5 class="element-header">Profile Statistics</h5>
            <div class="row m-b">
                <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="row">
                        <div class="col-6">
                            <app-dashboard-stats-card
                                [data]="totalInvestmentsCount"
                            ></app-dashboard-stats-card>
                        </div>
                        <div class="col-6">
                            <app-dashboard-stats-card
                                [data]="totalAmountInvested"
                            ></app-dashboard-stats-card>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6">
                            <app-dashboard-stats-card
                                [data]="totalUnApprovedAmount"
                            ></app-dashboard-stats-card>
                        </div>
                        <div class="col-6">
                            <app-dashboard-stats-card
                                [data]="totalApprovedAmount"
                            ></app-dashboard-stats-card>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="element-wrapper">
            <h6 class="element-header">Profile Data</h6>
            <div class="element-box">
                <div class="col-lg-6 col-md-6 col-sm-12">
                    <form>
                        <div class="form-group row">
                            <label class="col-sm-4 text-right"> First Name</label>
                            <div class="col-sm-8">
                                <label class="text-primary">{{ user?.firstName }}</label>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-4 text-right"> Last Name</label>
                            <div class="col-sm-8 text-primary">
                                <label class="">{{ user?.lastName }}</label>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-4 text-right"> Email:</label>
                            <div class="col-sm-8 text-primary">
                                <label class="">{{ user?.email }}</label>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-4 text-right"> Phone:</label>
                            <div class="col-sm-8 text-primary">
                                <label class="">{{ user?.phone }}</label>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-4 text-right">Date: of Birth</label>
                            <div class="col-sm-8 text-primary">
                                <label> {{ user?.dob | date }} </label>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-4 text-right"> Occupation:</label>
                            <div class="col-sm-8 text-primary">
                                <label> {{ user?.designation }} </label>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-4 text-right"> City:</label>
                            <div class="col-sm-8 text-primary">
                                <label> {{ user?.city }}</label>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-sm-4 text-right"> State:</label>
                            <div class="col-sm-8 text-primary">
                                <label> {{ user?.state }}</label>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-4 text-right"> Country:</label>
                            <div class="col-sm-8 text-primary">
                                <label> {{ user?.country }}</label>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-4 text-right"> Address:</label>
                            <div class="col-sm-8 text-primary">
                                <p>{{ user?.address }}</p>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-4 text-right">Preference: </label>
                            <div class="col-sm-8">
                                <label class="text-primary">Allow Profile View?</label>
                                <label
                                    class="space-l-5"
                                    [ngClass]="{
                                        'text-success': user?.preference?.allowProfileView,
                                        'text-danger': !user?.preference?.allowProfileView
                                    }"
                                    >{{ user?.preference?.allowProfileView ? 'Yes' : 'No' }}</label
                                >
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
