<div class="row">
    <div class="col-sm-12">
        <div class="element-wrapper">
            <div class="element-actions"></div>
            <h6 class="element-header text-uppercase letter-space-2">Dashboard</h6>
            <div class="element-content">
                <div class="row">
                    <div class="col-sm-4 col-xxxl-3">
                        <app-dashboard-stats-card
                            [data]="totalAmountInvested"
                        ></app-dashboard-stats-card>
                    </div>
                    <div class="col-sm-4 col-xxxl-3">
                        <app-dashboard-stats-card
                            [data]="propertyInvestedCount"
                        ></app-dashboard-stats-card>
                    </div>
                    <div class="col-sm-4 col-xxxl-3">
                        <app-dashboard-stats-card
                            [data]="activeInvestmentCount"
                        ></app-dashboard-stats-card>
                    </div>
                </div>

                <!-- properties table and chart -->
                <div class="row space-top-25">
                    <div class="col-sm-12 col-xxxl-6">
                        <div class="element-wrapper">
                            <h6 class="element-header text-uppercase letter-space-2">
                                Latest Properties With High ROI
                            </h6>
                            <div class="element-box">
                                <div
                                    *ngIf="latestProperties; else loading"
                                    class="table-responsive"
                                >
                                    <table
                                        class="table table-lightborder table-striped table-hover"
                                    >
                                        <thead>
                                            <tr>
                                                <th class="text-center">Title</th>
                                                <th class="text-center">Photos</th>
                                                <th class="text-center"></th>
                                                <th class="text-center">Status</th>
                                                <th class="text-center">Min Amount</th>
                                                <th class="text-center">Price</th>
                                                <th class="text-center">ROI (%)</th>
                                                <th class="text-center">Link</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let property of latestProperties">
                                                <td class="text-left" [title]="property.title">
                                                    <small>
                                                        {{
                                                            property?.title?.length > 13
                                                                ? (property.title | slice: 0:16) +
                                                                  '..'
                                                                : property.title
                                                        }}</small
                                                    >
                                                </td>
                                                <td>
                                                    <div class="cell-image-list">
                                                        <ng-container
                                                            *ngIf="
                                                                property?.image?.length < 3;
                                                                else propertyImage
                                                            "
                                                        >
                                                            <div
                                                                *ngFor="
                                                                    let image of property?.image
                                                                "
                                                                class="cell-img"
                                                                [ngStyle]="{
                                                                    'background-image':
                                                                        'url(' +
                                                                        image.secure_url +
                                                                        ')'
                                                                }"
                                                                style=""
                                                            ></div>
                                                        </ng-container>
                                                        <ng-template #propertyImage>
                                                            <div
                                                                class="cell-img"
                                                                [ngStyle]="{
                                                                    'background-image':
                                                                        'url(' +
                                                                        (property?.image)[0]
                                                                            .secure_url +
                                                                        ')'
                                                                }"
                                                                style=""
                                                            ></div>
                                                            <div
                                                                class="cell-img"
                                                                [ngStyle]="{
                                                                    'background-image':
                                                                        'url(' +
                                                                        (property?.image)[1]
                                                                            .secure_url +
                                                                        ')'
                                                                }"
                                                                style=""
                                                            ></div>
                                                            <div class="cell-img-more">
                                                                +
                                                                {{ property?.image?.length - 2 }}
                                                                more
                                                            </div>
                                                        </ng-template>
                                                    </div>
                                                </td>
                                                <td></td>
                                                <td class="text-center">
                                                    <div
                                                        class="status-pill"
                                                        [ngClass]="{
                                                            green:
                                                                property.status ===
                                                                'OPEN INVESTMENT',
                                                            red:
                                                                property.status ===
                                                                'CLOSED INVESTMENT'
                                                        }"
                                                        title=""
                                                    ></div>
                                                </td>
                                                <td class="text-center">
                                                    <small class="text-bold">
                                                        {{ property?.minAmount | shortNumber: '₦' }}
                                                    </small>
                                                </td>
                                                <td class="text-center">
                                                    <small class="text-bold text-primary">
                                                        {{ property.price | shortNumber: '₦' }}
                                                    </small>
                                                </td>
                                                <td class="text-center">
                                                    <small class="text-bold">
                                                        {{ property.returnOfInvestment | number }}%
                                                    </small>
                                                </td>
                                                <td class="text-center">
                                                    <small>
                                                        <a
                                                            [routerLink]="
                                                                '/app/property/' +
                                                                onEncode(property.title) +
                                                                '/' +
                                                                property.uud
                                                            "
                                                            class="btn btn-sm btn-primary text-white"
                                                        >
                                                            View Property
                                                        </a>
                                                    </small>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 d-xxxl-none">
                        <div class="element-wrapper">
                            <h6 class="element-header text-uppercase letter-space-2">
                                Your Accumulated ROI
                            </h6>
                            <div class="element-box">
                                <ng-container *ngIf="pieChartData; else loading">
                                    <ng-container *ngIf="pieChartData.data.length; else emptyData">
                                        <div class="el-chart-w">
                                            <div class="el-chart-w">
                                                <app-dashboard-chart-pie
                                                    [data]="pieChartData"
                                                ></app-dashboard-chart-pie>
                                            </div>
                                        </div>
                                        <div class="el-legend condensed">
                                            <h6 class="text-center">My Investment & Returns</h6>
                                            <div class="container table-responsive">
                                                <table
                                                    class="table table-lightborder table-striped"
                                                >
                                                    <thead>
                                                        <tr>
                                                            <th class="text-left">Property</th>
                                                            <th class="text-center">ROI (%)</th>
                                                            <th class="text-center">Duration</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr
                                                            *ngFor="
                                                                let res of pieChartData?.data;
                                                                let i = index
                                                            "
                                                        >
                                                            <td class="text-left">
                                                                <small
                                                                    [title]="
                                                                        pieChartData.property[i]
                                                                            .title
                                                                    "
                                                                >
                                                                    {{
                                                                        pieChartData.property[i]
                                                                            .title
                                                                            | truncateText: 20
                                                                    }}</small
                                                                >
                                                            </td>
                                                            <td class="text-center">
                                                                <small>
                                                                    {{
                                                                        pieChartData.data[i]
                                                                    }}</small
                                                                >
                                                            </td>
                                                            <td class="text-center">
                                                                <small>
                                                                    {{
                                                                        pieChartData.property[i]
                                                                            .duration
                                                                    }}
                                                                    Month(s)</small
                                                                >
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </ng-container>
                                </ng-container>
                            </div>
                        </div>
                    </div>

                    <div class="col-sm-12 d-xxxl-none">
                        <div class="element-wrapper">
                            <h6 class="element-header text-uppercase letter-space-2">
                                Profits You Have Made
                            </h6>
                            <div class="element-box">
                                <div class="el-chart-w">
                                    <ng-container *ngIf="profitsMade; else loading">
                                        <ng-container *ngIf="profitsMade.length; else emptyData">
                                            <app-dashboard-chart-doughnut
                                                [data]="profitsMade"
                                            ></app-dashboard-chart-doughnut>
                                        </ng-container>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-sm-12 d-xxxl-none">
                        <div class="element-wrapper">
                            <h6 class="element-header text-uppercase letter-space-2">
                                People You Have Invested With
                            </h6>

                            <div class="element-box">
                                <div class="element-box-tp">
                                    <ng-container *ngIf="investors; else loading">
                                        <ng-container *ngIf="investors.length; else emptyData">
                                            <ng-container *ngFor="let result of investors">
                                                <small class="element-info-text">
                                                    Shared Property:
                                                    <strong class="text-primary">
                                                        {{
                                                            result.property.title | truncateText: 25
                                                        }}
                                                    </strong>
                                                </small>
                                                <app-dashboard-co-investors
                                                    [coInvestors]="result"
                                                ></app-dashboard-co-investors>
                                            </ng-container>
                                        </ng-container>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ng-template #loading>
    <div class="text-center" style="padding: 13px;">
        <i class="fa fa-spinner fa-spin"></i>
    </div>
</ng-template>
<ng-template #emptyData>
    <div class="el-chart-w space-b-25">
        <h5 class="space-top-10">No record yet</h5>
    </div>
</ng-template>
