import {
    Component,
    ElementRef,
    Input,
    OnDestroy,
    OnInit,
    Renderer2,
    ViewChild,
} from '@angular/core';
import { PropertyPayload } from '@app/common/payload/property.payload';
import Swal from 'sweetalert2';
import { InvestmentPayload } from '@app/common/payload/investment.payload';
import { InvestmentPaymentOptions, InvestmentStatus } from '@app/common/enum/investment.enums';
import { Subscription } from 'rxjs';
import { PropertyService } from '@app/common/service/property.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '@app/common/service/auth.service';
import { InvestmentService } from '@app/common/service/investment.service';
import { User } from '@app/common/payload/user';
import { ShortNumberPipe } from '@app/common/pipes/short-number.pipe';
import { Router } from '@angular/router';
import { UploaderService } from '@app/common/service/uploader.service';

@Component({
    selector: 'app-dashboard-property-detail',
    templateUrl: './dashboard-property-detail.component.html',
    styleUrls: ['./dashboard-property-detail.component.css'],
    providers: [ShortNumberPipe],
})
export class DashboardPropertyDetailComponent implements OnInit, OnDestroy {
    @Input() property: PropertyPayload;
    mainImage: string = '';
    isOpen = true;
    @ViewChild('propertyDetails') propertyDetails: ElementRef;
    imageObject: { image: string; thumbImage: string; alt: string }[] = [];
    currentInvestments: InvestmentPayload[];
    subscription: Subscription = new Subscription();
    user: User;

    constructor(
        private renderer: Renderer2,
        private propertyService: PropertyService,
        private spinner: NgxSpinnerService,
        private toast: ToastrService,
        private authService: AuthService,
        private investmentService: InvestmentService,
        private shortNumberPipe: ShortNumberPipe,
        private router: Router,
        private uploaderService: UploaderService
    ) {}

    ngOnInit(): void {
        this.uploaderService.onPrepareUploader({ tag: 'pop', folder: 'pop' });
        if (this.property) {
            this.mainImage = this.property.image[0].secure_url;
            this.property.image.forEach((im) => {
                let imageObj = { image: '', thumbImage: '', alt: '' };
                imageObj.image = im.secure_url;
                imageObj.thumbImage = im.secure_url;
                imageObj.alt = this.property.title;
                this.imageObject.push(imageObj);
            });
        }
        this.user = this.authService.currentUserValue;

        this.subscription.add(
            this.investmentService.onGetInvestmentByUser(this.user.id).subscribe((result) => {
                if (result.status === 200) {
                    this.currentInvestments = result.body;
                } else {
                    this.toast.error("Couldn't current investments");
                    this.currentInvestments = [];
                }
            })
        );
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    imageClicked(event: number) {
        this.mainImage = this.imageObject[event].image;
    }

    onGetPercentage() {
        return Math.round((this.property.donation / this.property.target) * 100);
    }

    toggle(event: string) {
        switch (event) {
            case 'propertyDetails':
                const ele = this.propertyDetails.nativeElement;
                if (ele.style.opacity == 1) {
                    this.renderer.setStyle(ele, 'visibility', 'hidden');
                    this.renderer.setStyle(ele, 'opacity', 0);
                    this.renderer.setStyle(ele, 'display', 'none');
                } else {
                    this.renderer.setStyle(ele, 'visibility', 'visible');
                    this.renderer.setStyle(ele, 'opacity', 1);
                    this.renderer.setStyle(ele, 'display', 'block');
                }
        }
    }

    //handle property investment on user property view (Invest now button click)
    async onInvestInProperty(property: PropertyPayload) {
        //check duplicate investment
        if (this.investmentService.hasDuplicateInvestment(this.currentInvestments, property)) {
            Swal.fire({
                title: 'Duplicate Investment',
                icon: 'info',
                html: `<small>This property is in your investment list already, <br> Click Goto Investment Button to see the property and upgrade the amount</small>`,
                confirmButtonText: 'Goto Investment',
                cancelButtonText: 'No, Close',
                showCancelButton: true,
            }).then((value) => {
                if (value.isConfirmed) {
                    this.router.navigateByUrl('/app/investment').then();
                }
            });
            return;
        }

        this.router
            .navigateByUrl('/app/invest/' + this.onEncode(property.title) + '/' + property.uud, {
                state: { data: property },
            })
            .then();
    }

    onEncode(title: string) {
        return encodeURI(title);
    }
}
