import { Component, OnInit } from '@angular/core';
import { AuthService } from '@app/common/service/auth.service';

@Component({
    selector: 'app-dashboard-usercard-menu',
    templateUrl: './dashboard-usercard-menu.component.html',
    styleUrls: ['./dashboard-usercard-menu.component.css'],
})
export class DashboardUsercardMenuComponent implements OnInit {
    profileLink: string = '';
    investmentLink: string = '';
    identityLink: string = '';

    constructor(private authService: AuthService) {}

    ngOnInit(): void {
        if (this.authService.currentUserValue.isAdmin) {
            this.profileLink = '/app/profile';
            this.investmentLink = '/app/admin/investments';
            this.identityLink = '/app/admin/identities';
        } else {
            this.profileLink = '/app/profile';
            this.investmentLink = '/app/investment';
            this.identityLink = '/app/identity';
        }
    }

    onSignOut() {
        this.authService.signout();
    }
}
