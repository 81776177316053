import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WebsiteLayoutModule } from './website-layout/website-layout.module';
import { WebsitePagesModule } from '@app/website/website-pages/website-pages.module';
import { WebsiteRoutingModule } from '@app/website/website-routing.module';
import { NgcCookieConsentModule } from 'ngx-cookieconsent';

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        WebsiteRoutingModule,
        WebsiteLayoutModule,
        WebsitePagesModule,
        NgcCookieConsentModule,
    ],
    exports: [WebsiteLayoutModule, WebsitePagesModule],
})
export class WebsiteModule {}
