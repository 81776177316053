import { Injectable } from '@angular/core';
import { environment } from '@environments/environment.prod';
import { HttpClient } from '@angular/common/http';
import {
    AdminDashboardData,
    CoInvestors,
    UserDashboardData,
} from '@app/common/payload/dashboard-stats-card';

@Injectable({
    providedIn: 'root',
})
export class DashboardService {
    uriEndpoint: string = environment.url;

    constructor(private http: HttpClient) {}

    onGetAdminDashboardData() {
        return this.http.get<AdminDashboardData>(`${this.uriEndpoint}/dashboard/admin`, {
            observe: 'response',
        });
    }

    onGetUserDashboardData() {
        return this.http.get<UserDashboardData>(`${this.uriEndpoint}/dashboard/user`, {
            observe: 'response',
        });
    }

    onGetCoInvestorsData(id: number) {
        return this.http.get<CoInvestors[]>(
            `${this.uriEndpoint}/dashboard/user/coinvestors/${id}`,
            {
                observe: 'response',
            }
        );
    }
}
