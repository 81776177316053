import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { environment } from '@environments/environment.prod';
import { DashboardService } from '@app/common/service/dashboard.service';
import { AdminDashboardData, DashboardStatsCard } from '@app/common/payload/dashboard-stats-card';

@Component({
    selector: 'app-admin-dashboard',
    templateUrl: './admin-dashboard.component.html',
    styleUrls: ['./admin-dashboard.component.css'],
})
export class AdminDashboardComponent implements OnInit {
    dashboardData: AdminDashboardData;

    userCountCard: DashboardStatsCard;
    propertyCountCard: DashboardStatsCard;
    investmentAmountCard: DashboardStatsCard;
    pendingInvestmentCount: DashboardStatsCard;

    constructor(private titleService: Title, private dashboardService: DashboardService) {}

    ngOnInit(): void {
        this.titleService.setTitle(`Admin Dashboard - ${environment.appName}`);
        this.dashboardService.onGetAdminDashboardData().subscribe((result) => {
            if (result.status === 200) {
                this.dashboardData = result.body;
                this.userCountCard = {
                    title: 'Total Users',
                    count: this.dashboardData.userCount,
                };

                this.propertyCountCard = {
                    title: 'Properties Count',
                    count: this.dashboardData.propertyData.totalProperty,
                };

                this.investmentAmountCard = {
                    title: 'Total Investment',
                    count: this.dashboardData.propertyData.totalInvestment,
                    isMoney: true,
                };

                this.pendingInvestmentCount = {
                    title: 'Pending Investment',
                    count: this.dashboardData.pendingInvestment,
                    showLink: true,
                    linkText: 'Goto Investments',
                    linkUrl: '/app/admin/investments',
                };
            }
        });
    }
}
