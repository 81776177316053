<ng-container *ngIf="id; else loading">
    <app-dashboard-returns-page
        [props]="{ isAdminView: true, id: id }"
    ></app-dashboard-returns-page>
</ng-container>

<ng-template #loading>
    <div class="text-center space-top-25" style="padding: 13px;">
        <i class="fa fa-spinner fa-spin"></i>
    </div>
</ng-template>
