import { Component, Input, OnInit } from '@angular/core';
import { PropertyPayload } from '@app/common/payload/property.payload';
import { PropertyService } from '@app/common/service/property.service';

@Component({
    selector: 'app-property-section',
    templateUrl: './property-section.component.html',
    styleUrls: ['./property-section.component.css'],
})
export class PropertySectionComponent implements OnInit {
    @Input() data: PropertyPayload[];

    constructor(private propertyService: PropertyService) {}

    ngOnInit() {}

    onGetPercentage(property: PropertyPayload) {
        return this.propertyService.onGetPercentage(property);
    }

    onEncode(title: string) {
        return encodeURI(title);
    }
}
