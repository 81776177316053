import { Component, OnInit } from '@angular/core';
// import { AnimationOptions } from 'ngx-lottie';
import { AnimationItem } from 'lottie-web';

@Component({
    selector: 'app-page-not-found',
    templateUrl: './page-not-found.component.html',
    styleUrls: ['./page-not-found.component.css'],
})
export class PageNotFoundComponent implements OnInit {
    // options: AnimationOptions = {
    //     path: '/assets/shared/graphs/404-animation.json',
    // };

    constructor() {}

    ngOnInit() {}

    animationCreated(animationItem: AnimationItem): void {}
}
