import { ImagePayload } from '@app/common/payload/property.payload';
import { UserPreference } from '@app/common/payload/auth.payload';

export class User {
    id?: number;
    email?: string;
    firstName?: string;
    lastName?: string;
    isVerified?: boolean;
    isAdmin?: boolean;
    accessToken?: string;
    image?: ImagePayload;
    phone?: string;
    address?: string;
    city?: string;
    state?: string;
    country?: string;
    designation?: string;
    dob?: any;
    preference?: UserPreference;
    investments?: any[];
    createdAt?: Date;
}

export interface BankDetails {
    userId?: number;
    id?: number;
    accountNumber?: string;
    accountName?: string;
    bankName?: string;
    activated?: boolean;
}
