<section id="popular-campaigns" class="xs-gray-bg waypoint-tigger xs-section-padding v3">
    <div class="container">
        <div class="xs-section-heading row xs-margin-0">
            <div class="prop-heading-title xs-padding-0 col-xl-9 col-md-9">
                <h2 class="color-navy-blue">Investment Opportunity</h2>
                <p>
                    Join Us today as we make real estate investing more transparent and accessible
                    to individual investors, helping them build wealth for their family and future.
                </p>
            </div>
        </div>
        <div class="row">
            <ng-container *ngIf="data; else loading">
                <div *ngFor="let property of data | slice: 0:6" class="col-lg-4">
                    <div class="xs-box-shadow prop-popular-item xs-bg-white">
                        <div class="prop-item-header">
                            <img
                                *ngIf="property.image[0]?.secure_url"
                                class="prop-image"
                                [src]="(property?.image)[0]?.secure_url"
                                [alt]="property.title"
                            />
                        </div>
                        <div class="prop-item-content xs-content-padding bg-color-white">
                            <a
                                class="d-block color-navy-blue prop-post-title"
                                style="color: #041d57;"
                                >{{ property.title | truncateText: 20 }}</a
                            >
                            <p class="text-black-50" style="color: #0a0b0b;">
                                {{ property.description | truncateText: 40 }}
                            </p>
                            <ul class="xs-list-with-content prop-list-item-content-v2">
                                <li class="xs-pie-chart-v2">
                                    <div class="icon-position-center" style="margin-top: 55px;">
                                        <div class="prop-progress" style="width: 65%;">
                                            <circle-progress
                                                [outerStrokeColor]="'#047bf8'"
                                                [responsive]="true"
                                                [percent]="onGetPercentage(property)"
                                                maxPercent="100"
                                                titleFontSize="30"
                                                titleFontWeight="400"
                                                subtitleFontSize="12"
                                            ></circle-progress>
                                            <span class="property-progress-text"></span>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                            <ul
                                class="xs-list-with-content prop-list-item-content"
                                style="margin-top: 99px;"
                            >
                                <li>{{ property.target | shortNumber }}<span>Target</span></li>
                                <li>{{ property.donation | shortNumber }}<span>Raised</span></li>
                                <li>
                                    {{ property.returnOfInvestment | shortNumber: ' ' }}%<span
                                        >Return <small>(ROI)</small></span
                                    >
                                </li>
                            </ul>

                            <span
                                class="xs-separetor border-separetor xs-separetor-v2 prop-separetor"
                            ></span>
                            <div class="row xs-margin-0">
                                <div class="col-8">
                                    <div class="full-round prop-avatar">
                                        <ng-container *ngIf="property?.user?.image?.public_id">
                                            <cl-image
                                                [public-id]="property?.user?.image?.public_id"
                                            >
                                                <cl-transformation
                                                    width="400"
                                                    height="400"
                                                    gravity="face"
                                                    radius="max"
                                                    crop="crop"
                                                >
                                                </cl-transformation>
                                                <cl-transformation width="200" crop="scale">
                                                </cl-transformation>
                                            </cl-image>
                                        </ng-container>
                                    </div>
                                    <div class="xs-avatar-title text-black-50">
                                        <a
                                            ><span>By</span>{{ property?.user?.firstName }}
                                            {{ property?.user?.lastName }}</a
                                        >
                                    </div>
                                </div>

                                <div class="col-4 text-right">
                                    <span class="categories-links point">
                                        <a
                                            [routerLink]="
                                                '/app/property/' +
                                                onEncode(property.title) +
                                                '/' +
                                                property.uud
                                            "
                                            [state]="{ data: property }"
                                            class="xs-blog-meta-tag green-bg bold color-white xs-border-radius"
                                            rel="category tag"
                                            >View
                                        </a></span
                                    >
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</section>

<ng-template #loading>
    <div class="text-center space-top-25 pad-15 loading">
        <i class="fa fa-spinner fa-spin"></i>
    </div>
</ng-template>
