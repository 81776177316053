<app-website-header></app-website-header>
<div class="space-top-25 ng-carousel">
    <ngb-carousel
        [wrap]="true"
        [animation]="true"
        [showNavigationArrows]="false"
        [interval]="6000"
        *ngIf="welcomeSectionProperties; else loading"
    >
        <ng-template ngbSlide *ngFor="let property of welcomeSectionProperties; let i = index">
            <app-welcome-section [property]="property"></app-welcome-section>
        </ng-template>
    </ngb-carousel>

    <app-intro-section></app-intro-section>

    <app-feature-section [data]="featureSectionProperties"></app-feature-section>

    <app-property-section [data]="propertySectionProperties"></app-property-section>
</div>
<app-website-footer></app-website-footer>

<ng-template #loading>
    <div class="text-center space-top-25 pad-15">
        <i class="fa fa-spinner fa-spin"></i>
    </div>
</ng-template>
