import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-dashboard-empty-anim',
    templateUrl: './dashboard-empty-anim.component.html',
    styleUrls: ['./dashboard-empty-anim.component.css'],
})
export class DashboardEmptyAnimComponent implements OnInit {
    @Input() file: string;
    src: string;

    constructor() {}

    ngOnInit(): void {
        this.file ? (this.src = this.file) : (this.src = '/assets/shared/graphs/no-record.gif');
    }
}
